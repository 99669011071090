import { default as enter_45bank_45accountpUeMPlT79wMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/autonomous-refunds/enter-bank-account.vue?macro=true";
import { default as indexI1P4nCl4h1Meta } from "/opt/atlassian/pipelines/agent/build/pages/account/autonomous-refunds/index.vue?macro=true";
import { default as refunds_45history9mJSL4vyzeMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/autonomous-refunds/refunds-history.vue?macro=true";
import { default as request_45refund9b8JMK8AncMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/autonomous-refunds/request-refund.vue?macro=true";
import { default as enter_45mail_45screenJJBJ54PIW8Meta } from "/opt/atlassian/pipelines/agent/build/pages/account/enter-mail-screen.vue?macro=true";
import { default as favoritesuX90IfJuKIMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/favorites.vue?macro=true";
import { default as indexMyUOJMAwXCMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/index.vue?macro=true";
import { default as link_45accounts_45welcome_45backrxEcf2SCyCMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/link-accounts-welcome-back.vue?macro=true";
import { default as my_45downloadso0hHC3sdRXMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/my-downloads.vue?macro=true";
import { default as my_45questionsijLpO8M2EVMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/my-questions.vue?macro=true";
import { default as my_45reviewsG816WyXmSLMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/my-reviews.vue?macro=true";
import { default as non_45reviewed_45products7d1tNiT7GjMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/non-reviewed-products.vue?macro=true";
import { default as payment_45instructions9PaMK8T0OpMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/[id]/payment-instructions.vue?macro=true";
import { default as indextmUcPdd9rKMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/index.vue?macro=true";
import { default as invoicing1toPH0bQOkMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/invoicing.vue?macro=true";
import { default as cancel_45overdue_45products995hZxRjQKMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/cancel-overdue-products.vue?macro=true";
import { default as cancel_45pending_45report3c7xdbUb2dMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/cancel-pending-report.vue?macro=true";
import { default as package_45statusOfTnzU7o3xMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/package-status.vue?macro=true";
import { default as product_45detailsjMJTcFLxS8Meta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/product-details.vue?macro=true";
import { default as report_45reasonz7eaqmyLZGMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/report-reason.vue?macro=true";
import { default as rm_45add_45evidencemxeqMyO3yXMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-add-evidence.vue?macro=true";
import { default as rm_45describe_45reasonLc7CWp45ZYMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-describe-reason.vue?macro=true";
import { default as rm_45not_45received_45amountSfmM6xT8aMMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-not-received-amount.vue?macro=true";
import { default as rm_45preferred_45actionb0BJujUUYSMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-preferred-action.vue?macro=true";
import { default as rm_45serial_45numberwBGbwqJfIZMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-serial-number.vue?macro=true";
import { default as rm_45success_45screenHvfD0iI5EIMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-success-screen.vue?macro=true";
import { default as rm_45update_45report8ANmkWlXroMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-update-report.vue?macro=true";
import { default as track_45orderlDfn1rYiltMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/orders/track-order.vue?macro=true";
import { default as public_45profilezMuirQjyTEMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/public-profile.vue?macro=true";
import { default as account_45deletionwZde8fFVLaMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/settings/account-deletion.vue?macro=true";
import { default as edit_45profileX32jzc0zv6Meta } from "/opt/atlassian/pipelines/agent/build/pages/account/settings/edit-profile.vue?macro=true";
import { default as indexhsMMLGRlErMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/settings/index.vue?macro=true";
import { default as my_45contributions7WBoqzIoiPMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/settings/my-contributions.vue?macro=true";
import { default as new_45passwordjMye0jb3Y6Meta } from "/opt/atlassian/pipelines/agent/build/pages/account/settings/new-password.vue?macro=true";
import { default as passwordaGvD25pwn3Meta } from "/opt/atlassian/pipelines/agent/build/pages/account/settings/password.vue?macro=true";
import { default as subscriptionsb58JEDaXOrMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/settings/subscriptions.vue?macro=true";
import { default as shipping_45billingOc4wcmIIeJMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/shipping-billing.vue?macro=true";
import { default as sign_45up_45welcomeV46425CiqxMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/sign-up-welcome.vue?macro=true";
import { default as sign_45up5JaaH5WHE4Meta } from "/opt/atlassian/pipelines/agent/build/pages/account/sign-up.vue?macro=true";
import { default as activation_45dataZg2Z9GdTqsMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/warranties/activation-data.vue?macro=true";
import { default as activation_45productxQUcD7VQbjMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/warranties/activation-product.vue?macro=true";
import { default as indexvNg0poEXtwMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/warranties/index.vue?macro=true";
import { default as manage_45infoSHvUOcCzLQMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/warranties/manage-info.vue?macro=true";
import { default as serial_45numberL3BIB9OLQIMeta } from "/opt/atlassian/pipelines/agent/build/pages/account/warranties/serial-number.vue?macro=true";
import { default as af_45configXfteuohGBZMeta } from "/opt/atlassian/pipelines/agent/build/pages/alchemy-force/af-config.vue?macro=true";
import { default as af_45versionsNgTRF57QxWMeta } from "/opt/atlassian/pipelines/agent/build/pages/alchemy-force/af-versions.vue?macro=true";
import { default as indexGvVc3LsSJyMeta } from "/opt/atlassian/pipelines/agent/build/pages/alchemy-force/index.vue?macro=true";
import { default as basketzXbLsUV22bMeta } from "/opt/atlassian/pipelines/agent/build/pages/basket.vue?macro=true";
import { default as _91id_93T2HSTEhDboMeta } from "/opt/atlassian/pipelines/agent/build/pages/brand/[id].vue?macro=true";
import { default as indexyZ08E5pe8SMeta } from "/opt/atlassian/pipelines/agent/build/pages/brands/index.vue?macro=true";
import { default as cataloggULKiQAQErMeta } from "/opt/atlassian/pipelines/agent/build/pages/catalog.vue?macro=true";
import { default as indexRVsCQKmOtbMeta } from "/opt/atlassian/pipelines/agent/build/pages/categories/index.vue?macro=true";
import { default as addressQ3sxz30GGEMeta } from "/opt/atlassian/pipelines/agent/build/pages/checkout-basket/address.vue?macro=true";
import { default as billing6bh6k0Uo72Meta } from "/opt/atlassian/pipelines/agent/build/pages/checkout-basket/billing.vue?macro=true";
import { default as monthly_45payment_45af3243lRtt8rMeta } from "/opt/atlassian/pipelines/agent/build/pages/checkout-basket/monthly-payment-af.vue?macro=true";
import { default as monthly_45paymentI371fbsHsQMeta } from "/opt/atlassian/pipelines/agent/build/pages/checkout-basket/monthly-payment.vue?macro=true";
import { default as order_45confirmationxemIAwbsssMeta } from "/opt/atlassian/pipelines/agent/build/pages/checkout-basket/order-confirmation.vue?macro=true";
import { default as payment_45confirmationU4vvKKPDT6Meta } from "/opt/atlassian/pipelines/agent/build/pages/checkout-basket/payment-confirmation.vue?macro=true";
import { default as paymentq2uWPwFkH7Meta } from "/opt/atlassian/pipelines/agent/build/pages/checkout-basket/payment.vue?macro=true";
import { default as ppcp_45form2bfkpV7bVsMeta } from "/opt/atlassian/pipelines/agent/build/pages/checkout-basket/ppcp-form.vue?macro=true";
import { default as redirectPwxzIFFsVVMeta } from "/opt/atlassian/pipelines/agent/build/pages/checkout-basket/redirect.vue?macro=true";
import { default as verify_45identitygQrt5xDQg0Meta } from "/opt/atlassian/pipelines/agent/build/pages/checkout-basket/verify-identity.vue?macro=true";
import { default as af_45detailsvDLvOw2ascMeta } from "/opt/atlassian/pipelines/agent/build/pages/details/[id]/af-details.vue?macro=true";
import { default as all_45answerswe9LO9Q5fkMeta } from "/opt/atlassian/pipelines/agent/build/pages/details/[id]/all-answers.vue?macro=true";
import { default as all_45questions_45and_45answerseC2YokFyYXMeta } from "/opt/atlassian/pipelines/agent/build/pages/details/[id]/all-questions-and-answers.vue?macro=true";
import { default as all_45reviewsQejx2ZtcNZMeta } from "/opt/atlassian/pipelines/agent/build/pages/details/[id]/all-reviews.vue?macro=true";
import { default as ask_45question5O3YugqAblMeta } from "/opt/atlassian/pipelines/agent/build/pages/details/[id]/ask-question.vue?macro=true";
import { default as galleryzJBN8AWJFOMeta } from "/opt/atlassian/pipelines/agent/build/pages/details/[id]/gallery.vue?macro=true";
import { default as indexxbrPWAJZ1GMeta } from "/opt/atlassian/pipelines/agent/build/pages/details/[id]/index.vue?macro=true";
import { default as specscHUauUwHMqMeta } from "/opt/atlassian/pipelines/agent/build/pages/details/[id]/specs.vue?macro=true";
import { default as write_45answerJXVo7x4AGdMeta } from "/opt/atlassian/pipelines/agent/build/pages/details/[id]/write-answer.vue?macro=true";
import { default as write_45new_45review1omGHi3PsXMeta } from "/opt/atlassian/pipelines/agent/build/pages/details/[id]/write-new-review.vue?macro=true";
import { default as indexOD9t3F2bSJMeta } from "/opt/atlassian/pipelines/agent/build/pages/index.vue?macro=true";
import { default as indexzAhq3S2HCNMeta } from "/opt/atlassian/pipelines/agent/build/pages/menu/index.vue?macro=true";
import { default as cms20dxsodaHPMeta } from "/opt/atlassian/pipelines/agent/build/pages/misc/cms.vue?macro=true";
import { default as connect_45with_45usuHMjtiBTqJMeta } from "/opt/atlassian/pipelines/agent/build/pages/misc/connect-with-us.vue?macro=true";
import { default as faqJE4iLptj7yMeta } from "/opt/atlassian/pipelines/agent/build/pages/misc/faq.vue?macro=true";
import { default as global_45rankingr9PI9LxHthMeta } from "/opt/atlassian/pipelines/agent/build/pages/misc/global-ranking.vue?macro=true";
import { default as information_45centeriNV6P0tazfMeta } from "/opt/atlassian/pipelines/agent/build/pages/misc/information-center.vue?macro=true";
import { default as sacLGypi7FqSBMeta } from "/opt/atlassian/pipelines/agent/build/pages/misc/sac.vue?macro=true";
import { default as shopping_45toolszFDl9xzHdJMeta } from "/opt/atlassian/pipelines/agent/build/pages/misc/shopping-tools.vue?macro=true";
import { default as _91_91q_93_93c7p6sFfcetMeta } from "/opt/atlassian/pipelines/agent/build/pages/search/[[q]].vue?macro=true";
import { default as test1g9xwIZN1aMeta } from "/opt/atlassian/pipelines/agent/build/pages/test.vue?macro=true";
import { default as unavailableuYzJVtHtiTMeta } from "/opt/atlassian/pipelines/agent/build/pages/unavailable.vue?macro=true";
import { default as indexaWD2qWLE0aMeta } from "/opt/atlassian/pipelines/agent/build/pages/viewHistory/index.vue?macro=true";
export default [
  {
    name: enter_45bank_45accountpUeMPlT79wMeta?.name ?? "account-autonomous-refunds-enter-bank-account___es-US",
    path: enter_45bank_45accountpUeMPlT79wMeta?.path ?? "/account/autonomous-refunds/enter-bank-account",
    meta: enter_45bank_45accountpUeMPlT79wMeta || {},
    alias: enter_45bank_45accountpUeMPlT79wMeta?.alias || [],
    redirect: enter_45bank_45accountpUeMPlT79wMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/autonomous-refunds/enter-bank-account.vue").then(m => m.default || m)
  },
  {
    name: indexI1P4nCl4h1Meta?.name ?? "account-autonomous-refunds___es-US",
    path: indexI1P4nCl4h1Meta?.path ?? "/account/autonomous-refunds",
    meta: indexI1P4nCl4h1Meta || {},
    alias: indexI1P4nCl4h1Meta?.alias || [],
    redirect: indexI1P4nCl4h1Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/autonomous-refunds/index.vue").then(m => m.default || m)
  },
  {
    name: refunds_45history9mJSL4vyzeMeta?.name ?? "account-autonomous-refunds-refunds-history___es-US",
    path: refunds_45history9mJSL4vyzeMeta?.path ?? "/account/autonomous-refunds/refunds-history",
    meta: refunds_45history9mJSL4vyzeMeta || {},
    alias: refunds_45history9mJSL4vyzeMeta?.alias || [],
    redirect: refunds_45history9mJSL4vyzeMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/autonomous-refunds/refunds-history.vue").then(m => m.default || m)
  },
  {
    name: request_45refund9b8JMK8AncMeta?.name ?? "account-autonomous-refunds-request-refund___es-US",
    path: request_45refund9b8JMK8AncMeta?.path ?? "/account/autonomous-refunds/request-refund",
    meta: request_45refund9b8JMK8AncMeta || {},
    alias: request_45refund9b8JMK8AncMeta?.alias || [],
    redirect: request_45refund9b8JMK8AncMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/autonomous-refunds/request-refund.vue").then(m => m.default || m)
  },
  {
    name: enter_45mail_45screenJJBJ54PIW8Meta?.name ?? "account-enter-mail-screen___es-US",
    path: enter_45mail_45screenJJBJ54PIW8Meta?.path ?? "/account/enter-mail-screen",
    meta: enter_45mail_45screenJJBJ54PIW8Meta || {},
    alias: enter_45mail_45screenJJBJ54PIW8Meta?.alias || [],
    redirect: enter_45mail_45screenJJBJ54PIW8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/enter-mail-screen.vue").then(m => m.default || m)
  },
  {
    name: favoritesuX90IfJuKIMeta?.name ?? "account-favorites___es-US",
    path: favoritesuX90IfJuKIMeta?.path ?? "/account/favorites",
    meta: favoritesuX90IfJuKIMeta || {},
    alias: favoritesuX90IfJuKIMeta?.alias || [],
    redirect: favoritesuX90IfJuKIMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/favorites.vue").then(m => m.default || m)
  },
  {
    name: indexMyUOJMAwXCMeta?.name ?? "account___es-US",
    path: indexMyUOJMAwXCMeta?.path ?? "/account",
    meta: indexMyUOJMAwXCMeta || {},
    alias: indexMyUOJMAwXCMeta?.alias || [],
    redirect: indexMyUOJMAwXCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/index.vue").then(m => m.default || m)
  },
  {
    name: link_45accounts_45welcome_45backrxEcf2SCyCMeta?.name ?? "account-link-accounts-welcome-back___es-US",
    path: link_45accounts_45welcome_45backrxEcf2SCyCMeta?.path ?? "/account/link-accounts-welcome-back",
    meta: link_45accounts_45welcome_45backrxEcf2SCyCMeta || {},
    alias: link_45accounts_45welcome_45backrxEcf2SCyCMeta?.alias || [],
    redirect: link_45accounts_45welcome_45backrxEcf2SCyCMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/link-accounts-welcome-back.vue").then(m => m.default || m)
  },
  {
    name: my_45downloadso0hHC3sdRXMeta?.name ?? "account-my-downloads___es-US",
    path: my_45downloadso0hHC3sdRXMeta?.path ?? "/account/my-downloads",
    meta: my_45downloadso0hHC3sdRXMeta || {},
    alias: my_45downloadso0hHC3sdRXMeta?.alias || [],
    redirect: my_45downloadso0hHC3sdRXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/my-downloads.vue").then(m => m.default || m)
  },
  {
    name: my_45questionsijLpO8M2EVMeta?.name ?? "account-my-questions___es-US",
    path: my_45questionsijLpO8M2EVMeta?.path ?? "/account/my-questions",
    meta: my_45questionsijLpO8M2EVMeta || {},
    alias: my_45questionsijLpO8M2EVMeta?.alias || [],
    redirect: my_45questionsijLpO8M2EVMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/my-questions.vue").then(m => m.default || m)
  },
  {
    name: my_45reviewsG816WyXmSLMeta?.name ?? "account-my-reviews___es-US",
    path: my_45reviewsG816WyXmSLMeta?.path ?? "/account/my-reviews",
    meta: my_45reviewsG816WyXmSLMeta || {},
    alias: my_45reviewsG816WyXmSLMeta?.alias || [],
    redirect: my_45reviewsG816WyXmSLMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/my-reviews.vue").then(m => m.default || m)
  },
  {
    name: non_45reviewed_45products7d1tNiT7GjMeta?.name ?? "account-non-reviewed-products___es-US",
    path: non_45reviewed_45products7d1tNiT7GjMeta?.path ?? "/account/non-reviewed-products",
    meta: non_45reviewed_45products7d1tNiT7GjMeta || {},
    alias: non_45reviewed_45products7d1tNiT7GjMeta?.alias || [],
    redirect: non_45reviewed_45products7d1tNiT7GjMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/non-reviewed-products.vue").then(m => m.default || m)
  },
  {
    name: payment_45instructions9PaMK8T0OpMeta?.name ?? "account-orders-id-payment-instructions___es-US",
    path: payment_45instructions9PaMK8T0OpMeta?.path ?? "/account/orders/:id()/payment-instructions",
    meta: payment_45instructions9PaMK8T0OpMeta || {},
    alias: payment_45instructions9PaMK8T0OpMeta?.alias || [],
    redirect: payment_45instructions9PaMK8T0OpMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/[id]/payment-instructions.vue").then(m => m.default || m)
  },
  {
    name: indextmUcPdd9rKMeta?.name ?? "account-orders___es-US",
    path: indextmUcPdd9rKMeta?.path ?? "/account/orders",
    meta: indextmUcPdd9rKMeta || {},
    alias: indextmUcPdd9rKMeta?.alias || [],
    redirect: indextmUcPdd9rKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/index.vue").then(m => m.default || m)
  },
  {
    name: invoicing1toPH0bQOkMeta?.name ?? "account-orders-invoicing___es-US",
    path: invoicing1toPH0bQOkMeta?.path ?? "/account/orders/invoicing",
    meta: invoicing1toPH0bQOkMeta || {},
    alias: invoicing1toPH0bQOkMeta?.alias || [],
    redirect: invoicing1toPH0bQOkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/invoicing.vue").then(m => m.default || m)
  },
  {
    name: cancel_45overdue_45products995hZxRjQKMeta?.name ?? "account-orders-returns-module-cancel-overdue-products___es-US",
    path: cancel_45overdue_45products995hZxRjQKMeta?.path ?? "/account/orders/returns-module/cancel-overdue-products",
    meta: cancel_45overdue_45products995hZxRjQKMeta || {},
    alias: cancel_45overdue_45products995hZxRjQKMeta?.alias || [],
    redirect: cancel_45overdue_45products995hZxRjQKMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/cancel-overdue-products.vue").then(m => m.default || m)
  },
  {
    name: cancel_45pending_45report3c7xdbUb2dMeta?.name ?? "account-orders-returns-module-cancel-pending-report___es-US",
    path: cancel_45pending_45report3c7xdbUb2dMeta?.path ?? "/account/orders/returns-module/cancel-pending-report",
    meta: cancel_45pending_45report3c7xdbUb2dMeta || {},
    alias: cancel_45pending_45report3c7xdbUb2dMeta?.alias || [],
    redirect: cancel_45pending_45report3c7xdbUb2dMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/cancel-pending-report.vue").then(m => m.default || m)
  },
  {
    name: package_45statusOfTnzU7o3xMeta?.name ?? "account-orders-returns-module-package-status___es-US",
    path: package_45statusOfTnzU7o3xMeta?.path ?? "/account/orders/returns-module/package-status",
    meta: package_45statusOfTnzU7o3xMeta || {},
    alias: package_45statusOfTnzU7o3xMeta?.alias || [],
    redirect: package_45statusOfTnzU7o3xMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/package-status.vue").then(m => m.default || m)
  },
  {
    name: product_45detailsjMJTcFLxS8Meta?.name ?? "account-orders-returns-module-product-details___es-US",
    path: product_45detailsjMJTcFLxS8Meta?.path ?? "/account/orders/returns-module/product-details",
    meta: product_45detailsjMJTcFLxS8Meta || {},
    alias: product_45detailsjMJTcFLxS8Meta?.alias || [],
    redirect: product_45detailsjMJTcFLxS8Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/product-details.vue").then(m => m.default || m)
  },
  {
    name: report_45reasonz7eaqmyLZGMeta?.name ?? "account-orders-returns-module-report-reason___es-US",
    path: report_45reasonz7eaqmyLZGMeta?.path ?? "/account/orders/returns-module/report-reason",
    meta: report_45reasonz7eaqmyLZGMeta || {},
    alias: report_45reasonz7eaqmyLZGMeta?.alias || [],
    redirect: report_45reasonz7eaqmyLZGMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/report-reason.vue").then(m => m.default || m)
  },
  {
    name: rm_45add_45evidencemxeqMyO3yXMeta?.name ?? "account-orders-returns-module-rm-add-evidence___es-US",
    path: rm_45add_45evidencemxeqMyO3yXMeta?.path ?? "/account/orders/returns-module/rm-add-evidence",
    meta: rm_45add_45evidencemxeqMyO3yXMeta || {},
    alias: rm_45add_45evidencemxeqMyO3yXMeta?.alias || [],
    redirect: rm_45add_45evidencemxeqMyO3yXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-add-evidence.vue").then(m => m.default || m)
  },
  {
    name: rm_45describe_45reasonLc7CWp45ZYMeta?.name ?? "account-orders-returns-module-rm-describe-reason___es-US",
    path: rm_45describe_45reasonLc7CWp45ZYMeta?.path ?? "/account/orders/returns-module/rm-describe-reason",
    meta: rm_45describe_45reasonLc7CWp45ZYMeta || {},
    alias: rm_45describe_45reasonLc7CWp45ZYMeta?.alias || [],
    redirect: rm_45describe_45reasonLc7CWp45ZYMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-describe-reason.vue").then(m => m.default || m)
  },
  {
    name: rm_45not_45received_45amountSfmM6xT8aMMeta?.name ?? "account-orders-returns-module-rm-not-received-amount___es-US",
    path: rm_45not_45received_45amountSfmM6xT8aMMeta?.path ?? "/account/orders/returns-module/rm-not-received-amount",
    meta: rm_45not_45received_45amountSfmM6xT8aMMeta || {},
    alias: rm_45not_45received_45amountSfmM6xT8aMMeta?.alias || [],
    redirect: rm_45not_45received_45amountSfmM6xT8aMMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-not-received-amount.vue").then(m => m.default || m)
  },
  {
    name: rm_45preferred_45actionb0BJujUUYSMeta?.name ?? "account-orders-returns-module-rm-preferred-action___es-US",
    path: rm_45preferred_45actionb0BJujUUYSMeta?.path ?? "/account/orders/returns-module/rm-preferred-action",
    meta: rm_45preferred_45actionb0BJujUUYSMeta || {},
    alias: rm_45preferred_45actionb0BJujUUYSMeta?.alias || [],
    redirect: rm_45preferred_45actionb0BJujUUYSMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-preferred-action.vue").then(m => m.default || m)
  },
  {
    name: rm_45serial_45numberwBGbwqJfIZMeta?.name ?? "account-orders-returns-module-rm-serial-number___es-US",
    path: rm_45serial_45numberwBGbwqJfIZMeta?.path ?? "/account/orders/returns-module/rm-serial-number",
    meta: rm_45serial_45numberwBGbwqJfIZMeta || {},
    alias: rm_45serial_45numberwBGbwqJfIZMeta?.alias || [],
    redirect: rm_45serial_45numberwBGbwqJfIZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-serial-number.vue").then(m => m.default || m)
  },
  {
    name: rm_45success_45screenHvfD0iI5EIMeta?.name ?? "account-orders-returns-module-rm-success-screen___es-US",
    path: rm_45success_45screenHvfD0iI5EIMeta?.path ?? "/account/orders/returns-module/rm-success-screen",
    meta: rm_45success_45screenHvfD0iI5EIMeta || {},
    alias: rm_45success_45screenHvfD0iI5EIMeta?.alias || [],
    redirect: rm_45success_45screenHvfD0iI5EIMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-success-screen.vue").then(m => m.default || m)
  },
  {
    name: rm_45update_45report8ANmkWlXroMeta?.name ?? "account-orders-returns-module-rm-update-report___es-US",
    path: rm_45update_45report8ANmkWlXroMeta?.path ?? "/account/orders/returns-module/rm-update-report",
    meta: rm_45update_45report8ANmkWlXroMeta || {},
    alias: rm_45update_45report8ANmkWlXroMeta?.alias || [],
    redirect: rm_45update_45report8ANmkWlXroMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/returns-module/rm-update-report.vue").then(m => m.default || m)
  },
  {
    name: track_45orderlDfn1rYiltMeta?.name ?? "account-orders-track-order___es-US",
    path: track_45orderlDfn1rYiltMeta?.path ?? "/account/orders/track-order",
    meta: track_45orderlDfn1rYiltMeta || {},
    alias: track_45orderlDfn1rYiltMeta?.alias || [],
    redirect: track_45orderlDfn1rYiltMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/orders/track-order.vue").then(m => m.default || m)
  },
  {
    name: public_45profilezMuirQjyTEMeta?.name ?? "account-public-profile___es-US",
    path: public_45profilezMuirQjyTEMeta?.path ?? "/account/public-profile",
    meta: public_45profilezMuirQjyTEMeta || {},
    alias: public_45profilezMuirQjyTEMeta?.alias || [],
    redirect: public_45profilezMuirQjyTEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/public-profile.vue").then(m => m.default || m)
  },
  {
    name: account_45deletionwZde8fFVLaMeta?.name ?? "account-settings-account-deletion___es-US",
    path: account_45deletionwZde8fFVLaMeta?.path ?? "/account/settings/account-deletion",
    meta: account_45deletionwZde8fFVLaMeta || {},
    alias: account_45deletionwZde8fFVLaMeta?.alias || [],
    redirect: account_45deletionwZde8fFVLaMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/settings/account-deletion.vue").then(m => m.default || m)
  },
  {
    name: edit_45profileX32jzc0zv6Meta?.name ?? "account-settings-edit-profile___es-US",
    path: edit_45profileX32jzc0zv6Meta?.path ?? "/account/settings/edit-profile",
    meta: edit_45profileX32jzc0zv6Meta || {},
    alias: edit_45profileX32jzc0zv6Meta?.alias || [],
    redirect: edit_45profileX32jzc0zv6Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/settings/edit-profile.vue").then(m => m.default || m)
  },
  {
    name: indexhsMMLGRlErMeta?.name ?? "account-settings___es-US",
    path: indexhsMMLGRlErMeta?.path ?? "/account/settings",
    meta: indexhsMMLGRlErMeta || {},
    alias: indexhsMMLGRlErMeta?.alias || [],
    redirect: indexhsMMLGRlErMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/settings/index.vue").then(m => m.default || m)
  },
  {
    name: my_45contributions7WBoqzIoiPMeta?.name ?? "account-settings-my-contributions___es-US",
    path: my_45contributions7WBoqzIoiPMeta?.path ?? "/account/settings/my-contributions",
    meta: my_45contributions7WBoqzIoiPMeta || {},
    alias: my_45contributions7WBoqzIoiPMeta?.alias || [],
    redirect: my_45contributions7WBoqzIoiPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/settings/my-contributions.vue").then(m => m.default || m)
  },
  {
    name: new_45passwordjMye0jb3Y6Meta?.name ?? "account-settings-new-password___es-US",
    path: new_45passwordjMye0jb3Y6Meta?.path ?? "/account/settings/new-password",
    meta: new_45passwordjMye0jb3Y6Meta || {},
    alias: new_45passwordjMye0jb3Y6Meta?.alias || [],
    redirect: new_45passwordjMye0jb3Y6Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/settings/new-password.vue").then(m => m.default || m)
  },
  {
    name: passwordaGvD25pwn3Meta?.name ?? "account-settings-password___es-US",
    path: passwordaGvD25pwn3Meta?.path ?? "/account/settings/password",
    meta: passwordaGvD25pwn3Meta || {},
    alias: passwordaGvD25pwn3Meta?.alias || [],
    redirect: passwordaGvD25pwn3Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/settings/password.vue").then(m => m.default || m)
  },
  {
    name: subscriptionsb58JEDaXOrMeta?.name ?? "account-settings-subscriptions___es-US",
    path: subscriptionsb58JEDaXOrMeta?.path ?? "/account/settings/subscriptions",
    meta: subscriptionsb58JEDaXOrMeta || {},
    alias: subscriptionsb58JEDaXOrMeta?.alias || [],
    redirect: subscriptionsb58JEDaXOrMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/settings/subscriptions.vue").then(m => m.default || m)
  },
  {
    name: shipping_45billingOc4wcmIIeJMeta?.name ?? "account-shipping-billing___es-US",
    path: shipping_45billingOc4wcmIIeJMeta?.path ?? "/account/shipping-billing",
    meta: shipping_45billingOc4wcmIIeJMeta || {},
    alias: shipping_45billingOc4wcmIIeJMeta?.alias || [],
    redirect: shipping_45billingOc4wcmIIeJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/shipping-billing.vue").then(m => m.default || m)
  },
  {
    name: sign_45up_45welcomeV46425CiqxMeta?.name ?? "account-sign-up-welcome___es-US",
    path: sign_45up_45welcomeV46425CiqxMeta?.path ?? "/account/sign-up-welcome",
    meta: sign_45up_45welcomeV46425CiqxMeta || {},
    alias: sign_45up_45welcomeV46425CiqxMeta?.alias || [],
    redirect: sign_45up_45welcomeV46425CiqxMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/sign-up-welcome.vue").then(m => m.default || m)
  },
  {
    name: sign_45up5JaaH5WHE4Meta?.name ?? "account-sign-up___es-US",
    path: sign_45up5JaaH5WHE4Meta?.path ?? "/account/sign-up",
    meta: sign_45up5JaaH5WHE4Meta || {},
    alias: sign_45up5JaaH5WHE4Meta?.alias || [],
    redirect: sign_45up5JaaH5WHE4Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/sign-up.vue").then(m => m.default || m)
  },
  {
    name: activation_45dataZg2Z9GdTqsMeta?.name ?? "account-warranties-activation-data___es-US",
    path: activation_45dataZg2Z9GdTqsMeta?.path ?? "/account/warranties/activation-data",
    meta: activation_45dataZg2Z9GdTqsMeta || {},
    alias: activation_45dataZg2Z9GdTqsMeta?.alias || [],
    redirect: activation_45dataZg2Z9GdTqsMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/warranties/activation-data.vue").then(m => m.default || m)
  },
  {
    name: activation_45productxQUcD7VQbjMeta?.name ?? "account-warranties-activation-product___es-US",
    path: activation_45productxQUcD7VQbjMeta?.path ?? "/account/warranties/activation-product",
    meta: activation_45productxQUcD7VQbjMeta || {},
    alias: activation_45productxQUcD7VQbjMeta?.alias || [],
    redirect: activation_45productxQUcD7VQbjMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/warranties/activation-product.vue").then(m => m.default || m)
  },
  {
    name: indexvNg0poEXtwMeta?.name ?? "account-warranties___es-US",
    path: indexvNg0poEXtwMeta?.path ?? "/account/warranties",
    meta: indexvNg0poEXtwMeta || {},
    alias: indexvNg0poEXtwMeta?.alias || [],
    redirect: indexvNg0poEXtwMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/warranties/index.vue").then(m => m.default || m)
  },
  {
    name: manage_45infoSHvUOcCzLQMeta?.name ?? "account-warranties-manage-info___es-US",
    path: manage_45infoSHvUOcCzLQMeta?.path ?? "/account/warranties/manage-info",
    meta: manage_45infoSHvUOcCzLQMeta || {},
    alias: manage_45infoSHvUOcCzLQMeta?.alias || [],
    redirect: manage_45infoSHvUOcCzLQMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/warranties/manage-info.vue").then(m => m.default || m)
  },
  {
    name: serial_45numberL3BIB9OLQIMeta?.name ?? "account-warranties-serial-number___es-US",
    path: serial_45numberL3BIB9OLQIMeta?.path ?? "/account/warranties/serial-number",
    meta: serial_45numberL3BIB9OLQIMeta || {},
    alias: serial_45numberL3BIB9OLQIMeta?.alias || [],
    redirect: serial_45numberL3BIB9OLQIMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/account/warranties/serial-number.vue").then(m => m.default || m)
  },
  {
    name: af_45configXfteuohGBZMeta?.name ?? "alchemy-force-af-config___es-US",
    path: af_45configXfteuohGBZMeta?.path ?? "/alchemy-force/af-config",
    meta: af_45configXfteuohGBZMeta || {},
    alias: af_45configXfteuohGBZMeta?.alias || [],
    redirect: af_45configXfteuohGBZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/alchemy-force/af-config.vue").then(m => m.default || m)
  },
  {
    name: af_45versionsNgTRF57QxWMeta?.name ?? "alchemy-force-af-versions___es-US",
    path: af_45versionsNgTRF57QxWMeta?.path ?? "/alchemy-force/af-versions",
    meta: af_45versionsNgTRF57QxWMeta || {},
    alias: af_45versionsNgTRF57QxWMeta?.alias || [],
    redirect: af_45versionsNgTRF57QxWMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/alchemy-force/af-versions.vue").then(m => m.default || m)
  },
  {
    name: indexGvVc3LsSJyMeta?.name ?? "alchemy-force___es-US",
    path: indexGvVc3LsSJyMeta?.path ?? "/alchemy-force",
    meta: indexGvVc3LsSJyMeta || {},
    alias: indexGvVc3LsSJyMeta?.alias || [],
    redirect: indexGvVc3LsSJyMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/alchemy-force/index.vue").then(m => m.default || m)
  },
  {
    name: basketzXbLsUV22bMeta?.name ?? "basket___es-US",
    path: basketzXbLsUV22bMeta?.path ?? "/basket",
    meta: basketzXbLsUV22bMeta || {},
    alias: basketzXbLsUV22bMeta?.alias || [],
    redirect: basketzXbLsUV22bMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/basket.vue").then(m => m.default || m)
  },
  {
    name: _91id_93T2HSTEhDboMeta?.name ?? "brand-id___es-US",
    path: _91id_93T2HSTEhDboMeta?.path ?? "/brand/:id()",
    meta: _91id_93T2HSTEhDboMeta || {},
    alias: _91id_93T2HSTEhDboMeta?.alias || [],
    redirect: _91id_93T2HSTEhDboMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/brand/[id].vue").then(m => m.default || m)
  },
  {
    name: indexyZ08E5pe8SMeta?.name ?? "brands___es-US",
    path: indexyZ08E5pe8SMeta?.path ?? "/brands",
    meta: indexyZ08E5pe8SMeta || {},
    alias: indexyZ08E5pe8SMeta?.alias || [],
    redirect: indexyZ08E5pe8SMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/brands/index.vue").then(m => m.default || m)
  },
  {
    name: cataloggULKiQAQErMeta?.name ?? "catalog___es-US",
    path: cataloggULKiQAQErMeta?.path ?? "/catalog",
    meta: cataloggULKiQAQErMeta || {},
    alias: cataloggULKiQAQErMeta?.alias || [],
    redirect: cataloggULKiQAQErMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/catalog.vue").then(m => m.default || m)
  },
  {
    name: indexRVsCQKmOtbMeta?.name ?? "categories___es-US",
    path: indexRVsCQKmOtbMeta?.path ?? "/categories",
    meta: indexRVsCQKmOtbMeta || {},
    alias: indexRVsCQKmOtbMeta?.alias || [],
    redirect: indexRVsCQKmOtbMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/categories/index.vue").then(m => m.default || m)
  },
  {
    name: addressQ3sxz30GGEMeta?.name ?? "checkout-basket-address___es-US",
    path: addressQ3sxz30GGEMeta?.path ?? "/checkout-basket/address",
    meta: addressQ3sxz30GGEMeta || {},
    alias: addressQ3sxz30GGEMeta?.alias || [],
    redirect: addressQ3sxz30GGEMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout-basket/address.vue").then(m => m.default || m)
  },
  {
    name: billing6bh6k0Uo72Meta?.name ?? "checkout-basket-billing___es-US",
    path: billing6bh6k0Uo72Meta?.path ?? "/checkout-basket/billing",
    meta: billing6bh6k0Uo72Meta || {},
    alias: billing6bh6k0Uo72Meta?.alias || [],
    redirect: billing6bh6k0Uo72Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout-basket/billing.vue").then(m => m.default || m)
  },
  {
    name: monthly_45payment_45af3243lRtt8rMeta?.name ?? "checkout-basket-monthly-payment-af___es-US",
    path: monthly_45payment_45af3243lRtt8rMeta?.path ?? "/checkout-basket/monthly-payment-af",
    meta: monthly_45payment_45af3243lRtt8rMeta || {},
    alias: monthly_45payment_45af3243lRtt8rMeta?.alias || [],
    redirect: monthly_45payment_45af3243lRtt8rMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout-basket/monthly-payment-af.vue").then(m => m.default || m)
  },
  {
    name: monthly_45paymentI371fbsHsQMeta?.name ?? "checkout-basket-monthly-payment___es-US",
    path: monthly_45paymentI371fbsHsQMeta?.path ?? "/checkout-basket/monthly-payment",
    meta: monthly_45paymentI371fbsHsQMeta || {},
    alias: monthly_45paymentI371fbsHsQMeta?.alias || [],
    redirect: monthly_45paymentI371fbsHsQMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout-basket/monthly-payment.vue").then(m => m.default || m)
  },
  {
    name: order_45confirmationxemIAwbsssMeta?.name ?? "checkout-basket-order-confirmation___es-US",
    path: order_45confirmationxemIAwbsssMeta?.path ?? "/checkout-basket/order-confirmation",
    meta: order_45confirmationxemIAwbsssMeta || {},
    alias: order_45confirmationxemIAwbsssMeta?.alias || [],
    redirect: order_45confirmationxemIAwbsssMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout-basket/order-confirmation.vue").then(m => m.default || m)
  },
  {
    name: payment_45confirmationU4vvKKPDT6Meta?.name ?? "checkout-basket-payment-confirmation___es-US",
    path: payment_45confirmationU4vvKKPDT6Meta?.path ?? "/checkout-basket/payment-confirmation",
    meta: payment_45confirmationU4vvKKPDT6Meta || {},
    alias: payment_45confirmationU4vvKKPDT6Meta?.alias || [],
    redirect: payment_45confirmationU4vvKKPDT6Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout-basket/payment-confirmation.vue").then(m => m.default || m)
  },
  {
    name: paymentq2uWPwFkH7Meta?.name ?? "checkout-basket-payment___es-US",
    path: paymentq2uWPwFkH7Meta?.path ?? "/checkout-basket/payment",
    meta: paymentq2uWPwFkH7Meta || {},
    alias: paymentq2uWPwFkH7Meta?.alias || [],
    redirect: paymentq2uWPwFkH7Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout-basket/payment.vue").then(m => m.default || m)
  },
  {
    name: ppcp_45form2bfkpV7bVsMeta?.name ?? "checkout-basket-ppcp-form___es-US",
    path: ppcp_45form2bfkpV7bVsMeta?.path ?? "/checkout-basket/ppcp-form",
    meta: ppcp_45form2bfkpV7bVsMeta || {},
    alias: ppcp_45form2bfkpV7bVsMeta?.alias || [],
    redirect: ppcp_45form2bfkpV7bVsMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout-basket/ppcp-form.vue").then(m => m.default || m)
  },
  {
    name: redirectPwxzIFFsVVMeta?.name ?? "checkout-basket-redirect___es-US",
    path: redirectPwxzIFFsVVMeta?.path ?? "/checkout-basket/redirect",
    meta: redirectPwxzIFFsVVMeta || {},
    alias: redirectPwxzIFFsVVMeta?.alias || [],
    redirect: redirectPwxzIFFsVVMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout-basket/redirect.vue").then(m => m.default || m)
  },
  {
    name: verify_45identitygQrt5xDQg0Meta?.name ?? "checkout-basket-verify-identity___es-US",
    path: verify_45identitygQrt5xDQg0Meta?.path ?? "/checkout-basket/verify-identity",
    meta: verify_45identitygQrt5xDQg0Meta || {},
    alias: verify_45identitygQrt5xDQg0Meta?.alias || [],
    redirect: verify_45identitygQrt5xDQg0Meta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/checkout-basket/verify-identity.vue").then(m => m.default || m)
  },
  {
    name: af_45detailsvDLvOw2ascMeta?.name ?? "details-id-af-details___es-US",
    path: af_45detailsvDLvOw2ascMeta?.path ?? "/details/:id()/af-details",
    meta: af_45detailsvDLvOw2ascMeta || {},
    alias: af_45detailsvDLvOw2ascMeta?.alias || [],
    redirect: af_45detailsvDLvOw2ascMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/details/[id]/af-details.vue").then(m => m.default || m)
  },
  {
    name: all_45answerswe9LO9Q5fkMeta?.name ?? "details-id-all-answers___es-US",
    path: all_45answerswe9LO9Q5fkMeta?.path ?? "/details/:id()/all-answers",
    meta: all_45answerswe9LO9Q5fkMeta || {},
    alias: all_45answerswe9LO9Q5fkMeta?.alias || [],
    redirect: all_45answerswe9LO9Q5fkMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/details/[id]/all-answers.vue").then(m => m.default || m)
  },
  {
    name: all_45questions_45and_45answerseC2YokFyYXMeta?.name ?? "details-id-all-questions-and-answers___es-US",
    path: all_45questions_45and_45answerseC2YokFyYXMeta?.path ?? "/details/:id()/all-questions-and-answers",
    meta: all_45questions_45and_45answerseC2YokFyYXMeta || {},
    alias: all_45questions_45and_45answerseC2YokFyYXMeta?.alias || [],
    redirect: all_45questions_45and_45answerseC2YokFyYXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/details/[id]/all-questions-and-answers.vue").then(m => m.default || m)
  },
  {
    name: all_45reviewsQejx2ZtcNZMeta?.name ?? "details-id-all-reviews___es-US",
    path: all_45reviewsQejx2ZtcNZMeta?.path ?? "/details/:id()/all-reviews",
    meta: all_45reviewsQejx2ZtcNZMeta || {},
    alias: all_45reviewsQejx2ZtcNZMeta?.alias || [],
    redirect: all_45reviewsQejx2ZtcNZMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/details/[id]/all-reviews.vue").then(m => m.default || m)
  },
  {
    name: ask_45question5O3YugqAblMeta?.name ?? "details-id-ask-question___es-US",
    path: ask_45question5O3YugqAblMeta?.path ?? "/details/:id()/ask-question",
    meta: ask_45question5O3YugqAblMeta || {},
    alias: ask_45question5O3YugqAblMeta?.alias || [],
    redirect: ask_45question5O3YugqAblMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/details/[id]/ask-question.vue").then(m => m.default || m)
  },
  {
    name: galleryzJBN8AWJFOMeta?.name ?? "details-id-gallery___es-US",
    path: galleryzJBN8AWJFOMeta?.path ?? "/details/:id()/gallery",
    meta: galleryzJBN8AWJFOMeta || {},
    alias: galleryzJBN8AWJFOMeta?.alias || [],
    redirect: galleryzJBN8AWJFOMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/details/[id]/gallery.vue").then(m => m.default || m)
  },
  {
    name: indexxbrPWAJZ1GMeta?.name ?? "details-id___es-US",
    path: indexxbrPWAJZ1GMeta?.path ?? "/details/:id()",
    meta: indexxbrPWAJZ1GMeta || {},
    alias: indexxbrPWAJZ1GMeta?.alias || [],
    redirect: indexxbrPWAJZ1GMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/details/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: specscHUauUwHMqMeta?.name ?? "details-id-specs___es-US",
    path: specscHUauUwHMqMeta?.path ?? "/details/:id()/specs",
    meta: specscHUauUwHMqMeta || {},
    alias: specscHUauUwHMqMeta?.alias || [],
    redirect: specscHUauUwHMqMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/details/[id]/specs.vue").then(m => m.default || m)
  },
  {
    name: write_45answerJXVo7x4AGdMeta?.name ?? "details-id-write-answer___es-US",
    path: write_45answerJXVo7x4AGdMeta?.path ?? "/details/:id()/write-answer",
    meta: write_45answerJXVo7x4AGdMeta || {},
    alias: write_45answerJXVo7x4AGdMeta?.alias || [],
    redirect: write_45answerJXVo7x4AGdMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/details/[id]/write-answer.vue").then(m => m.default || m)
  },
  {
    name: write_45new_45review1omGHi3PsXMeta?.name ?? "details-id-write-new-review___es-US",
    path: write_45new_45review1omGHi3PsXMeta?.path ?? "/details/:id()/write-new-review",
    meta: write_45new_45review1omGHi3PsXMeta || {},
    alias: write_45new_45review1omGHi3PsXMeta?.alias || [],
    redirect: write_45new_45review1omGHi3PsXMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/details/[id]/write-new-review.vue").then(m => m.default || m)
  },
  {
    name: indexOD9t3F2bSJMeta?.name ?? "index___es-US",
    path: indexOD9t3F2bSJMeta?.path ?? "/",
    meta: indexOD9t3F2bSJMeta || {},
    alias: indexOD9t3F2bSJMeta?.alias || [],
    redirect: indexOD9t3F2bSJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexzAhq3S2HCNMeta?.name ?? "menu___es-US",
    path: indexzAhq3S2HCNMeta?.path ?? "/menu",
    meta: indexzAhq3S2HCNMeta || {},
    alias: indexzAhq3S2HCNMeta?.alias || [],
    redirect: indexzAhq3S2HCNMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/menu/index.vue").then(m => m.default || m)
  },
  {
    name: cms20dxsodaHPMeta?.name ?? "misc-cms___es-US",
    path: cms20dxsodaHPMeta?.path ?? "/misc/cms",
    meta: cms20dxsodaHPMeta || {},
    alias: cms20dxsodaHPMeta?.alias || [],
    redirect: cms20dxsodaHPMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/misc/cms.vue").then(m => m.default || m)
  },
  {
    name: connect_45with_45usuHMjtiBTqJMeta?.name ?? "misc-connect-with-us___es-US",
    path: connect_45with_45usuHMjtiBTqJMeta?.path ?? "/misc/connect-with-us",
    meta: connect_45with_45usuHMjtiBTqJMeta || {},
    alias: connect_45with_45usuHMjtiBTqJMeta?.alias || [],
    redirect: connect_45with_45usuHMjtiBTqJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/misc/connect-with-us.vue").then(m => m.default || m)
  },
  {
    name: faqJE4iLptj7yMeta?.name ?? "misc-faq___es-US",
    path: faqJE4iLptj7yMeta?.path ?? "/misc/faq",
    meta: faqJE4iLptj7yMeta || {},
    alias: faqJE4iLptj7yMeta?.alias || [],
    redirect: faqJE4iLptj7yMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/misc/faq.vue").then(m => m.default || m)
  },
  {
    name: global_45rankingr9PI9LxHthMeta?.name ?? "misc-global-ranking___es-US",
    path: global_45rankingr9PI9LxHthMeta?.path ?? "/misc/global-ranking",
    meta: global_45rankingr9PI9LxHthMeta || {},
    alias: global_45rankingr9PI9LxHthMeta?.alias || [],
    redirect: global_45rankingr9PI9LxHthMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/misc/global-ranking.vue").then(m => m.default || m)
  },
  {
    name: information_45centeriNV6P0tazfMeta?.name ?? "misc-information-center___es-US",
    path: information_45centeriNV6P0tazfMeta?.path ?? "/misc/information-center",
    meta: information_45centeriNV6P0tazfMeta || {},
    alias: information_45centeriNV6P0tazfMeta?.alias || [],
    redirect: information_45centeriNV6P0tazfMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/misc/information-center.vue").then(m => m.default || m)
  },
  {
    name: sacLGypi7FqSBMeta?.name ?? "misc-sac___es-US",
    path: sacLGypi7FqSBMeta?.path ?? "/misc/sac",
    meta: sacLGypi7FqSBMeta || {},
    alias: sacLGypi7FqSBMeta?.alias || [],
    redirect: sacLGypi7FqSBMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/misc/sac.vue").then(m => m.default || m)
  },
  {
    name: shopping_45toolszFDl9xzHdJMeta?.name ?? "misc-shopping-tools___es-US",
    path: shopping_45toolszFDl9xzHdJMeta?.path ?? "/misc/shopping-tools",
    meta: shopping_45toolszFDl9xzHdJMeta || {},
    alias: shopping_45toolszFDl9xzHdJMeta?.alias || [],
    redirect: shopping_45toolszFDl9xzHdJMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/misc/shopping-tools.vue").then(m => m.default || m)
  },
  {
    name: _91_91q_93_93c7p6sFfcetMeta?.name ?? "search-q___es-US",
    path: _91_91q_93_93c7p6sFfcetMeta?.path ?? "/search/:q?",
    meta: _91_91q_93_93c7p6sFfcetMeta || {},
    alias: _91_91q_93_93c7p6sFfcetMeta?.alias || [],
    redirect: _91_91q_93_93c7p6sFfcetMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/search/[[q]].vue").then(m => m.default || m)
  },
  {
    name: test1g9xwIZN1aMeta?.name ?? "test___es-US",
    path: test1g9xwIZN1aMeta?.path ?? "/test",
    meta: test1g9xwIZN1aMeta || {},
    alias: test1g9xwIZN1aMeta?.alias || [],
    redirect: test1g9xwIZN1aMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/test.vue").then(m => m.default || m)
  },
  {
    name: unavailableuYzJVtHtiTMeta?.name ?? "unavailable___es-US",
    path: unavailableuYzJVtHtiTMeta?.path ?? "/unavailable",
    meta: unavailableuYzJVtHtiTMeta || {},
    alias: unavailableuYzJVtHtiTMeta?.alias || [],
    redirect: unavailableuYzJVtHtiTMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/unavailable.vue").then(m => m.default || m)
  },
  {
    name: indexaWD2qWLE0aMeta?.name ?? "viewHistory___es-US",
    path: indexaWD2qWLE0aMeta?.path ?? "/viewHistory",
    meta: indexaWD2qWLE0aMeta || {},
    alias: indexaWD2qWLE0aMeta?.alias || [],
    redirect: indexaWD2qWLE0aMeta?.redirect || undefined,
    component: () => import("/opt/atlassian/pipelines/agent/build/pages/viewHistory/index.vue").then(m => m.default || m)
  }
]