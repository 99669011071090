import {
  homeRoute,
  unavailableRoute,
  routes,
} from '~/app/router.options';
import {useParseSeoUrlStore} from '~/stores';
import {useCreateBillingAgreement} from '~/composables/queries/paymentMethods';
import {flatCategories} from '~/composables';
import type {RouteLocationNormalized} from 'vue-router';
import {useCatalogStore, useRouterStore} from '~/stores';
import {tradeTrackerValidParams} from '~/utils';

interface ISeoProps extends Record<string, unknown> {
  cl?: string
  type?: string
  fnc?: string
  canonical?: string
}

const previewParse = async (route: RouteLocationNormalized): Promise<ISeoProps> => {
  if (route.query.cl || route.query.fnc || route.query.type) {
    return {
      ...route.query,
    };
  }

  const parseSeoUrl = useParseSeoUrlStore();
  const nuxtApp = useNuxtApp();
  const AF_CATALOG_ID = '45ea769aaedc434e1bcaeb68e0df1b5c';


  const {data: parse} = await useParseSeoUrl(route.fullPath, route.path, nuxtApp.ssrContext?.event);
  parseSeoUrl.setByUrl(route.fullPath, parse.value);
  // console.log(parse.value);

  // HACK: IT-19461 (Check with Alex to delete this)
  if (parse.value.cl === 'alist' && route.path == '/') {
    return {};
  }

  // HACK: Check with Alex to remove AF_CATALOG_ID
  if (parse.value.cl === 'manufacturerlist' && (parse.value.af || parse.value.mnid == AF_CATALOG_ID)) {
    return {
      ...parse.value,
      cl: 'catalog_af',
    };
  }

  if (parse.value.cl === 'alist') {
    const isParent = flatCategories[parse.value.cnid]?.subCategories?.length;
    if (isParent) {
      return {cl: 'categories_menu'};
    }
  }

  return parse.value;
};

const MAX_REDIRECTS = 5;

export default defineNuxtRouteMiddleware(async (to, from) => {
  // Trade tracker logic
  if (Object.keys(to.query).some((e) => tradeTrackerValidParams.includes(e))) {
    useRouterStore().tradeQueryParams = to.query;
  }
  if (to.fullPath.toLowerCase().includes('bienvenidott')) {
    navigateTo({name: 'https://www.cyberpuerta.mx/', query: to.query}, {external: true});
  }

  const pathIsAmbiguous = to.matched[0].path === '/:link*' || to.path === '/';
  if (!pathIsAmbiguous) {
    return;
  }

  // HACK: Avoid requesting SEO when filters are applied
  // Improvement: Infer seo response when applying a filter
  if (to.name === 'catalog' && from.name === 'catalog' && useCatalogStore().filtersApplied) {
    return;
  }
  if (to.name === 'catalog-af' && from.name === 'catalog-af' && useCatalogStore().filtersApplied) {
    return;
  }

  if (to.fullPath.toLowerCase().includes('/configurador-de-pc')) {
    navigateTo('https://www.cyberpuerta.mx/configurador-de-pc', {external: true});
  }

  if (to.path.endsWith('/') && to.params.link && Array.isArray(to.params.link)) {
    to.params.link.push('');
  }

  const seoProps = await previewParse(to);
  let redirectCount = 0;

  if (seoProps.canonical) {
    const canonical: string = seoProps.canonical.toString().startsWith('/') ?
      seoProps.canonical :
      ('/' + seoProps.canonical);
    const seoPage = seoProps.page || 1;

    if (
      (canonical.toLocaleLowerCase() !== to.path.toLocaleLowerCase() &&
      !seoProps.filter &&
      Number(seoPage) === 1 && seoProps.cl !== 'details') ||
      (!seoProps.url_is_actual && seoProps.cl === 'details')
    ) {
      redirectCount++;

      // Verify if the maximum number of redirects has been exceeded
      if (redirectCount > MAX_REDIRECTS) {
        throw createError({
          statusCode: 500,
          message: 'Too many redirects',
        });
      }

      const nuxtApp = useNuxtApp();
      const {data: redirectParse} = await useParseSeoUrl(canonical, canonical, nuxtApp.ssrContext?.event);
      if (redirectParse.value.canonical) {
        const newCanonical = redirectParse.value.canonical.toString().startsWith('/') ?
          redirectParse.value.canonical :
          '/' + redirectParse.value.canonical;

        // Verify if the new canonical URL is different from the current and previous
        if (newCanonical.toLocaleLowerCase() !== to.path.toLocaleLowerCase() &&
          newCanonical.toLocaleLowerCase() !== canonical.toLocaleLowerCase()
        ) {
          return navigateTo(newCanonical, {
            redirectCode: 301,
          });
        } else {
          // If the new URL is equal to the current or previous, do not redirect
          return;
        }
      }
      return navigateTo(canonical, {
        redirectCode: 301,
      });
    }
  }

  if (seoProps.cl || seoProps.fnc || seoProps.type) {
    const clInput = seoProps.cl as string;
    const typeInput = seoProps.type as string;
    const fncInput = seoProps.fnc as string;

    for (let i = 0; i < routes.length; i++) {
      const route = routes[i];

      const includeCl = route.meta?.routerConfig &&
        route.meta.routerConfig.cls &&
        route.meta.routerConfig.cls.includes(clInput);

      const includeType = route.meta?.routerConfig &&
        route.meta.routerConfig.types &&
        route.meta.routerConfig.types.includes(typeInput);

      const includeFnc = route.meta?.routerConfig &&
        route.meta.routerConfig.fncs &&
        route.meta.routerConfig.fncs.includes(fncInput);

      if (includeCl || includeType || includeFnc) {
        if (route.name === to.name) {
          return;
        }
        const isUnknownRoute = useRouterStore().unknownRoute;
        useRouterStore().unknownRoute = false;
        return {
          name: route.name,
          query: route.meta?.routerConfig?.cleanQuery ? undefined : to.query,
          params: to.params,
          replace: isUnknownRoute ? false : true,
        };
      }
    }
  }

  if (to.query.fnc?.includes('emsaldo')) {
    return {
      name: 'home',
    };
  }

  if (to.query.cl === 'cppaypal_endpoint') {
    if (process.client) {
      if (to.query.fnc === 'success' && to.query.ba_token) {
        await useCreateBillingAgreement(to.query.ba_token.toString());
        // Return to clean route to remove query params and not perform this action on every reload
        return {
          name: 'checkout-confirmation',
        };
      }
    }
  }

  if (to.query.fnc === 'validateCode') {
    if (process.client) {
      // Is an address code?, redirect to checkout confirmation
      if (to.query.addr) {
        return {
          name: 'checkout-confirmation',
          query: {cpcode: to.query.cpcode, action: 'address'},
        };
      }
      /**  If not, it's an account activation
          TODO: Ask backend team to add properly params for this, utm campaigns are defined by marketing,
          It is not a reliable parameter for now we´re receiving registrationmail in utm for first account
          requests and registrationverifycode but if is not the first code sent we cant know if it was in
          checkout or menu/account so we'll always redirect to account for now
      **/
      return {
        name: 'account',
        query: {cpcode: to.query.cpcode, action: 'account'},
      };
    }
  }

  // // Paypal PPCP
  // if (to.path.includes('cl=paypal-ppcp')) {
  //     // Don't remove logs pls
  //     console.log('Intercepting PPCP')
  //     const params = new URL(to.path).searchParams
  //     const ppcpId = params.get('ppcpId')
  //     const ppcpType = params.get('ppcpType')
  //     const ppcpMonth = params.get('ppcpMonth')

  //     // @ts-ignore-next-line
  //     useCheckoutStore().setPPCP({ id: ppcpId, isVaultCard: false, month: ppcpMonth, type: ppcpType })
  //     return false
  // }

  // Any Query param with / is home screen
  if (to.path == '/') {
    if (to.name === homeRoute.name) return;
    return {name: homeRoute.name, query: to.query};
  }
  // Cl from behind but without implementation is unavailable screen
  if (seoProps.cl) {
    if (to.name === unavailableRoute.name) return;
    return {
      name: unavailableRoute.name,
      params: to.params,
      query: to.query,
    };
  }
  // Route without Cl is unknown Screen
  throw createError({
    statusCode: 404,
  });
});
