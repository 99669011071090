<script setup lang="ts">
  import {computed} from 'vue';
  import type {ICpTextVariants, ICpTypeWithModifier} from '~/composables/useCpType';
  import {toCssVariable as toCss} from '~/composables/useCpType';

  /** Used to display text in the shop
   * *  Never use text outside a CpText */
  const props = withDefaults(defineProps<{
    variant: ICpTextVariants
    block?: boolean
    type?: ICpTypeWithModifier
    align?: 'center'|'left'|'right'
    truncateLines?: number,
    extraBold?: boolean;
    tag?: string;
    textDecoration?: 'line-through'|'underline';
  }>(), {
    type: 'neutral-dark',
    block: false,
    align: undefined,
    truncateLines: undefined,
    extraBold: false,
  });
  const toCssVariable = toCss;
  const isGradient = computed(() => (props.type as string).includes('gradient'));

  const componentTag = computed(() => {
    // force tag for SEO proposes
    if (props.tag) {
      return props.tag;
    }

    // Headin-h1-pdp is just a workaround for the PDP, since we needed body-regular styles but H1 for SEO.
    if (props.variant === 'heading-h1' || props.variant === 'heading-h1-pdp') {
      return 'h1';
    }
    if (props.variant === 'heading-h2') {
      return 'h2';
    }
    if (props.variant === 'heading-h3' || props.variant === 'heading-h3-pdp') {
      return 'h3';
    }
    return 'span';
  });
</script>

<template>
    <component
        :is="componentTag"
        class="cp-text"
        :class="{
            [`cp-text--${variant}`]: true,
            'cp-text--is-gradient': isGradient,
            'cp-text--block': block,
            [`cp-text--align-${align}`]: align,
            [`cp-text--extra-bold`]: extraBold,
            'cp-text--truncate-lines': !!truncateLines,
            [`cp-text--text-decoration`]: !!textDecoration,
        }"
    >
        <slot />
    </component>
</template>

<style scoped lang="scss">
  .cp-text {
    color: v-bind("toCssVariable(props.type)");
    text-decoration-color: v-bind("toCssVariable(props.type)");
    &--is-gradient{
      background: v-bind("toCssVariable(props.type)");
      -webkit-background-clip: text;
      background-clip: text;
      color: transparent;
    }
    &--truncate-lines {
      display: -webkit-box;
      -webkit-line-clamp: v-bind(truncateLines);
      -webkit-box-orient: vertical;
      overflow: hidden;
      text-overflow: ellipsis;
    }

    &--align {
      &-center {
        text-align: center;
      }
      &-left {
        text-align: left;
      }
      &-right {
        text-align: right;
      }
    }

    // text variants
    &--heading-h1 {
      font-family: var(--cp-heading-font-family);
      font-weight: var(--cp-bold);
      font-size: var(--cp-h1-size);
      line-height: var(--cp-h1-line-height);
      display: unset;
    }
    &--heading-h1-fake {
      font-family: var(--cp-heading-font-family);
      font-weight: var(--cp-bold);
      font-size: var(--cp-h1-size);
      line-height: var(--cp-h1-line-height);
      display: unset;
    }
    &--heading-h2 {
      font-family: var(--cp-heading-font-family);
      font-weight: var(--cp-bold);
      font-size: var(--cp-h2-size);
      line-height: var(--cp-h2-line-height);
      display: unset;
    }
    &--heading-h3 {
      font-family: var(--cp-heading-font-family);
      font-weight: var(--cp-bold);
      font-size: var(--cp-h3-size);
      line-height: var(--cp-h3-line-height);
      letter-spacing: var(--cp-general-letter-spacing);
      display: unset;
    }
    &--heading-h4 {
      font-family: var(--cp-heading-font-family);
      font-weight: var(--cp-bold);
      font-size: var(--cp-h4-size);
      line-height: var(--cp-h4-line-height);
      display: unset;
    }
    &--heading-h3-fake {
      font-family: var(--cp-heading-font-family);
      font-weight: var(--cp-bold);
      font-size: var(--cp-h3-size);
      line-height: var(--cp-h3-line-height);
      display: unset;
      word-break: break-word;
    }
    /*H3 that looks like a body-regular*/
    &--heading-h3-pdp {
      font-family: var(--cp-heading-font-family);
      font-weight: var(--cp-regular);
      font-size: var(--cp-body-regular-size);
      line-height: var(--cp-body-regular-line-height);
    }
    &--subheading {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-subheading-weight);
      font-size: var(--cp-subheading-size);
      line-height: var(--cp-subheading-line-height);
    }
    &--body-bold {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-body-bold);
      font-size: var(--cp-body-bold-size);
      line-height: var(--cp-body-bold-line-height);
    }
    &--body-link {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-bold);
      font-size: var(--cp-body-link-size);
      line-height: var(--cp-body-link-line-height);
      text-decoration: underline;
      cursor: pointer;
    }
    &--body-regular {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-regular);
      font-size: var(--cp-body-regular-size);
      line-height: var(--cp-body-regular-line-height);
      word-break: break-word;
    }
    &--heading-h1-pdp {
      font-family: var(--cp-heading-font-family);
      font-weight: var(--cp-regular);
      font-size: var(--cp-body-regular-size);
      line-height: var(--cp-body-regular-line-height);
    }
    &--caption-bold {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-caption-bold-weight);
      font-size: var(--cp-caption-bold-size);
      line-height: var(--cp-caption-bold-line-height);
      letter-spacing: var(--cp-caption-letter-spacing);
    }
    &--caption-cart {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-regular);
      font-size: var(--cp-caption-cart-size);
      line-height: var(--cp-caption-cart-line-height);
      letter-spacing: var(--cp-caption-letter-spacing);
    }
    &--caption-regular {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-regular);
      font-size: var(--cp-caption-size);
      line-height: var(--cp-caption-line-height);
      letter-spacing: var(--cp-caption-letter-spacing);
    }
    &--button-regular {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-regular);
      font-size: var(--cp-btn-size);
      line-height: var(--cp-btn-line-height);
    }
    &--button-bold {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-bold);
      font-size: var(--cp-btn-bold-size);
      line-height: var(--cp-btn-bold-line-height);
    }
    &--price-from {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-regular);
      font-size: var(--cp-price-from-size);
      line-height: var(--cp-price-from-line-height);
      text-decoration: line-through;
    }
    &--price-regular {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-regular);
      font-size: var(--cp-price-regular-size);
      line-height: var(--cp-price-regular-line-height);
    }
    &--price-offer {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-bold);
      font-size: var(--cp-price-offer-size);
      line-height: var(--cp-price-offer-line-height);
    }
    &--price-total {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-bold);
      font-size: var(--cp-price-total-size);
      line-height: var(--cp-price-total-line-height);
    }
    &--caption-link {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-bold);
      font-size: var(--cp-caption-bold-size);
      line-height: var(--cp-caption-bold-line-height);
      text-decoration: underline;
      cursor: pointer;
    }
    &--display-1 {
      font-family: var(--cp-heading-font-family);
      font-weight: var(--cp-bold);
      font-size: var(--cp-d1-size-font);
      line-height: var(--cp-d1-line-height);
    }
    &--headline {
      font-family: var(--cp-font-family);
      font-weight: var(--cp-regular);
      font-size: var(--cp-headline-size);
      line-height: var(--cp-headline-line-height);
    }
    &--highlight{
      font-family: var(--cp-font-family);
      font-weight: var(--cp-regular);
      font-size: var(--cp-highlight-size);
      line-height: var(--cp-highlight-line-height);
    }
    &--highlight-bold{
      font-family: var(--cp-font-family);
      font-weight: var(--cp-extra-bold);
      font-size: var(--cp-highlight-bold-size);
      line-height: var(--cp-highlight-bold-line-height);
      letter-spacing: var(--cp-highligh-letter-spacing);
    }
    &--extra-bold{
      font-weight: var(--cp-extra-bold);
    }

    &--block {
      display: block;
    }

    &--text-decoration {
      text-decoration: v-bind("props.textDecoration");
    }
  }
</style>
