import {useCpUser} from '~/composables/useCpUser';
import {QUERY_KEYS} from '~/utils/services/keys';
import {rejectQueryFn} from '~/utils';
import type {
    IPpcpPaymentInfoRequestParams,
} from '~/utils/services/api/checkout/paymentMethods';
import {
    getPayPalBillingAgreements,
    getPaymentMethods,
    validatePaymentMethod,
    createPaypalBillingAgreement,
    getPaypalInstallments,
    getRememberedCards,
    setTransactionContext,
    setPaypalMonthInstallment,
    deletePayPalBillingAgreements,
    getPaypalPpcpPaymentInfo,
    getPaypalPaymentSettings,
    getPaypalInstallmentsMSI,
    deleteRememberedCard,
    getPaypalFraudnetInfo,
    getRememberedCardById,
} from '~/utils/services/api/checkout/paymentMethods';
import {useCheckoutStore} from '~/stores';

export const usePaymentBillingAgreement = () => {
    const {userEmail} = useCpUser();
    return useAsyncData(QUERY_KEYS.account.paypalBillingAgreements, () => {
        if (!userEmail) {
            return rejectQueryFn();
        } else {
            return getPayPalBillingAgreements();
        }
    });
};

export const useDeletePaypalBillingAgreement = (agreementId: string) => {
    const {userEmail} = useCpUser();
    return useAsyncData(QUERY_KEYS.account.deletePaypalBillingAgreements, () => {
        if (userEmail === undefined) {
            return rejectQueryFn();
        } else {
            return deletePayPalBillingAgreements(userEmail, agreementId);
        }
    });
};

export const usePaymentMethods = () => {
    return useAsyncData(QUERY_KEYS.checkout.paymentMethods, () => getPaymentMethods());
};

export const useGetRememberedCards = (metaId: string) => {
    return useAsyncData(QUERY_KEYS.paypal.paypalRememberedCards, () => {
        return useCpUser().userEmail || useCheckoutStore().customEmail ?
            getRememberedCards(
                useCheckoutStore().customEmail || useCpUser().userEmail!,
                metaId,
            ) : rejectQueryFn();
});
};

export const useGetRememberedCardById = (cardId: string, metaId: string) => {
    return useAsyncData(QUERY_KEYS.paypal.paypalRememberedCardById(cardId), () => {
        return useCpUser().userEmail || useCheckoutStore().customEmail ?
            getRememberedCardById(
                useCheckoutStore().customEmail || useCpUser().userEmail!,
                metaId,
                cardId,
            ) : rejectQueryFn();
});
};

export const useDeleteRememberedCard = (id: string, metaId: string) => {
    return useAsyncData(QUERY_KEYS.paypal.paypalDeleteRememberedCard(id), () => {
        return useCpUser().userEmail || useCheckoutStore().customEmail ?
            deleteRememberedCard(
                useCheckoutStore().customEmail || useCpUser().userEmail!,
                id,
                metaId,
            ) : rejectQueryFn();
    });
};

export const useCreateBillingAgreement = (token: string) => {
    return useAsyncData(QUERY_KEYS.account.paypalBillingAgreements, () => createPaypalBillingAgreement(token));
};

export const useValidatePaymentMethod = (paypalCallbackUrl?: string) => {
    return useAsyncData(QUERY_KEYS.checkout.validatePaymentMethod, () => validatePaymentMethod(paypalCallbackUrl));
};

export const useGetPaypalInstallments = (metaId: string, cardId?: string) => useAsyncData(QUERY_KEYS.paypal.paypalInstallments,
    () => getPaypalInstallments(metaId, cardId));

export const useSetPaypalMonthInstallment = (month: number, metaId: string) =>
    useAsyncData(QUERY_KEYS.paypal.paypalInstallments, () => setPaypalMonthInstallment(month, metaId));

export const useSetTransactionContext = (metaId: string) =>
    useAsyncData(QUERY_KEYS.checkout.transactionContext, () => setTransactionContext(metaId));

export const useGetPaypalPpcpPaymentInfo = (orderId: string, metaId: string) =>
    useAsyncData(QUERY_KEYS.checkout.ppcpPaymentInfo(orderId), () => getPaypalPpcpPaymentInfo(orderId, metaId));

export const useGetPaypalPaymentSettings = (metaId: string) => useAsyncData(QUERY_KEYS.paypal.paypalPaymentSettings,
  () => getPaypalPaymentSettings(metaId));

export const useGetPaypalFraudnetInfo = () => useAsyncData(QUERY_KEYS.paypal.paypalPaymentFraudnetInfo,
    () => getPaypalFraudnetInfo());

export const useGetPaypalInstallmentsMSI = (total: number, isCheckout = false) =>
    useAsyncData(QUERY_KEYS.paypal.installmentsMSI, () => getPaypalInstallmentsMSI(total, isCheckout));
