import {defineStore} from 'pinia';
import {STORE_KEYS} from '~/stores/storeKeys';
import type {
  CatalogType, FilterStatus, IAppliedFilterType, ICatalog, ICheckboxItem, IDropdownTwoLevelsModelFilterData,
  IElementAttributes, IFilters, IPFCheckBox, ISpecialLink, ISpecialLinkCard,
} from '~/types';
import {useBannerPlace} from '~/composables/queries/banner';
import {useAFCatalog, useAFCatalogFilter, useAFCatalogArticles, type OrderCatalogTypes}
  from '~/composables/queries/useCatalog';
import type {RangeType} from '~/types';
import {useRecentQueriesStore} from '~/stores/recentQueriesStore';
import type {IHomeSchema, IOffersWidget} from '~/utils/services/api';
import {homePaths} from '~/utils/services/paths';
import {getPrettyPageLinks} from '~/utils/helpers/routes.helpers';

interface ICatalogAFStore {
  af: boolean
  id: string
  type: CatalogType
  page: number
  metaRobots: string | undefined
  /** Number of items per page */
  pageSize: number;
  /** Indicates if the initial page is two or later, which disables infinite scroll */
  isInitialPageTwoOrLater?: boolean;
  orderBy: OrderCatalogTypes
  catalogData?: ICatalog
  filterData?: IFilters
  articles: any[]
  showSideBarFilter: boolean
  displayAs: 'grid' | 'list'
  loading: boolean
  filtersApplied: boolean
  status: string[]
  ranking: number[]
  price: RangeType
  search: string
  categories: string[]
  man: string[]
  bannersData?: any
  ckList: Record<string, string[]>
  isDailyOffers: boolean
  maxDiscount?: string
  maxSalePercent?: string
  loadingProducts: boolean
  showMore: boolean
  articlesIdsBacklog: string[]
  breadcrumb: BreadCrumbItem[]
  msku?: string
  /** Link to the previous page */
  previousPageLink?: string;
  /** Link to the next page */
  nextPageLink?: string;
  /** Whether there was an error loading products */
  productLoadingError?: boolean;
}

interface PriceFilter {
  'cpsp[pricemin]'?: number;
  'cpsp[pricemax]'?: number;
}

const mapLink = (link: string) => {
  const queryPos = link.indexOf('?');
  if (queryPos !== -1) {
    const lastCharParamsPos = queryPos - 1;
    return link[lastCharParamsPos] === '/' ? link : link.replace('?', '/?');
  }
  if (link.endsWith('/')) {
    return link;
  }
  return link + '/';
};

const DEFAULT_SORT = 'cprelevance-desc';
const ARTICLES_BY_PAGE = 24;
const OUTLET_ID = '60b9457209fc8';
const OFFERS_ID = 'emsalearticle_startdetail';

const FILTER_LABELS = {
  home: 'Inicio',
  status: 'Estatus',
  search: 'Búsqueda',
  ranking: 'Opiniones',
  star: ' estrella',
  stars: ' estrellas',
  from: 'Desde $',
  to: 'Hasta $',
  price: 'Precio',
  categories: 'Categorías',
  man: 'Marcas',
  byBrands: 'Por marca',
  // eslint-disable-next-line max-len
  afDesc: 'Venta en línea de PCs Gamer con Alchemy Force. Arma tu PC Gamer con componentes de marcas líderes como Intel, NVIDIA, AMD y mucho más',
  afSiteName: 'Alchemy Force ¡Las Mejores PCs Gamer! | Cyberpuerta.mx',
  mskuTitle: 'Búsqueda Especial',
  mskuFilter: 'Microsoft Precio especial',
};

export const useCatalogStore = defineStore(STORE_KEYS.CatalogAF, {
  state: (): ICatalogAFStore => ({
    af: false,
    id: '',
    type: 'man',
    pageSize: 24,
    page: 0,
    orderBy: DEFAULT_SORT,
    articles: [],
    showSideBarFilter: false,
    displayAs: 'list',
    loading: false,
    filtersApplied: false,
    status: [],
    ranking: [],
    price: {},
    search: '',
    categories: [],
    man: [],
    ckList: {},
    isDailyOffers: false,
    loadingProducts: false,
    showMore: false,
    articlesIdsBacklog: [],
    breadcrumb: [],
    metaRobots: undefined,
  }),
  actions: {
    async init(af = false) {
      this.loading = true;
      this.af = af;

      if (!this.filtersApplied) {
        this.articlesIdsBacklog = [];
        this.articles = [];
        await this.initValues();
        const catalogResponse = await useAFCatalog(this.id, this.type, this.page);
        this.catalogData = catalogResponse.data.value as ICatalog;

        const promises = [];
        promises.push(this.generateBreadCrumbs());

        if (this.catalogData.isParent) {
          this.filtersApplied = false;
          this.loading = false;

          const [breadCrumbs] = await Promise.all(promises);
          this.breadcrumb = breadCrumbs as BreadCrumbItem[];
          return;
        }

        if (this.id === OFFERS_ID) {
          this.isDailyOffers = true;
          promises.push(this.loadFeaturedCounter());
        } else {
          this.isDailyOffers = false;
          promises.push(this.loadBanners());
        }
        promises.push(this.loadMoreProducts(true, this.page));

        const [breadCrumbs] = await Promise.all(promises);
        this.breadcrumb = breadCrumbs as BreadCrumbItem[];
      }

      if (this.type === 'search' && !this.msku) {
        const recentQueries = useRecentQueriesStore();
        recentQueries.selectedQuery = this.catalogData?.id ?? '';
      }

      this.filtersApplied = false;
      this.loading = false;
    },
    parseCpspByQueryParams(url: any) {
      const params = new URLSearchParams(url.split('?')[1]);
      const outputObj: any = {};
      for (const [key, value] of params) {
        const match = key.match(/^cpsp\[(.*?)\]/);
        if (match) {
          const paramKey = match[1];
          if (paramKey === 'man' || paramKey === 'ranking') {
            if (!outputObj[paramKey]) outputObj[paramKey] = [];
            outputObj[paramKey.toLowerCase()].push(value);
          } else {
            outputObj[paramKey.toLowerCase()] = value;
          }
        }
      }

      return outputObj;
    },
    async getParseSeo() {
      const route = useRoute();
      const linkMap = mapLink(route.fullPath);
      if (route.query.cl === 'search') {
        const r = {
          cl: route.query.cl,
          searchparam: route.query.searchparam,
          cpsp: this.parseCpspByQueryParams(route.fullPath),
        };
        return r;
      }
      const {data: parseSeo, error: parseSeoError} = await useParseSeoUrl(linkMap);

      if (parseSeoError.value || (parseSeo.value && !this.parseCl(parseSeo.value.cl))) {
        throw createError({statusCode: 404});
      }


      return parseSeo.value;
    },
    async initValues() {
      const route = useRoute();
      const parseSeoValue = await this.getParseSeo();
      const type = this.parseCl(parseSeoValue.cl);
      if (!type) {
        throw createError({statusCode: 404});
      }

      this.id = parseSeoValue.mnid ?? parseSeoValue.cnid ?? parseSeoValue.searchparam ?? 'Promoción';
      this.type = type;
      this.page = 0;
      this.pageSize = 24;
      this.articles = [];
      this.ckList = {};
      this.bannersData = undefined;
      this.maxDiscount = undefined;
      this.maxSalePercent = undefined;
      this.metaRobots = parseSeoValue.meta_robots;

      if (this.type === 'search') {
        const recentQueries = useRecentQueriesStore();
        route.meta.menuSelected = null;
        if (!parseSeoValue.msku) {
          recentQueries.selectedQuery = parseSeoValue.searchparam;
        }
      } else {
        route.meta.menuSelected = 'categories';
      }

      if (route.meta.navHeaderOptions) {
        const routeProps: any = route.meta.navHeaderOptions;
        routeProps.showLongSearchBar = this.type === 'search';
        routeProps.blueVersion = this.type === 'search';
        routeProps.searchFocus = this.type !== 'search';
        routeProps.showSearch = this.type !== 'search';
      }

      // Init filters
      // Order by
      if (route.query?.listorderby && route.query?.listorder) {
        this.orderBy = route.query.listorderby + '-' + route.query.listorder as OrderCatalogTypes;
      } else {
        this.orderBy = DEFAULT_SORT;
      }

      // pagination
      const seoPage = (Number(parseSeoValue.page) - 1) || 0;
      this.page = seoPage;
      this.isInitialPageTwoOrLater = seoPage >= 1;

      // msku
      this.msku = parseSeoValue.msku;

      if (parseSeoValue.cpsp) {
        // Status
        const noStatusFilters = ['pricemax', 'pricemin', 'cklist', 'cat', 'search', 'ranking', 'man', 'msku'];
        this.status = Object.keys(parseSeoValue.cpsp)
          .filter((key) => !noStatusFilters.includes(key))
          .map((key) => `cpsp[${key}]`);
        // Search
        this.search = parseSeoValue.cpsp?.search ?? '';
        // Ranking
        this.ranking = parseSeoValue.cpsp?.ranking?.map((r: string) => parseInt(r)) ?? [];
        // Price
        this.price = {
          max: parseSeoValue.cpsp?.pricemax ? parseInt(parseSeoValue.cpsp?.pricemax) : undefined,
          min: parseSeoValue.cpsp?.pricemin ? parseInt(parseSeoValue.cpsp?.pricemin) : undefined,
        };
        // Categories
        this.categories = parseSeoValue.cpsp?.cat ?? [];
        // Man
        this.man = parseSeoValue.cpsp?.man ?? [];
        // CkList
        this.ckList = parseSeoValue.cpsp?.cklist ?? {};
        // msku
        this.msku = parseSeoValue.cpsp.msku ?? this.msku;
      } else {
        this.search = '';
        this.status = [];
        this.ranking = [];
        this.price = {max: undefined, min: undefined};
        this.categories = [];
        this.man = [];
        this.ckList = {};
      }
    },
    parseCl(clSeo: string) {
      switch (clSeo) {
        case 'manufacturerlist':
          return 'man';
        case 'alist':
          return 'cat';
        case 'search':
          return 'search';
      }
    },
    async applyFilters(showSidebar = true, isOrderBy = false) {
      this.loading = true;
      this.filtersApplied = true;
      this.articles = [];
      this.articlesIdsBacklog = [];
      this.page = 0;
      this.showMore = true;
      this.isInitialPageTwoOrLater = false;

      await this.loadMoreProducts();
      await navigateTo(this.getLink());
      this.showSideBarFilter = !!showSidebar;
      if (isOrderBy) {
        this.filtersApplied = false;
      }
      this.loading = false;
    },
    async loadMoreProducts(initial = true, initialPage = 0) {
      if (initial) {
        this.page = initialPage;
      }
      await this.getDataPage(initial);
    },
    async getDataPage(initial = true) {
      this.loadingProducts = true;
      const filters = this.mapFilterToFetch();

      if (this.articlesIdsBacklog.length === 0) {
        if (!initial) {
          this.page++;
        }
        const filterResponse = await useAFCatalogFilter(this.id, this.type, filters, this.orderBy, this.page, this.af, this.pageSize);
        this.filterData = filterResponse.data.value as IFilters;

        if (filterResponse.error.value) {
          this.showMore = false;
          this.productLoadingError = true;
          this.loadingProducts = false;
          return;
        }
        this.productLoadingError = false;

        if (this.filterData && this.filterData.articleIds && this.filterData.articleIds.length) {
          this.articlesIdsBacklog.push(...this.filterData.articleIds);
          this.showMore = true;
        } else {
          this.showMore = false;
        }

        const [, urlWithPage] = this.filterData?.urls ?? [];
        const [previousPrettyLink, nextPrettyLink] = getPrettyPageLinks(urlWithPage ?? '', this.filterData.total, this.pageSize);
        this.previousPageLink = previousPrettyLink;
        this.nextPageLink = nextPrettyLink;
      }

      if (this.showMore) {
        const articlesChunk = this.articlesIdsBacklog.slice(0, ARTICLES_BY_PAGE);
        this.articlesIdsBacklog = this.articlesIdsBacklog.slice(ARTICLES_BY_PAGE);
        const articlesResponse = await useAFCatalogArticles(articlesChunk, this.af);
        const newArticles = articlesResponse.data.value?.articles ?? [];
        this.articles.push(...newArticles);
      }
      this.loadingProducts = false;
    },
    removeAllFilters() {
      this.status = [];
      this.ranking = [];
      this.price = {};
      this.search = '';
      this.categories = [];
      this.man = [];
      this.ckList = {};
      this.showMore = true;
      this.metaRobots = undefined;
      return this.applyFilters();
    },
    getLink() {
      const link = this.filterData?.urls[0] ?? '';
      let query = '';
      if (this.orderBy && this.orderBy !== DEFAULT_SORT) {
        const orderType = this.orderBy.split('-');
        query = `?listorderby=${orderType[0]}&listorder=${orderType[1]}`;
      }
      return useCpNavigateToParams(this.af ? 'catalog-af' : 'catalog', link + query);
    },
    mapFilterToFetch() {
      let msku = {};
      if (this.msku) {
        msku = {
          [`cpsp[msku]`]: this.msku,
        };
      }

      return {
        ...this.mapStatus(),
        ...this.mapSearch(),
        ...this.mapRanking(),
        ...this.mapPrice(),
        ...this.mapCategories(),
        ...this.mapMan(),
        ...this.mapCkList(),
        ...msku,
      };
    },
    mapStatus() {
      return this.status.reduce((prev: Record<string, number>, current) => {
        prev[current] = 1;
        return prev;
      }, {});
    },
    mapSearch() {
      if (this.search && this.search.length) {
        return {'cpsp[search]': this.search};
      }
      return {};
    },
    mapRanking() {
      return this.ranking.reduce((prev, current) => {
        prev[`cpsp[ranking][${Object.keys(prev).length}]`] = current;
        return prev;
      }, {} as Record<string, unknown>);
    },
    mapPrice(): PriceFilter {
      const priceFilter: PriceFilter = {};
      if (this.price.min) {
        priceFilter['cpsp[pricemin]'] = this.price.min;
      }
      if (this.price.max) {
        priceFilter['cpsp[pricemax]'] = this.price.max;
      }

      return priceFilter;
    },
    mapCategories() {
      if (this.categories && this.categories.length) {
        return {'cpsp[cat][]': this.categories};
      }
      return {};
    },
    mapMan() {
      if (this.man && this.man.length) {
        return {'cpsp[man][]': this.man};
      }
      return {};
    },
    mapCkList() {
      if (this.ckList && Object.keys(this.ckList).length) {
        const all = Object.keys(this.ckList).map((key: string) => {
          return {[`cpsp[cklist][${key}][]`]: this.ckList[key]};
        });

        let ckListAll = {};
        for (let i = 0; i < all.length; i++) {
          const element = all[i];
          ckListAll = {
            ...ckListAll,
            ...element,
          };
        }

        return ckListAll;
      }
      return {};
    },
    async loadBanners() {
      try {
        if (!this.catalogData || !this.catalogData.bannerContext) {
          return;
        }
        const controllerId: BannerController = this.type === 'cat' ?
          'alist' :
          this.type === 'man' ?
            'manufacturerlist' :
            'search';

        const {data: bannersResponse} = await useBannerPlace({
          placeId: 'list-main',
          controllerId,
          context: this.catalogData.bannerContext,
        });

        this.bannersData = bannersResponse.value?.data;
      } catch { }
    },
    async loadFeaturedCounter() {
      try {
        type Discount = { maxDiscount: string, maxSalePercent: string };
        const {maxDiscount, maxSalePercent} = await $cpFetch<Discount>(homePaths.home, {
          parseResponse: (text) => {
            const data = JSON.parse(text) as IHomeSchema;
            const offers = data.widgets.find((a) => a.type === 'offers') as IOffersWidget;
            return {maxDiscount: offers?.saleData.maxDiscount, maxSalePercent: offers?.saleData.maxSalePercent};
          },
        });
        this.maxDiscount = maxDiscount;
        this.maxSalePercent = maxSalePercent;
      } catch { }
    },
    async generateBreadCrumbs(): Promise<BreadCrumbItem[]> {
      const HOME: BreadCrumbItem = {label: FILTER_LABELS.home, value: {name: 'home'}};
      if (this.type === 'man') {
        return [
          HOME,
          {label: FILTER_LABELS.byBrands, value: {name: 'brands'}},
          {label: this.catalogData?.title ?? '', value: null},
        ];
      } else if (this.type === 'search') {
        return [
          HOME,
          {label: FILTER_LABELS.search, value: null},
        ];
      }
      const categoryUUID = this.catalogData?.id ?? '';

      if (categoryUUID === OFFERS_ID || categoryUUID === OUTLET_ID) {
        return [
          HOME,
          {label: this.catalogData?.title ?? '', value: null},
        ];
      }

      const categoriesResponse = await useFlatCategories();

      const transformData = (data: Record<string, { title: string, parentId: string, link: string }>): BreadCrumbItem[] => {
        const current = data[categoryUUID];
        if (!current) {
          return [];
        }
        const parentCategoriesTransform: BreadCrumbItem[] = [{
          label: current.title,
          value: null,
        }];
        let findParentId = current.parentId;

        while (findParentId && findParentId.length) {
          const parent = data[findParentId];
          if (parent) {
            parentCategoriesTransform.push({
              label: parent.title,
              value: parent.link, // .replace('https://www.cyberpuerta.mx', ''),
            });
          }
          findParentId = parent.parentId;
        }
        return parentCategoriesTransform;
      };

      const categoryTransform = transformData(categoriesResponse.data.value);

      if (!categoryTransform || categoryTransform.length === 0) {
        return [
          HOME,
          {label: this.catalogData?.title ?? '', value: null},
        ];
      }

      categoryTransform.push(HOME);
      this.breadcrumb = categoryTransform.reverse();
      return this.breadcrumb;
    },
  },
  getters: {
    featuredCategories(state): { id: string, title: string, message: string, imageUrl: string }[] {
      return state.catalogData?.featuredCategories?.map((a) => ({
        id: a.title,
        title: a.title,
        message: a.title,
        imageUrl: a.image,
      })) ?? [];
    },
    specialLinks(state): ISpecialLinkCard[] {
      return state.catalogData?.specialLinks?.map((a: ISpecialLink) => {
        return {
          id: a.id,
          title: a.title,
          text: a.text ?? '',
          image: a.image,
          url: a.url,
          selected: a.url.endsWith(this.filterData?.urls[0] ?? '') ||
            (a.url + '/').endsWith(this.filterData?.urls[0] + ''),
        };
      }) ?? [];
    },
    filterList(state): unknown[] {
      return state.catalogData?.filters?.filterElements ?? [];
    },
    filterCount(state): number {
      const statusCount = state.status.length;
      const searchCount = state.search && state.search.length ? 1 : 0;
      const rankingCount = state.ranking.length;
      const priceCount = (state.price.min ? 1 : 0) + (state.price.max ? 1 : 0);
      const categoriesCount = state.categories.length;
      const manCount = state.man.length;
      const ckListCount = Object.keys(state.ckList)
        .reduce((prev: number, current: string) => prev + state.ckList[current].length, 0);
      const mskuCount = !!this.msku ? 1 : 0;

      return statusCount + searchCount + rankingCount + priceCount +
        categoriesCount + ckListCount + manCount + mskuCount;
    },
    appliedFilters(state): IAppliedFilterType[] {
      const appliedFiltersList: IAppliedFilterType[] = [];
      if (state.status.length > 0) {
        const statusFilter = state.catalogData?.filters?.filterElements.find((filter) => filter.id === 'statuses');

        if (statusFilter) {
          appliedFiltersList.push({
            value: 'status',
            label: FILTER_LABELS.status,
            appliedFilters: statusFilter.checkBoxes
              .filter((item: { name: string }) => state.status.includes(item.name))
              .map((item: { name: string, title: string }) => ({label: item.title, value: item.name})),
          });
        }
      }
      if (state.search && state.search.length) {
        appliedFiltersList.push({
          value: 'search',
          label: FILTER_LABELS.search,
          appliedFilters: [{label: state.search, value: state.search}],
        });
      }
      if (state.ranking.length > 0) {
        appliedFiltersList.push({
          value: 'ranking',
          label: FILTER_LABELS.ranking,
          appliedFilters: state.ranking.map((a: number) => {
            return {label: a + (a === 1 ? FILTER_LABELS.star : FILTER_LABELS.stars), value: a};
          }),
        });
      }
      if (state.price.min || state.price.max) {
        const priceFilters = [];
        if (state.price.min) {
          priceFilters.push({value: 'min', label: FILTER_LABELS.from + state.price.min});
        }
        if (state.price.max) {
          priceFilters.push({value: 'max', label: FILTER_LABELS.to + state.price.max});
        }
        appliedFiltersList.push({
          value: 'price',
          label: FILTER_LABELS.price,
          appliedFilters: priceFilters,
        });
      }
      if (state.categories.length) {
        const categoriesApplied: IAppliedFilterType[] = [];
        const filterElement = state.catalogData?.filters?.filterElements
          .find((a) => a.title === FILTER_LABELS.categories);

        if (filterElement && filterElement.rootCategories) {
          const rootCategories: Record<string, Omit<IElementAttributes, 'rootCategories'>> =
            filterElement.rootCategories;

          let elementFind: IPFCheckBox;
          state.categories.forEach((categorySelected: string) => {
            const parent = Object.keys(rootCategories).find((a) => {
              const cbx = (rootCategories)[a].checkBoxes;
              const find = cbx.find((b) => b.value === categorySelected);
              if (find) {
                elementFind = find;
              }
              return find;
            });

            if (parent) {
              const categoryParent = (rootCategories)[parent];
              const exist = categoriesApplied.find((a) => a.value === 'category_' + categoryParent.id);

              if (exist) {
                exist.appliedFilters.push({value: categorySelected, label: elementFind?.title});
              } else {
                categoriesApplied.push({
                  value: 'category_' + categoryParent.id,
                  label: categoryParent.title,
                  appliedFilters: [{value: categorySelected, label: elementFind?.title}],
                });
              }
            }
          });

          appliedFiltersList.push(...categoriesApplied);
        }
      }
      if (state.man.length) {
        const manFilter = state.catalogData?.filters?.filterElements.find((filter) => filter.id === 'man');

        appliedFiltersList.push({
          value: 'man',
          label: FILTER_LABELS.man,
          appliedFilters: manFilter?.checkBoxes
            .filter((a: { value: string }) => state.man.includes(a.value))
            .map((a: { value: string, title: string }) => ({label: a.title, value: a.value})) ?? [],
        });
      }
      if (Object.keys(state.ckList).length) {
        Object.keys(state.ckList).forEach((key: string) => {
          if (state.ckList[key].length) {
            const filterElement = state.catalogData?.filters?.filterElements.find((a: { id: string }) => a.id === key);
            appliedFiltersList.push({
              value: 'cklist_' + key,
              label: filterElement?.title ?? '',
              appliedFilters: state.ckList[key].map((a: string) => ({label: a, value: a})),
            });
          }
        });
      }

      if (this.msku && this.type !== 'search') {
        appliedFiltersList.push({
          label: FILTER_LABELS.mskuTitle,
          value: 'msku',
          appliedFilters: [{
            label: FILTER_LABELS.mskuFilter,
            value: 'msku',
          }],
        });
      }
      return appliedFiltersList;
    },
    baseLink(state): string {
      return state.catalogData?.redirectUrl ??
        state.catalogData?.seoProps.canonical ??
        state.catalogData?.filters.baseLink ??
        '';
    },
    statusItems(state): ICheckboxItem<string>[] {
      const filter = state.catalogData?.filters?.filterElements.find((filter) => filter.id === 'statuses');
      const statusCounts = state.filterData?.counts.status;

      if (!filter) {
        return [];
      }

      return filter.checkBoxes.map((a) => {
        const statusMap = a.name.replace('cpsp[', '').replace(']', '');
        const quantity = (statusCounts as FilterStatus)[statusMap as keyof FilterStatus];
        return {label: a.title, value: a.name, quantity, canHide: a.canHide};
      });
    },
    categoriesItems(state): IDropdownTwoLevelsModelFilterData {
      const filterElement = state.catalogData?.filters?.filterElements
        .find((a) => a.title === FILTER_LABELS.categories);
      const filterCounts = state.filterData?.counts.cat;

      if (!filterElement || !filterElement.rootCategories) {
        return {
          title: FILTER_LABELS.categories,
          items: [],
        };
      }

      return {
        title: FILTER_LABELS.categories,
        items: Object.keys(filterElement.rootCategories).map((a) => {
          const category = (filterElement.rootCategories)?.[a];
          return {
            title: category?.title ?? '',
            key: a,
            maxItems: category?.checkBoxes.filter((a) => !a.canHide).length ?? 5,
            subItems: category?.checkBoxes.map((b) => ({
              title: b.title,
              count: Number.parseInt(filterCounts[b.value]?.toString() ?? 0),
              value: b.value,
              canHide: b.canHide,
            })) ?? [],
          };
        }),
      };
    },
    getCatalogTotal(): number {
      return 12;
    },
    getRedirectToCatalog(state): string {
      return state.catalogData?.redirectUrl ?? state.catalogData?.seoProps.canonical ?? '/';
    },
    getTitle(state): string {
      if (this.af) {
        return 'ALCHEMY FORCE';
      }
      if (state.type === 'search') {
        return state.catalogData?.id ?? '';
      }
      return state.filterData?.seoProps.pageTitle ?? state.catalogData?.seoProps.pageTitle ??
        state.catalogData?.title ?? '';
    },
    getSiteName(state): string {
      if (this.af) {
        return FILTER_LABELS.afSiteName;
      }
      return state.filterData?.seoProps.siteTitle ?? state.catalogData?.seoProps.siteTitle ?? '';
    },
    getCanonical(state): string {
      return state.filterData?.seoProps?.canonicalTag ??
        state.catalogData?.seoProps?.canonicalTag ??
        state.catalogData?.seoProps?.canonical ?? '';
    },
    getDescription(state): string {
      if (this.af) {
        return FILTER_LABELS.afDesc;
      }
      return state.filterData?.seoProps.metaDescription ??
        state.catalogData?.seoProps.metaDescription ?? '';
    },
    getImageUrl(state): string {
      return state.filterData?.seoProps.image ?? state.catalogData?.seoProps.image ?? '';
    },
    getLongDescription(state): string {
      return state.filterData?.seoProps.longDesc ?? state.catalogData?.seoProps.longDesc ?? '';
    },
    getDidYouMeanText(): string {
      try {
        if (this.catalogData) {
          const showDidYouMean = this.catalogData.autocomplete.toLowerCase() !== this.getTitle.toLowerCase();
          return showDidYouMean ? this.catalogData.autocomplete : '';
        }
        return '';
      } catch {
        return '';
      }
    },
    getTotal(state): number {
      return state.filterData?.total ?? 0;
    },
    getMetaRobots(state): string | undefined {
      return state.metaRobots;
    },
    seoMeta(): Record<string, unknown> {
      const title = this.getTitle;
      const siteName = this.getSiteName;
      const description = this.getDescription;
      const imageUrl = this.getImageUrl;
      const url = this.getCanonical;
      const metaRobots = this.getMetaRobots;

      return {
        title: siteName,
        description: description,
        ...(metaRobots ? {robots: metaRobots} : {}),
        // Open Graph Meta Tags
        ogUrl: url,
        ogType: 'website',
        ogTitle: siteName,
        ogDescription: description,
        ogSiteName: 'cyberpuerta.mx',
        ogImage: {
          url: imageUrl,
          width: 200,
          height: 200,
          alt: title,
        },

        twitterCard: 'summary',
        twitterTitle: siteName,
        twitterDescription: description,
        twitterImage: {
          url: imageUrl,
          // width: '',
          // height: '',
          alt: title,
        },
        keywords: '',
      };
    },
    getHead(): Record<string, unknown> {
      return {
        script: [{
          type: 'application/ld+json',
          children: generateCatalogJsonLd({
            title: this.getTitle,
            canonical: this.getCanonical,
            catalogTotal: this.getCatalogTotal,
            breadcrumb: this.breadcrumb,
            articles: this.articles,
            maxArticles: this.af ? 24 : 16,
          }),
          key: 'af-catalog-itemList-schema',
        }],
        link: [{
          rel: 'canonical',
          href: this.getCanonical,
        }],
      };
    },
  },
});
