import type {
  ICatalogRecommendedParams,
} from '~/utils/services/api/catalog/catalog';
import {
  getBrands,
  getLastSeen,
  getCatalogRecommended,
  getCatalogSearchSuggestions,
  addLastSeen,
} from '~/utils/services/api/catalog/catalog';
import {QUERY_KEYS} from '~/utils/services/keys';
import {useCpUser} from '~/composables/useCpUser';
import {getAFCatalogArticles} from '~/utils/services/api/ms-catalog/ms-catalog';
import type { ICpArticle } from '~/types';

export const useBrands = (ids: string[]) => {
  return useAsyncData(QUERY_KEYS.catalog.brands, getBrands, {
    transform: (data) => {
      return data.filter((brand) => ids.includes(brand.id));
    },
  });
};

export const useRecommendations = (
  params: Omit<ICatalogRecommendedParams, 'userId'>,
) => {
  const auth = useCpUser();
  const userId = auth.userId;
  const paramsToSent: ICatalogRecommendedParams = {
    ...params,
    userId,
  };
  return useAsyncData(
    QUERY_KEYS.catalog.recommendations(params.slot ?? ''),
    () => getCatalogRecommended(paramsToSent),
  );
};

export const useRecommendationsWithArticles = (
  params: Omit<ICatalogRecommendedParams, 'userId'>,
  server = false,
) => {
  const auth = useCpUser();
  const userId = auth.userId;
  const paramsToSent: ICatalogRecommendedParams = {
    ...params,
    userId,
  };
  return useAsyncData(
    QUERY_KEYS.catalog.recommendations(params.slot + params.sku),
    async () => {
      const response = await getCatalogRecommended(paramsToSent);
      const articlesIds = response.products.map(({productid}) => productid);
      const articles = await getAFCatalogArticles(articlesIds, true) as unknown as {data: ICpArticle[]};
      return {articles: articles.data, title: response.title};
    },
    {
      server,
    },
  );
};

export const useLastSeen = () => {
  const {userEmail} = useCpUser();
  return useAsyncData(QUERY_KEYS.catalog.lastSeen, () => {
    // This method is used without auth. If we have an auth user, we can use `useProductHistory`
    if (!userEmail) {
      return getLastSeen();
    }
    return rejectQueryFn();
  });
};

export const useAddLastSeen = (articleId: string, server = true) => {
  return useAsyncData(QUERY_KEYS.catalog.lastSeen, () => addLastSeen(articleId), {server});
};

export const useCatalogSearchParams = (
  q: string,
  catId?: string,
  manId?: string,
) => {
  return useAsyncData(QUERY_KEYS.catalog.search, () =>
    getCatalogSearchSuggestions(q, catId, manId),
  );
};
