import type {H3Event} from 'h3';
import type {AsyncDataOptions} from '#app';
import type {subscriptionPayload} from '~/utils/services/api';
import {
    addBillingDataByIDCIF,
    addBillingDataCertificate,
    deleteProductHistory,
    getUsageCodeById,
    getUserRating,
    getCmsPage,
    getEtaInfo,
    parseSeoUrl,
    sendEsdCode,
    postContentReport,
    noStockSubscription, getDeniedCarriers, validateBankAccount,
} from '~/utils/services/api';
import {QUERY_KEYS} from '~/utils/services/keys';
import {useCpUser} from '~/composables/useCpUser';
import {rejectQueryFn} from '~/utils';
import type {ICpBasketContents, IDeliverySet, IDeliverySetGroup, IEtaInfo} from '~/types';
import {useRouterStore} from '~/stores';

interface EtaCalulatorOptions {
  baseMax?: number;
  baseMin?: number;
  deliveryMethod: string;
  productType: string;
  zip: string;
}
export interface IContentReport {
  data: unknown
  reason: string
  type: 'review' | 'question' | 'answer'
  user: string
}
// NOTE: product Id added due to duplicated responses issues
export const useEtaCalculator = (etaMeta: Partial<EtaCalulatorOptions>, productId = '', options: AsyncDataOptions<IEtaInfo> = {}) => {
  const store = useCpUser();
  const meta = {zip: store.user?.value?.zip ?? undefined, ...etaMeta};
  const key = `${QUERY_KEYS.utils.eta(productId)}-${JSON.stringify(meta)}`;
  return useAsyncData(key, () => getEtaInfo(meta), {
    ...useClientCache(),
    ...options,
  });
};
export const useUserRating = (page = 1) => {
  return useAsyncData(QUERY_KEYS.utils.globalRank, () => getUserRating(page));
};

export const deleteUserHistory = () => {
  return useAsyncData(QUERY_KEYS.account.productHistory, () => deleteProductHistory());
};

export const useUsageCodeById = (id: string) => {
  return useAsyncData(QUERY_KEYS.utils.billingUsageCodeById, () => getUsageCodeById(id));
};

export const useBillingByIDCIF = (idCif: string, rfc: string) => {
  return useAsyncData(QUERY_KEYS.utils.billingByIDCIF, () => addBillingDataByIDCIF(idCif, rfc));
};

export const useBillingCertificate = (certificate: any) => {
  return useAsyncData(QUERY_KEYS.utils.billingCertificate, () => addBillingDataCertificate(certificate));
};

export const useCmsPage = (id: string, options?: {ignoreParse: boolean}) => {
  return useAsyncData(QUERY_KEYS.utils.useCms, () => getCmsPage(id, options?.ignoreParse));
};

export const useReportContent = (payload: IContentReport) => {
    return useAsyncData(QUERY_KEYS.utils.contentReport, () => {
        const {userEmail} = useCpUser();
        const newData: IContentReport = {
            ...payload,
            user: userEmail as string,
        };
        if (userEmail) {
            return postContentReport(newData);
        } else {
            return rejectQueryFn('No user found');
        }
    });
};
export const useSendEsdCode = (code: string) => {
  return useAsyncData (QUERY_KEYS.account.sendEsdCode, () => sendEsdCode(code));
};

export const useParseSeoUrl = (url: string, path?: string, event?: H3Event) => {
    const store = useRouterStore();
    const app = useNuxtApp();

    const _cacheKey = path && path === '/' ? '/home' : path ?? url;
    // This help use to avoid create directories and files with the same path IT-21040
    const cacheKey = createParseSeoUrlCacheKey(_cacheKey);
    return useCachedAsyncData(QUERY_KEYS.utils.parseSeoUrl + cacheKey,
        async () => {
            if (store.seoUrlCache[url]) {
                return store.seoUrlCache[url];
            }

            const temp = await parseSeoUrl(url);
            store.seoUrlCache[url] = temp;
            return temp;
        },
        {
            getCachedData: (key: string) => {
              const data = app.payload.data[key] || app.static.data[key];
              if (!data) {
                return;
              }
              const expirationDate = new Date(data.fetchedAt);
              // Expire data after 5 minutes
              expirationDate.setTime(expirationDate.getTime() + 1000 * 60 * 5);
              const isExpired = expirationDate.getTime() < Date.now();
              if (isExpired) {
                return;
              }
              return data;
            },
            transform: (input: any) => {
                if (!input.data?.attributes) {
                    return {...input, fetchedAt: new Date()};
                }

                const params: any = Object.fromEntries(new URLSearchParams(input.data.attributes.prepared_response.legacy.stdurl.split('?')[1]));

                if (input.data?.attributes?.prepared_response?.params?.cpsp) {
                    return {
                        ...params,
                        cpsp: input.data.attributes.prepared_response.params.cpsp,
                        fetchedAt: new Date(),
                        canonical: input.data?.attributes?.canonical,
                        filter: input.data?.filter,
                        url_is_actual: input.data?.attributes?.url_is_actual,
                        seo: input.data?.attributes?.prepared_response?.seo,
                    };
                }
                return {
                  ...params,
                  canonical: input.data?.attributes?.canonical,
                  filter: input.data?.filter,
                  fetchedAt: new Date(),
                  url_is_actual: input.data?.attributes?.url_is_actual,
                  seo: input.data?.attributes?.prepared_response?.seo,
                };
            },
        },
        {
          cacheKey: (QUERY_KEYS.utils.parseSeoUrl + cacheKey),
          cacheTags: [QUERY_KEYS.utils.parseSeoUrl + cacheKey],
          cacheExpires: 3600 * 24 * 7,
          event,
        },
    );
};

export const ONE_MINUTE = 1000 * 60;
export const FIVE_MINUTES = 1000 * 60 * 5;
export const ONE_HOUR = 1000 * 60 * 60;
export const ONE_DAY = 1000 * 60 * 60 * 24;

export const useNoStockSubscription = (payload: subscriptionPayload) => {
  return useAsyncData(QUERY_KEYS.utils.noStockSubscription, () => noStockSubscription(payload));
};
export const useGetDeniedCarriers = async (userEmail: string) => {
  const {data} = await useAsyncData(QUERY_KEYS.deniedCarriers, () => getDeniedCarriers(userEmail), {lazy: true});
  return data.value?.data;
};
export const useValidateCarriers = async (basket: ICpBasketContents) => {
  const deliverySetGroups = basket?.deliverySetGroups;
  const {userEmail} = useCpUser();
  if (userEmail) {
    const deniedCarriers = await useGetDeniedCarriers(userEmail);
    if (!deliverySetGroups || !deniedCarriers) {
      return null;
    }
    const validCarriers: IDeliverySetGroup[] = [];
    deliverySetGroups.forEach((group) => {
      const helperGroup: IDeliverySetGroup = {
        etaGroups: group.etaGroups,
        deliverySets: [],
      };
      group?.deliverySets?.forEach((set) => {
        const helperSet: IDeliverySet = set;
        if (deniedCarriers?.deliveryCostGroups.includes(set.id)) {
          helperSet.description = `Lamentablemente, debido a restricciones de seguridad en el envío de pedidos, ${set.title} no está disponible para tu usuario.`;
          helperSet.deactivate = true;
          helperSet.denied = true;
        }
        helperGroup.deliverySets.push(helperSet);
      });
      validCarriers.push(helperGroup);
    });
    return validCarriers;
  }
  return null;
};

export const useValidateBankAccount = (clabe: string) => {
    return useAsyncData(QUERY_KEYS.utils.validateBankAccount, () => validateBankAccount(clabe));
};

/**
 * This function help us to create a key for urls with query strings
 * @param url
 * @returns url without query params
 */
function createParseSeoUrlCacheKey(url: string) {
  const parts = url.split('?')[0].split('/').filter(Boolean);
  return `/${parts.join('/')}.cache`;
}
