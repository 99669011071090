// Generated by nuxt-prepare
export const env = {
  "categoryTreeUrl": "https://www.cyberpuerta.mx/out/generated/main-menu/6-oxrootid-1741403860.json",
  "sessionToken": "73A9E497",
  "fid": {
    "enabled": true,
    "gsi_clientId": "1044917490556-kg2simrb5fm8ml0s0uubmgeri9rrfhf1.apps.googleusercontent.com",
    "gsi_clientId_android": "1044917490556-9kvrnb85iao90rr8ejf6l4ksd5idut33.apps.googleusercontent.com",
    "gsi_clientId_ios": "1044917490556-nqt66sdvhcel94loopng83jvjle8g4va.apps.googleusercontent.com",
    "apple_clientId": "mx.cyberpuerta",
    "autoLink": false,
    "autoRegister": true,
    "canAddPasswordAgain": true,
    "requirePasswordToLink": false
  },
  "additionalTaxInfo": "hide",
  "redirectUrl": "https://www.cyberpuerta.mx/",
  "minRequiredVersion": "5.0.14",
  "rfcPattern": "^([A-ZÑ&]{3,4}([0-9]{2})(0[1-9]|1[0-2])(0[1-9]|1[0-9]|2[0-9]|3[0-1]))([A-Z0-9]{2}[A0-9])$",
  "rfcGenericNationalPattern": "XAXX010101000",
  "rfcGenericForeignPattern": "XEXX010101000",
  "notificationSettingEnabled": true
}
export type Env = typeof env
export const categories = [
  {
    "id": "4fadfd9ad0936",
    "title": "Promociones",
    "link": "https://www.cyberpuerta.mx/Promociones/",
    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/promociones.png",
    "parentId": "",
    "subCategories": [],
    "path": [],
    "hasSubCategories": false
  },
  {
    "id": "74afe6aa9b55b844c73cc99e5c3c4b18",
    "title": "Nuevos artículos",
    "link": "https://www.cyberpuerta.mx/Nuevos-articulos/",
    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/nuevos-articulos.png",
    "parentId": "",
    "subCategories": [],
    "path": [],
    "hasSubCategories": false
  },
  {
    "id": "99748977a0a9f40e2.09126772",
    "title": "Cómputo (Hardware)",
    "link": "https://www.cyberpuerta.mx/Computo-Hardware/",
    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/99748977a0a9f40e2.09126772-CP.png",
    "parentId": "",
    "subCategories": [
      {
        "id": "58bef5d8bc540",
        "title": "PC Gaming",
        "link": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pcgaming2.png",
        "parentId": "99748977a0a9f40e2.09126772",
        "subCategories": [
          {
            "id": "f2a49b6c01beef27f48e7958c3316508",
            "title": "Tarjetas Madre Gamer",
            "link": "https://www.cyberpuerta.mx/Tarjetas-Madre-Gamer/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gaming-tmadre.png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "be600e7634dc4702686e82ea8d585c9b",
            "title": "Tarjetas de Video Gamer",
            "link": "https://www.cyberpuerta.mx/Tarjetas-de-Video-Gamer/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tdv(2).png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "0cd2390a7ff6dc0f54f49a148cff5144",
            "title": "Monitores Gamer",
            "link": "https://www.cyberpuerta.mx/Monitores-Gamer/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gaming-monitores(1).png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "36b19896a9505c922b7fd996e6844f3a",
            "title": "Audífonos Gamer",
            "link": "https://www.cyberpuerta.mx/Audifonos-Gamer/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/audifonosgamer4(1).png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "1a2f70800b9a8f8488548d37a7a4e103",
            "title": "Mouse Gamer",
            "link": "https://www.cyberpuerta.mx/Mouse-Gamer/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gaming-mouse(1).png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "d47ac7c578d7e1084d7f2951a81edc5d",
            "title": "Kit Gamer de Teclado y Mouse",
            "link": "https://www.cyberpuerta.mx/Kit-Gamer-de-Teclado-y-Mouse/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gaming-kittecladomouse.png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "034a70ec33c1f3cc4a3ef59378235d0b",
            "title": "Mousepad Gamer",
            "link": "https://www.cyberpuerta.mx/Mousepad-Gamer/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gaming-mousepad(1).png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "1b94c976c1d32772dbf29457b73ecc1a",
            "title": "Gabinetes Gamer ",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Gabinetes/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gaming-gabinetes.png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "c250dcc1f77c6c5084a29673420f6227",
            "title": "Fuentes de Poder para PC's",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Fuentes-de-Poder-para-PC-s/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c250dcc1f77c6c5084a29673420f6227-CP.png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "80dd251aacd85cb67076c54020a46311",
            "title": "PC´s Gamer",
            "link": "https://www.cyberpuerta.mx/PC-s-Gamer/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pc-gamer.png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4d579179133925e93ef0ce53d59ef26e",
            "title": "Laptops Gamer ",
            "link": "https://www.cyberpuerta.mx/Laptops-Gamer/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/laptop-gamer.png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "58bf31aa9abc7",
            "title": "Sillas Gamer",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/Sillas-Gamer/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gaming-silla.png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=b30i6q70a8jeco2j86ajt3uo3n",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/?force_sid=b30i6q70a8jeco2j86ajt3uo3n",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "6008c77c04790",
            "title": "Escritorios Gamer",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/Escritorios-Gamer/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/escritorios-gamer.png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "76ccc517bb4bb6cd4f411cdea9f642bd",
            "title": "Sofás Gamer",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/Sofas-Gamer/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/76ccc517bb4bb6cd4f411cdea9f642bd-CP.png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "6695ef5ee8d833b446b76e964ef8be40",
            "title": "Routers Gamer",
            "link": "https://www.cyberpuerta.mx/Router-Gamer/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/router-gamer.png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "b588089107337bd5a37c499abfac7fe7",
            "title": "Lentes de Realidad Virtual",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/Lentes-de-Realidad-Virtual/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lentes-de-realidad-virtual.png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51e85a57ffe52bbcef62fc5aa57145f2",
            "title": "Asientos Simuladores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/Asientos-Simuladores/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/51e85a57ffe52bbcef62fc5aa57145f2-CP.png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5f47fd92e1430",
            "title": "Juegos para PC",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/Juegos-para-PC/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/juegosparapc.png",
            "parentId": "58bef5d8bc540",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "58bef5d8bc540": {
                "id": null,
                "title": "PC Gaming",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "99748977a0a9f40e2.09126772": {
            "id": null,
            "title": "Cómputo (Hardware)",
            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "646722751330a9b99e9bac8c8e01a40a",
        "title": "Componentes",
        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/componentes2.png",
        "parentId": "99748977a0a9f40e2.09126772",
        "subCategories": [
          {
            "id": "4fe8a03e147dd",
            "title": "Procesadores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Procesadores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/procesador(1).png",
            "parentId": "646722751330a9b99e9bac8c8e01a40a",
            "subCategories": [
              {
                "id": "30eff6a3636173e2c0d4865dbdf9459f",
                "title": "Procesadores para PC",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Procesadores/Procesadores-para-PC/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/procesador-ryzen.png",
                "parentId": "4fe8a03e147dd",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe8a03e147dd": {
                    "id": null,
                    "title": "Procesadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Procesadores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b0f6aa60e",
                "title": "Procesadores para Servidores",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Procesadores/Procesadores-para-Servidores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/proesadores-server.png",
                "parentId": "4fe8a03e147dd",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe8a03e147dd": {
                    "id": null,
                    "title": "Procesadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Procesadores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "646722751330a9b99e9bac8c8e01a40a": {
                "id": null,
                "title": "Componentes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "30e19d35e24b506ba02e1a2e0e0d94a7",
            "title": "Tarjetas Madre",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-Madre/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/motherboard2.png",
            "parentId": "646722751330a9b99e9bac8c8e01a40a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "646722751330a9b99e9bac8c8e01a40a": {
                "id": null,
                "title": "Componentes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "30ee946749fd3e8fb8e6c3916e5d08e8",
            "title": "Tarjetas de Video",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Video/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tdv(1).png",
            "parentId": "646722751330a9b99e9bac8c8e01a40a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=i6nsdloc58tkl0i41mnpak2rqa",
                "image": null,
                "stock": 0
              },
              "646722751330a9b99e9bac8c8e01a40a": {
                "id": null,
                "title": "Componentes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/?force_sid=i6nsdloc58tkl0i41mnpak2rqa",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "30e288ef78daed188bb188e1f2ce790d",
            "title": "Gabinetes",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Gabinetes/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gabinetes.png",
            "parentId": "646722751330a9b99e9bac8c8e01a40a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=c5o92vgbrse0dbtd3bmcnh2vbc",
                "image": null,
                "stock": 0
              },
              "646722751330a9b99e9bac8c8e01a40a": {
                "id": null,
                "title": "Componentes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/?force_sid=c5o92vgbrse0dbtd3bmcnh2vbc",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5f1b6f8f466c31a34e44e286ae43c2ca",
            "title": "Fuentes de Poder para PC's",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Fuentes-de-Poder-para-PC-s/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/667b1dcc1245d-CP.webp",
            "parentId": "646722751330a9b99e9bac8c8e01a40a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=c5o92vgbrse0dbtd3bmcnh2vbc",
                "image": null,
                "stock": 0
              },
              "646722751330a9b99e9bac8c8e01a40a": {
                "id": null,
                "title": "Componentes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/?force_sid=c5o92vgbrse0dbtd3bmcnh2vbc",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "50ef0057d64a4",
            "title": "Enfriamiento y Ventilación",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/enfriamiento-ventilacion.png",
            "parentId": "646722751330a9b99e9bac8c8e01a40a",
            "subCategories": [
              {
                "id": "50ef03ae69e7a",
                "title": "Enfriamiento Líquido",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/Enfriamiento-Liquido/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/disipadores-liquido.png",
                "parentId": "50ef0057d64a4",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=n1oiu3i0r71uaqi9cvegpsmq17",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/?force_sid=n1oiu3i0r71uaqi9cvegpsmq17",
                    "image": null,
                    "stock": 0
                  },
                  "50ef0057d64a4": {
                    "id": null,
                    "title": "Enfriamiento y Ventilación",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/?force_sid=n1oiu3i0r71uaqi9cvegpsmq17",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "6657c482447d1e3232daf58565940b18",
                "title": "Ventiladores",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/Ventiladores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ventiladores(1).png",
                "parentId": "50ef0057d64a4",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=h466vrqovkia6alhfgunbtb104",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/?force_sid=h466vrqovkia6alhfgunbtb104",
                    "image": null,
                    "stock": 0
                  },
                  "50ef0057d64a4": {
                    "id": null,
                    "title": "Enfriamiento y Ventilación",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/?force_sid=h466vrqovkia6alhfgunbtb104",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "50ef02ca4b293",
                "title": "Disipadores para CPU",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/Disipadores-para-CPU/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/disipadores-cpu.png",
                "parentId": "50ef0057d64a4",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=tbkvi07ei9clh3e3pm08u8jnbv",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/?force_sid=tbkvi07ei9clh3e3pm08u8jnbv",
                    "image": null,
                    "stock": 0
                  },
                  "50ef0057d64a4": {
                    "id": null,
                    "title": "Enfriamiento y Ventilación",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/?force_sid=tbkvi07ei9clh3e3pm08u8jnbv",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51099f6bd848a",
                "title": "Ventiladores para RAM",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/Ventiladores-para-RAM/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ventilador-memoria.png",
                "parentId": "50ef0057d64a4",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "50ef0057d64a4": {
                    "id": null,
                    "title": "Enfriamiento y Ventilación",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "525dbadcf00cf",
                "title": "Control de Ventiladores",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/Control-de-Ventiladores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/control-ventilador.png",
                "parentId": "50ef0057d64a4",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "50ef0057d64a4": {
                    "id": null,
                    "title": "Enfriamiento y Ventilación",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "50ef016272f49",
                "title": "Pasta Térmica",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/Pasta-Termica/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pasta-termica.png",
                "parentId": "50ef0057d64a4",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=1lluafn2v122h12otjr73emic6",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/?force_sid=1lluafn2v122h12otjr73emic6",
                    "image": null,
                    "stock": 0
                  },
                  "50ef0057d64a4": {
                    "id": null,
                    "title": "Enfriamiento y Ventilación",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/?force_sid=1lluafn2v122h12otjr73emic6",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "58e27c9523463",
                "title": "Accesorios para Enfriamiento Líquido",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/Accesorios-para-Enfriamiento-Liquido/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-refrigeracion-liquida.png",
                "parentId": "50ef0057d64a4",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "50ef0057d64a4": {
                    "id": null,
                    "title": "Enfriamiento y Ventilación",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Enfriamiento-y-Ventilacion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "646722751330a9b99e9bac8c8e01a40a": {
                "id": null,
                "title": "Componentes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "4fe8a822cf072",
            "title": "Tarjetas de Expansión",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjetas-add-on.png",
            "parentId": "646722751330a9b99e9bac8c8e01a40a",
            "subCategories": [
              {
                "id": "4fe3b1c9b36f9",
                "title": "Tarjetas PCI Express",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/Tarjetas-PCI-Express/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjetapciexpress.png",
                "parentId": "4fe8a822cf072",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe8a822cf072": {
                    "id": null,
                    "title": "Tarjetas de Expansión",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5c4c79116dd9128484fb636e7c6ce5bb",
                "title": "Tarjetas PCI",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/Tarjetas-PCI/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjetasaddon.png",
                "parentId": "4fe8a822cf072",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe8a822cf072": {
                    "id": null,
                    "title": "Tarjetas de Expansión",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe8a9b8c538f",
                "title": "ExpressCard",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/ExpressCard/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/expresscard.png",
                "parentId": "4fe8a822cf072",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe8a822cf072": {
                    "id": null,
                    "title": "Tarjetas de Expansión",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "30eac5750c75e8c410e389ed6a8eec16",
                "title": "Tarjetas de Sonido",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/Tarjetas-de-Sonido/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjeta-sonido.png",
                "parentId": "4fe8a822cf072",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe8a822cf072": {
                    "id": null,
                    "title": "Tarjetas de Expansión",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "53dab01092827",
                "title": "Cajas de Expansión",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/Cajas-de-Expansion/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/extension-ranuras.png",
                "parentId": "4fe8a822cf072",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe8a822cf072": {
                    "id": null,
                    "title": "Tarjetas de Expansión",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "eef99b6ed8c72fd536261a3ca9e9a52b",
                "title": "Tarjetas de Televisión",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/Tarjetas-de-Television/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjetas-television.png",
                "parentId": "4fe8a822cf072",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe8a822cf072": {
                    "id": null,
                    "title": "Tarjetas de Expansión",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "58375fb3b9a73",
                "title": "Tarjetas para Captura de Video",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/Tarjetas-para-Captura-de-Video/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjetas-captura-video(1).png",
                "parentId": "4fe8a822cf072",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe8a822cf072": {
                    "id": null,
                    "title": "Tarjetas de Expansión",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "646722751330a9b99e9bac8c8e01a40a": {
                "id": null,
                "title": "Componentes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "591a25b7bd506",
            "title": "Accesorios para Gabinetes",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Accesorios-para-Gabinetes/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-para-gabinetes.png",
            "parentId": "646722751330a9b99e9bac8c8e01a40a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=4oaptkg0fmh5cv9jql2mek5803",
                "image": null,
                "stock": 0
              },
              "646722751330a9b99e9bac8c8e01a40a": {
                "id": null,
                "title": "Componentes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/?force_sid=4oaptkg0fmh5cv9jql2mek5803",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "60d607b684081",
            "title": "Placas de Desarrollo",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/13placas-de-desarrollo.png",
            "parentId": "646722751330a9b99e9bac8c8e01a40a",
            "subCategories": [
              {
                "id": "60a3db5901357",
                "title": "Placas de Desarrollo",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/Placas-de-Desarrollo/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/13placas-de-desarrollo(1).png",
                "parentId": "60d607b684081",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "60d607b684081": {
                    "id": null,
                    "title": "Placas de Desarrollo",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60d614438f724",
                "title": "Sensores para Placas de Desarrollo",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/Sensores-para-Placas-de-Desarrollo/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/14sensores-para-placas-de-desarrollo.png",
                "parentId": "60d607b684081",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "60d607b684081": {
                    "id": null,
                    "title": "Placas de Desarrollo",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60d615c062fb0",
                "title": "Pantallas para Placas de Desarrollo",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/Pantallas-para-Placas-de-Desarrollo/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/15pantallas-para-placas-de-desarrollo.png",
                "parentId": "60d607b684081",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "60d607b684081": {
                    "id": null,
                    "title": "Placas de Desarrollo",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60d6175eeb612",
                "title": "Protoboards",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/Protoboards/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/16protoboards.png",
                "parentId": "60d607b684081",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "60d607b684081": {
                    "id": null,
                    "title": "Placas de Desarrollo",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60dcb6fe40f39",
                "title": "LEDs",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/LEDs/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/19leds.png",
                "parentId": "60d607b684081",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "60d607b684081": {
                    "id": null,
                    "title": "Placas de Desarrollo",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60df4f72946f1",
                "title": "Módulos y Motores",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/Modulos-y-Motores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/21modulos-y-motores.png",
                "parentId": "60d607b684081",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "60d607b684081": {
                    "id": null,
                    "title": "Placas de Desarrollo",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60df67e8dd56f",
                "title": "Cables y Jumpers",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/Cables-y-Jumpers/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/22cables-y-jumpers.png",
                "parentId": "60d607b684081",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "60d607b684081": {
                    "id": null,
                    "title": "Placas de Desarrollo",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60e88cdd4f5ef",
                "title": "Montajes para Placas de Desarrollo",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/Montajes-para-Placas-de-Desarrollo/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/23montajes-para-placas-de-desarrollo.png",
                "parentId": "60d607b684081",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "60d607b684081": {
                    "id": null,
                    "title": "Placas de Desarrollo",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "64f9ec9f64324",
                "title": "Transistores",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/Transistores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/transistor.png",
                "parentId": "60d607b684081",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "60d607b684081": {
                    "id": null,
                    "title": "Placas de Desarrollo",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "6508d42f8879a",
                "title": "Accesorios para Placas de Desarrollo",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/Accesorios-para-Placas-de-Desarrollo/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorioplacasdesarrollo.png",
                "parentId": "60d607b684081",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "60d607b684081": {
                    "id": null,
                    "title": "Placas de Desarrollo",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "653949f7ef06e",
                "title": "Interruptores para Placas de Desarrollo",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/Interruptores-para-Placas-de-Desarrollo/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/interruptorplaca.png",
                "parentId": "60d607b684081",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "60d607b684081": {
                    "id": null,
                    "title": "Placas de Desarrollo",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "6508e3885553f",
                "title": "Cámaras para Placas de Desarrollo",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/Camaras-para-Placas-de-Desarrollo/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/camaraplacasdesarrollo.png",
                "parentId": "60d607b684081",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "646722751330a9b99e9bac8c8e01a40a": {
                    "id": null,
                    "title": "Componentes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                    "image": null,
                    "stock": 0
                  },
                  "60d607b684081": {
                    "id": null,
                    "title": "Placas de Desarrollo",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Placas-de-Desarrollo/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "646722751330a9b99e9bac8c8e01a40a": {
                "id": null,
                "title": "Componentes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "99748977a0a9f40e2.09126772": {
            "id": null,
            "title": "Cómputo (Hardware)",
            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "30e48d39777ee7c4b1625ff8d1114591",
        "title": "Discos Duros / SSD / NAS",
        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ddssdnas5.png",
        "parentId": "99748977a0a9f40e2.09126772",
        "subCategories": [
          {
            "id": "0f6a54c8f8a59414ea149d309aa87d98",
            "title": "SSD",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/SSD/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ssds.png",
            "parentId": "30e48d39777ee7c4b1625ff8d1114591",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=jtc8r0hmchl77o80ilu7j0vb4v",
                "image": null,
                "stock": 0
              },
              "30e48d39777ee7c4b1625ff8d1114591": {
                "id": null,
                "title": "Discos Duros - SSD - NAS",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/?force_sid=jtc8r0hmchl77o80ilu7j0vb4v",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "30e385464cc73736dd4b21343026e1fd",
            "title": "Discos Duros Internos para PC",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Discos-Duros-Internos-para-PC/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/disco-interno.png",
            "parentId": "30e48d39777ee7c4b1625ff8d1114591",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e48d39777ee7c4b1625ff8d1114591": {
                "id": null,
                "title": "Discos Duros - SSD - NAS",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3b27b891b7",
            "title": "Discos Duros Internos para Laptop",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Discos-Duros-Internos-para-Laptop/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/disco-interno-laptop.png",
            "parentId": "30e48d39777ee7c4b1625ff8d1114591",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e48d39777ee7c4b1625ff8d1114591": {
                "id": null,
                "title": "Discos Duros - SSD - NAS",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "50abc096b63e4",
            "title": "Discos Duros para Servidores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Discos-Duros-para-Servidores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/dd-servidores.png",
            "parentId": "30e48d39777ee7c4b1625ff8d1114591",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e48d39777ee7c4b1625ff8d1114591": {
                "id": null,
                "title": "Discos Duros - SSD - NAS",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "a307adbf9a13b95c0019f58a84240372",
            "title": "Discos Duros para Videovigilancia",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Discos-Duros-para-Videovigilancia/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/discos-duros-vigilancia(1).png",
            "parentId": "30e48d39777ee7c4b1625ff8d1114591",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e48d39777ee7c4b1625ff8d1114591": {
                "id": null,
                "title": "Discos Duros - SSD - NAS",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5cbfa04bbfc70",
            "title": "Discos Duros para NAS",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Discos-Duros-para-NAS/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/dd-nas.png",
            "parentId": "30e48d39777ee7c4b1625ff8d1114591",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e48d39777ee7c4b1625ff8d1114591": {
                "id": null,
                "title": "Discos Duros - SSD - NAS",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "30ec8dabc2c766e09a243ec08541624f",
            "title": "Discos Duros Externos",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Discos-Duros-Externos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/disco-externo.png",
            "parentId": "30e48d39777ee7c4b1625ff8d1114591",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e48d39777ee7c4b1625ff8d1114591": {
                "id": null,
                "title": "Discos Duros - SSD - NAS",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "71743b1b31f14245ee7b54eb95d21889",
            "title": "Almacenamiento NAS",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Almacenamiento-NAS/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/almacenamiento-nas.png",
            "parentId": "30e48d39777ee7c4b1625ff8d1114591",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e48d39777ee7c4b1625ff8d1114591": {
                "id": null,
                "title": "Discos Duros - SSD - NAS",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "56391f1a131a5",
            "title": "Almacenamiento MSA",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Almacenamiento-MSA/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/almacenamiento-msa.png",
            "parentId": "30e48d39777ee7c4b1625ff8d1114591",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e48d39777ee7c4b1625ff8d1114591": {
                "id": null,
                "title": "Discos Duros - SSD - NAS",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3b32858e6a",
            "title": "Accesorios para Discos Duros",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Accesorios-para-Discos-Duros/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios(13).png",
            "parentId": "30e48d39777ee7c4b1625ff8d1114591",
            "subCategories": [
              {
                "id": "5c4bd2f09b640a5851aaec5e0b7755e9",
                "title": "Gabinetes de Discos Duros",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Accesorios-para-Discos-Duros/Gabinetes-de-Discos-Duros/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gabinete(1).png",
                "parentId": "4fe3b32858e6a",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30e48d39777ee7c4b1625ff8d1114591": {
                    "id": null,
                    "title": "Discos Duros - SSD - NAS",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3b32858e6a": {
                    "id": null,
                    "title": "Accesorios para Discos Duros",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Accesorios-para-Discos-Duros/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b50359db1",
                "title": "Adaptadores de Discos Duros",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Accesorios-para-Discos-Duros/Adaptadores-de-Discos-Duros/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptador.png",
                "parentId": "4fe3b32858e6a",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30e48d39777ee7c4b1625ff8d1114591": {
                    "id": null,
                    "title": "Discos Duros - SSD - NAS",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3b32858e6a": {
                    "id": null,
                    "title": "Accesorios para Discos Duros",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Accesorios-para-Discos-Duros/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51bb65b4ce2f5",
                "title": "Racks Extraíbles y Backplanes",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Accesorios-para-Discos-Duros/Racks-Extraibles-y-Backplanes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/racks-extraibles.png",
                "parentId": "4fe3b32858e6a",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30e48d39777ee7c4b1625ff8d1114591": {
                    "id": null,
                    "title": "Discos Duros - SSD - NAS",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3b32858e6a": {
                    "id": null,
                    "title": "Accesorios para Discos Duros",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Accesorios-para-Discos-Duros/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51bb65f5d41e8",
                "title": "Soportes y Accesorios para Montaje",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Accesorios-para-Discos-Duros/Soportes-y-Accesorios-para-Montaje/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/kits-montaje(1).png",
                "parentId": "4fe3b32858e6a",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30e48d39777ee7c4b1625ff8d1114591": {
                    "id": null,
                    "title": "Discos Duros - SSD - NAS",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3b32858e6a": {
                    "id": null,
                    "title": "Accesorios para Discos Duros",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Accesorios-para-Discos-Duros/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e48d39777ee7c4b1625ff8d1114591": {
                "id": null,
                "title": "Discos Duros - SSD - NAS",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5f07587e0e98f90c77387e416ec95478",
            "title": "Accesorios para SSD",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Accesorios-para-SSD/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/5f07587e0e98f90c77387e416ec95478-CP.png",
            "parentId": "30e48d39777ee7c4b1625ff8d1114591",
            "subCategories": [
              {
                "id": "cb96914242cf7fff598a26f948c7e47f",
                "title": "Gabinetes para SSD",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Accesorios-para-SSD/Gabinetes-para-SSD/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/cb96914242cf7fff598a26f948c7e47f-CP.png",
                "parentId": "5f07587e0e98f90c77387e416ec95478",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=hgqi9352snd6kc0454l9kmbutv",
                    "image": null,
                    "stock": 0
                  },
                  "30e48d39777ee7c4b1625ff8d1114591": {
                    "id": null,
                    "title": "Discos Duros - SSD - NAS",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/?force_sid=hgqi9352snd6kc0454l9kmbutv",
                    "image": null,
                    "stock": 0
                  },
                  "5f07587e0e98f90c77387e416ec95478": {
                    "id": null,
                    "title": "Accesorios para SSD",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Accesorios-para-SSD/?force_sid=hgqi9352snd6kc0454l9kmbutv",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e48d39777ee7c4b1625ff8d1114591": {
                "id": null,
                "title": "Discos Duros - SSD - NAS",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "f38c8922f655f7b737548ef89657f2b6",
            "title": "SSD's para Servidores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/SSD-s-para-Servidores/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/f38c8922f655f7b737548ef89657f2b6-CP.png",
            "parentId": "30e48d39777ee7c4b1625ff8d1114591",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=nine9s5t6lah0rhe4sg4i77ga0",
                "image": null,
                "stock": 0
              },
              "30e48d39777ee7c4b1625ff8d1114591": {
                "id": null,
                "title": "Discos Duros - SSD - NAS",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/?force_sid=nine9s5t6lah0rhe4sg4i77ga0",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "99748977a0a9f40e2.09126772": {
            "id": null,
            "title": "Cómputo (Hardware)",
            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "30ec3cee8c179220af729fb6036b5e5a",
        "title": "Memorias RAM y Flash",
        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/memoriasramyflash2.png",
        "parentId": "99748977a0a9f40e2.09126772",
        "subCategories": [
          {
            "id": "30e5217593dbce995a235db629f92956",
            "title": "Memorias RAM para PC",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/Memorias-RAM-para-PC/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/30e5217593dbce995a235db629f92956-CP.png",
            "parentId": "30ec3cee8c179220af729fb6036b5e5a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=c5o92vgbrse0dbtd3bmcnh2vbc",
                "image": null,
                "stock": 0
              },
              "30ec3cee8c179220af729fb6036b5e5a": {
                "id": null,
                "title": "Memorias RAM y Flash",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/?force_sid=c5o92vgbrse0dbtd3bmcnh2vbc",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3b562005b2",
            "title": "Memorias RAM para Laptop",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/Memorias-RAM-para-Laptop/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/memorias-ram-para-laptop.png",
            "parentId": "30ec3cee8c179220af729fb6036b5e5a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30ec3cee8c179220af729fb6036b5e5a": {
                "id": null,
                "title": "Memorias RAM y Flash",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3b5714f837",
            "title": "Memorias RAM para Mac",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/Memorias-RAM-para-Mac/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/memoria-ram-mac.png",
            "parentId": "30ec3cee8c179220af729fb6036b5e5a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30ec3cee8c179220af729fb6036b5e5a": {
                "id": null,
                "title": "Memorias RAM y Flash",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3b57e86408",
            "title": "Memorias RAM para Servidores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/Memorias-RAM-para-Servidores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/server(1).png",
            "parentId": "30ec3cee8c179220af729fb6036b5e5a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30ec3cee8c179220af729fb6036b5e5a": {
                "id": null,
                "title": "Memorias RAM y Flash",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "30e898a7a46b66174a3245f3c7282dab",
            "title": "Memorias Flash",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/Memorias-Flash/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/memorias-flash.png",
            "parentId": "30ec3cee8c179220af729fb6036b5e5a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30ec3cee8c179220af729fb6036b5e5a": {
                "id": null,
                "title": "Memorias RAM y Flash",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "30ee554956a55ffff4ceeaa4f2064726",
            "title": "Memorias USB",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/Memorias-USB/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/memorias-usb.png",
            "parentId": "30ec3cee8c179220af729fb6036b5e5a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=viqjjn52v484ps2fufpcr3enic",
                "image": null,
                "stock": 0
              },
              "30ec3cee8c179220af729fb6036b5e5a": {
                "id": null,
                "title": "Memorias RAM y Flash",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/?force_sid=viqjjn52v484ps2fufpcr3enic",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "b6373f09a8f9b746a7bfd9f067cbe2d0",
            "title": "Lectores de Memoria",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/Lectores-de-Memoria/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lectores-memoria.png",
            "parentId": "30ec3cee8c179220af729fb6036b5e5a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30ec3cee8c179220af729fb6036b5e5a": {
                "id": null,
                "title": "Memorias RAM y Flash",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "99748977a0a9f40e2.09126772": {
            "id": null,
            "title": "Cómputo (Hardware)",
            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "4fe3b044544d3",
        "title": "Monitores",
        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/monitor.png",
        "parentId": "99748977a0a9f40e2.09126772",
        "subCategories": [
          {
            "id": "40549b0491f74c1e4.29004321",
            "title": "Monitores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/Monitores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/monitores(1).png",
            "parentId": "4fe3b044544d3",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4fe3b044544d3": {
                "id": null,
                "title": "Monitores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "c59528691f4281125b302c868745cb11",
            "title": "Monitores Portátiles",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/Monitores-Portatiles/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c59528691f4281125b302c868745cb11-CP.png",
            "parentId": "4fe3b044544d3",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4fe3b044544d3": {
                "id": null,
                "title": "Monitores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "7b3189c29616c6e68b92c3b76bcc0d07",
            "title": "Soportes para Monitores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/Soportes-para-Monitores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soportes(1).png",
            "parentId": "4fe3b044544d3",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4fe3b044544d3": {
                "id": null,
                "title": "Monitores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "d0aa4b6903a210255a7cdd6ee298eeee",
            "title": "Filtros de Privacidad para Monitores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/Filtros-de-Privacidad-para-Monitores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/filtros-privacidad-monitores.png",
            "parentId": "4fe3b044544d3",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4fe3b044544d3": {
                "id": null,
                "title": "Monitores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "617c57fc168b4",
            "title": "Estaciones de Trabajo",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/Estaciones-de-Trabajo/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/estaciones-de-trabajo.png",
            "parentId": "4fe3b044544d3",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4fe3b044544d3": {
                "id": null,
                "title": "Monitores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "617c5c3c70be8",
            "title": "Accesorios para Estaciones de Trabajo",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/Accesorios-para-Estaciones-de-Trabajo/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-para-estaciones-de-trabajo.png",
            "parentId": "4fe3b044544d3",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4fe3b044544d3": {
                "id": null,
                "title": "Monitores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "99748977a0a9f40e2.09126772": {
            "id": null,
            "title": "Cómputo (Hardware)",
            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "30e31ee6476c7801816ebf8dd6c9e398",
        "title": "Dispositivos de Entrada",
        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/dispositivosdeentrada2.png",
        "parentId": "99748977a0a9f40e2.09126772",
        "subCategories": [
          {
            "id": "6461a908df1f9e667ee381c57d7c3175",
            "title": "Kits de Teclado y Mouse",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/Kits-de-Teclado-y-Mouse/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sets.png",
            "parentId": "30e31ee6476c7801816ebf8dd6c9e398",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e31ee6476c7801816ebf8dd6c9e398": {
                "id": null,
                "title": "Dispositivos de Entrada",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "646488d652fabfc509cb9826a2299260",
            "title": "Mouse",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/Mouse/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/raton.png",
            "parentId": "30e31ee6476c7801816ebf8dd6c9e398",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e31ee6476c7801816ebf8dd6c9e398": {
                "id": null,
                "title": "Dispositivos de Entrada",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5c4a28cdc313584a10b53b2811a5a1d5",
            "title": "Mousepads",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/Mousepads/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/mousepad.png",
            "parentId": "30e31ee6476c7801816ebf8dd6c9e398",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e31ee6476c7801816ebf8dd6c9e398": {
                "id": null,
                "title": "Dispositivos de Entrada",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "646847c2db77e56d69aae26209348b7c",
            "title": "Teclados",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/Teclados/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/teclado.png",
            "parentId": "30e31ee6476c7801816ebf8dd6c9e398",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e31ee6476c7801816ebf8dd6c9e398": {
                "id": null,
                "title": "Dispositivos de Entrada",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "52053fa618a17",
            "title": "Teclados Numéricos",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/Teclados-Numericos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/teclado-numerico.png",
            "parentId": "30e31ee6476c7801816ebf8dd6c9e398",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e31ee6476c7801816ebf8dd6c9e398": {
                "id": null,
                "title": "Dispositivos de Entrada",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "54b44c3a29010",
            "title": "Teclas para Teclados",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/Teclas-para-Teclados/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/teclasteclado.png",
            "parentId": "30e31ee6476c7801816ebf8dd6c9e398",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e31ee6476c7801816ebf8dd6c9e398": {
                "id": null,
                "title": "Dispositivos de Entrada",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5f1df892628d0e69168f712bd2b947c6",
            "title": "Tablets Gráficas",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/Tablets-Graficas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tableta23.png",
            "parentId": "30e31ee6476c7801816ebf8dd6c9e398",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e31ee6476c7801816ebf8dd6c9e398": {
                "id": null,
                "title": "Dispositivos de Entrada",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "c2f757e4b2d2a91b3c89aa2e010bbd60",
            "title": "Controles de Juego para PC",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/Controles-de-Juego-para-PC/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/controles-juego-pc.png",
            "parentId": "30e31ee6476c7801816ebf8dd6c9e398",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e31ee6476c7801816ebf8dd6c9e398": {
                "id": null,
                "title": "Dispositivos de Entrada",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "c7b717ad4d16c2747ec70f1708c87045",
            "title": "Volantes para Simulador",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/Volantes-para-Simulador/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c7b717ad4d16c2747ec70f1708c87045-CP.png",
            "parentId": "30e31ee6476c7801816ebf8dd6c9e398",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e31ee6476c7801816ebf8dd6c9e398": {
                "id": null,
                "title": "Dispositivos de Entrada",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "99748977a0a9f40e2.09126772": {
            "id": null,
            "title": "Cómputo (Hardware)",
            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "cda7d1fae164e9e1eaaec486e53338ae",
        "title": "Audífonos Gamer",
        "link": "https://www.cyberpuerta.mx/Audifonos-Gamer/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/audifonosgamer4(2)(1).png",
        "parentId": "99748977a0a9f40e2.09126772",
        "subCategories": [],
        "path": {
          "99748977a0a9f40e2.09126772": {
            "id": null,
            "title": "Cómputo (Hardware)",
            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "30eafb0fad987d38cf6c1b67b41a84a2",
        "title": "Redes",
        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/redes3.png",
        "parentId": "99748977a0a9f40e2.09126772",
        "subCategories": [
          {
            "id": "30ed875a38762bbf17d4876aec29e555",
            "title": "Switches",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Switches/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/switches.png",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fea24cf30982",
            "title": "Router",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Router/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/routers.png",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "635be8926eccf",
            "title": "Firewall",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Firewall/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/firewall_.png",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "7bb0fb73ff318b845c25693e9f4e5181",
            "title": "Access Points",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Access-Points/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/acces-point.png",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "6317d3163affc",
            "title": "CPE PtMP y PtP",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/CPE-PtMP-y-PtP/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cpe-ptmp.png",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4ae0f1347795f99bcb024bc603b4e018",
            "title": "Wi-Fi en Malla",
            "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Smart-WiFi/Wi-Fi-en-Malla/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/wifi-en-malla(1).png",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "55c50d49a6443",
            "title": "Extensor de Señal WiFi",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Extensor-de-Senal-WiFi/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/extensores-de-rango.png",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "30e81228dcab097120f57267c6f4da3b",
            "title": "Modems",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Modems/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/modem.jpg",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3b98810642",
            "title": "Adaptadores de Red USB",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Adaptadores-de-Red-USB/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/usb(4).png",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3b86627098",
            "title": "Powerline",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Powerline/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/powerline.png",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3b873576c3",
            "title": "Antenas",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Antenas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/antena.png",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5aa85043ecb1d",
            "title": "Unidades de Red Óptica",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Unidades-de-Red-Optica/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/unidad-red-optica.png",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5a972e447deb3",
            "title": "Backhauls",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Backhauls/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/backhauls.png",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51ffd2161e7cd",
            "title": "Accesorios de Redes",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-redes.png",
            "parentId": "30eafb0fad987d38cf6c1b67b41a84a2",
            "subCategories": [
              {
                "id": "543ea2c9b9330",
                "title": "Controladores Inalámbricos",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Controladores-Inalambricos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/controladores-inalambricos.png",
                "parentId": "51ffd2161e7cd",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30eafb0fad987d38cf6c1b67b41a84a2": {
                    "id": null,
                    "title": "Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                    "image": null,
                    "stock": 0
                  },
                  "51ffd2161e7cd": {
                    "id": null,
                    "title": "Accesorios de Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "503e4c5ec591d",
                "title": "Módulos Transceptores",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Modulos-Transceptores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/transceptores.png",
                "parentId": "51ffd2161e7cd",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30eafb0fad987d38cf6c1b67b41a84a2": {
                    "id": null,
                    "title": "Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                    "image": null,
                    "stock": 0
                  },
                  "51ffd2161e7cd": {
                    "id": null,
                    "title": "Accesorios de Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "559d392112ff3",
                "title": "Tarjetas y Módulos de Red",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Tarjetas-y-Modulos-de-Red/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjetas-modulos-red.png",
                "parentId": "51ffd2161e7cd",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30eafb0fad987d38cf6c1b67b41a84a2": {
                    "id": null,
                    "title": "Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                    "image": null,
                    "stock": 0
                  },
                  "51ffd2161e7cd": {
                    "id": null,
                    "title": "Accesorios de Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "543d61b93dcdd",
                "title": "Módulos de Red de Voz",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Modulos-de-Red-de-Voz/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/modulos-red-voz.png",
                "parentId": "51ffd2161e7cd",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30eafb0fad987d38cf6c1b67b41a84a2": {
                    "id": null,
                    "title": "Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                    "image": null,
                    "stock": 0
                  },
                  "51ffd2161e7cd": {
                    "id": null,
                    "title": "Accesorios de Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5c071959b9535",
                "title": "Convertidores de Medios",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Convertidores-de-Medios/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cp-startechcom-mcm1110mmlc-1.png",
                "parentId": "51ffd2161e7cd",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30eafb0fad987d38cf6c1b67b41a84a2": {
                    "id": null,
                    "title": "Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                    "image": null,
                    "stock": 0
                  },
                  "51ffd2161e7cd": {
                    "id": null,
                    "title": "Accesorios de Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "12e106c86e10bb3d574d6481c9a63791",
                "title": "Energía para Redes",
                "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Redes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-redes2.png",
                "parentId": "51ffd2161e7cd",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30eafb0fad987d38cf6c1b67b41a84a2": {
                    "id": null,
                    "title": "Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                    "image": null,
                    "stock": 0
                  },
                  "51ffd2161e7cd": {
                    "id": null,
                    "title": "Accesorios de Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5c0719bf1ec13",
                "title": "Routerboards",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Routerboards/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cp-mikrotik-rb922uags-5hpacd-1.png",
                "parentId": "51ffd2161e7cd",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30eafb0fad987d38cf6c1b67b41a84a2": {
                    "id": null,
                    "title": "Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                    "image": null,
                    "stock": 0
                  },
                  "51ffd2161e7cd": {
                    "id": null,
                    "title": "Accesorios de Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5a9998f008593",
                "title": "Multiplexores",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Multiplexores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/multiplexor.png",
                "parentId": "51ffd2161e7cd",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30eafb0fad987d38cf6c1b67b41a84a2": {
                    "id": null,
                    "title": "Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                    "image": null,
                    "stock": 0
                  },
                  "51ffd2161e7cd": {
                    "id": null,
                    "title": "Accesorios de Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "62bf6057edc3d",
                "title": "Servidores Seriales",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Servidores-Seriales/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/servidores-seriales.png",
                "parentId": "51ffd2161e7cd",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30eafb0fad987d38cf6c1b67b41a84a2": {
                    "id": null,
                    "title": "Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                    "image": null,
                    "stock": 0
                  },
                  "51ffd2161e7cd": {
                    "id": null,
                    "title": "Accesorios de Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "55a4036fc8d9b",
                "title": "Montaje de Redes",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Montaje-de-Redes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/montaje-redes.png",
                "parentId": "51ffd2161e7cd",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30eafb0fad987d38cf6c1b67b41a84a2": {
                    "id": null,
                    "title": "Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                    "image": null,
                    "stock": 0
                  },
                  "51ffd2161e7cd": {
                    "id": null,
                    "title": "Accesorios de Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "39b0803f86f28d9db61a5708c3d373a4",
                "title": "Torres y Mástiles",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Torres-y-Mastiles/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/39b0803f86f28d9db61a5708c3d373a4-CP.png",
                "parentId": "51ffd2161e7cd",
                "subCategories": [
                  {
                    "id": "b1e4fb80f40c81824118421b48f0b553",
                    "title": "Torres Arriostradas",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Torres-y-Mastiles/Torres-Arriostradas/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/b1e4fb80f40c81824118421b48f0b553-CP.png",
                    "parentId": "39b0803f86f28d9db61a5708c3d373a4",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "30eafb0fad987d38cf6c1b67b41a84a2": {
                        "id": null,
                        "title": "Redes",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                        "image": null,
                        "stock": 0
                      },
                      "51ffd2161e7cd": {
                        "id": null,
                        "title": "Accesorios de Redes",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                        "image": null,
                        "stock": 0
                      },
                      "39b0803f86f28d9db61a5708c3d373a4": {
                        "id": null,
                        "title": "Torres y Mástiles",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Torres-y-Mastiles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "5953a7be1085a893f0bd8d5273776dfc",
                    "title": "Mástiles",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Torres-y-Mastiles/Mastiles/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/5953a7be1085a893f0bd8d5273776dfc-CP.png",
                    "parentId": "39b0803f86f28d9db61a5708c3d373a4",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "30eafb0fad987d38cf6c1b67b41a84a2": {
                        "id": null,
                        "title": "Redes",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                        "image": null,
                        "stock": 0
                      },
                      "51ffd2161e7cd": {
                        "id": null,
                        "title": "Accesorios de Redes",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                        "image": null,
                        "stock": 0
                      },
                      "39b0803f86f28d9db61a5708c3d373a4": {
                        "id": null,
                        "title": "Torres y Mástiles",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Torres-y-Mastiles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "5304a5e0ae47088d20d1565afc9fbc95",
                    "title": "Accesorios para Torres Arriostradas",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Torres-y-Mastiles/Accesorios-para-Torres-Arriostradas/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/5304a5e0ae47088d20d1565afc9fbc95-CP.png",
                    "parentId": "39b0803f86f28d9db61a5708c3d373a4",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "30eafb0fad987d38cf6c1b67b41a84a2": {
                        "id": null,
                        "title": "Redes",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                        "image": null,
                        "stock": 0
                      },
                      "51ffd2161e7cd": {
                        "id": null,
                        "title": "Accesorios de Redes",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                        "image": null,
                        "stock": 0
                      },
                      "39b0803f86f28d9db61a5708c3d373a4": {
                        "id": null,
                        "title": "Torres y Mástiles",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Torres-y-Mastiles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "fd7ee08517820ded499e10e70f9d2a53",
                    "title": "Anclas Temporales",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Torres-y-Mastiles/Anclas-Temporales/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/fd7ee08517820ded499e10e70f9d2a53-CP.png",
                    "parentId": "39b0803f86f28d9db61a5708c3d373a4",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=6k76gqvvg00vmqtn24udc3d379",
                        "image": null,
                        "stock": 0
                      },
                      "30eafb0fad987d38cf6c1b67b41a84a2": {
                        "id": null,
                        "title": "Redes",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/?force_sid=6k76gqvvg00vmqtn24udc3d379",
                        "image": null,
                        "stock": 0
                      },
                      "51ffd2161e7cd": {
                        "id": null,
                        "title": "Accesorios de Redes",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/?force_sid=6k76gqvvg00vmqtn24udc3d379",
                        "image": null,
                        "stock": 0
                      },
                      "39b0803f86f28d9db61a5708c3d373a4": {
                        "id": null,
                        "title": "Torres y Mástiles",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Torres-y-Mastiles/?force_sid=6k76gqvvg00vmqtn24udc3d379",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "0435fdcc3fb562cc6dfecc553eb28a32",
                    "title": "Montaje para Torres",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Torres-y-Mastiles/Montaje-para-Torres/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/0435fdcc3fb562cc6dfecc553eb28a32-CP.png",
                    "parentId": "39b0803f86f28d9db61a5708c3d373a4",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "30eafb0fad987d38cf6c1b67b41a84a2": {
                        "id": null,
                        "title": "Redes",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                        "image": null,
                        "stock": 0
                      },
                      "51ffd2161e7cd": {
                        "id": null,
                        "title": "Accesorios de Redes",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                        "image": null,
                        "stock": 0
                      },
                      "39b0803f86f28d9db61a5708c3d373a4": {
                        "id": null,
                        "title": "Torres y Mástiles",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/Torres-y-Mastiles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "30eafb0fad987d38cf6c1b67b41a84a2": {
                    "id": null,
                    "title": "Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                    "image": null,
                    "stock": 0
                  },
                  "51ffd2161e7cd": {
                    "id": null,
                    "title": "Accesorios de Redes",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Accesorios-de-Redes/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30eafb0fad987d38cf6c1b67b41a84a2": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "99748977a0a9f40e2.09126772": {
            "id": null,
            "title": "Cómputo (Hardware)",
            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "68cad6f4086bfaf3ba2dc11e30aa06cd",
        "title": "Servidores",
        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/servidores.png",
        "parentId": "99748977a0a9f40e2.09126772",
        "subCategories": [
          {
            "id": "35e7a82bca0b81e9acbc95d64fbf5757",
            "title": "Servidores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Servidores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/servidores2.png",
            "parentId": "68cad6f4086bfaf3ba2dc11e30aa06cd",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                "id": null,
                "title": "Servidores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "35e038c9e5171e03ad2e319224a94762",
            "title": "Servidores de Impresión",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Servidores-de-Impresion/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/servidor-impresion.png",
            "parentId": "68cad6f4086bfaf3ba2dc11e30aa06cd",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                "id": null,
                "title": "Servidores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "54eb7806c360e",
            "title": "Unidades de Cinta",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Unidades-de-Cinta/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/unidades-cinta.png",
            "parentId": "68cad6f4086bfaf3ba2dc11e30aa06cd",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                "id": null,
                "title": "Servidores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "53bf0ede1aed0",
            "title": "Discos Duros para Servidores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Discos-Duros-para-Servidores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hot-plug(1).png",
            "parentId": "68cad6f4086bfaf3ba2dc11e30aa06cd",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                "id": null,
                "title": "Servidores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51b7a417069b6",
            "title": "Accesorios para Servidores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-servidores.png",
            "parentId": "68cad6f4086bfaf3ba2dc11e30aa06cd",
            "subCategories": [
              {
                "id": "51b7a4691ea13",
                "title": "Racks y Gabinetes",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/Racks-y-Gabinetes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/racks.png",
                "parentId": "51b7a417069b6",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                    "image": null,
                    "stock": 0
                  },
                  "51b7a417069b6": {
                    "id": null,
                    "title": "Accesorios para Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51b7a482daa79",
                "title": "Charolas para Racks y Gabinetes",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/Charolas-para-Racks-y-Gabinetes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/charolas.png",
                "parentId": "51b7a417069b6",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                    "image": null,
                    "stock": 0
                  },
                  "51b7a417069b6": {
                    "id": null,
                    "title": "Accesorios para Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51f98a4b62a11",
                "title": "Consolas de Rack",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/Consolas-de-Rack/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/consolas-rack.png",
                "parentId": "51b7a417069b6",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                    "image": null,
                    "stock": 0
                  },
                  "51b7a417069b6": {
                    "id": null,
                    "title": "Accesorios para Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "53bf0f505f9ee",
                "title": "Soportes y Paneles",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/Soportes-y-Paneles/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soportes-paneles.png",
                "parentId": "51b7a417069b6",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                    "image": null,
                    "stock": 0
                  },
                  "51b7a417069b6": {
                    "id": null,
                    "title": "Accesorios para Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51e6ce97e22fc",
                "title": "Fuentes de Poder",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/Fuentes-de-Poder/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fuentes-poder.png",
                "parentId": "51b7a417069b6",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                    "image": null,
                    "stock": 0
                  },
                  "51b7a417069b6": {
                    "id": null,
                    "title": "Accesorios para Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51c211193b418",
                "title": "Cableado Estructurado para Servidores",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/Cableado-Estructurado-para-Servidores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cableado-estructurado.png",
                "parentId": "51b7a417069b6",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                    "image": null,
                    "stock": 0
                  },
                  "51b7a417069b6": {
                    "id": null,
                    "title": "Accesorios para Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51b7a4fc70278",
                "title": "Kits de Montaje",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/Kits-de-Montaje/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/kits-montaje.png",
                "parentId": "51b7a417069b6",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=l5efsa44toghr4vadqnj0ii2mp",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/?force_sid=l5efsa44toghr4vadqnj0ii2mp",
                    "image": null,
                    "stock": 0
                  },
                  "51b7a417069b6": {
                    "id": null,
                    "title": "Accesorios para Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/?force_sid=l5efsa44toghr4vadqnj0ii2mp",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51e02d38667a0",
                "title": "Switches KVM",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/Switches-KVM/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/switches-kvm.png",
                "parentId": "51b7a417069b6",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                    "image": null,
                    "stock": 0
                  },
                  "51b7a417069b6": {
                    "id": null,
                    "title": "Accesorios para Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51e02d4b61b8b",
                "title": "Cables KVM",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/Cables-KVM/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables-kvm.png",
                "parentId": "51b7a417069b6",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                    "image": null,
                    "stock": 0
                  },
                  "51b7a417069b6": {
                    "id": null,
                    "title": "Accesorios para Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5852d84d8c23d",
                "title": "Enfriamiento para Servidores",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/Enfriamiento-para-Servidores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/enfriamentoservidores.png",
                "parentId": "51b7a417069b6",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                    "image": null,
                    "stock": 0
                  },
                  "51b7a417069b6": {
                    "id": null,
                    "title": "Accesorios para Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Accesorios-para-Servidores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                "id": null,
                "title": "Servidores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "7a6342a1ffd3d4c4bdcf3c144acebeac",
            "title": "Soportes de Datos",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Soportes-de-Datos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soportededatos.png",
            "parentId": "68cad6f4086bfaf3ba2dc11e30aa06cd",
            "subCategories": [
              {
                "id": "51e036e535c51",
                "title": "DLT",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Soportes-de-Datos/DLT/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/dlt.png",
                "parentId": "7a6342a1ffd3d4c4bdcf3c144acebeac",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                    "image": null,
                    "stock": 0
                  },
                  "7a6342a1ffd3d4c4bdcf3c144acebeac": {
                    "id": null,
                    "title": "Soportes de Datos",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Soportes-de-Datos/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51e037056c5db",
                "title": "Ultrium",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Soportes-de-Datos/Ultrium/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ultrium(1).png",
                "parentId": "7a6342a1ffd3d4c4bdcf3c144acebeac",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                    "image": null,
                    "stock": 0
                  },
                  "7a6342a1ffd3d4c4bdcf3c144acebeac": {
                    "id": null,
                    "title": "Soportes de Datos",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Soportes-de-Datos/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                "id": null,
                "title": "Servidores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "50a6b30d6ce94",
            "title": "Cartuchos de Limpieza",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Cartuchos-de-Limpieza/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cartuchos-limpieza.png",
            "parentId": "68cad6f4086bfaf3ba2dc11e30aa06cd",
            "subCategories": [
              {
                "id": "51e0375f77521",
                "title": "Ultrium",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Cartuchos-de-Limpieza/Ultrium/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ultrium.png",
                "parentId": "50a6b30d6ce94",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=po0ljqi7usv0ee888pqmjdna13",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/?force_sid=po0ljqi7usv0ee888pqmjdna13",
                    "image": null,
                    "stock": 0
                  },
                  "50a6b30d6ce94": {
                    "id": null,
                    "title": "Cartuchos de Limpieza",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Cartuchos-de-Limpieza/?force_sid=po0ljqi7usv0ee888pqmjdna13",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51e037938d3a3",
                "title": "DDS",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Cartuchos-de-Limpieza/DDS/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/dds-dat(1).png",
                "parentId": "50a6b30d6ce94",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                    "id": null,
                    "title": "Servidores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                    "image": null,
                    "stock": 0
                  },
                  "50a6b30d6ce94": {
                    "id": null,
                    "title": "Cartuchos de Limpieza",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/Cartuchos-de-Limpieza/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                "id": null,
                "title": "Servidores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "ab571689893510d0e6f756351523e8a2",
            "title": "Sistemas Operativos para Servidores",
            "link": "https://www.cyberpuerta.mx/Software-y-Servicios/Sistemas-Operativos-para-Servidores/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/ab571689893510d0e6f756351523e8a2-CP.png",
            "parentId": "68cad6f4086bfaf3ba2dc11e30aa06cd",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "68cad6f4086bfaf3ba2dc11e30aa06cd": {
                "id": null,
                "title": "Servidores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Servidores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "99748977a0a9f40e2.09126772": {
            "id": null,
            "title": "Cómputo (Hardware)",
            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "86a7fab2cad69bcd4bbb00964c59cebb",
        "title": "Adaptadores",
        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores2.png",
        "parentId": "99748977a0a9f40e2.09126772",
        "subCategories": [
          {
            "id": "4fe38d830e16f",
            "title": "Adaptadores Audio",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Audio/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptador-de-audio.png",
            "parentId": "86a7fab2cad69bcd4bbb00964c59cebb",
            "subCategories": [
              {
                "id": "4fe8b9503deab",
                "title": "Adaptadores Audio USB",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Audio/Adaptadores-Audio-USB/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/usb(2).png",
                "parentId": "4fe38d830e16f",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38d830e16f": {
                    "id": null,
                    "title": "Adaptadores Audio",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b5fa67730",
                "title": "Adaptadores Audio AUX",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Audio/Adaptadores-Audio-AUX/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/auxiliar.png",
                "parentId": "4fe38d830e16f",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38d830e16f": {
                    "id": null,
                    "title": "Adaptadores Audio",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b646431af",
                "title": "Toslink",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Audio/Toslink/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/toslink.png",
                "parentId": "4fe38d830e16f",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38d830e16f": {
                    "id": null,
                    "title": "Adaptadores Audio",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b65b1d569",
                "title": "Adaptadores XLR",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Audio/Adaptadores-XLR/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/xlr(1).png",
                "parentId": "4fe38d830e16f",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38d830e16f": {
                    "id": null,
                    "title": "Adaptadores Audio",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "86a7fab2cad69bcd4bbb00964c59cebb": {
                "id": null,
                "title": "Adaptadores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "4fe38da246d6a",
            "title": "Adaptadores Video",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores-video.png",
            "parentId": "86a7fab2cad69bcd4bbb00964c59cebb",
            "subCategories": [
              {
                "id": "4fe3b6972cb13",
                "title": "Adaptadores Video DVI",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/Adaptadores-Video-DVI/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/dvi(1).png",
                "parentId": "4fe38da246d6a",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38da246d6a": {
                    "id": null,
                    "title": "Adaptadores Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b6b52ccd0",
                "title": "Adaptadores Video HDMI",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/Adaptadores-Video-HDMI/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hdmi(1).png",
                "parentId": "4fe38da246d6a",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38da246d6a": {
                    "id": null,
                    "title": "Adaptadores Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b6da791b8",
                "title": "Adaptadores Video VGA / HD15",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/Adaptadores-Video-VGA-HD15/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/vga-hd15.png",
                "parentId": "4fe38da246d6a",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38da246d6a": {
                    "id": null,
                    "title": "Adaptadores Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe8bef1e8430",
                "title": "Adaptadores Video DisplayPort",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/Adaptadores-Video-DisplayPort/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/display-port.png",
                "parentId": "4fe38da246d6a",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38da246d6a": {
                    "id": null,
                    "title": "Adaptadores Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b6caf2a23",
                "title": "Adaptadores Video S-Video",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/Adaptadores-Video-S-Video/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/s-video(1).png",
                "parentId": "4fe38da246d6a",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38da246d6a": {
                    "id": null,
                    "title": "Adaptadores Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "1e67ad61088b09508ce4a6722268d389",
                "title": "Divisores de Video",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/Divisores-de-Video/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/video-splitter.png",
                "parentId": "4fe38da246d6a",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38da246d6a": {
                    "id": null,
                    "title": "Adaptadores Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5ba9300320ac7",
                "title": "Extensores y Receptores de Video",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/Extensores-y-Receptores-de-Video/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/externsorvideo.png",
                "parentId": "4fe38da246d6a",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38da246d6a": {
                    "id": null,
                    "title": "Adaptadores Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "58068c40e7102",
                "title": "Puentes SLI",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/Puentes-SLI/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/puentes-sli.png",
                "parentId": "4fe38da246d6a",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38da246d6a": {
                    "id": null,
                    "title": "Adaptadores Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5837633737e99",
                "title": "Capturadoras de Video",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/Capturadoras-de-Video/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/capturadorasdevideo.png",
                "parentId": "4fe38da246d6a",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=di2i7osarahsl0bhecrjp8ebrb",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/?force_sid=di2i7osarahsl0bhecrjp8ebrb",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38da246d6a": {
                    "id": null,
                    "title": "Adaptadores Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-Video/?force_sid=di2i7osarahsl0bhecrjp8ebrb",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "86a7fab2cad69bcd4bbb00964c59cebb": {
                "id": null,
                "title": "Adaptadores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "4fe38db974150",
            "title": "Adaptadores PC",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores-pc.png",
            "parentId": "86a7fab2cad69bcd4bbb00964c59cebb",
            "subCategories": [
              {
                "id": "4fe3b70c55662",
                "title": "Bluetooth",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/Bluetooth/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/bluetooth.png",
                "parentId": "4fe38db974150",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=elrllrb7kg3aok7385sjg055qm",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/?force_sid=elrllrb7kg3aok7385sjg055qm",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38db974150": {
                    "id": null,
                    "title": "Adaptadores PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/?force_sid=elrllrb7kg3aok7385sjg055qm",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "500edb6de711c",
                "title": "Switches KVM",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/Switches-KVM/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/switch-kvm.png",
                "parentId": "4fe38db974150",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38db974150": {
                    "id": null,
                    "title": "Adaptadores PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b7c04fb36",
                "title": "Adaptadores PC USB",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/Adaptadores-PC-USB/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/usb(3).png",
                "parentId": "4fe38db974150",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38db974150": {
                    "id": null,
                    "title": "Adaptadores PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "53d93761d7604",
                "title": "Adaptadores PC Serial",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/Adaptadores-PC-Serial/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/serial.png",
                "parentId": "4fe38db974150",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38db974150": {
                    "id": null,
                    "title": "Adaptadores PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b7942dd9c",
                "title": "Adaptadores PC Teclado",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/Adaptadores-PC-Teclado/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptador-teclado.png",
                "parentId": "4fe38db974150",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38db974150": {
                    "id": null,
                    "title": "Adaptadores PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b7d0b31fd",
                "title": "Adaptadores PC SATA",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/Adaptadores-PC-SATA/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores-sata.png",
                "parentId": "4fe38db974150",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38db974150": {
                    "id": null,
                    "title": "Adaptadores PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b7a252c71",
                "title": "Adaptadores PC PS/2",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/Adaptadores-PC-PS-2/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ps2.png",
                "parentId": "4fe38db974150",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38db974150": {
                    "id": null,
                    "title": "Adaptadores PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b77384672",
                "title": "Adaptadores PC IDE",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/Adaptadores-PC-IDE/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores-ide.png",
                "parentId": "4fe38db974150",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38db974150": {
                    "id": null,
                    "title": "Adaptadores PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3b7b12950b",
                "title": "RJ-45",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/RJ-45/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/rj-45.png",
                "parentId": "4fe38db974150",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "86a7fab2cad69bcd4bbb00964c59cebb": {
                    "id": null,
                    "title": "Adaptadores",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe38db974150": {
                    "id": null,
                    "title": "Adaptadores PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Adaptadores-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "86a7fab2cad69bcd4bbb00964c59cebb": {
                "id": null,
                "title": "Adaptadores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "7bb67ac5d645afb17397b00c7f2d550f",
            "title": "Repetidores USB",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/Repetidores-USB/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/repetidores-usb.png",
            "parentId": "86a7fab2cad69bcd4bbb00964c59cebb",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "86a7fab2cad69bcd4bbb00964c59cebb": {
                "id": null,
                "title": "Adaptadores",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "99748977a0a9f40e2.09126772": {
            "id": null,
            "title": "Cómputo (Hardware)",
            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "4e9572d4a1515cc5550b24cb4f340236",
        "title": "Cables",
        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables2.png",
        "parentId": "99748977a0a9f40e2.09126772",
        "subCategories": [
          {
            "id": "4fd7abac4ab41",
            "title": "Bobinas",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Bobinas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables-bobina.png",
            "parentId": "4e9572d4a1515cc5550b24cb4f340236",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4e9572d4a1515cc5550b24cb4f340236": {
                "id": null,
                "title": "Cables",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3b9ce3d132",
            "title": "Cables PC",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cablespc.png",
            "parentId": "4e9572d4a1515cc5550b24cb4f340236",
            "subCategories": [
              {
                "id": "4fe3baadc1b41",
                "title": "Cables PC KVM",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/Cables-PC-KVM/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/kvm.jpg",
                "parentId": "4fe3b9ce3d132",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3b9ce3d132": {
                    "id": null,
                    "title": "Cables PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5c4145fc5d4c1b749e73222a88ce20fa",
                "title": "Cables PC USB",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/Cables-PC-USB/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/usb.png",
                "parentId": "4fe3b9ce3d132",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3b9ce3d132": {
                    "id": null,
                    "title": "Cables PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "54ff50ca94304",
                "title": "Cables PC VGA / HD15",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/Cables-PC-VGA-HD15/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/vga-hd15(1).png",
                "parentId": "4fe3b9ce3d132",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3b9ce3d132": {
                    "id": null,
                    "title": "Cables PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3ba9b305ff",
                "title": "Cables PC Serial",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/Cables-PC-Serial/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/serial.jpg",
                "parentId": "4fe3b9ce3d132",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3b9ce3d132": {
                    "id": null,
                    "title": "Cables PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5c448c8b4294f10082102df389d638bc",
                "title": "Cables PC SATA / eSATA",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/Cables-PC-SATA-eSATA/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sata.png",
                "parentId": "4fe3b9ce3d132",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=se44dackv3k058k71ick5cv0rn",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/?force_sid=se44dackv3k058k71ick5cv0rn",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3b9ce3d132": {
                    "id": null,
                    "title": "Cables PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/?force_sid=se44dackv3k058k71ick5cv0rn",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3bb0a58900",
                "title": "Cables PC SAS",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/Cables-PC-SAS/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sas.png",
                "parentId": "4fe3b9ce3d132",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3b9ce3d132": {
                    "id": null,
                    "title": "Cables PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3ba48b42a3",
                "title": "Cables PC FireWire",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/Cables-PC-FireWire/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/firewire.jpg",
                "parentId": "4fe3b9ce3d132",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3b9ce3d132": {
                    "id": null,
                    "title": "Cables PC",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-PC/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4e9572d4a1515cc5550b24cb4f340236": {
                "id": null,
                "title": "Cables",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5c40994a565a1a7268cc16fc163617af",
            "title": "Cables de Audio / Video",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cablesdeaudio.png",
            "parentId": "4e9572d4a1515cc5550b24cb4f340236",
            "subCategories": [
              {
                "id": "4fe3bb9b3b4a1",
                "title": "Cables de Audio / Video DVI",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/Cables-de-Audio-Video-DVI/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/dvi(2).png",
                "parentId": "5c40994a565a1a7268cc16fc163617af",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5c40994a565a1a7268cc16fc163617af": {
                    "id": null,
                    "title": "Cables de Audio - Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5c48ef812211fc95ef51d62af0ce401a",
                "title": "Cables de Audio / Video HDMI",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/Cables-de-Audio-Video-HDMI/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hdmi.png",
                "parentId": "5c40994a565a1a7268cc16fc163617af",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5c40994a565a1a7268cc16fc163617af": {
                    "id": null,
                    "title": "Cables de Audio - Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3bc0ac61cc",
                "title": "Cables de Audio / Video VGA",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/Cables-de-Audio-Video-VGA/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/vga(2).png",
                "parentId": "5c40994a565a1a7268cc16fc163617af",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5c40994a565a1a7268cc16fc163617af": {
                    "id": null,
                    "title": "Cables de Audio - Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3bc51f2c85",
                "title": "Cables de Audio / Video DisplayPort",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/Cables-de-Audio-Video-DisplayPort/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/displayport(1).png",
                "parentId": "5c40994a565a1a7268cc16fc163617af",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5c40994a565a1a7268cc16fc163617af": {
                    "id": null,
                    "title": "Cables de Audio - Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3bb34890de",
                "title": "Cables de Audio / Video AUX",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/Cables-de-Audio-Video-AUX/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/aux1.png",
                "parentId": "5c40994a565a1a7268cc16fc163617af",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5c40994a565a1a7268cc16fc163617af": {
                    "id": null,
                    "title": "Cables de Audio - Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3bb8442c6d",
                "title": "Cables XLR",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/Cables-XLR/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/xlr.png",
                "parentId": "5c40994a565a1a7268cc16fc163617af",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5c40994a565a1a7268cc16fc163617af": {
                    "id": null,
                    "title": "Cables de Audio - Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "54c1350e82467",
                "title": "Cables de Audio / Video BNC",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/Cables-de-Audio-Video-BNC/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables-bnc.png",
                "parentId": "5c40994a565a1a7268cc16fc163617af",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5c40994a565a1a7268cc16fc163617af": {
                    "id": null,
                    "title": "Cables de Audio - Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3bb6e10e4f",
                "title": "Cables de Audio / Video Toslink",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/Cables-de-Audio-Video-Toslink/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/toslink.png",
                "parentId": "5c40994a565a1a7268cc16fc163617af",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5c40994a565a1a7268cc16fc163617af": {
                    "id": null,
                    "title": "Cables de Audio - Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe8ff1ab5bae",
                "title": "Cables de Audio / Video RCA",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/Cables-de-Audio-Video-RCA/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/rca.png",
                "parentId": "5c40994a565a1a7268cc16fc163617af",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5c40994a565a1a7268cc16fc163617af": {
                    "id": null,
                    "title": "Cables de Audio - Video",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Audio-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4e9572d4a1515cc5550b24cb4f340236": {
                "id": null,
                "title": "Cables",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "55771e233a596",
            "title": "Cableado Estructurado",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cable-estructurado.png",
            "parentId": "4e9572d4a1515cc5550b24cb4f340236",
            "subCategories": [
              {
                "id": "d278aed76607a6607a8da6daaff60521",
                "title": "Condulets",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Condulets/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/d278aed76607a6607a8da6daaff60521-CP.png",
                "parentId": "55771e233a596",
                "subCategories": [
                  {
                    "id": "76b755d916f805a1a4f39f1d1e7fef76",
                    "title": "Cajas Condulets",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Condulets/Cajas-Condulets/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66a3d06528910-CP.png",
                    "parentId": "d278aed76607a6607a8da6daaff60521",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "55771e233a596": {
                        "id": null,
                        "title": "Cableado Estructurado",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                        "image": null,
                        "stock": 0
                      },
                      "d278aed76607a6607a8da6daaff60521": {
                        "id": null,
                        "title": "Condulets",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Condulets/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "67ea71e0b96609977eed65c1e3649d22",
                    "title": "Tapas para Condulets",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Condulets/Tapas-para-Condulets/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66a3d09e46353-CP.png",
                    "parentId": "d278aed76607a6607a8da6daaff60521",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "55771e233a596": {
                        "id": null,
                        "title": "Cableado Estructurado",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                        "image": null,
                        "stock": 0
                      },
                      "d278aed76607a6607a8da6daaff60521": {
                        "id": null,
                        "title": "Condulets",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Condulets/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "55771e233a596": {
                    "id": null,
                    "title": "Cableado Estructurado",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "29782811a0050d4b5f5173d5c261a7c1",
                "title": "Canaletas",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/29782811a0050d4b5f5173d5c261a7c1-CP.png",
                "parentId": "55771e233a596",
                "subCategories": [
                  {
                    "id": "1e70431e5af043ab15c8205f4a16cb6c",
                    "title": "Canaletas Eléctricas",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Canaletas-Electricas/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66a3d0b09babb-CP.png",
                    "parentId": "29782811a0050d4b5f5173d5c261a7c1",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "55771e233a596": {
                        "id": null,
                        "title": "Cableado Estructurado",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                        "image": null,
                        "stock": 0
                      },
                      "29782811a0050d4b5f5173d5c261a7c1": {
                        "id": null,
                        "title": "Canaletas",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "5bc9fb14b5686",
                    "title": "Tapas para Canaletas",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Tapas-para-Canaletas/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tapasparacanaleta1.png",
                    "parentId": "29782811a0050d4b5f5173d5c261a7c1",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "55771e233a596": {
                        "id": null,
                        "title": "Cableado Estructurado",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                        "image": null,
                        "stock": 0
                      },
                      "29782811a0050d4b5f5173d5c261a7c1": {
                        "id": null,
                        "title": "Canaletas",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "dd6243901f97c6d3d2f658bb88599923",
                    "title": "Sujetadores de Canaletas",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Sujetadores-de-Canaletas/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/dd6243901f97c6d3d2f658bb88599923-CP.png",
                    "parentId": "29782811a0050d4b5f5173d5c261a7c1",
                    "subCategories": [
                      {
                        "id": "5bc8e61d2ef53",
                        "title": "Ménsulas para Canaletas",
                        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Sujetadores-de-Canaletas/Mensulas-para-Canaletas/",
                        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sujetadordecanaleta.png",
                        "parentId": "dd6243901f97c6d3d2f658bb88599923",
                        "subCategories": [],
                        "path": {
                          "99748977a0a9f40e2.09126772": {
                            "id": null,
                            "title": "Cómputo (Hardware)",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                            "image": null,
                            "stock": 0
                          },
                          "4e9572d4a1515cc5550b24cb4f340236": {
                            "id": null,
                            "title": "Cables",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                            "image": null,
                            "stock": 0
                          },
                          "55771e233a596": {
                            "id": null,
                            "title": "Cableado Estructurado",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                            "image": null,
                            "stock": 0
                          },
                          "29782811a0050d4b5f5173d5c261a7c1": {
                            "id": null,
                            "title": "Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/",
                            "image": null,
                            "stock": 0
                          },
                          "dd6243901f97c6d3d2f658bb88599923": {
                            "id": null,
                            "title": "Sujetadores de Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Sujetadores-de-Canaletas/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      },
                      {
                        "id": "a4dd7cd1ecc58aae8283e12191e4ca2a",
                        "title": "Uniones para Canaletas",
                        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Sujetadores-de-Canaletas/Uniones-para-Canaletas/",
                        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/a4dd7cd1ecc58aae8283e12191e4ca2a-CP.png",
                        "parentId": "dd6243901f97c6d3d2f658bb88599923",
                        "subCategories": [],
                        "path": {
                          "99748977a0a9f40e2.09126772": {
                            "id": null,
                            "title": "Cómputo (Hardware)",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                            "image": null,
                            "stock": 0
                          },
                          "4e9572d4a1515cc5550b24cb4f340236": {
                            "id": null,
                            "title": "Cables",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                            "image": null,
                            "stock": 0
                          },
                          "55771e233a596": {
                            "id": null,
                            "title": "Cableado Estructurado",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                            "image": null,
                            "stock": 0
                          },
                          "29782811a0050d4b5f5173d5c261a7c1": {
                            "id": null,
                            "title": "Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/",
                            "image": null,
                            "stock": 0
                          },
                          "dd6243901f97c6d3d2f658bb88599923": {
                            "id": null,
                            "title": "Sujetadores de Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Sujetadores-de-Canaletas/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      },
                      {
                        "id": "5ee70790523b372a098f7626ae4e471b",
                        "title": "Bajadas para Canaletas",
                        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Sujetadores-de-Canaletas/Bajadas-para-Canaletas/",
                        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/5ee70790523b372a098f7626ae4e471b-CP.png",
                        "parentId": "dd6243901f97c6d3d2f658bb88599923",
                        "subCategories": [],
                        "path": {
                          "99748977a0a9f40e2.09126772": {
                            "id": null,
                            "title": "Cómputo (Hardware)",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                            "image": null,
                            "stock": 0
                          },
                          "4e9572d4a1515cc5550b24cb4f340236": {
                            "id": null,
                            "title": "Cables",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                            "image": null,
                            "stock": 0
                          },
                          "55771e233a596": {
                            "id": null,
                            "title": "Cableado Estructurado",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                            "image": null,
                            "stock": 0
                          },
                          "29782811a0050d4b5f5173d5c261a7c1": {
                            "id": null,
                            "title": "Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/",
                            "image": null,
                            "stock": 0
                          },
                          "dd6243901f97c6d3d2f658bb88599923": {
                            "id": null,
                            "title": "Sujetadores de Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Sujetadores-de-Canaletas/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      },
                      {
                        "id": "7c0aefc5d2fc41f2ecd4789d8d365e1e",
                        "title": "Abrazaderas para Canaletas",
                        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Sujetadores-de-Canaletas/Abrazaderas-para-Canaletas/",
                        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/7c0aefc5d2fc41f2ecd4789d8d365e1e-CP.png",
                        "parentId": "dd6243901f97c6d3d2f658bb88599923",
                        "subCategories": [],
                        "path": {
                          "99748977a0a9f40e2.09126772": {
                            "id": null,
                            "title": "Cómputo (Hardware)",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                            "image": null,
                            "stock": 0
                          },
                          "4e9572d4a1515cc5550b24cb4f340236": {
                            "id": null,
                            "title": "Cables",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                            "image": null,
                            "stock": 0
                          },
                          "55771e233a596": {
                            "id": null,
                            "title": "Cableado Estructurado",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                            "image": null,
                            "stock": 0
                          },
                          "29782811a0050d4b5f5173d5c261a7c1": {
                            "id": null,
                            "title": "Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/",
                            "image": null,
                            "stock": 0
                          },
                          "dd6243901f97c6d3d2f658bb88599923": {
                            "id": null,
                            "title": "Sujetadores de Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Sujetadores-de-Canaletas/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      }
                    ],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "55771e233a596": {
                        "id": null,
                        "title": "Cableado Estructurado",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                        "image": null,
                        "stock": 0
                      },
                      "29782811a0050d4b5f5173d5c261a7c1": {
                        "id": null,
                        "title": "Canaletas",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": true
                  },
                  {
                    "id": "c2db05ae5fa3a5c5d4557e9ed89d350e",
                    "title": "Conectores para Canaletas",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Conectores-para-Canaletas/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66a3d0e46d65a-CP.png",
                    "parentId": "29782811a0050d4b5f5173d5c261a7c1",
                    "subCategories": [
                      {
                        "id": "5bca005d5df73",
                        "title": "Esquinas",
                        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Conectores-para-Canaletas/Esquinas/",
                        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/esquina.png",
                        "parentId": "c2db05ae5fa3a5c5d4557e9ed89d350e",
                        "subCategories": [],
                        "path": {
                          "99748977a0a9f40e2.09126772": {
                            "id": null,
                            "title": "Cómputo (Hardware)",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                            "image": null,
                            "stock": 0
                          },
                          "4e9572d4a1515cc5550b24cb4f340236": {
                            "id": null,
                            "title": "Cables",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                            "image": null,
                            "stock": 0
                          },
                          "55771e233a596": {
                            "id": null,
                            "title": "Cableado Estructurado",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                            "image": null,
                            "stock": 0
                          },
                          "29782811a0050d4b5f5173d5c261a7c1": {
                            "id": null,
                            "title": "Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/",
                            "image": null,
                            "stock": 0
                          },
                          "c2db05ae5fa3a5c5d4557e9ed89d350e": {
                            "id": null,
                            "title": "Conectores para Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Conectores-para-Canaletas/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      },
                      {
                        "id": "5bca07480fb24",
                        "title": "Conexiones T",
                        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Conectores-para-Canaletas/Conexiones-T/",
                        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/conexionent.png",
                        "parentId": "c2db05ae5fa3a5c5d4557e9ed89d350e",
                        "subCategories": [],
                        "path": {
                          "99748977a0a9f40e2.09126772": {
                            "id": null,
                            "title": "Cómputo (Hardware)",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                            "image": null,
                            "stock": 0
                          },
                          "4e9572d4a1515cc5550b24cb4f340236": {
                            "id": null,
                            "title": "Cables",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                            "image": null,
                            "stock": 0
                          },
                          "55771e233a596": {
                            "id": null,
                            "title": "Cableado Estructurado",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                            "image": null,
                            "stock": 0
                          },
                          "29782811a0050d4b5f5173d5c261a7c1": {
                            "id": null,
                            "title": "Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/",
                            "image": null,
                            "stock": 0
                          },
                          "c2db05ae5fa3a5c5d4557e9ed89d350e": {
                            "id": null,
                            "title": "Conectores para Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Conectores-para-Canaletas/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      },
                      {
                        "id": "5bca03697b987",
                        "title": "Reductores",
                        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Conectores-para-Canaletas/Reductores/",
                        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/reductor.png",
                        "parentId": "c2db05ae5fa3a5c5d4557e9ed89d350e",
                        "subCategories": [],
                        "path": {
                          "99748977a0a9f40e2.09126772": {
                            "id": null,
                            "title": "Cómputo (Hardware)",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                            "image": null,
                            "stock": 0
                          },
                          "4e9572d4a1515cc5550b24cb4f340236": {
                            "id": null,
                            "title": "Cables",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                            "image": null,
                            "stock": 0
                          },
                          "55771e233a596": {
                            "id": null,
                            "title": "Cableado Estructurado",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                            "image": null,
                            "stock": 0
                          },
                          "29782811a0050d4b5f5173d5c261a7c1": {
                            "id": null,
                            "title": "Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/",
                            "image": null,
                            "stock": 0
                          },
                          "c2db05ae5fa3a5c5d4557e9ed89d350e": {
                            "id": null,
                            "title": "Conectores para Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Conectores-para-Canaletas/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      },
                      {
                        "id": "5c3fbcfd17abc",
                        "title": "Acopladores",
                        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Conectores-para-Canaletas/Acopladores/",
                        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/acopladores.png",
                        "parentId": "c2db05ae5fa3a5c5d4557e9ed89d350e",
                        "subCategories": [],
                        "path": {
                          "99748977a0a9f40e2.09126772": {
                            "id": null,
                            "title": "Cómputo (Hardware)",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                            "image": null,
                            "stock": 0
                          },
                          "4e9572d4a1515cc5550b24cb4f340236": {
                            "id": null,
                            "title": "Cables",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                            "image": null,
                            "stock": 0
                          },
                          "55771e233a596": {
                            "id": null,
                            "title": "Cableado Estructurado",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                            "image": null,
                            "stock": 0
                          },
                          "29782811a0050d4b5f5173d5c261a7c1": {
                            "id": null,
                            "title": "Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/",
                            "image": null,
                            "stock": 0
                          },
                          "c2db05ae5fa3a5c5d4557e9ed89d350e": {
                            "id": null,
                            "title": "Conectores para Canaletas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/Conectores-para-Canaletas/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      }
                    ],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "55771e233a596": {
                        "id": null,
                        "title": "Cableado Estructurado",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                        "image": null,
                        "stock": 0
                      },
                      "29782811a0050d4b5f5173d5c261a7c1": {
                        "id": null,
                        "title": "Canaletas",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Canaletas/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": true
                  }
                ],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "55771e233a596": {
                    "id": null,
                    "title": "Cableado Estructurado",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "b56efcd086808c6c0b8bdcaa2d057a1e",
                "title": "Sujetadores de Cables",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Sujetadores-de-Cables/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66a3d10c91738-CP.png",
                "parentId": "55771e233a596",
                "subCategories": [
                  {
                    "id": "67aa0e54df787f1caa46b194922cf336",
                    "title": "Cinchos",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Sujetadores-de-Cables/Cinchos/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66a3d12f9c560-CP.png",
                    "parentId": "b56efcd086808c6c0b8bdcaa2d057a1e",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "55771e233a596": {
                        "id": null,
                        "title": "Cableado Estructurado",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                        "image": null,
                        "stock": 0
                      },
                      "b56efcd086808c6c0b8bdcaa2d057a1e": {
                        "id": null,
                        "title": "Sujetadores de Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Sujetadores-de-Cables/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "3d1225767ed1df799e288f7baa949198",
                    "title": "Grapas para Cables",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Sujetadores-de-Cables/Grapas-para-Cables/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66a3d140841de-CP.png",
                    "parentId": "b56efcd086808c6c0b8bdcaa2d057a1e",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "55771e233a596": {
                        "id": null,
                        "title": "Cableado Estructurado",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                        "image": null,
                        "stock": 0
                      },
                      "b56efcd086808c6c0b8bdcaa2d057a1e": {
                        "id": null,
                        "title": "Sujetadores de Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Sujetadores-de-Cables/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "61a08c633f42f093db908e6f76b81468",
                    "title": "Abrazaderas para Cables",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Sujetadores-de-Cables/Abrazaderas-para-Cables/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66abba9f0667b-CP.png",
                    "parentId": "b56efcd086808c6c0b8bdcaa2d057a1e",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "55771e233a596": {
                        "id": null,
                        "title": "Cableado Estructurado",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                        "image": null,
                        "stock": 0
                      },
                      "b56efcd086808c6c0b8bdcaa2d057a1e": {
                        "id": null,
                        "title": "Sujetadores de Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Sujetadores-de-Cables/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "55771e233a596": {
                    "id": null,
                    "title": "Cableado Estructurado",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "3064486939e9bf11d500845b48e8db49",
                "title": "Tuberías Eléctricas",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/3064486939e9bf11d500845b48e8db49-CP.png",
                "parentId": "55771e233a596",
                "subCategories": [
                  {
                    "id": "c2af09af83ce91702278e07b8dbaab16",
                    "title": "Conduit",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/Conduit/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c2af09af83ce91702278e07b8dbaab16-CP.png",
                    "parentId": "3064486939e9bf11d500845b48e8db49",
                    "subCategories": [
                      {
                        "id": "16c1eb1c77198a81b1050a56431b105a",
                        "title": "Tuberías Conduit",
                        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/Conduit/Tuberias-Conduit/",
                        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6696d5ebba88e-CP.png",
                        "parentId": "c2af09af83ce91702278e07b8dbaab16",
                        "subCategories": [],
                        "path": {
                          "99748977a0a9f40e2.09126772": {
                            "id": null,
                            "title": "Cómputo (Hardware)",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                            "image": null,
                            "stock": 0
                          },
                          "4e9572d4a1515cc5550b24cb4f340236": {
                            "id": null,
                            "title": "Cables",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                            "image": null,
                            "stock": 0
                          },
                          "55771e233a596": {
                            "id": null,
                            "title": "Cableado Estructurado",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                            "image": null,
                            "stock": 0
                          },
                          "3064486939e9bf11d500845b48e8db49": {
                            "id": null,
                            "title": "Tuberías Eléctricas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/",
                            "image": null,
                            "stock": 0
                          },
                          "c2af09af83ce91702278e07b8dbaab16": {
                            "id": null,
                            "title": "Conduit",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/Conduit/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      },
                      {
                        "id": "069ca0a34b0efe3e6c403530549de8f3",
                        "title": "Conectores Conduit",
                        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/Conduit/Conectores-Conduit/",
                        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66a3cf446b93f-CP.png",
                        "parentId": "c2af09af83ce91702278e07b8dbaab16",
                        "subCategories": [],
                        "path": {
                          "99748977a0a9f40e2.09126772": {
                            "id": null,
                            "title": "Cómputo (Hardware)",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                            "image": null,
                            "stock": 0
                          },
                          "4e9572d4a1515cc5550b24cb4f340236": {
                            "id": null,
                            "title": "Cables",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                            "image": null,
                            "stock": 0
                          },
                          "55771e233a596": {
                            "id": null,
                            "title": "Cableado Estructurado",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                            "image": null,
                            "stock": 0
                          },
                          "3064486939e9bf11d500845b48e8db49": {
                            "id": null,
                            "title": "Tuberías Eléctricas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/",
                            "image": null,
                            "stock": 0
                          },
                          "c2af09af83ce91702278e07b8dbaab16": {
                            "id": null,
                            "title": "Conduit",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/Conduit/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": true
                      },
                      {
                        "id": "568d2a1dc507c6ebcd1b8f740dc0319c",
                        "title": "Coples Conduit",
                        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/Conduit/Coples-Conduit/",
                        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66a3d1a9dc77e-CP.png",
                        "parentId": "c2af09af83ce91702278e07b8dbaab16",
                        "subCategories": [],
                        "path": {
                          "99748977a0a9f40e2.09126772": {
                            "id": null,
                            "title": "Cómputo (Hardware)",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                            "image": null,
                            "stock": 0
                          },
                          "4e9572d4a1515cc5550b24cb4f340236": {
                            "id": null,
                            "title": "Cables",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                            "image": null,
                            "stock": 0
                          },
                          "55771e233a596": {
                            "id": null,
                            "title": "Cableado Estructurado",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                            "image": null,
                            "stock": 0
                          },
                          "3064486939e9bf11d500845b48e8db49": {
                            "id": null,
                            "title": "Tuberías Eléctricas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/",
                            "image": null,
                            "stock": 0
                          },
                          "c2af09af83ce91702278e07b8dbaab16": {
                            "id": null,
                            "title": "Conduit",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/Conduit/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      },
                      {
                        "id": "972559861e8142b0f05e6eb191cd0ee4",
                        "title": "Sellos Aislantes Conduit",
                        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/Conduit/Sellos-Aislantes-Conduit/",
                        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66a3cfb376787-CP.png",
                        "parentId": "c2af09af83ce91702278e07b8dbaab16",
                        "subCategories": [],
                        "path": {
                          "99748977a0a9f40e2.09126772": {
                            "id": null,
                            "title": "Cómputo (Hardware)",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                            "image": null,
                            "stock": 0
                          },
                          "4e9572d4a1515cc5550b24cb4f340236": {
                            "id": null,
                            "title": "Cables",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                            "image": null,
                            "stock": 0
                          },
                          "55771e233a596": {
                            "id": null,
                            "title": "Cableado Estructurado",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                            "image": null,
                            "stock": 0
                          },
                          "3064486939e9bf11d500845b48e8db49": {
                            "id": null,
                            "title": "Tuberías Eléctricas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/",
                            "image": null,
                            "stock": 0
                          },
                          "c2af09af83ce91702278e07b8dbaab16": {
                            "id": null,
                            "title": "Conduit",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/Conduit/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      },
                      {
                        "id": "d6cf6df91666a96de2d488afae52b28f",
                        "title": "Dobladores de Tubos Conduit",
                        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/Conduit/Dobladores-de-Tubos-Conduit/",
                        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66a3d03fa00f7-CP.png",
                        "parentId": "c2af09af83ce91702278e07b8dbaab16",
                        "subCategories": [],
                        "path": {
                          "99748977a0a9f40e2.09126772": {
                            "id": null,
                            "title": "Cómputo (Hardware)",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                            "image": null,
                            "stock": 0
                          },
                          "4e9572d4a1515cc5550b24cb4f340236": {
                            "id": null,
                            "title": "Cables",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                            "image": null,
                            "stock": 0
                          },
                          "55771e233a596": {
                            "id": null,
                            "title": "Cableado Estructurado",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                            "image": null,
                            "stock": 0
                          },
                          "3064486939e9bf11d500845b48e8db49": {
                            "id": null,
                            "title": "Tuberías Eléctricas",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/",
                            "image": null,
                            "stock": 0
                          },
                          "c2af09af83ce91702278e07b8dbaab16": {
                            "id": null,
                            "title": "Conduit",
                            "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/Conduit/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      }
                    ],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "55771e233a596": {
                        "id": null,
                        "title": "Cableado Estructurado",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                        "image": null,
                        "stock": 0
                      },
                      "3064486939e9bf11d500845b48e8db49": {
                        "id": null,
                        "title": "Tuberías Eléctricas",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": true
                  },
                  {
                    "id": "cdd6b873057ec33de3dc3bf78833f872",
                    "title": "Termorretráctil",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/Termorretractil/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/cdd6b873057ec33de3dc3bf78833f872-CP.png",
                    "parentId": "3064486939e9bf11d500845b48e8db49",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "55771e233a596": {
                        "id": null,
                        "title": "Cableado Estructurado",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                        "image": null,
                        "stock": 0
                      },
                      "3064486939e9bf11d500845b48e8db49": {
                        "id": null,
                        "title": "Tuberías Eléctricas",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "072b4136ca9574e2b0b8f8013f1ffc0c",
                    "title": "Corrugado",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/Corrugado/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66df3b1694be0-CP.png",
                    "parentId": "3064486939e9bf11d500845b48e8db49",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "55771e233a596": {
                        "id": null,
                        "title": "Cableado Estructurado",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                        "image": null,
                        "stock": 0
                      },
                      "3064486939e9bf11d500845b48e8db49": {
                        "id": null,
                        "title": "Tuberías Eléctricas",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/Tuberias-Electricas/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "55771e233a596": {
                    "id": null,
                    "title": "Cableado Estructurado",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cableado-Estructurado/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4e9572d4a1515cc5550b24cb4f340236": {
                "id": null,
                "title": "Cables",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "4fe3bc6568adc",
            "title": "Cables de Red",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cablesdered(1).png",
            "parentId": "4e9572d4a1515cc5550b24cb4f340236",
            "subCategories": [
              {
                "id": "24053883535a6b2cda791d5f2c50c5e5",
                "title": "Cables Patch",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/Cables-Patch/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cablespatch.png",
                "parentId": "4fe3bc6568adc",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3bc6568adc": {
                    "id": null,
                    "title": "Cables de Red",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3bcc3c4135",
                "title": "Cables Fibra Óptica",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/Cables-Fibra-Optica/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fibra-optica.png",
                "parentId": "4fe3bc6568adc",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3bc6568adc": {
                    "id": null,
                    "title": "Cables de Red",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51fbebe24034c",
                "title": "Cables de Antena",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/Cables-de-Antena/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables-antena.png",
                "parentId": "4fe3bc6568adc",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3bc6568adc": {
                    "id": null,
                    "title": "Cables de Red",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51fbec0203ad3",
                "title": "Cables SFP+",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/Cables-SFP/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables-sfp.png",
                "parentId": "4fe3bc6568adc",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3bc6568adc": {
                    "id": null,
                    "title": "Cables de Red",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "543d5c19215b7",
                "title": "Cables Stack",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/Cables-Stack/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables-stack.png",
                "parentId": "4fe3bc6568adc",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3bc6568adc": {
                    "id": null,
                    "title": "Cables de Red",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3bca7ebc5b",
                "title": "Cables Coaxiales",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/Cables-Coaxiales/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/coaxial.png",
                "parentId": "4fe3bc6568adc",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3bc6568adc": {
                    "id": null,
                    "title": "Cables de Red",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4e9572d4a1515cc5550b24cb4f340236": {
                "id": null,
                "title": "Cables",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "4fe3bcfd493b7",
            "title": "Cables de Energía",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Energia/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cablesdeenergia.png",
            "parentId": "4e9572d4a1515cc5550b24cb4f340236",
            "subCategories": [
              {
                "id": "5c4e914fe19a4c38c55cb5a0c4940b47",
                "title": "Cables de Poder (Externo)",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Energia/Cables-de-Poder-Externo/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cablesdepoder.png",
                "parentId": "4fe3bcfd493b7",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3bcfd493b7": {
                    "id": null,
                    "title": "Cables de Energía",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Energia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe3bd2c31b6e",
                "title": "Cables de Poder (Interno)",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Energia/Cables-de-Poder-Interno/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables_poder_interno.png",
                "parentId": "4fe3bcfd493b7",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=a58klo6nccfitarjc94j6v6m20",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/?force_sid=a58klo6nccfitarjc94j6v6m20",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3bcfd493b7": {
                    "id": null,
                    "title": "Cables de Energía",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Energia/?force_sid=a58klo6nccfitarjc94j6v6m20",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4e9572d4a1515cc5550b24cb4f340236": {
                "id": null,
                "title": "Cables",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "4fe8d906d5119",
            "title": "Cables para Impresoras",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-para-Impresoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresora.jpg",
            "parentId": "4e9572d4a1515cc5550b24cb4f340236",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4e9572d4a1515cc5550b24cb4f340236": {
                "id": null,
                "title": "Cables",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5241f97d2b79d",
            "title": "Accesorios para Cables",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesoriocable.png",
            "parentId": "4e9572d4a1515cc5550b24cb4f340236",
            "subCategories": [
              {
                "id": "4fee1d203b6d7",
                "title": "Jacks de Red",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Jacks-de-Red/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/jackdered.png",
                "parentId": "5241f97d2b79d",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5241f97d2b79d": {
                    "id": null,
                    "title": "Accesorios para Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51fbec7055920",
                "title": "Conectores",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Conectores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/conectores.png",
                "parentId": "5241f97d2b79d",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5241f97d2b79d": {
                    "id": null,
                    "title": "Accesorios para Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5241f9b9eb2ae",
                "title": "Herramientas para Cables",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Herramientas-para-Cables/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ferreteria_0006_herramientas-parap-cables.png",
                "parentId": "5241f97d2b79d",
                "subCategories": [
                  {
                    "id": "662fea7f06e66",
                    "title": "Pinzas Ponchadoras",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Herramientas-para-Cables/Pinzas-Ponchadoras/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pinzaponchadora.png",
                    "parentId": "5241f9b9eb2ae",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "5241f97d2b79d": {
                        "id": null,
                        "title": "Accesorios para Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "5241f9b9eb2ae": {
                        "id": null,
                        "title": "Herramientas para Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Herramientas-para-Cables/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "662ff6fee2da3",
                    "title": "Probadores de Cable",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Herramientas-para-Cables/Probadores-de-Cable/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/probadorcable.png",
                    "parentId": "5241f9b9eb2ae",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "5241f97d2b79d": {
                        "id": null,
                        "title": "Accesorios para Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "5241f9b9eb2ae": {
                        "id": null,
                        "title": "Herramientas para Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Herramientas-para-Cables/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "663014ac204cc",
                    "title": "Multímetros",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Herramientas-para-Cables/Multimetros/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/multimetro.png",
                    "parentId": "5241f9b9eb2ae",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/?force_sid=c4o8jr0hemfsj3sneeq96dstde",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/?force_sid=c4o8jr0hemfsj3sneeq96dstde",
                        "image": null,
                        "stock": 0
                      },
                      "5241f97d2b79d": {
                        "id": null,
                        "title": "Accesorios para Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/?force_sid=c4o8jr0hemfsj3sneeq96dstde",
                        "image": null,
                        "stock": 0
                      },
                      "5241f9b9eb2ae": {
                        "id": null,
                        "title": "Herramientas para Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Herramientas-para-Cables/?force_sid=c4o8jr0hemfsj3sneeq96dstde",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "66301e8e0f8f5",
                    "title": "Cortadoras de Cable",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Herramientas-para-Cables/Cortadoras-de-Cable/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cortadorcable.png",
                    "parentId": "5241f9b9eb2ae",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "5241f97d2b79d": {
                        "id": null,
                        "title": "Accesorios para Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "5241f9b9eb2ae": {
                        "id": null,
                        "title": "Herramientas para Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Herramientas-para-Cables/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "66302a2c05cf0",
                    "title": "Puntas de Prueba",
                    "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Herramientas-para-Cables/Puntas-de-Prueba/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/puntasprueba.png",
                    "parentId": "5241f9b9eb2ae",
                    "subCategories": [],
                    "path": {
                      "99748977a0a9f40e2.09126772": {
                        "id": null,
                        "title": "Cómputo (Hardware)",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                        "image": null,
                        "stock": 0
                      },
                      "4e9572d4a1515cc5550b24cb4f340236": {
                        "id": null,
                        "title": "Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "5241f97d2b79d": {
                        "id": null,
                        "title": "Accesorios para Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/",
                        "image": null,
                        "stock": 0
                      },
                      "5241f9b9eb2ae": {
                        "id": null,
                        "title": "Herramientas para Cables",
                        "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Herramientas-para-Cables/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5241f97d2b79d": {
                    "id": null,
                    "title": "Accesorios para Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "5241fa2997940",
                "title": "Tapas y Cajas",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Tapas-y-Cajas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tapas-cajas(1).png",
                "parentId": "5241f97d2b79d",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5241f97d2b79d": {
                    "id": null,
                    "title": "Accesorios para Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5cfaa0770afb8",
                "title": "Protectores para Cables",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Protectores-para-Cables/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/protectoresparacables.png",
                "parentId": "5241f97d2b79d",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5241f97d2b79d": {
                    "id": null,
                    "title": "Accesorios para Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "611d72481f474",
                "title": "Terminales para Cables",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Terminales-para-Cables/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0000_terminales-para-cables.png",
                "parentId": "5241f97d2b79d",
                "subCategories": [],
                "path": {
                  "99748977a0a9f40e2.09126772": {
                    "id": null,
                    "title": "Cómputo (Hardware)",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                    "image": null,
                    "stock": 0
                  },
                  "4e9572d4a1515cc5550b24cb4f340236": {
                    "id": null,
                    "title": "Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                    "image": null,
                    "stock": 0
                  },
                  "5241f97d2b79d": {
                    "id": null,
                    "title": "Accesorios para Cables",
                    "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "4e9572d4a1515cc5550b24cb4f340236": {
                "id": null,
                "title": "Cables",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "99748977a0a9f40e2.09126772": {
            "id": null,
            "title": "Cómputo (Hardware)",
            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "30e1b12515eb8a7874b1f5d0c8bcbc0a",
        "title": "Unidades de Disco",
        "link": "https://www.cyberpuerta.mx/Computo-Hardware/Unidades-de-Disco/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/unidadesdediscopng.png",
        "parentId": "99748977a0a9f40e2.09126772",
        "subCategories": [
          {
            "id": "30e4dfa7dbaa50eda751f5e75b63e3f4",
            "title": "Quemadores de DVD",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Unidades-de-Disco/Quemadores-de-DVD/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/quemador-dvd.png",
            "parentId": "30e1b12515eb8a7874b1f5d0c8bcbc0a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e1b12515eb8a7874b1f5d0c8bcbc0a": {
                "id": null,
                "title": "Unidades de Disco",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Unidades-de-Disco/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "30ed084af81240a0f3ee8d6dfbbd7e01",
            "title": "Quemadores de Blu-ray",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Unidades-de-Disco/Quemadores-de-Blu-ray/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/quemador-blu-ray.png",
            "parentId": "30e1b12515eb8a7874b1f5d0c8bcbc0a",
            "subCategories": [],
            "path": {
              "99748977a0a9f40e2.09126772": {
                "id": null,
                "title": "Cómputo (Hardware)",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
                "image": null,
                "stock": 0
              },
              "30e1b12515eb8a7874b1f5d0c8bcbc0a": {
                "id": null,
                "title": "Unidades de Disco",
                "url": "https://www.cyberpuerta.mx/Computo-Hardware/Unidades-de-Disco/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "99748977a0a9f40e2.09126772": {
            "id": null,
            "title": "Cómputo (Hardware)",
            "url": "https://www.cyberpuerta.mx/Computo-Hardware/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      }
    ],
    "path": [],
    "hasSubCategories": true
  },
  {
    "id": "4fe38c01729fc",
    "title": "Computadoras",
    "link": "https://www.cyberpuerta.mx/Computadoras/",
    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/4fe38c01729fc-CP.png",
    "parentId": "",
    "subCategories": [
      {
        "id": "f6f4f35b28088224551a35ca23d248fd",
        "title": "PC's de Escritorio",
        "link": "https://www.cyberpuerta.mx/Computadoras/PC-s-de-Escritorio/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pc-escritorio.png",
        "parentId": "4fe38c01729fc",
        "subCategories": [],
        "path": {
          "4fe38c01729fc": {
            "id": null,
            "title": "Computadoras",
            "url": "https://www.cyberpuerta.mx/Computadoras/?force_sid=nnjsuv82a718ej9s98cne7dnjg",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "2f60c37a62c2a35200a845c09a3e8bf9",
        "title": "Laptops",
        "link": "https://www.cyberpuerta.mx/Computadoras/Laptops/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/laptops-home2(1).png",
        "parentId": "4fe38c01729fc",
        "subCategories": [],
        "path": {
          "4fe38c01729fc": {
            "id": null,
            "title": "Computadoras",
            "url": "https://www.cyberpuerta.mx/Computadoras/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "4feb6f4b369b9",
        "title": "Tablets",
        "link": "https://www.cyberpuerta.mx/Computadoras/Tablets/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tabletas.png",
        "parentId": "4fe38c01729fc",
        "subCategories": [
          {
            "id": "2f2579a462c464accbf3adf54e63ad2d",
            "title": "Tablets",
            "link": "https://www.cyberpuerta.mx/Computadoras/Tablets/Tablets/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tabletas(1).png",
            "parentId": "4feb6f4b369b9",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "4feb6f4b369b9": {
                "id": null,
                "title": "Tablets",
                "url": "https://www.cyberpuerta.mx/Computadoras/Tablets/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5f1b4a4d51cb78f65d7faa150fea61bf",
            "title": "Tablets Gráficas",
            "link": "https://www.cyberpuerta.mx/Computadoras/Tablets/Tablets-Graficas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tableta23(1).png",
            "parentId": "4feb6f4b369b9",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "4feb6f4b369b9": {
                "id": null,
                "title": "Tablets",
                "url": "https://www.cyberpuerta.mx/Computadoras/Tablets/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3adf916a9a",
            "title": "Lápices para Tablets",
            "link": "https://www.cyberpuerta.mx/Computadoras/Tablets/Lapices-para-Tablets/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lapices-tablets.png",
            "parentId": "4feb6f4b369b9",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "4feb6f4b369b9": {
                "id": null,
                "title": "Tablets",
                "url": "https://www.cyberpuerta.mx/Computadoras/Tablets/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "54adb3b575ea3",
            "title": "Fundas y Skins",
            "link": "https://www.cyberpuerta.mx/Computadoras/Tablets/Fundas-y-Skins/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fundas-skins(1).png",
            "parentId": "4feb6f4b369b9",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "4feb6f4b369b9": {
                "id": null,
                "title": "Tablets",
                "url": "https://www.cyberpuerta.mx/Computadoras/Tablets/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "16ada34273d16a641588a9a053ea9e51",
            "title": "Cargadores Portátiles",
            "link": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/Cargadores-Portatiles/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cargador-portatil.png",
            "parentId": "4feb6f4b369b9",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "4feb6f4b369b9": {
                "id": null,
                "title": "Tablets",
                "url": "https://www.cyberpuerta.mx/Computadoras/Tablets/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "585afcdcb3bb9",
            "title": "Soportes para Celulares y Tablets",
            "link": "https://www.cyberpuerta.mx/Computadoras/Tablets/Soportes-para-Celulares-y-Tablets/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soporte-mov-tablets.png",
            "parentId": "4feb6f4b369b9",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "4feb6f4b369b9": {
                "id": null,
                "title": "Tablets",
                "url": "https://www.cyberpuerta.mx/Computadoras/Tablets/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fe38c01729fc": {
            "id": null,
            "title": "Computadoras",
            "url": "https://www.cyberpuerta.mx/Computadoras/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "71bedc8440dba86b6874b46bef107ca2",
        "title": "Workstations",
        "link": "https://www.cyberpuerta.mx/Computadoras/Workstations/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/workstations.png",
        "parentId": "4fe38c01729fc",
        "subCategories": [],
        "path": {
          "4fe38c01729fc": {
            "id": null,
            "title": "Computadoras",
            "url": "https://www.cyberpuerta.mx/Computadoras/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "f6fc757214e9f383263281ecce695687",
        "title": "All in One",
        "link": "https://www.cyberpuerta.mx/Computadoras/All-in-One/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/all-in-one(1).png",
        "parentId": "4fe38c01729fc",
        "subCategories": [],
        "path": {
          "4fe38c01729fc": {
            "id": null,
            "title": "Computadoras",
            "url": "https://www.cyberpuerta.mx/Computadoras/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "21368f84b34ed775ba94fea2cdacac5b",
        "title": "Thin Clients",
        "link": "https://www.cyberpuerta.mx/Computadoras/Thin-Clients/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/thin-client.png",
        "parentId": "4fe38c01729fc",
        "subCategories": [],
        "path": {
          "4fe38c01729fc": {
            "id": null,
            "title": "Computadoras",
            "url": "https://www.cyberpuerta.mx/Computadoras/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "522e32ffbd317",
        "title": "Mini PC's",
        "link": "https://www.cyberpuerta.mx/Computadoras/Mini-PC-s/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/mini-pc.png",
        "parentId": "4fe38c01729fc",
        "subCategories": [],
        "path": {
          "4fe38c01729fc": {
            "id": null,
            "title": "Computadoras",
            "url": "https://www.cyberpuerta.mx/Computadoras/?force_sid=261k7jn43abs8nme0libmit8js",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "4fe3abe754917",
        "title": "Barebones",
        "link": "https://www.cyberpuerta.mx/Computadoras/Barebones/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/barebones.png",
        "parentId": "4fe38c01729fc",
        "subCategories": [],
        "path": {
          "4fe38c01729fc": {
            "id": null,
            "title": "Computadoras",
            "url": "https://www.cyberpuerta.mx/Computadoras/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "586d2ce970058",
        "title": "Webcam y Audio",
        "link": "https://www.cyberpuerta.mx/Computadoras/Webcam-Audio/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/webcamyaudio.png",
        "parentId": "4fe38c01729fc",
        "subCategories": [
          {
            "id": "30ea07c33174e8cdc74fb47123258e53",
            "title": "Webcams",
            "link": "https://www.cyberpuerta.mx/Computadoras/Webcam-Audio/Webcams/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/webcams2.png",
            "parentId": "586d2ce970058",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "586d2ce970058": {
                "id": null,
                "title": "Webcam y Audio",
                "url": "https://www.cyberpuerta.mx/Computadoras/Webcam-Audio/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "586d2e1d88595",
            "title": "Audífonos",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Audifonos/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/586d2e1d88595-CP.png",
            "parentId": "586d2ce970058",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "586d2ce970058": {
                "id": null,
                "title": "Webcam y Audio",
                "url": "https://www.cyberpuerta.mx/Computadoras/Webcam-Audio/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fe38c01729fc": {
            "id": null,
            "title": "Computadoras",
            "url": "https://www.cyberpuerta.mx/Computadoras/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "8610ea12cee28e930b15bb952f1b52e7",
        "title": "Accesorios para PC's",
        "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-pc.png",
        "parentId": "4fe38c01729fc",
        "subCategories": [
          {
            "id": "86109cc3e289baa2b9c88c27f28c3e30",
            "title": "Bases para PC's",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/Bases-para-PC-s/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/bases-pc.png",
            "parentId": "8610ea12cee28e930b15bb952f1b52e7",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "8610ea12cee28e930b15bb952f1b52e7": {
                "id": null,
                "title": "Accesorios para PC's",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51c4c1d34c9c3",
            "title": "Protectores para PC's",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/Protectores-para-PC-s/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/protectores-pc.png",
            "parentId": "8610ea12cee28e930b15bb952f1b52e7",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/?force_sid=t11nd7anhpa07iilak27rhvc6t",
                "image": null,
                "stock": 0
              },
              "8610ea12cee28e930b15bb952f1b52e7": {
                "id": null,
                "title": "Accesorios para PC's",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/?force_sid=t11nd7anhpa07iilak27rhvc6t",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "8617788f818d7c5de5c9fd2dbe36c151",
            "title": "Kits de Herramientas",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/Kits-de-Herramientas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/kit-herramientas.png",
            "parentId": "8610ea12cee28e930b15bb952f1b52e7",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "8610ea12cee28e930b15bb952f1b52e7": {
                "id": null,
                "title": "Accesorios para PC's",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51c3486d08095",
            "title": "Limpiadores para Electrónicos",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/Limpiadores-para-Electronicos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/limpiadores-electronicos.png",
            "parentId": "8610ea12cee28e930b15bb952f1b52e7",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "8610ea12cee28e930b15bb952f1b52e7": {
                "id": null,
                "title": "Accesorios para PC's",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51f0138acb4c2",
            "title": "Iluminación para PC",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/Iluminacion-para-PC/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables-led.png",
            "parentId": "8610ea12cee28e930b15bb952f1b52e7",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "8610ea12cee28e930b15bb952f1b52e7": {
                "id": null,
                "title": "Accesorios para PC's",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "525d94bb9be0e",
            "title": "Candados para PC's",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/Candados-para-PC-s/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/candados-pc.png",
            "parentId": "8610ea12cee28e930b15bb952f1b52e7",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "8610ea12cee28e930b15bb952f1b52e7": {
                "id": null,
                "title": "Accesorios para PC's",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "602572bce7f2b",
            "title": "Lentes para Computadora",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/Lentes-para-Computadora/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lentes-para-compu.png",
            "parentId": "8610ea12cee28e930b15bb952f1b52e7",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "8610ea12cee28e930b15bb952f1b52e7": {
                "id": null,
                "title": "Accesorios para PC's",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fe38c01729fc": {
            "id": null,
            "title": "Computadoras",
            "url": "https://www.cyberpuerta.mx/Computadoras/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "30e394a966d4c84c04fdda76114aff23",
        "title": "Accesorios para Laptops",
        "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios(2).png",
        "parentId": "4fe38c01729fc",
        "subCategories": [
          {
            "id": "7b38b948aa71cfd71759b707dd80a7ce",
            "title": "Bases para Laptops",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Laptops/Bases-para-Laptops/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/basesportatiles.png",
            "parentId": "30e394a966d4c84c04fdda76114aff23",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "30e394a966d4c84c04fdda76114aff23": {
                "id": null,
                "title": "Accesorios para Laptops",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "016bb01a8b229edeb62c74dfb9304773",
            "title": "Docking Stations",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/Docking-Stations/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/docking-stations.png",
            "parentId": "30e394a966d4c84c04fdda76114aff23",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "30e394a966d4c84c04fdda76114aff23": {
                "id": null,
                "title": "Accesorios para Laptops",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "a0de32ea022bd6d5add2f95b6f6f5472",
            "title": "Cargadores",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-Computo/Cargadores-para-Laptop/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/a0de32ea022bd6d5add2f95b6f6f5472-CP.png",
            "parentId": "30e394a966d4c84c04fdda76114aff23",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "30e394a966d4c84c04fdda76114aff23": {
                "id": null,
                "title": "Accesorios para Laptops",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "e971e5c1f92ff9abd1ac03374b3f4279",
            "title": "Baterías",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-Computo/Baterias-para-Laptop/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/e971e5c1f92ff9abd1ac03374b3f4279-CP.png",
            "parentId": "30e394a966d4c84c04fdda76114aff23",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "30e394a966d4c84c04fdda76114aff23": {
                "id": null,
                "title": "Accesorios para Laptops",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "523b63c78c018",
            "title": "Mochilas para Laptops",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/Mochilas-para-Laptops/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/669fcc5a0b837-CP.png",
            "parentId": "30e394a966d4c84c04fdda76114aff23",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "30e394a966d4c84c04fdda76114aff23": {
                "id": null,
                "title": "Accesorios para Laptops",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "30ef2d22dafe566efe0e74ab08230939",
            "title": "Maletines",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/Maletines/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/maletin.png",
            "parentId": "30e394a966d4c84c04fdda76114aff23",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "30e394a966d4c84c04fdda76114aff23": {
                "id": null,
                "title": "Accesorios para Laptops",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "700c2dd8ffee086f743aeacba2a68ee6",
            "title": "Fundas y Skins",
            "link": "https://www.cyberpuerta.mx/Computadoras/Tablets/Fundas-y-Skins/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fundas-skins.png",
            "parentId": "30e394a966d4c84c04fdda76114aff23",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "30e394a966d4c84c04fdda76114aff23": {
                "id": null,
                "title": "Accesorios para Laptops",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "d0a1e4d79f87013c41b3f33476a769ac",
            "title": "Filtros de privacidad",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/Filtros-de-privacidad/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/filtros-privacidad.png",
            "parentId": "30e394a966d4c84c04fdda76114aff23",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "30e394a966d4c84c04fdda76114aff23": {
                "id": null,
                "title": "Accesorios para Laptops",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "86a71459c3ff8edc688805490b54ae6c",
            "title": "Candados para Laptops",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/Candados-para-Laptops/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/candados-laptops.png",
            "parentId": "30e394a966d4c84c04fdda76114aff23",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "30e394a966d4c84c04fdda76114aff23": {
                "id": null,
                "title": "Accesorios para Laptops",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "58e2985349fbe",
            "title": "Lámparas para Laptop",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/Lamparas-para-Laptop/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lamparalaptop.png",
            "parentId": "30e394a966d4c84c04fdda76114aff23",
            "subCategories": [],
            "path": {
              "4fe38c01729fc": {
                "id": null,
                "title": "Computadoras",
                "url": "https://www.cyberpuerta.mx/Computadoras/",
                "image": null,
                "stock": 0
              },
              "30e394a966d4c84c04fdda76114aff23": {
                "id": null,
                "title": "Accesorios para Laptops",
                "url": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fe38c01729fc": {
            "id": null,
            "title": "Computadoras",
            "url": "https://www.cyberpuerta.mx/Computadoras/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      }
    ],
    "path": [],
    "hasSubCategories": true
  },
  {
    "id": "07a49b1b2f27967a1.24876408",
    "title": "Impresión y Copiado",
    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/07a49b1b2f27967a1.24876408-CP.png",
    "parentId": "",
    "subCategories": [
      {
        "id": "30ee7af6815c720ab7535d72194d617c",
        "title": "Escáneres",
        "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Escaneres/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/escaneres.png",
        "parentId": "07a49b1b2f27967a1.24876408",
        "subCategories": [],
        "path": {
          "07a49b1b2f27967a1.24876408": {
            "id": null,
            "title": "Impresión y Copiado",
            "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "5859639c4d823",
        "title": "Impresoras y Multifuncionales",
        "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresoras-multifuncionales.png",
        "parentId": "07a49b1b2f27967a1.24876408",
        "subCategories": [
          {
            "id": "30e0bc399bea2885ace6a7343d0ee261",
            "title": "Impresoras",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Impresoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/laser-bn.png",
            "parentId": "5859639c4d823",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/?force_sid=1omqv2gal86u2e12e24a3onf11",
                "image": null,
                "stock": 0
              },
              "5859639c4d823": {
                "id": null,
                "title": "Impresoras y Multifuncionales",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/?force_sid=1omqv2gal86u2e12e24a3onf11",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "187a19a02243b32f400a07ac2f1c4599",
            "title": "Multifuncionales",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Multifuncionales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/multi-laser.png",
            "parentId": "5859639c4d823",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/?force_sid=3ottb1lsgr3tncussoe6c1o4kr",
                "image": null,
                "stock": 0
              },
              "5859639c4d823": {
                "id": null,
                "title": "Impresoras y Multifuncionales",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/?force_sid=3ottb1lsgr3tncussoe6c1o4kr",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "7b3af585782257c6f358e84b8474e3ea",
            "title": "Plotter",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Plotter/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/plotter.png",
            "parentId": "5859639c4d823",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "5859639c4d823": {
                "id": null,
                "title": "Impresoras y Multifuncionales",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "512e6a0e6dcbd",
            "title": "Impresoras Fotográficas",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Impresoras-Fotograficas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresion-fotografica(1).png",
            "parentId": "5859639c4d823",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "5859639c4d823": {
                "id": null,
                "title": "Impresoras y Multifuncionales",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5637aa30f3874",
            "title": "Impresión y Escaneo 3D",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Impresion-y-Escaneo-3D/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresion-escaneo-3d.png",
            "parentId": "5859639c4d823",
            "subCategories": [
              {
                "id": "5637aab64fcc0",
                "title": "Impresoras 3D",
                "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Impresion-y-Escaneo-3D/Impresoras-3D/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresora-3d.png",
                "parentId": "5637aa30f3874",
                "subCategories": [],
                "path": {
                  "07a49b1b2f27967a1.24876408": {
                    "id": null,
                    "title": "Impresión y Copiado",
                    "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                    "image": null,
                    "stock": 0
                  },
                  "5859639c4d823": {
                    "id": null,
                    "title": "Impresoras y Multifuncionales",
                    "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                    "image": null,
                    "stock": 0
                  },
                  "5637aa30f3874": {
                    "id": null,
                    "title": "Impresión y Escaneo 3D",
                    "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Impresion-y-Escaneo-3D/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5637ab09b0fbe",
                "title": "Bobinas de Filamento",
                "link": "https://www.cyberpuerta.mx/Impresion-Copiado/Consumibles-para-Impresoras/Bobinas-de-Filamento/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/bobinas-filamento(1).png",
                "parentId": "5637aa30f3874",
                "subCategories": [],
                "path": {
                  "07a49b1b2f27967a1.24876408": {
                    "id": null,
                    "title": "Impresión y Copiado",
                    "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                    "image": null,
                    "stock": 0
                  },
                  "5859639c4d823": {
                    "id": null,
                    "title": "Impresoras y Multifuncionales",
                    "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                    "image": null,
                    "stock": 0
                  },
                  "5637aa30f3874": {
                    "id": null,
                    "title": "Impresión y Escaneo 3D",
                    "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Impresion-y-Escaneo-3D/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "2c38badf095f609ebd18b46400c02dcc",
                "title": "Resina",
                "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/Resina/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/consumibles-resina.png",
                "parentId": "5637aa30f3874",
                "subCategories": [],
                "path": {
                  "07a49b1b2f27967a1.24876408": {
                    "id": null,
                    "title": "Impresión y Copiado",
                    "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                    "image": null,
                    "stock": 0
                  },
                  "5859639c4d823": {
                    "id": null,
                    "title": "Impresoras y Multifuncionales",
                    "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                    "image": null,
                    "stock": 0
                  },
                  "5637aa30f3874": {
                    "id": null,
                    "title": "Impresión y Escaneo 3D",
                    "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Impresion-y-Escaneo-3D/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5637ab389d956",
                "title": "Accesorios para Impresión 3D",
                "link": "https://www.cyberpuerta.mx/Impresion-Copiado/Accesorios-de-Impresion/Accesorios-para-Impresion-3D/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-impresion-3d.png",
                "parentId": "5637aa30f3874",
                "subCategories": [],
                "path": {
                  "07a49b1b2f27967a1.24876408": {
                    "id": null,
                    "title": "Impresión y Copiado",
                    "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                    "image": null,
                    "stock": 0
                  },
                  "5859639c4d823": {
                    "id": null,
                    "title": "Impresoras y Multifuncionales",
                    "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                    "image": null,
                    "stock": 0
                  },
                  "5637aa30f3874": {
                    "id": null,
                    "title": "Impresión y Escaneo 3D",
                    "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Impresion-y-Escaneo-3D/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "5859639c4d823": {
                "id": null,
                "title": "Impresoras y Multifuncionales",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "500487b50a975",
            "title": "Impresoras de Credenciales",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Impresoras-de-Credenciales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresora-de-credenciales.png",
            "parentId": "5859639c4d823",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "5859639c4d823": {
                "id": null,
                "title": "Impresoras y Multifuncionales",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "a67580b55dfad8d2f1fd802ebff5a706",
            "title": "Impresoras de Tickets",
            "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Impresoras-de-Tickets/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresora-tickets.png",
            "parentId": "5859639c4d823",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "5859639c4d823": {
                "id": null,
                "title": "Impresoras y Multifuncionales",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4baaf5c96e9b12214b684aba7e15dfa5",
            "title": "Impresoras de Etiquetas",
            "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Impresoras-de-Etiquetas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresora-de-etiquetas-new(1).png",
            "parentId": "5859639c4d823",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "5859639c4d823": {
                "id": null,
                "title": "Impresoras y Multifuncionales",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5266c22da9eff",
            "title": "Impresoras de Cheques",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Impresoras-de-Cheques/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresoras-cheques.png",
            "parentId": "5859639c4d823",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "5859639c4d823": {
                "id": null,
                "title": "Impresoras y Multifuncionales",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "53e369d81f1ee9916eb0cf9172216243",
            "title": "Impresoras Móviles",
            "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Impresoras-Moviles/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/53e369d81f1ee9916eb0cf9172216243-CP.png",
            "parentId": "5859639c4d823",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "5859639c4d823": {
                "id": null,
                "title": "Impresoras y Multifuncionales",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "511e630fddf65",
            "title": "Rotuladores",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Rotuladores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/rotulador-new.png",
            "parentId": "5859639c4d823",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "5859639c4d823": {
                "id": null,
                "title": "Impresoras y Multifuncionales",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "30e978a4a215a21e5c0ea61da25af573",
            "title": "Impresoras de Matriz de Punto",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Impresoras-de-Matriz-de-Punto/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/matriz-puntos.png",
            "parentId": "5859639c4d823",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "5859639c4d823": {
                "id": null,
                "title": "Impresoras y Multifuncionales",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "07a49b1b2f27967a1.24876408": {
            "id": null,
            "title": "Impresión y Copiado",
            "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "30e0abcc20996350644b2398b9b6d440",
        "title": "Consumibles para Impresoras",
        "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cartuchos-toner-cabezal.png",
        "parentId": "07a49b1b2f27967a1.24876408",
        "subCategories": [
          {
            "id": "bea8861a33a81279070ad53ba0b6fc7a",
            "title": "Tóner",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/Toner/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/toner2.png",
            "parentId": "30e0abcc20996350644b2398b9b6d440",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/?force_sid=e1d3qsq3jhcp30v9otr26l8e1q",
                "image": null,
                "stock": 0
              },
              "30e0abcc20996350644b2398b9b6d440": {
                "id": null,
                "title": "Consumibles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/?force_sid=e1d3qsq3jhcp30v9otr26l8e1q",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "bea77ca9db93f46b56703559a176c3c7",
            "title": "Cartuchos",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/Cartuchos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cartuchos.png",
            "parentId": "30e0abcc20996350644b2398b9b6d440",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "30e0abcc20996350644b2398b9b6d440": {
                "id": null,
                "title": "Consumibles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "beae62b7f06a035ee1b46fbd00a61aa8",
            "title": "Cintas",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/Cintas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cintas.png",
            "parentId": "30e0abcc20996350644b2398b9b6d440",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "30e0abcc20996350644b2398b9b6d440": {
                "id": null,
                "title": "Consumibles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "bea0e6e40d6102db935b87071e90491b",
            "title": "Tintas Sólidas",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/Tintas-Solidas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tintas-solidas.png",
            "parentId": "30e0abcc20996350644b2398b9b6d440",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "30e0abcc20996350644b2398b9b6d440": {
                "id": null,
                "title": "Consumibles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "beaa4262980eb2c2b60e969622d7a05a",
            "title": "Tanques de tinta",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/Tanques-de-tinta/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tanque-tinta.png",
            "parentId": "30e0abcc20996350644b2398b9b6d440",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "30e0abcc20996350644b2398b9b6d440": {
                "id": null,
                "title": "Consumibles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "bea172e933306e95ca48d350bef4e2a4",
            "title": "Cabezales",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/Cabezales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cabezales.png",
            "parentId": "30e0abcc20996350644b2398b9b6d440",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "30e0abcc20996350644b2398b9b6d440": {
                "id": null,
                "title": "Consumibles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "e854c0ff05beb28228ede9838ce933c8",
            "title": "Unidades de Imagen",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/Unidades-de-Imagen/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/unidad-iamgen.png",
            "parentId": "30e0abcc20996350644b2398b9b6d440",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "30e0abcc20996350644b2398b9b6d440": {
                "id": null,
                "title": "Consumibles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "e85ee18e7e6b5e8bbd849c92faa94414",
            "title": "Fotoconductores",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/Fotoconductores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fotoconductores.png",
            "parentId": "30e0abcc20996350644b2398b9b6d440",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "30e0abcc20996350644b2398b9b6d440": {
                "id": null,
                "title": "Consumibles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5637abc6612ef",
            "title": "Bobinas de Filamento",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/Bobinas-de-Filamento/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/bobinas-filamento.png",
            "parentId": "30e0abcc20996350644b2398b9b6d440",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "30e0abcc20996350644b2398b9b6d440": {
                "id": null,
                "title": "Consumibles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "635bf2251dd28",
            "title": "Resina",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/Resina/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/resina.png",
            "parentId": "30e0abcc20996350644b2398b9b6d440",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "30e0abcc20996350644b2398b9b6d440": {
                "id": null,
                "title": "Consumibles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "585d75a0b66a5",
            "title": "Bolsas de Tinta",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/Bolsas-de-Tinta/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/bolsas-tinta.png",
            "parentId": "30e0abcc20996350644b2398b9b6d440",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "30e0abcc20996350644b2398b9b6d440": {
                "id": null,
                "title": "Consumibles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "07a49b1b2f27967a1.24876408": {
            "id": null,
            "title": "Impresión y Copiado",
            "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "4feba6273a963",
        "title": "Papeles para Impresoras",
        "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/papeles.png",
        "parentId": "07a49b1b2f27967a1.24876408",
        "subCategories": [
          {
            "id": "5f117789c81efcfb722edd79faa5876c",
            "title": "Papel",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Papel/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/papel.png",
            "parentId": "4feba6273a963",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4feba6273a963": {
                "id": null,
                "title": "Papeles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "50eee9229a181",
            "title": "Papel Stock",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Papel-Stock/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/papel-stock.png",
            "parentId": "4feba6273a963",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4feba6273a963": {
                "id": null,
                "title": "Papeles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "50eef33d2c593",
            "title": "Papel Opalina",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Papel-Opalina/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/papeles-opalina.png",
            "parentId": "4feba6273a963",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4feba6273a963": {
                "id": null,
                "title": "Papeles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "50eef3f475745",
            "title": "Papel de Colores",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Papel-de-Colores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/papeles-colores.png",
            "parentId": "4feba6273a963",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4feba6273a963": {
                "id": null,
                "title": "Papeles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3a81877ceb",
            "title": "Papel Fotográfico",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Papel-Fotografico/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/papel-fotografico(1).png",
            "parentId": "4feba6273a963",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4feba6273a963": {
                "id": null,
                "title": "Papeles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3c2fc12d40",
            "title": "Rollos de Papel para Plotter",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Rollos-de-Papel-para-Plotter/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/rollos-de-papel.png",
            "parentId": "4feba6273a963",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4feba6273a963": {
                "id": null,
                "title": "Papeles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3c30e748dd",
            "title": "Papel POS",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Papel-POS/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/papelpos.png",
            "parentId": "4feba6273a963",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/?force_sid=s3d0lefmf65kt1ao328v88p091",
                "image": null,
                "stock": 0
              },
              "4feba6273a963": {
                "id": null,
                "title": "Papeles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/?force_sid=s3d0lefmf65kt1ao328v88p091",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5f9c386e9a17a",
            "title": "Etiquetas POS",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Etiquetas-POS/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/etiqueta-pos.png",
            "parentId": "4feba6273a963",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4feba6273a963": {
                "id": null,
                "title": "Papeles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51d451fa3012e",
            "title": "Tarjetas para Credenciales",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Tarjetas-para-Credenciales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjetas-credenciales.png",
            "parentId": "4feba6273a963",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4feba6273a963": {
                "id": null,
                "title": "Papeles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51cddbcae8326",
            "title": "Brazaletes",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Brazaletes/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/brazaletes.png",
            "parentId": "4feba6273a963",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4feba6273a963": {
                "id": null,
                "title": "Papeles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "509311d1d30ac",
            "title": "Etiquetas para CD/DVD",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Etiquetas-para-CD-DVD/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/etiquetas-cd.png",
            "parentId": "4feba6273a963",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4feba6273a963": {
                "id": null,
                "title": "Papeles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "489704c7e9cb5710b0b1bfbe992e3775",
            "title": "Etiquetas Adhesivas",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Etiquetas-Adhesivas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/etiquetas-adhesivas.png",
            "parentId": "4feba6273a963",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4feba6273a963": {
                "id": null,
                "title": "Papeles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "79cac6ec151cea44393840ec2ffccf4a",
            "title": "Tarjetas de Presentación",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Tarjetas-de-Presentacion/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/79cac6ec151cea44393840ec2ffccf4a-CP.png",
            "parentId": "4feba6273a963",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4feba6273a963": {
                "id": null,
                "title": "Papeles para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "07a49b1b2f27967a1.24876408": {
            "id": null,
            "title": "Impresión y Copiado",
            "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "86a27f23a01504222ee03d8eef133a4d",
        "title": "Refacción para Impresoras",
        "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/refaccin.png",
        "parentId": "07a49b1b2f27967a1.24876408",
        "subCategories": [
          {
            "id": "bea4c582b6678908e22a6245f16f47d6",
            "title": "Tambores",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/Tambores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tambores.png",
            "parentId": "86a27f23a01504222ee03d8eef133a4d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "86a27f23a01504222ee03d8eef133a4d": {
                "id": null,
                "title": "Refacción para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "e85ed4acf03204d91eb00775505307f5",
            "title": "Fusores",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/Fusores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fusores.png",
            "parentId": "86a27f23a01504222ee03d8eef133a4d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "86a27f23a01504222ee03d8eef133a4d": {
                "id": null,
                "title": "Refacción para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51d46e990a652",
            "title": "Unidades de Transferencia",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/Unidades-de-Transferencia/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/rodillos-transferencia.png",
            "parentId": "86a27f23a01504222ee03d8eef133a4d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "86a27f23a01504222ee03d8eef133a4d": {
                "id": null,
                "title": "Refacción para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51c2025a3eb1d",
            "title": "Rodillos de Alimentación",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/Rodillos-de-Alimentacion/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/rodillos-alimentacion.png",
            "parentId": "86a27f23a01504222ee03d8eef133a4d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "86a27f23a01504222ee03d8eef133a4d": {
                "id": null,
                "title": "Refacción para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "53a4b4649ebd0",
            "title": "Rodillos de Presión",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/Rodillos-de-Presion/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pressure.png",
            "parentId": "86a27f23a01504222ee03d8eef133a4d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "86a27f23a01504222ee03d8eef133a4d": {
                "id": null,
                "title": "Refacción para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "538e0c4d92f47",
            "title": "Pads Separadores",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/Pads-Separadores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/separation.png",
            "parentId": "86a27f23a01504222ee03d8eef133a4d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "86a27f23a01504222ee03d8eef133a4d": {
                "id": null,
                "title": "Refacción para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fce5908eff74",
            "title": "Reveladores",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/Reveladores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/reveladores.png",
            "parentId": "86a27f23a01504222ee03d8eef133a4d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "86a27f23a01504222ee03d8eef133a4d": {
                "id": null,
                "title": "Refacción para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5391f119d0b37",
            "title": "Tarjetas para Impresoras",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/Tarjetas-para-Impresoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjetas-impresoras.png",
            "parentId": "86a27f23a01504222ee03d8eef133a4d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "86a27f23a01504222ee03d8eef133a4d": {
                "id": null,
                "title": "Refacción para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "53a9a9d7edd7c",
            "title": "Enfriamiento para Impresoras",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/Enfriamiento-para-Impresoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/enfriamiento-impresoras.png",
            "parentId": "86a27f23a01504222ee03d8eef133a4d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "86a27f23a01504222ee03d8eef133a4d": {
                "id": null,
                "title": "Refacción para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "53c82079c13ed",
            "title": "Paneles de Control",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/Paneles-de-Control/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/panel-control.png",
            "parentId": "86a27f23a01504222ee03d8eef133a4d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "86a27f23a01504222ee03d8eef133a4d": {
                "id": null,
                "title": "Refacción para Impresoras",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Refaccion-para-Impresoras/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "07a49b1b2f27967a1.24876408": {
            "id": null,
            "title": "Impresión y Copiado",
            "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "4e96185c8ed189e7e8c2103a5048630d",
        "title": "Accesorios de Impresión",
        "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios(4).png",
        "parentId": "07a49b1b2f27967a1.24876408",
        "subCategories": [
          {
            "id": "bea595f321d143bcbb65ed3ef8fde01a",
            "title": "Kits de Mantenimiento",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/Kits-de-Mantenimiento/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/kit-mantenimiento.png",
            "parentId": "4e96185c8ed189e7e8c2103a5048630d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4e96185c8ed189e7e8c2103a5048630d": {
                "id": null,
                "title": "Accesorios de Impresión",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51d4660c55738",
            "title": "Bandejas y Alimentadores",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/Bandejas-y-Alimentadores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/bandejas-papel.png",
            "parentId": "4e96185c8ed189e7e8c2103a5048630d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4e96185c8ed189e7e8c2103a5048630d": {
                "id": null,
                "title": "Accesorios de Impresión",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51d471ea1000d",
            "title": "Gabinetes y Soportes",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/Gabinetes-y-Soportes/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gabinetes-soportes.png",
            "parentId": "4e96185c8ed189e7e8c2103a5048630d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4e96185c8ed189e7e8c2103a5048630d": {
                "id": null,
                "title": "Accesorios de Impresión",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51d31df44ddda",
            "title": "Contenedores de Desperdicio",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/Contenedores-de-Desperdicio/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/contenedores-desperdicio.png",
            "parentId": "4e96185c8ed189e7e8c2103a5048630d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4e96185c8ed189e7e8c2103a5048630d": {
                "id": null,
                "title": "Accesorios de Impresión",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "553fdc3caf5dc",
            "title": "Adaptadores de Red para Impresoras",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/Adaptadores-de-Red-para-Impresoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores-red-impresoras.png",
            "parentId": "4e96185c8ed189e7e8c2103a5048630d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4e96185c8ed189e7e8c2103a5048630d": {
                "id": null,
                "title": "Accesorios de Impresión",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "03e7ce3f55b5ec658ee8510cf89cf76a",
            "title": "Cargadores para Impresoras",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-Impresion-Copiado/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cargador-impresora.png",
            "parentId": "4e96185c8ed189e7e8c2103a5048630d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4e96185c8ed189e7e8c2103a5048630d": {
                "id": null,
                "title": "Accesorios de Impresión",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "68cd63d83dab3e9639cb36eb07c778ff",
            "title": "Cables para Impresoras",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-para-Impresoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cablesparaimpresora.png",
            "parentId": "4e96185c8ed189e7e8c2103a5048630d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4e96185c8ed189e7e8c2103a5048630d": {
                "id": null,
                "title": "Accesorios de Impresión",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51d2fe6b1791f",
            "title": "Limpieza para Impresoras",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/Limpieza-para-Impresoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-impresion-limpieza.png",
            "parentId": "4e96185c8ed189e7e8c2103a5048630d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4e96185c8ed189e7e8c2103a5048630d": {
                "id": null,
                "title": "Accesorios de Impresión",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5637abdf3104d",
            "title": "Accesorios para Impresión 3D",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/Accesorios-para-Impresion-3D/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-impresion-3d(1).png",
            "parentId": "4e96185c8ed189e7e8c2103a5048630d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4e96185c8ed189e7e8c2103a5048630d": {
                "id": null,
                "title": "Accesorios de Impresión",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51d1c298a8ee9",
            "title": "Software para Impresoras",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/Software-para-Impresoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/software-impresoras.png",
            "parentId": "4e96185c8ed189e7e8c2103a5048630d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4e96185c8ed189e7e8c2103a5048630d": {
                "id": null,
                "title": "Accesorios de Impresión",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5995c96b7e0eb",
            "title": "Módulos Multifunción",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/Modulos-Multifuncion/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/modulos-multifuncion.png",
            "parentId": "4e96185c8ed189e7e8c2103a5048630d",
            "subCategories": [],
            "path": {
              "07a49b1b2f27967a1.24876408": {
                "id": null,
                "title": "Impresión y Copiado",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
                "image": null,
                "stock": 0
              },
              "4e96185c8ed189e7e8c2103a5048630d": {
                "id": null,
                "title": "Accesorios de Impresión",
                "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Accesorios-de-Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "07a49b1b2f27967a1.24876408": {
            "id": null,
            "title": "Impresión y Copiado",
            "url": "https://www.cyberpuerta.mx/Impresion-y-Copiado/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      }
    ],
    "path": [],
    "hasSubCategories": true
  },
  {
    "id": "4fe353e55a8d4",
    "title": "Audio y Video",
    "link": "https://www.cyberpuerta.mx/Audio-y-Video/",
    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66968debcfca0-CP.png",
    "parentId": "",
    "subCategories": [
      {
        "id": "a4f4869881ac085f0.77594950",
        "title": "Audio",
        "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/audio-mp3.png",
        "parentId": "4fe353e55a8d4",
        "subCategories": [
          {
            "id": "a39bb41ed04ce9ba76de69be1cfbe5c3",
            "title": "Bocinas",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Bocinas/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/a39bb41ed04ce9ba76de69be1cfbe5c3-CP.png",
            "parentId": "a4f4869881ac085f0.77594950",
            "subCategories": [
              {
                "id": "86a819b929559c8195e197fb4bb656a1",
                "title": "Bocinas y Bafles",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Bocinas/Bocinas-y-Bafles/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/bocinas.png",
                "parentId": "a39bb41ed04ce9ba76de69be1cfbe5c3",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "a39bb41ed04ce9ba76de69be1cfbe5c3": {
                    "id": null,
                    "title": "Bocinas",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Bocinas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4828f2e48ed5bc08da2b32e0063adbdc",
                "title": "Bocinas Portatiles",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Bocinas/Bocinas-Portatiles/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/4828f2e48ed5bc08da2b32e0063adbdc-CP.png",
                "parentId": "a39bb41ed04ce9ba76de69be1cfbe5c3",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "a39bb41ed04ce9ba76de69be1cfbe5c3": {
                    "id": null,
                    "title": "Bocinas",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Bocinas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "9b5217828b0a26cc5b83ac3694223f1c",
                "title": "Bocinas para Computadora",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Bocinas/Bocinas-para-Computadora/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/9b5217828b0a26cc5b83ac3694223f1c-CP.png",
                "parentId": "a39bb41ed04ce9ba76de69be1cfbe5c3",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "a39bb41ed04ce9ba76de69be1cfbe5c3": {
                    "id": null,
                    "title": "Bocinas",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Bocinas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "50075c711f559cf8f81e4f3cb3a8ce8c",
                "title": "Barras de Sonido",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Bocinas/Barras-de-Sonido/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/50075c711f559cf8f81e4f3cb3a8ce8c-CP.png",
                "parentId": "a39bb41ed04ce9ba76de69be1cfbe5c3",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "a39bb41ed04ce9ba76de69be1cfbe5c3": {
                    "id": null,
                    "title": "Bocinas",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Bocinas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869881ac085f0.77594950": {
                "id": null,
                "title": "Audio",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "4fe3aaa6ba047",
            "title": "Audífonos",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Audifonos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/audifonosgamer4.png",
            "parentId": "a4f4869881ac085f0.77594950",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869881ac085f0.77594950": {
                "id": null,
                "title": "Audio",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "86ab4b5a4650901af375b094fa2fc3ed",
            "title": "Audífonos Intrauriculares",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Audifonos-Intrauriculares/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/intrauriculares.png",
            "parentId": "a4f4869881ac085f0.77594950",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869881ac085f0.77594950": {
                "id": null,
                "title": "Audio",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "a4f48698877ca2c31.50495605",
            "title": "Estéreos",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Estereos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/estereos.png",
            "parentId": "a4f4869881ac085f0.77594950",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869881ac085f0.77594950": {
                "id": null,
                "title": "Audio",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "30e2becc30a64a199bff3298f1f732fb",
            "title": "Reproductores MP3 / MP4",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Reproductores-MP3-MP4/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/mp3.png",
            "parentId": "a4f4869881ac085f0.77594950",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869881ac085f0.77594950": {
                "id": null,
                "title": "Audio",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "500f04d88b788",
            "title": "Grabadoras Reporteras",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Grabadoras-Reporteras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/grabadorasreporteras.png",
            "parentId": "a4f4869881ac085f0.77594950",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869881ac085f0.77594950": {
                "id": null,
                "title": "Audio",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe497c5dcafa",
            "title": "Radios Despertadores",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Radios-Despertadores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/radios.png",
            "parentId": "a4f4869881ac085f0.77594950",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869881ac085f0.77594950": {
                "id": null,
                "title": "Audio",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "62d9b2a5a65c7",
            "title": "Sonido para Centros de Entretenimiento",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Sonido-para-Centros-de-Entretenimiento/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sonido-para-centros-de-entrenamiento.png",
            "parentId": "a4f4869881ac085f0.77594950",
            "subCategories": [
              {
                "id": "ab348ac5ba035fc04.67832455",
                "title": "Home Theater",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Sonido-para-Centros-de-Entretenimiento/Home-Theater/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hometheatre.png",
                "parentId": "62d9b2a5a65c7",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "62d9b2a5a65c7": {
                    "id": null,
                    "title": "Sonido para Centros de Entretenimiento",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Sonido-para-Centros-de-Entretenimiento/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "62bb1a58b9729",
                "title": "Amplificadores y Receptores AV",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Sonido-para-Centros-de-Entretenimiento/Amplificadores-y-Receptores-AV/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/amplificadores-y-receptores-av.png",
                "parentId": "62d9b2a5a65c7",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "62d9b2a5a65c7": {
                    "id": null,
                    "title": "Sonido para Centros de Entretenimiento",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Sonido-para-Centros-de-Entretenimiento/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "62d9bcad2cea0",
                "title": "Bocinas para Sistemas de Audio",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Sonido-para-Centros-de-Entretenimiento/Bocinas-para-Sistemas-de-Audio/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sonido-para-centros-de-entretenimiento.png",
                "parentId": "62d9b2a5a65c7",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "62d9b2a5a65c7": {
                    "id": null,
                    "title": "Sonido para Centros de Entretenimiento",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Sonido-para-Centros-de-Entretenimiento/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "13e36ed54a5aff9929eccdde5a3d0a58",
                "title": "Barras de Sonido",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Bocinas/Barras-de-Sonido/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/barras-de-sonido.png",
                "parentId": "62d9b2a5a65c7",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "62d9b2a5a65c7": {
                    "id": null,
                    "title": "Sonido para Centros de Entretenimiento",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Sonido-para-Centros-de-Entretenimiento/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "62e063f106079",
                "title": "Bafles y Subwoofers Profesionales",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Sonido-para-Centros-de-Entretenimiento/Bafles-y-Subwoofers-Profesionales/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/subwoofers-para-sonido-envolvente.png",
                "parentId": "62d9b2a5a65c7",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "62d9b2a5a65c7": {
                    "id": null,
                    "title": "Sonido para Centros de Entretenimiento",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Sonido-para-Centros-de-Entretenimiento/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "530e27cd4fff5",
                "title": "Soportes",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Sonido-para-Centros-de-Entretenimiento/Soportes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soportes-blu-ray.png",
                "parentId": "62d9b2a5a65c7",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "62d9b2a5a65c7": {
                    "id": null,
                    "title": "Sonido para Centros de Entretenimiento",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Sonido-para-Centros-de-Entretenimiento/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869881ac085f0.77594950": {
                "id": null,
                "title": "Audio",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "610049aa762ae",
            "title": "Producción de Audio",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/31produccion-de-audio.png",
            "parentId": "a4f4869881ac085f0.77594950",
            "subCategories": [
              {
                "id": "3904a1ea8bc21bea1.83478732",
                "title": "Equipo para DJ",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/Equipo-para-DJ/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/equipo-dj.png",
                "parentId": "610049aa762ae",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "610049aa762ae": {
                    "id": null,
                    "title": "Producción de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "61004b1440c49",
                "title": "Interfaces de Audio",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/Interfaces-de-Audio/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/32interfaces-de-audio.png",
                "parentId": "610049aa762ae",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "610049aa762ae": {
                    "id": null,
                    "title": "Producción de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "610052548b4e0",
                "title": "Ecualizadores / Crossover / Amplificadores",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/Ecualizadores-Crossover-Amplificadores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/33ecualizadores-_-crossover-_-amplificadores.png",
                "parentId": "610049aa762ae",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "610049aa762ae": {
                    "id": null,
                    "title": "Producción de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "6100560df2bd5",
                "title": "Pedales",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/Pedales/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pedales.png",
                "parentId": "610049aa762ae",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "610049aa762ae": {
                    "id": null,
                    "title": "Producción de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "6100567c89af3",
                "title": "Proyectores de Luz",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/Proyectores-de-Luz/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0001_proyectores-de-luz.png",
                "parentId": "610049aa762ae",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "610049aa762ae": {
                    "id": null,
                    "title": "Producción de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "610056d88b211",
                "title": "Amplificadores para Audífonos",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/Amplificadores-para-Audifonos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0000_amplificadores-para-audifonos.png",
                "parentId": "610049aa762ae",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "610049aa762ae": {
                    "id": null,
                    "title": "Producción de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "61096b1a7ef51",
                "title": "Mezcladoras",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/Mezcladoras/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/34mezcladoras.png",
                "parentId": "610049aa762ae",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "610049aa762ae": {
                    "id": null,
                    "title": "Producción de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "611bfaae7a7ea",
                "title": "Sistemas de Grabación",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/Sistemas-de-Grabacion/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0001_sistemas-de-grabacion.png",
                "parentId": "610049aa762ae",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "610049aa762ae": {
                    "id": null,
                    "title": "Producción de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Produccion-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869881ac085f0.77594950": {
                "id": null,
                "title": "Audio",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "64138a5e39261",
            "title": "Instrumentos Musicales",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Instrumentos-Musicales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/instrumentos-musicales.png",
            "parentId": "a4f4869881ac085f0.77594950",
            "subCategories": [
              {
                "id": "64274c13a21e7",
                "title": "Teclados Musicales",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Instrumentos-Musicales/Teclados-Musicales/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/teclados.png",
                "parentId": "64138a5e39261",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "64138a5e39261": {
                    "id": null,
                    "title": "Instrumentos Musicales",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Instrumentos-Musicales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869881ac085f0.77594950": {
                "id": null,
                "title": "Audio",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "646fd67ec0258c722bda23cc9e26325b",
            "title": "Accesorios de Audio",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios(1).png",
            "parentId": "a4f4869881ac085f0.77594950",
            "subCategories": [
              {
                "id": "51e079292d8ad",
                "title": "Discos CD",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/Discos-CD/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cd-r.png",
                "parentId": "646fd67ec0258c722bda23cc9e26325b",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "646fd67ec0258c722bda23cc9e26325b": {
                    "id": null,
                    "title": "Accesorios de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51e07940ad1de",
                "title": "Discos DVD",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/Discos-DVD/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/dvd-r.png",
                "parentId": "646fd67ec0258c722bda23cc9e26325b",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "646fd67ec0258c722bda23cc9e26325b": {
                    "id": null,
                    "title": "Accesorios de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51e079547503b",
                "title": "Discos Blu-Ray",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/Discos-Blu-Ray/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/blu-ray.png",
                "parentId": "646fd67ec0258c722bda23cc9e26325b",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "646fd67ec0258c722bda23cc9e26325b": {
                    "id": null,
                    "title": "Accesorios de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51e0796c3d55d",
                "title": "Estuches y Cajas",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/Estuches-y-Cajas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/estuches-cd.png",
                "parentId": "646fd67ec0258c722bda23cc9e26325b",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "646fd67ec0258c722bda23cc9e26325b": {
                    "id": null,
                    "title": "Accesorios de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "86a35a1479f59c87862e7aaea5449771",
                "title": "Micrófonos",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/Microfonos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/microfonos.png",
                "parentId": "646fd67ec0258c722bda23cc9e26325b",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "646fd67ec0258c722bda23cc9e26325b": {
                    "id": null,
                    "title": "Accesorios de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5f765c9f9c36a",
                "title": "Accesorios para Micrófonos",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/Accesorios-para-Microfonos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/4accesoriosparamicrofonos.png",
                "parentId": "646fd67ec0258c722bda23cc9e26325b",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                    "image": null,
                    "stock": 0
                  },
                  "646fd67ec0258c722bda23cc9e26325b": {
                    "id": null,
                    "title": "Accesorios de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60ef33c463eef",
                "title": "Bases para Audífonos",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/Bases-para-Audifonos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/24bases-para-audifonos.png",
                "parentId": "646fd67ec0258c722bda23cc9e26325b",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/?force_sid=rg4tmiobc230ilvad8vle6m9ip",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869881ac085f0.77594950": {
                    "id": null,
                    "title": "Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/?force_sid=rg4tmiobc230ilvad8vle6m9ip",
                    "image": null,
                    "stock": 0
                  },
                  "646fd67ec0258c722bda23cc9e26325b": {
                    "id": null,
                    "title": "Accesorios de Audio",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Accesorios-de-Audio/?force_sid=rg4tmiobc230ilvad8vle6m9ip",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869881ac085f0.77594950": {
                "id": null,
                "title": "Audio",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "4fe353e55a8d4": {
            "id": null,
            "title": "Audio y Video",
            "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "a4f4869872082cfc0.47037207",
        "title": "Foto y Video",
        "link": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/foto-video.png",
        "parentId": "4fe353e55a8d4",
        "subCategories": [
          {
            "id": "a4f48698780cf4be6.52122619",
            "title": "Cámaras  Digitales",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Camaras-Digitales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/camaras-digitales.png",
            "parentId": "a4f4869872082cfc0.47037207",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869872082cfc0.47037207": {
                "id": null,
                "title": "Foto y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "30e57993e1b37cde60d2bb1456f49df8",
            "title": "Cámaras Reflex",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Camaras-Reflex/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/camara-reflex.png",
            "parentId": "a4f4869872082cfc0.47037207",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869872082cfc0.47037207": {
                "id": null,
                "title": "Foto y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "a4f486987eb649072.89549534",
            "title": "Cámaras de Video",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Camaras-de-Video/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/camaras-video.png",
            "parentId": "a4f4869872082cfc0.47037207",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869872082cfc0.47037207": {
                "id": null,
                "title": "Foto y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "41619a0fd3e6cb1408c3c854fedec123",
            "title": "Cámaras Deportivas",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/Camaras-Deportivas/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/41619a0fd3e6cb1408c3c854fedec123-CP.png",
            "parentId": "a4f4869872082cfc0.47037207",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869872082cfc0.47037207": {
                "id": null,
                "title": "Foto y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "8f9abc9698d7a41bf53547daeec7a4c2",
            "title": "Drones",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/Drones/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/8f9abc9698d7a41bf53547daeec7a4c2-CP.png",
            "parentId": "a4f4869872082cfc0.47037207",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869872082cfc0.47037207": {
                "id": null,
                "title": "Foto y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "c6362bdadc8e7e6bfac9fa64a866842e",
            "title": "Accesorios Foto y Video",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios.png",
            "parentId": "a4f4869872082cfc0.47037207",
            "subCategories": [
              {
                "id": "54b41811785b3",
                "title": "Selfie Sticks",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/Selfie-Sticks/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/selfie-stick.png",
                "parentId": "c6362bdadc8e7e6bfac9fa64a866842e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869872082cfc0.47037207": {
                    "id": null,
                    "title": "Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "c6362bdadc8e7e6bfac9fa64a866842e": {
                    "id": null,
                    "title": "Accesorios Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4e90ce7e8bd7cbc4ae7573b16324d1a9",
                "title": "Lentes",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/Lentes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lentes.png",
                "parentId": "c6362bdadc8e7e6bfac9fa64a866842e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869872082cfc0.47037207": {
                    "id": null,
                    "title": "Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "c6362bdadc8e7e6bfac9fa64a866842e": {
                    "id": null,
                    "title": "Accesorios Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "67110e6377897f42572e701ebe38520c",
                "title": "Tripies",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/Tripies/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tripie.png",
                "parentId": "c6362bdadc8e7e6bfac9fa64a866842e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869872082cfc0.47037207": {
                    "id": null,
                    "title": "Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "c6362bdadc8e7e6bfac9fa64a866842e": {
                    "id": null,
                    "title": "Accesorios Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "9acfc7b146b506ccecc13d0f43d8c4a2",
                "title": "Cargadores y Baterías",
                "link": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/Cargadores-de-Pilas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/9acfc7b146b506ccecc13d0f43d8c4a2-CP.png",
                "parentId": "c6362bdadc8e7e6bfac9fa64a866842e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869872082cfc0.47037207": {
                    "id": null,
                    "title": "Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "c6362bdadc8e7e6bfac9fa64a866842e": {
                    "id": null,
                    "title": "Accesorios Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "7b32ad5cc8cdf2ee6a91aa640692b887",
                "title": "Fundas y Estuches",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/Fundas-y-Estuches/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fundas-estuches.png",
                "parentId": "c6362bdadc8e7e6bfac9fa64a866842e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869872082cfc0.47037207": {
                    "id": null,
                    "title": "Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "c6362bdadc8e7e6bfac9fa64a866842e": {
                    "id": null,
                    "title": "Accesorios Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "55dd7a1b0517f11e5cc4e8d4e0cc31b4",
                "title": "Estabilizadores para Celulares",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Estabilizadores-para-Celulares/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/55dd7a1b0517f11e5cc4e8d4e0cc31b4-CP.png",
                "parentId": "c6362bdadc8e7e6bfac9fa64a866842e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869872082cfc0.47037207": {
                    "id": null,
                    "title": "Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "c6362bdadc8e7e6bfac9fa64a866842e": {
                    "id": null,
                    "title": "Accesorios Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "59ef85cc88d9b",
                "title": "Soportes para Cámaras",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/Soportes-para-Camaras/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soporte-camaras.png",
                "parentId": "c6362bdadc8e7e6bfac9fa64a866842e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869872082cfc0.47037207": {
                    "id": null,
                    "title": "Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "c6362bdadc8e7e6bfac9fa64a866842e": {
                    "id": null,
                    "title": "Accesorios Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "618320a0af101",
                "title": "Iluminación para Foto y Video",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/Iluminacion-para-Foto-y-Video/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0001_iluminacion-para-foto-y-video.png",
                "parentId": "c6362bdadc8e7e6bfac9fa64a866842e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "a4f4869872082cfc0.47037207": {
                    "id": null,
                    "title": "Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "c6362bdadc8e7e6bfac9fa64a866842e": {
                    "id": null,
                    "title": "Accesorios Foto y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Accesorios-Foto-y-Video/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "a4f4869872082cfc0.47037207": {
                "id": null,
                "title": "Foto y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "4fe353e55a8d4": {
            "id": null,
            "title": "Audio y Video",
            "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "9954867a8efa28455.92316750",
        "title": "TV y Pantallas",
        "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/televisores.png",
        "parentId": "4fe353e55a8d4",
        "subCategories": [
          {
            "id": "fa0ccb885b98debfafe543cf50fb3907",
            "title": "Pantallas",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Pantallas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pantallas-led.png",
            "parentId": "9954867a8efa28455.92316750",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "9954867a8efa28455.92316750": {
                "id": null,
                "title": "TV y Pantallas",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4f7b3527c4611",
            "title": "Pantallas Comerciales",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Pantallas-Comerciales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/dlp.png",
            "parentId": "9954867a8efa28455.92316750",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "9954867a8efa28455.92316750": {
                "id": null,
                "title": "TV y Pantallas",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "6647d49503e95",
            "title": "Pantallas Interactivas",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Pantallas-Interactivas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pantallainteractiva.png",
            "parentId": "9954867a8efa28455.92316750",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "9954867a8efa28455.92316750": {
                "id": null,
                "title": "TV y Pantallas",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "64626b3c37a1a5f208c1f1be40949d3d",
            "title": "Accesorios TV",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Accesorios-TV/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-tv.png",
            "parentId": "9954867a8efa28455.92316750",
            "subCategories": [
              {
                "id": "51dda0b9c834b",
                "title": "Reproductores para Transmisión Multimedia",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Accesorios-TV/Reproductores-para-Transmision-Multimedia/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sistemas-entretenimiento.png",
                "parentId": "64626b3c37a1a5f208c1f1be40949d3d",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "9954867a8efa28455.92316750": {
                    "id": null,
                    "title": "TV y Pantallas",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/",
                    "image": null,
                    "stock": 0
                  },
                  "64626b3c37a1a5f208c1f1be40949d3d": {
                    "id": null,
                    "title": "Accesorios TV",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Accesorios-TV/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fe8af91cc8f0",
                "title": "Sintonizadores para Pantallas",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Accesorios-TV/Sintonizadores-para-Pantallas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sintonizadorestv.png",
                "parentId": "64626b3c37a1a5f208c1f1be40949d3d",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "9954867a8efa28455.92316750": {
                    "id": null,
                    "title": "TV y Pantallas",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/",
                    "image": null,
                    "stock": 0
                  },
                  "64626b3c37a1a5f208c1f1be40949d3d": {
                    "id": null,
                    "title": "Accesorios TV",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Accesorios-TV/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5f1ed9af80bb25654cf0611978aaebd1",
                "title": "Soportes para Pantallas",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Accesorios-TV/Soportes-para-Pantallas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soportes-pantallas-2018.png",
                "parentId": "64626b3c37a1a5f208c1f1be40949d3d",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "9954867a8efa28455.92316750": {
                    "id": null,
                    "title": "TV y Pantallas",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/",
                    "image": null,
                    "stock": 0
                  },
                  "64626b3c37a1a5f208c1f1be40949d3d": {
                    "id": null,
                    "title": "Accesorios TV",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Accesorios-TV/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "606550d19ef7547340e75a04d877cc26",
                "title": "Controles Remoto",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Accesorios-TV/Controles-Remoto/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/control-remoto.png",
                "parentId": "64626b3c37a1a5f208c1f1be40949d3d",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "9954867a8efa28455.92316750": {
                    "id": null,
                    "title": "TV y Pantallas",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/",
                    "image": null,
                    "stock": 0
                  },
                  "64626b3c37a1a5f208c1f1be40949d3d": {
                    "id": null,
                    "title": "Accesorios TV",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Accesorios-TV/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "59c2aacb607d0",
                "title": "Antenas para Televisión",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Accesorios-TV/Antenas-para-Television/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/antenas-tv.png",
                "parentId": "64626b3c37a1a5f208c1f1be40949d3d",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/?force_sid=2gcdticqn28lr27ovfo3acek9m",
                    "image": null,
                    "stock": 0
                  },
                  "9954867a8efa28455.92316750": {
                    "id": null,
                    "title": "TV y Pantallas",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/?force_sid=2gcdticqn28lr27ovfo3acek9m",
                    "image": null,
                    "stock": 0
                  },
                  "64626b3c37a1a5f208c1f1be40949d3d": {
                    "id": null,
                    "title": "Accesorios TV",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Accesorios-TV/?force_sid=2gcdticqn28lr27ovfo3acek9m",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "9954867a8efa28455.92316750": {
                "id": null,
                "title": "TV y Pantallas",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "657cbac14bd42",
            "title": "Videowall",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Videowall/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/videowall(1).png",
            "parentId": "9954867a8efa28455.92316750",
            "subCategories": [
              {
                "id": "657cbb383e8a2",
                "title": "Videowalls",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Videowall/Videowalls/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/out/pictures/master/category/icon/videowalls.png",
                "parentId": "657cbac14bd42",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "9954867a8efa28455.92316750": {
                    "id": null,
                    "title": "TV y Pantallas",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/",
                    "image": null,
                    "stock": 0
                  },
                  "657cbac14bd42": {
                    "id": null,
                    "title": "Videowall",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Videowall/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "657cbb4b92a91",
                "title": "Soportes para Videowalls",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Videowall/Soportes-para-Videowalls/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soportevideowall.png",
                "parentId": "657cbac14bd42",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "9954867a8efa28455.92316750": {
                    "id": null,
                    "title": "TV y Pantallas",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/",
                    "image": null,
                    "stock": 0
                  },
                  "657cbac14bd42": {
                    "id": null,
                    "title": "Videowall",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Videowall/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "656a65147626c",
                "title": "Accesorios para Videowall",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Videowall/Accesorios-para-Videowall/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/out/pictures/master/category/icon/accesoriovideowall.png",
                "parentId": "657cbac14bd42",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "9954867a8efa28455.92316750": {
                    "id": null,
                    "title": "TV y Pantallas",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/",
                    "image": null,
                    "stock": 0
                  },
                  "657cbac14bd42": {
                    "id": null,
                    "title": "Videowall",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Videowall/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "9954867a8efa28455.92316750": {
                "id": null,
                "title": "TV y Pantallas",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "4fe353e55a8d4": {
            "id": null,
            "title": "Audio y Video",
            "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "4fe3a8c069010",
        "title": "Proyección",
        "link": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/proyectores.png",
        "parentId": "4fe353e55a8d4",
        "subCategories": [
          {
            "id": "30e8d8d843eaa93cf3169501c6962a6d",
            "title": "Proyectores",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Proyectores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/proyector.jpg",
            "parentId": "4fe3a8c069010",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "4fe3a8c069010": {
                "id": null,
                "title": "Proyección",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3a99265140",
            "title": "Accesorios para Proyectores",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-proyectores.png",
            "parentId": "4fe3a8c069010",
            "subCategories": [
              {
                "id": "4f70f888b0fc3",
                "title": "Pantallas de Proyección",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/Pantallas-de-Proyeccion/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pantalla.png",
                "parentId": "4fe3a99265140",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/?force_sid=hgstbln65e9ksmod46lddms0h9",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a8c069010": {
                    "id": null,
                    "title": "Proyección",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/?force_sid=hgstbln65e9ksmod46lddms0h9",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a99265140": {
                    "id": null,
                    "title": "Accesorios para Proyectores",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/?force_sid=hgstbln65e9ksmod46lddms0h9",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "a55d56ec76f68604a8ec79b3d248c0b7",
                "title": "Lámparas para Proyectores",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/Lamparas-para-Proyectores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lamparas.png",
                "parentId": "4fe3a99265140",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a8c069010": {
                    "id": null,
                    "title": "Proyección",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a99265140": {
                    "id": null,
                    "title": "Accesorios para Proyectores",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "7b346172aafe66c7e7876328f14fd42e",
                "title": "Soportes de Proyectores",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/Soportes-de-Proyectores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soportes-proyectores.png",
                "parentId": "4fe3a99265140",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a8c069010": {
                    "id": null,
                    "title": "Proyección",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a99265140": {
                    "id": null,
                    "title": "Accesorios para Proyectores",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "01140f9c71187e72bd359154a8b67a31",
                "title": "Presentadores",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/Presentadores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/presentadores.png",
                "parentId": "4fe3a99265140",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a8c069010": {
                    "id": null,
                    "title": "Proyección",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a99265140": {
                    "id": null,
                    "title": "Accesorios para Proyectores",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "50a52cb37450a",
                "title": "Adaptadores de Red para Proyectores",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/Adaptadores-de-Red-para-Proyectores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores-red-proyectores.png",
                "parentId": "4fe3a99265140",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a8c069010": {
                    "id": null,
                    "title": "Proyección",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a99265140": {
                    "id": null,
                    "title": "Accesorios para Proyectores",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "553ea79a841fb",
                "title": "Lentes para Proyectores",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/Lentes-para-Proyectores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lentesparaproyector.png",
                "parentId": "4fe3a99265140",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a8c069010": {
                    "id": null,
                    "title": "Proyección",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a99265140": {
                    "id": null,
                    "title": "Accesorios para Proyectores",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "57696ff3880b6",
                "title": "Maletines para Proyectores",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/Maletines-para-Proyectores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/maletines-proyectores.png",
                "parentId": "4fe3a99265140",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a8c069010": {
                    "id": null,
                    "title": "Proyección",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/",
                    "image": null,
                    "stock": 0
                  },
                  "4fe3a99265140": {
                    "id": null,
                    "title": "Accesorios para Proyectores",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/Accesorios-para-Proyectores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "4fe3a8c069010": {
                "id": null,
                "title": "Proyección",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Proyeccion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "4fe353e55a8d4": {
            "id": null,
            "title": "Audio y Video",
            "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "8a142c3e4143562a5.46426637",
        "title": "Consolas y Juegos",
        "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0004_consolas-y-juegos.png",
        "parentId": "4fe353e55a8d4",
        "subCategories": [
          {
            "id": "60a3e29775a8e",
            "title": "Xbox Series X|S",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-Series-X-S/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/5xboxseriesxs.png",
            "parentId": "8a142c3e4143562a5.46426637",
            "subCategories": [
              {
                "id": "6009bd8151ccc",
                "title": "Consolas Xbox Series X|S",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-Series-X-S/Consolas-Xbox-Series-X-S/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/consolasxbox.jpg",
                "parentId": "60a3e29775a8e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                    "image": null,
                    "stock": 0
                  },
                  "60a3e29775a8e": {
                    "id": null,
                    "title": "Xbox Series X|S",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-Series-X-S/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60a3f36b9f998",
                "title": "Juegos Xbox Series X|S",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-Series-X-S/Juegos-Xbox-Series-X-S/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/juegosxbox.jpg",
                "parentId": "60a3e29775a8e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                    "image": null,
                    "stock": 0
                  },
                  "60a3e29775a8e": {
                    "id": null,
                    "title": "Xbox Series X|S",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-Series-X-S/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "627a98fd9bc6f",
                "title": "Accesorios para Xbox Series X|S",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-Series-X-S/Accesorios-para-Xbox-Series-X-S/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesoriosxbox.jpg",
                "parentId": "60a3e29775a8e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                    "image": null,
                    "stock": 0
                  },
                  "60a3e29775a8e": {
                    "id": null,
                    "title": "Xbox Series X|S",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-Series-X-S/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "8a142c3e4143562a5.46426637": {
                "id": null,
                "title": "Consolas y Juegos",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "52b4837f1ad2d",
            "title": "Xbox One",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-One/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/xbox-one-set.png",
            "parentId": "8a142c3e4143562a5.46426637",
            "subCategories": [
              {
                "id": "52b483d6a31ac",
                "title": "Juegos Xbox One",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-One/Juegos-Xbox-One/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/juegos-xbox-one.png",
                "parentId": "52b4837f1ad2d",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                    "image": null,
                    "stock": 0
                  },
                  "52b4837f1ad2d": {
                    "id": null,
                    "title": "Xbox One",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-One/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "52b4839825ec5",
                "title": "Accesorios Xbox One",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-One/Accesorios-Xbox-One/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-xbox-one.png",
                "parentId": "52b4837f1ad2d",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/?force_sid=sle1lqi73b7o7tikneg87v7aoe",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/?force_sid=sle1lqi73b7o7tikneg87v7aoe",
                    "image": null,
                    "stock": 0
                  },
                  "52b4837f1ad2d": {
                    "id": null,
                    "title": "Xbox One",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-One/?force_sid=sle1lqi73b7o7tikneg87v7aoe",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "8a142c3e4143562a5.46426637": {
                "id": null,
                "title": "Consolas y Juegos",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "8a142c3e44ea4e714.31136811",
            "title": "Xbox 360",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-360/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/x-box.png",
            "parentId": "8a142c3e4143562a5.46426637",
            "subCategories": [
              {
                "id": "4f9ed84e6ee97",
                "title": "Juegos Xbox 360",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-360/Juegos-Xbox-360/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/juego.png",
                "parentId": "8a142c3e44ea4e714.31136811",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e44ea4e714.31136811": {
                    "id": null,
                    "title": "Xbox 360",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Xbox-360/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "8a142c3e4143562a5.46426637": {
                "id": null,
                "title": "Consolas y Juegos",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5faae54b20c2d",
            "title": "PlayStation 5 (PS5)",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/PlayStation-5-PS5/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ps5.png",
            "parentId": "8a142c3e4143562a5.46426637",
            "subCategories": [
              {
                "id": "62325f4a9c5cc",
                "title": "Consolas PlayStation 5",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/PlayStation-5-PS5/Consolas-PlayStation-5/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/consola-ps5.png",
                "parentId": "5faae54b20c2d",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                    "image": null,
                    "stock": 0
                  },
                  "5faae54b20c2d": {
                    "id": null,
                    "title": "PlayStation 5 (PS5)",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/PlayStation-5-PS5/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "62325ec678f5f",
                "title": "Juegos PlayStation 5",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/PlayStation-5-PS5/Juegos-PlayStation-5/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/juegos-ps5.png",
                "parentId": "5faae54b20c2d",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                    "image": null,
                    "stock": 0
                  },
                  "5faae54b20c2d": {
                    "id": null,
                    "title": "PlayStation 5 (PS5)",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/PlayStation-5-PS5/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "623265bf472a4",
                "title": "Accesorios PlayStation 5",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/PlayStation-5-PS5/Accesorios-PlayStation-5/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-ps5.png",
                "parentId": "5faae54b20c2d",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                    "image": null,
                    "stock": 0
                  },
                  "5faae54b20c2d": {
                    "id": null,
                    "title": "PlayStation 5 (PS5)",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/PlayStation-5-PS5/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "8a142c3e4143562a5.46426637": {
                "id": null,
                "title": "Consolas y Juegos",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "52d06d3303b9b",
            "title": "Playstation 4 (PS4)",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Playstation-4-PS4/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/playstation-4-set.png",
            "parentId": "8a142c3e4143562a5.46426637",
            "subCategories": [
              {
                "id": "52d06d58d8a49",
                "title": "Juegos Playstation 4",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Playstation-4-PS4/Juegos-Playstation-4/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/juegos-playstation-4.png",
                "parentId": "52d06d3303b9b",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                    "image": null,
                    "stock": 0
                  },
                  "52d06d3303b9b": {
                    "id": null,
                    "title": "Playstation 4 (PS4)",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Playstation-4-PS4/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "52d06d6ceff5e",
                "title": "Accesorios Playstation 4",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Playstation-4-PS4/Accesorios-Playstation-4/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-ps4.png",
                "parentId": "52d06d3303b9b",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                    "image": null,
                    "stock": 0
                  },
                  "52d06d3303b9b": {
                    "id": null,
                    "title": "Playstation 4 (PS4)",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Playstation-4-PS4/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "8a142c3e4143562a5.46426637": {
                "id": null,
                "title": "Consolas y Juegos",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "668486c08b965fe61.94375384",
            "title": "Playstation 3 (PS3)",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Playstation-3-PS3/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/playstation.png",
            "parentId": "8a142c3e4143562a5.46426637",
            "subCategories": [
              {
                "id": "4f9ed96241c69",
                "title": "Accesorios Playstation 3",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Playstation-3-PS3/Accesorios-Playstation-3/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios(6).png",
                "parentId": "668486c08b965fe61.94375384",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                    "image": null,
                    "stock": 0
                  },
                  "668486c08b965fe61.94375384": {
                    "id": null,
                    "title": "Playstation 3 (PS3)",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Playstation-3-PS3/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "8a142c3e4143562a5.46426637": {
                "id": null,
                "title": "Consolas y Juegos",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5bef3ffebc54e",
            "title": "Nintendo Switch",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Nintendo-Switch/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/nintendoswitch.png",
            "parentId": "8a142c3e4143562a5.46426637",
            "subCategories": [
              {
                "id": "5c083caf78c02",
                "title": "Consolas Nintendo Switch",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Nintendo-Switch/Consolas-Nintendo-Switch/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/switch-consola.png",
                "parentId": "5bef3ffebc54e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/?force_sid=pp13nftfqog89avnvf09ul430f",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/?force_sid=pp13nftfqog89avnvf09ul430f",
                    "image": null,
                    "stock": 0
                  },
                  "5bef3ffebc54e": {
                    "id": null,
                    "title": "Nintendo Switch",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Nintendo-Switch/?force_sid=pp13nftfqog89avnvf09ul430f",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5c08525ccbe4e",
                "title": "Juegos Nintendo Switch",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Nintendo-Switch/Juegos-Nintendo-Switch/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/switch-juego.png",
                "parentId": "5bef3ffebc54e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                    "image": null,
                    "stock": 0
                  },
                  "5bef3ffebc54e": {
                    "id": null,
                    "title": "Nintendo Switch",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Nintendo-Switch/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5f58068811e3c",
                "title": "Accesorios para Nintendo Switch",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Nintendo-Switch/Accesorios-para-Nintendo-Switch/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-nintendo-switch.png",
                "parentId": "5bef3ffebc54e",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "8a142c3e4143562a5.46426637": {
                    "id": null,
                    "title": "Consolas y Juegos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                    "image": null,
                    "stock": 0
                  },
                  "5bef3ffebc54e": {
                    "id": null,
                    "title": "Nintendo Switch",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Nintendo-Switch/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "8a142c3e4143562a5.46426637": {
                "id": null,
                "title": "Consolas y Juegos",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5984aa52255e9",
            "title": "Complementos para Videojuegos",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Complementos-para-Videojuegos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/puntosdejuego.png",
            "parentId": "8a142c3e4143562a5.46426637",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "8a142c3e4143562a5.46426637": {
                "id": null,
                "title": "Consolas y Juegos",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "62aa5f16b4d06",
            "title": "Consolas Arcade",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Consolas-Arcade/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/consolas-arcade.png",
            "parentId": "8a142c3e4143562a5.46426637",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "8a142c3e4143562a5.46426637": {
                "id": null,
                "title": "Consolas y Juegos",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "64077151a7a39",
            "title": "Consolas Portátiles",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Consolas-Portatiles/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/steam-deck.png",
            "parentId": "8a142c3e4143562a5.46426637",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "8a142c3e4143562a5.46426637": {
                "id": null,
                "title": "Consolas y Juegos",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fe353e55a8d4": {
            "id": null,
            "title": "Audio y Video",
            "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "51dd99d1ca1d8",
        "title": "GPS y Auto",
        "link": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gps-stereo-auto.png",
        "parentId": "4fe353e55a8d4",
        "subCategories": [
          {
            "id": "4fce433eee590",
            "title": "Estéreos para Autos",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/Estereos-para-Autos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/estereosautos(1).png",
            "parentId": "51dd99d1ca1d8",
            "subCategories": [
              {
                "id": "4fce444099277",
                "title": "Autoestéreos",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/Estereos-para-Autos/Autoestereos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/estereosautos.png",
                "parentId": "4fce433eee590",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "51dd99d1ca1d8": {
                    "id": null,
                    "title": "GPS y Auto",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/",
                    "image": null,
                    "stock": 0
                  },
                  "4fce433eee590": {
                    "id": null,
                    "title": "Estéreos para Autos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/Estereos-para-Autos/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fce4489c2e41",
                "title": "Amplificadores",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/Estereos-para-Autos/Amplificadores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/amplificadores.png",
                "parentId": "4fce433eee590",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "51dd99d1ca1d8": {
                    "id": null,
                    "title": "GPS y Auto",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/",
                    "image": null,
                    "stock": 0
                  },
                  "4fce433eee590": {
                    "id": null,
                    "title": "Estéreos para Autos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/Estereos-para-Autos/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fce44a27d9f5",
                "title": "Bocinas",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/Estereos-para-Autos/Bocinas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/bocinas(1).png",
                "parentId": "4fce433eee590",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "51dd99d1ca1d8": {
                    "id": null,
                    "title": "GPS y Auto",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/",
                    "image": null,
                    "stock": 0
                  },
                  "4fce433eee590": {
                    "id": null,
                    "title": "Estéreos para Autos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/Estereos-para-Autos/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4fce44b5d48ec",
                "title": "Subwoofers",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/Estereos-para-Autos/Subwoofers/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/subwoofer.png",
                "parentId": "4fce433eee590",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "51dd99d1ca1d8": {
                    "id": null,
                    "title": "GPS y Auto",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/",
                    "image": null,
                    "stock": 0
                  },
                  "4fce433eee590": {
                    "id": null,
                    "title": "Estéreos para Autos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/Estereos-para-Autos/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "525ef978b2170",
                "title": "Transmisores FM",
                "link": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/Estereos-para-Autos/Transmisores-FM/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/transmisor-radio.png",
                "parentId": "4fce433eee590",
                "subCategories": [],
                "path": {
                  "4fe353e55a8d4": {
                    "id": null,
                    "title": "Audio y Video",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                    "image": null,
                    "stock": 0
                  },
                  "51dd99d1ca1d8": {
                    "id": null,
                    "title": "GPS y Auto",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/",
                    "image": null,
                    "stock": 0
                  },
                  "4fce433eee590": {
                    "id": null,
                    "title": "Estéreos para Autos",
                    "url": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/Estereos-para-Autos/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "51dd99d1ca1d8": {
                "id": null,
                "title": "GPS y Auto",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "51dd9a88b896b",
            "title": "Navegadores GPS",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/Navegadores-GPS/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gps.png",
            "parentId": "51dd99d1ca1d8",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "51dd99d1ca1d8": {
                "id": null,
                "title": "GPS y Auto",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fe353e55a8d4": {
            "id": null,
            "title": "Audio y Video",
            "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "59d56471a7d6a",
        "title": "Gadgets",
        "link": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gadgets.png",
        "parentId": "4fe353e55a8d4",
        "subCategories": [
          {
            "id": "56f436216bc3c",
            "title": "Drones",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/Drones/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/drones.png",
            "parentId": "59d56471a7d6a",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "59d56471a7d6a": {
                "id": null,
                "title": "Gadgets",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5858209ee7c8f",
            "title": "Lentes de Realidad Virtual",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/Lentes-de-Realidad-Virtual/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lentes-realidad-virtual.png",
            "parentId": "59d56471a7d6a",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "59d56471a7d6a": {
                "id": null,
                "title": "Gadgets",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "56421d48488c2",
            "title": "Smartwatch",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/Smartwatch/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/relojes-inteligentes.png",
            "parentId": "59d56471a7d6a",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "59d56471a7d6a": {
                "id": null,
                "title": "Gadgets",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "59dfb5a55a0ac",
            "title": "Cámaras Deportivas",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/Camaras-Deportivas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/camaras-deportivas.png",
            "parentId": "59d56471a7d6a",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "59d56471a7d6a": {
                "id": null,
                "title": "Gadgets",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "28150853a03e8c0fcaf57d7d1f1beb81",
            "title": "Dash Cam",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Foto-y-Video/Camaras-de-Video/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/28150853a03e8c0fcaf57d7d1f1beb81-CP.png",
            "parentId": "59d56471a7d6a",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "59d56471a7d6a": {
                "id": null,
                "title": "Gadgets",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "63c08f3f6063b",
            "title": "Accesorios para Smartwatch",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/Accesorios-para-Smartwatch/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-para-smartwatch.png",
            "parentId": "59d56471a7d6a",
            "subCategories": [],
            "path": {
              "4fe353e55a8d4": {
                "id": null,
                "title": "Audio y Video",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
                "image": null,
                "stock": 0
              },
              "59d56471a7d6a": {
                "id": null,
                "title": "Gadgets",
                "url": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fe353e55a8d4": {
            "id": null,
            "title": "Audio y Video",
            "url": "https://www.cyberpuerta.mx/Audio-y-Video/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      }
    ],
    "path": [],
    "hasSubCategories": true
  },
  {
    "id": "5f1c30320758d566474702e532b4ff20",
    "title": "Energía",
    "link": "https://www.cyberpuerta.mx/Energia/",
    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/5f1c30320758d566474702e532b4ff20-CP.png",
    "parentId": "",
    "subCategories": [
      {
        "id": "5b4e1e3644c95",
        "title": "Protección Contra Descargas",
        "link": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/proteccion-descarga.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [
          {
            "id": "5f11683990ce1ea3d15f61649a210bb3",
            "title": "Reguladores de Voltaje",
            "link": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/Reguladores-de-Voltaje/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/reguladores.png",
            "parentId": "5b4e1e3644c95",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4e1e3644c95": {
                "id": null,
                "title": "Protección Contra Descargas",
                "url": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "523b51372c83f",
            "title": "No Break / UPS",
            "link": "https://www.cyberpuerta.mx/Energia/No-Break-UPS/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/no-breaks-ups.png",
            "parentId": "5b4e1e3644c95",
            "subCategories": [
              {
                "id": "5f163fcb352021c8db7f426a272c150c",
                "title": "No Break",
                "link": "https://www.cyberpuerta.mx/Energia/No-Break-UPS/No-Break/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/nobreak.png",
                "parentId": "523b51372c83f",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b4e1e3644c95": {
                    "id": null,
                    "title": "Protección Contra Descargas",
                    "url": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/",
                    "image": null,
                    "stock": 0
                  },
                  "523b51372c83f": {
                    "id": null,
                    "title": "No Break - UPS",
                    "url": "https://www.cyberpuerta.mx/Energia/No-Break-UPS/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51c49c3346082",
                "title": "Baterías para No Break",
                "link": "https://www.cyberpuerta.mx/Energia/No-Break-UPS/Baterias-para-No-Break/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/baterias-no-break.png",
                "parentId": "523b51372c83f",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b4e1e3644c95": {
                    "id": null,
                    "title": "Protección Contra Descargas",
                    "url": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/",
                    "image": null,
                    "stock": 0
                  },
                  "523b51372c83f": {
                    "id": null,
                    "title": "No Break - UPS",
                    "url": "https://www.cyberpuerta.mx/Energia/No-Break-UPS/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5554d68f86a09",
                "title": "Accesorios No Break",
                "link": "https://www.cyberpuerta.mx/Energia/No-Break-UPS/Accesorios-No-Break/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-no-break.png",
                "parentId": "523b51372c83f",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b4e1e3644c95": {
                    "id": null,
                    "title": "Protección Contra Descargas",
                    "url": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/",
                    "image": null,
                    "stock": 0
                  },
                  "523b51372c83f": {
                    "id": null,
                    "title": "No Break - UPS",
                    "url": "https://www.cyberpuerta.mx/Energia/No-Break-UPS/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4e1e3644c95": {
                "id": null,
                "title": "Protección Contra Descargas",
                "url": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "51c4c8b28a9e7",
            "title": "PDU's",
            "link": "https://www.cyberpuerta.mx/Energia/PDU-s/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pdu.png",
            "parentId": "5b4e1e3644c95",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4e1e3644c95": {
                "id": null,
                "title": "Protección Contra Descargas",
                "url": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5f12041459be3d9bdd6831e0aca8be33",
            "title": "Supresores de Picos",
            "link": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/Supresores-de-Picos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/supresor-picos.png",
            "parentId": "5b4e1e3644c95",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/?force_sid=he7dk192lovbvo2qhje3i0mu7k",
                "image": null,
                "stock": 0
              },
              "5b4e1e3644c95": {
                "id": null,
                "title": "Protección Contra Descargas",
                "url": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/?force_sid=he7dk192lovbvo2qhje3i0mu7k",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "654daafaffc24db33288eb4ab491f66e",
            "title": "Protectores PoE",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Redes/Protectores-PoE/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/protectorpoe(1).png",
            "parentId": "5b4e1e3644c95",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4e1e3644c95": {
                "id": null,
                "title": "Protección Contra Descargas",
                "url": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "555f455e26e70",
            "title": "Reductores de Voltaje",
            "link": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/Reductores-de-Voltaje/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/transformadores.png",
            "parentId": "5b4e1e3644c95",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4e1e3644c95": {
                "id": null,
                "title": "Protección Contra Descargas",
                "url": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5b999b8e73b0c",
            "title": "Protectores Coaxiales",
            "link": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/Protectores-Coaxiales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/protectorescoaxiales.png",
            "parentId": "5b4e1e3644c95",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4e1e3644c95": {
                "id": null,
                "title": "Protección Contra Descargas",
                "url": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "53c6eaacf3979",
        "title": "Adaptadores, Cargadores y Pilas",
        "link": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cargadores-pilas-baterias.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [
          {
            "id": "53c6eb0799182",
            "title": "Cargadores Portátiles",
            "link": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/Cargadores-Portatiles/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/celulares-baterias(2).png",
            "parentId": "53c6eaacf3979",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "53c6eaacf3979": {
                "id": null,
                "title": "Adaptadores, Cargadores y Pilas",
                "url": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51c4c56c91ebc",
            "title": "Cargadores de Dispositivos Móviles",
            "link": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/Cargadores-de-Dispositivos-Moviles/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cargadores-moviles.png",
            "parentId": "53c6eaacf3979",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "53c6eaacf3979": {
                "id": null,
                "title": "Adaptadores, Cargadores y Pilas",
                "url": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51c33b794d2e7",
            "title": "Pilas",
            "link": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/Pilas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pilas.png",
            "parentId": "53c6eaacf3979",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "53c6eaacf3979": {
                "id": null,
                "title": "Adaptadores, Cargadores y Pilas",
                "url": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51ded7c4d70cb",
            "title": "Cargadores de Pilas",
            "link": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/Cargadores-de-Pilas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cargador-pilas.png",
            "parentId": "53c6eaacf3979",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "53c6eaacf3979": {
                "id": null,
                "title": "Adaptadores, Cargadores y Pilas",
                "url": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "58504a31e8ec2",
            "title": "Adaptadores de Energía Universal",
            "link": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/Adaptadores-de-Energia-Universal/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores-energia.png",
            "parentId": "53c6eaacf3979",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "53c6eaacf3979": {
                "id": null,
                "title": "Adaptadores, Cargadores y Pilas",
                "url": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5b4e1eb2d9226",
            "title": "Extensiones",
            "link": "https://www.cyberpuerta.mx/Energia/Extensiones/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/extensiones.png",
            "parentId": "53c6eaacf3979",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "53c6eaacf3979": {
                "id": null,
                "title": "Adaptadores, Cargadores y Pilas",
                "url": "https://www.cyberpuerta.mx/Energia/Cargadores-Baterias-Pilas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5b4e17310590b",
        "title": "Energía Computo",
        "link": "https://www.cyberpuerta.mx/Energia/Energia-Computo/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-laptops.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [
          {
            "id": "7e13eba7a15ed8c92e07e902c6538e02",
            "title": "Fuentes de Poder para PC's ",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Fuentes-de-Poder-para-PC-s/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fuente-poder-pc.png",
            "parentId": "5b4e17310590b",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4e17310590b": {
                "id": null,
                "title": "Energía Computo",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "6c9ed571197a69ef60a3993e267c8f33",
            "title": "Baterías para Laptop",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-Computo/Baterias-para-Laptop/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/baterias.png",
            "parentId": "5b4e17310590b",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4e17310590b": {
                "id": null,
                "title": "Energía Computo",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4e9af4d778cf6fe3dd23a8af1645b1f5",
            "title": "Cargadores para Laptop",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-Computo/Cargadores-para-Laptop/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cargadores.png",
            "parentId": "5b4e17310590b",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4e17310590b": {
                "id": null,
                "title": "Energía Computo",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "60647993b85cb41fa716b6d5aa3de533",
        "title": "Energía Foto y Video",
        "link": "https://www.cyberpuerta.mx/Energia/Energia-Foto-Video/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cargadores(1).png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "5390d4c66fb98",
        "title": "Energía Impresión y Copiado",
        "link": "https://www.cyberpuerta.mx/Energia/Energia-Impresion-Copiado/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cargador-impresoras.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "5b4e19f7d73eb",
        "title": "Energía para Redes",
        "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Redes/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-redes-1.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [
          {
            "id": "51ffd29ba3604",
            "title": "Fuentes de Poder para Switches",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Redes/Fuentes-de-Poder-para-Switches/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-switches.png",
            "parentId": "5b4e19f7d73eb",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4e19f7d73eb": {
                "id": null,
                "title": "Energía para Redes",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "6c9e253753f3443f4215d773130448d3",
            "title": "Adaptadores PoE",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Redes/Adaptadores-PoE/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores(2).png",
            "parentId": "5b4e19f7d73eb",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4e19f7d73eb": {
                "id": null,
                "title": "Energía para Redes",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5ab29b611bb2e",
            "title": "Protectores PoE",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Redes/Protectores-PoE/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/protectorpoe.png",
            "parentId": "5b4e19f7d73eb",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4e19f7d73eb": {
                "id": null,
                "title": "Energía para Redes",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "51f6c5008d8a0",
        "title": "Energía Seguridad y Vigilancia",
        "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Seguridad-Vigilancia/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fuente-poder(2).png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [
          {
            "id": "5b50d02366fa4",
            "title": "Energía para Cámaras y Grabadoras",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Seguridad-Vigilancia/Energia-para-Camaras-y-Grabadoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energiacamaras.png",
            "parentId": "51f6c5008d8a0",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "51f6c5008d8a0": {
                "id": null,
                "title": "Energía Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Seguridad-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5b50d0ec1a3a4",
            "title": "Energía para Sistemas de Alarmas",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Seguridad-Vigilancia/Energia-para-Sistemas-de-Alarmas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-alarmas.png",
            "parentId": "51f6c5008d8a0",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "51f6c5008d8a0": {
                "id": null,
                "title": "Energía Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Seguridad-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5b50d113914bd",
            "title": "Energía Control de Acceso",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Seguridad-Vigilancia/Energia-Control-de-Acceso/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-controlacceso.png",
            "parentId": "51f6c5008d8a0",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "51f6c5008d8a0": {
                "id": null,
                "title": "Energía Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Seguridad-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5b50d137568ae",
            "title": "Transformadores",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Seguridad-Vigilancia/Transformadores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/transformadores.png",
            "parentId": "51f6c5008d8a0",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "51f6c5008d8a0": {
                "id": null,
                "title": "Energía Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Seguridad-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5b50d153c6154",
            "title": "Tarjetas para Fuente de Poder",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Seguridad-Vigilancia/Tarjetas-para-Fuente-de-Poder/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjetas-fuentepoder.png",
            "parentId": "51f6c5008d8a0",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/?force_sid=j3ok8lp28hf95vcgrq71b4fcqh",
                "image": null,
                "stock": 0
              },
              "51f6c5008d8a0": {
                "id": null,
                "title": "Energía Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Seguridad-Vigilancia/?force_sid=j3ok8lp28hf95vcgrq71b4fcqh",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "51a6239f2e1cb",
        "title": "Energía Punto de Venta (POS)",
        "link": "https://www.cyberpuerta.mx/Energia/Energia-Punto-de-Venta-POS/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fuente-poder(1).png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "5b4f7ad221a46",
        "title": "Energía para Telecomunicación",
        "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-telecomunicaciones.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [
          {
            "id": "54356bcee7c56",
            "title": "Energía para Celulares",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Celulares/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cargadores-cables(2).png",
            "parentId": "5b4f7ad221a46",
            "subCategories": [
              {
                "id": "5202818b57256",
                "title": "Cargadores y Cables",
                "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Celulares/Cargadores-Cables/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cargadores-cables(1).png",
                "parentId": "54356bcee7c56",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b4f7ad221a46": {
                    "id": null,
                    "title": "Energía para Telecomunicación",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "54356bcee7c56": {
                    "id": null,
                    "title": "Energía para Celulares",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Celulares/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "b9932784f45736cc8c6238c1ef53dcbc",
                "title": "Cargadores Portátiles",
                "link": "https://www.cyberpuerta.mx/Computadoras/Tablets/Cargadores-Portatiles/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/b9932784f45736cc8c6238c1ef53dcbc-CP.png",
                "parentId": "54356bcee7c56",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b4f7ad221a46": {
                    "id": null,
                    "title": "Energía para Telecomunicación",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "54356bcee7c56": {
                    "id": null,
                    "title": "Energía para Celulares",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Celulares/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4f7ad221a46": {
                "id": null,
                "title": "Energía para Telecomunicación",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "54356e3f0dc91",
            "title": "Energía para Teléfonos Convencionales",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-Convencionales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-telefonia-convencional.png",
            "parentId": "5b4f7ad221a46",
            "subCategories": [
              {
                "id": "5435786270a78",
                "title": "Cargadores para Teléfonos Convencionales",
                "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-Convencionales/Cargadores-para-Telefonos-Convencionales/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores-cargadores.png",
                "parentId": "54356e3f0dc91",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b4f7ad221a46": {
                    "id": null,
                    "title": "Energía para Telecomunicación",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "54356e3f0dc91": {
                    "id": null,
                    "title": "Energía para Teléfonos Convencionales",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-Convencionales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "53c7ede434b94",
                "title": "Cables Telefónicos",
                "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-Convencionales/Cables-Telefonicos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cable-telefonico.png",
                "parentId": "54356e3f0dc91",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b4f7ad221a46": {
                    "id": null,
                    "title": "Energía para Telecomunicación",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "54356e3f0dc91": {
                    "id": null,
                    "title": "Energía para Teléfonos Convencionales",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-Convencionales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "543578b20c836",
                "title": "Baterías para Teléfonos Convencionales",
                "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-Convencionales/Baterias-para-Telefonos-Convencionales/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/bateria(2).png",
                "parentId": "54356e3f0dc91",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b4f7ad221a46": {
                    "id": null,
                    "title": "Energía para Telecomunicación",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "54356e3f0dc91": {
                    "id": null,
                    "title": "Energía para Teléfonos Convencionales",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-Convencionales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "64fb8cc2c164d",
                "title": "Protectores para Línea Telefónica",
                "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-Convencionales/Protectores-para-Linea-Telefonica/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/protectorlineatelefono.png",
                "parentId": "54356e3f0dc91",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b4f7ad221a46": {
                    "id": null,
                    "title": "Energía para Telecomunicación",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "54356e3f0dc91": {
                    "id": null,
                    "title": "Energía para Teléfonos Convencionales",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-Convencionales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4f7ad221a46": {
                "id": null,
                "title": "Energía para Telecomunicación",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "54356c5cee491",
            "title": "Energía para Teléfonos VoIP",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-VoIP/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-telefonia-voip.png",
            "parentId": "5b4f7ad221a46",
            "subCategories": [
              {
                "id": "52028576965b5",
                "title": "Cargadores para Teléfonos VoIP",
                "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-VoIP/Cargadores-para-Telefonos-VoIP/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cargadores-cables.png",
                "parentId": "54356c5cee491",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b4f7ad221a46": {
                    "id": null,
                    "title": "Energía para Telecomunicación",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "54356c5cee491": {
                    "id": null,
                    "title": "Energía para Teléfonos VoIP",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-VoIP/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5435762e8168a",
                "title": "Bases de Carga",
                "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-VoIP/Bases-de-Carga/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/basesdecarga.png",
                "parentId": "54356c5cee491",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b4f7ad221a46": {
                    "id": null,
                    "title": "Energía para Telecomunicación",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "54356c5cee491": {
                    "id": null,
                    "title": "Energía para Teléfonos VoIP",
                    "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-VoIP/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4f7ad221a46": {
                "id": null,
                "title": "Energía para Telecomunicación",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "591de09aee175",
            "title": "Energía para Radios",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Radios/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-para-radios.png",
            "parentId": "5b4f7ad221a46",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "5b4f7ad221a46": {
                "id": null,
                "title": "Energía para Telecomunicación",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "65ef954f10ba5",
        "title": "Energía para el Hogar",
        "link": "https://www.cyberpuerta.mx/Energia/Energia-para-el-Hogar/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energiahogar.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [
          {
            "id": "663a938f049dd",
            "title": "Cables Eléctricos",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-el-Hogar/Cables-Electricos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cableselectricos.png",
            "parentId": "65ef954f10ba5",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "65ef954f10ba5": {
                "id": null,
                "title": "Energía para el Hogar",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-el-Hogar/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "65f9e5f79075c",
            "title": "Tomacorrientes",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-el-Hogar/Tomacorrientes/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tomacorrientes.png",
            "parentId": "65ef954f10ba5",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "65ef954f10ba5": {
                "id": null,
                "title": "Energía para el Hogar",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-el-Hogar/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "ab7847d852736a44a3be0d6239f26be9",
            "title": "Apagadores e Interruptores",
            "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Iluminacion-Inteligente/Interruptores-y-Atenuadores-Inteligentes/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6675efabecb68-CP.jpg",
            "parentId": "65ef954f10ba5",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "65ef954f10ba5": {
                "id": null,
                "title": "Energía para el Hogar",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-el-Hogar/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "65ef95c995fd5",
            "title": "Centros de Carga",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-el-Hogar/Centros-de-Carga/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6675effbbbf6b-CP.png",
            "parentId": "65ef954f10ba5",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "65ef954f10ba5": {
                "id": null,
                "title": "Energía para el Hogar",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-el-Hogar/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "65ef95fbc7bac",
            "title": "Frentes para Centros de Carga",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-el-Hogar/Frentes-para-Centros-de-Carga/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/667b1d5f54322-CP.png",
            "parentId": "65ef954f10ba5",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/?force_sid=jaho9fri369ulmq902jaett0uk",
                "image": null,
                "stock": 0
              },
              "65ef954f10ba5": {
                "id": null,
                "title": "Energía para el Hogar",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-para-el-Hogar/?force_sid=jaho9fri369ulmq902jaett0uk",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "60d6478cadc57",
        "title": "Energía para Herramientas Eléctricas",
        "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Herramientas-Electricas/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energiaherramientas.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "656e0d3cb7fe8",
        "title": "Baterías",
        "link": "https://www.cyberpuerta.mx/Energia/Baterias/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/baterias.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [
          {
            "id": "656e0d9848acf",
            "title": "Baterías Selladas",
            "link": "https://www.cyberpuerta.mx/Energia/Baterias/Baterias-Selladas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/bateriasellada.png",
            "parentId": "656e0d3cb7fe8",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "656e0d3cb7fe8": {
                "id": null,
                "title": "Baterías",
                "url": "https://www.cyberpuerta.mx/Energia/Baterias/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "9761f0580d8fb563eee95656d06d81dd",
            "title": "Gabinetes para Baterías",
            "link": "https://www.cyberpuerta.mx/Energia/Baterias/Gabinetes-para-Baterias/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/9761f0580d8fb563eee95656d06d81dd-CP.png",
            "parentId": "656e0d3cb7fe8",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "656e0d3cb7fe8": {
                "id": null,
                "title": "Baterías",
                "url": "https://www.cyberpuerta.mx/Energia/Baterias/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "214ffceffbdb103c32e60c8739c7d2ff",
            "title": "Cargadores de Baterías Selladas",
            "link": "https://www.cyberpuerta.mx/Energia/Baterias/Cargadores-de-Baterias-Selladas/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/214ffceffbdb103c32e60c8739c7d2ff-CP.png",
            "parentId": "656e0d3cb7fe8",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "656e0d3cb7fe8": {
                "id": null,
                "title": "Baterías",
                "url": "https://www.cyberpuerta.mx/Energia/Baterias/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "4d89ca104601726ab8172154d064b411",
        "title": "Fuentes Industriales",
        "link": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/",
        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/669000eecc503-CP.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [
          {
            "id": "e834d5dd7a000bced024a6160c675151",
            "title": "Fuentes Industriales CD",
            "link": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/Fuentes-Industriales-CD/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/e834d5dd7a000bced024a6160c675151-CP.png",
            "parentId": "4d89ca104601726ab8172154d064b411",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "4d89ca104601726ab8172154d064b411": {
                "id": null,
                "title": "Fuentes Industriales",
                "url": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "140031e7db34fd2b24264e9e06ad6420",
            "title": "Interruptores Industriales",
            "link": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/Interruptores-Industriales/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/140031e7db34fd2b24264e9e06ad6420-CP.png",
            "parentId": "4d89ca104601726ab8172154d064b411",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "4d89ca104601726ab8172154d064b411": {
                "id": null,
                "title": "Fuentes Industriales",
                "url": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "8a912e72864b460e4b05f39e219d2e16",
            "title": "Accesorios para Interruptores",
            "link": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/Accesorios-para-Interruptores/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/8a912e72864b460e4b05f39e219d2e16-CP.png",
            "parentId": "4d89ca104601726ab8172154d064b411",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "4d89ca104601726ab8172154d064b411": {
                "id": null,
                "title": "Fuentes Industriales",
                "url": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "494cbca3fe1a6b8cba5520df786d5c79",
            "title": "Fusibles Industriales y Eléctricos",
            "link": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/Fusibles-Industriales-y-Electricos/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/494cbca3fe1a6b8cba5520df786d5c79-CP.png",
            "parentId": "4d89ca104601726ab8172154d064b411",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "4d89ca104601726ab8172154d064b411": {
                "id": null,
                "title": "Fuentes Industriales",
                "url": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "da431e717eeb499637a65fab331dfbd3",
            "title": "Tableros de Distribución y Alumbrado",
            "link": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/Tableros-de-Distribucion-y-Alumbrado/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/da431e717eeb499637a65fab331dfbd3-CP.png",
            "parentId": "4d89ca104601726ab8172154d064b411",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/?force_sid=piuahubcracdi13g57t18g5ii6",
                "image": null,
                "stock": 0
              },
              "4d89ca104601726ab8172154d064b411": {
                "id": null,
                "title": "Fuentes Industriales",
                "url": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/?force_sid=piuahubcracdi13g57t18g5ii6",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "860879ce8db2058636f491da93752898",
            "title": "Control Industrial",
            "link": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/Control-Industrial/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/860879ce8db2058636f491da93752898-CP.png",
            "parentId": "4d89ca104601726ab8172154d064b411",
            "subCategories": [
              {
                "id": "10804035b9b36049df508f80c40175a9",
                "title": "Contactores",
                "link": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/Control-Industrial/Contactores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6690015b9258c-CP.png",
                "parentId": "860879ce8db2058636f491da93752898",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "4d89ca104601726ab8172154d064b411": {
                    "id": null,
                    "title": "Fuentes Industriales",
                    "url": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/",
                    "image": null,
                    "stock": 0
                  },
                  "860879ce8db2058636f491da93752898": {
                    "id": null,
                    "title": "Control Industrial",
                    "url": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/Control-Industrial/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "e17bc9423fcb94bcad784167bf788f5b",
                "title": "Arrancadores",
                "link": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/Control-Industrial/Arrancadores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6690011a4c079-CP.png",
                "parentId": "860879ce8db2058636f491da93752898",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "4d89ca104601726ab8172154d064b411": {
                    "id": null,
                    "title": "Fuentes Industriales",
                    "url": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/",
                    "image": null,
                    "stock": 0
                  },
                  "860879ce8db2058636f491da93752898": {
                    "id": null,
                    "title": "Control Industrial",
                    "url": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/Control-Industrial/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "d6508a993844da399e3e5ce257e68992",
                "title": "Relevadores",
                "link": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/Control-Industrial/Relevadores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6690010162b6d-CP.png",
                "parentId": "860879ce8db2058636f491da93752898",
                "subCategories": [],
                "path": {
                  "5f1c30320758d566474702e532b4ff20": {
                    "id": null,
                    "title": "Energía",
                    "url": "https://www.cyberpuerta.mx/Energia/",
                    "image": null,
                    "stock": 0
                  },
                  "4d89ca104601726ab8172154d064b411": {
                    "id": null,
                    "title": "Fuentes Industriales",
                    "url": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/",
                    "image": null,
                    "stock": 0
                  },
                  "860879ce8db2058636f491da93752898": {
                    "id": null,
                    "title": "Control Industrial",
                    "url": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/Control-Industrial/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "4d89ca104601726ab8172154d064b411": {
                "id": null,
                "title": "Fuentes Industriales",
                "url": "https://www.cyberpuerta.mx/Energia/Fuentes-Industriales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "59c0d5494f324150a10a4a071165d5f9",
        "title": "Estaciones de Carga",
        "link": "https://www.cyberpuerta.mx/Energia/Estaciones-de-Carga/",
        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/59c0d5494f324150a10a4a071165d5f9-CP.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [
          {
            "id": "f2d66a7b01cc266914cf3b0f13d1d854",
            "title": "Estación de Carga",
            "link": "https://www.cyberpuerta.mx/Energia/Estaciones-de-Carga/Estacion-de-Carga/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/f2d66a7b01cc266914cf3b0f13d1d854-CP.png",
            "parentId": "59c0d5494f324150a10a4a071165d5f9",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "59c0d5494f324150a10a4a071165d5f9": {
                "id": null,
                "title": "Estaciones de Carga",
                "url": "https://www.cyberpuerta.mx/Energia/Estaciones-de-Carga/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "61304ee16b13ebe7900966530ac91d71",
            "title": "Estación de Carga Portátil",
            "link": "https://www.cyberpuerta.mx/Energia/Estaciones-de-Carga/Estacion-de-Carga-Portatil/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/61304ee16b13ebe7900966530ac91d71-CP.png",
            "parentId": "59c0d5494f324150a10a4a071165d5f9",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "59c0d5494f324150a10a4a071165d5f9": {
                "id": null,
                "title": "Estaciones de Carga",
                "url": "https://www.cyberpuerta.mx/Energia/Estaciones-de-Carga/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "c7364f565cf069f054886ea61f0fb98c",
        "title": "Energía Solar",
        "link": "https://www.cyberpuerta.mx/Energia/Energia-Solar/",
        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c7364f565cf069f054886ea61f0fb98c-CP.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [
          {
            "id": "5bbf9c891d09465a3d3b132419d3f379",
            "title": "Paneles Solares",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-Solar/Paneles-Solares/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/5bbf9c891d09465a3d3b132419d3f379-CP.png",
            "parentId": "c7364f565cf069f054886ea61f0fb98c",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "c7364f565cf069f054886ea61f0fb98c": {
                "id": null,
                "title": "Energía Solar",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-Solar/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "2ff71007916f897b32cb49533eb0737a",
            "title": "Controladores de Carga Solar",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-Solar/Controladores-de-Carga-Solar/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/2ff71007916f897b32cb49533eb0737a-CP.png",
            "parentId": "c7364f565cf069f054886ea61f0fb98c",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "c7364f565cf069f054886ea61f0fb98c": {
                "id": null,
                "title": "Energía Solar",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-Solar/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "fabadb18dabc7ed9580276bb666d9032",
            "title": "Sistemas de Interconexión a la Red Grid - Tie",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-Solar/Sistemas-de-Interconexion-a-la-Red-Grid-Tie/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/fabadb18dabc7ed9580276bb666d9032-CP.png",
            "parentId": "c7364f565cf069f054886ea61f0fb98c",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "c7364f565cf069f054886ea61f0fb98c": {
                "id": null,
                "title": "Energía Solar",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-Solar/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "1547a4c4ef0b70506274497659dd2869",
            "title": "Protección Térmica para Paneles Solares",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-Solar/Proteccion-Termica-para-Paneles-Solares/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/1547a4c4ef0b70506274497659dd2869-CP.png",
            "parentId": "c7364f565cf069f054886ea61f0fb98c",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "c7364f565cf069f054886ea61f0fb98c": {
                "id": null,
                "title": "Energía Solar",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-Solar/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "e04dbfdec666ec06bad31be47a08b59d",
            "title": "Accesorios para Paneles Solares",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-Solar/Accesorios-para-Paneles-Solares/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/e04dbfdec666ec06bad31be47a08b59d-CP.png",
            "parentId": "c7364f565cf069f054886ea61f0fb98c",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "c7364f565cf069f054886ea61f0fb98c": {
                "id": null,
                "title": "Energía Solar",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-Solar/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "0fc17a7d397dc17f3f5e7efbfe248ee2",
            "title": "Cables Fotovoltaicos",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-Solar/Cables-Fotovoltaicos/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/0fc17a7d397dc17f3f5e7efbfe248ee2-CP.png",
            "parentId": "c7364f565cf069f054886ea61f0fb98c",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "c7364f565cf069f054886ea61f0fb98c": {
                "id": null,
                "title": "Energía Solar",
                "url": "https://www.cyberpuerta.mx/Energia/Energia-Solar/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "2b5981d0fb1cf0b10caf4341f8f58c5a",
        "title": "Tierra Física y Pararrayos",
        "link": "https://www.cyberpuerta.mx/Energia/Tierra-Fisica-y-Pararrayos/",
        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/2b5981d0fb1cf0b10caf4341f8f58c5a-CP.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [
          {
            "id": "1ce1869745387b9ebb471f303118f9c8",
            "title": "Tierra Física",
            "link": "https://www.cyberpuerta.mx/Energia/Tierra-Fisica-y-Pararrayos/Tierra-Fisica/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/1ce1869745387b9ebb471f303118f9c8-CP.png",
            "parentId": "2b5981d0fb1cf0b10caf4341f8f58c5a",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "2b5981d0fb1cf0b10caf4341f8f58c5a": {
                "id": null,
                "title": "Tierra Física y Pararrayos",
                "url": "https://www.cyberpuerta.mx/Energia/Tierra-Fisica-y-Pararrayos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "e6db2c04531225186513345ac4f6b96c",
            "title": "Pararrayos",
            "link": "https://www.cyberpuerta.mx/Energia/Tierra-Fisica-y-Pararrayos/Pararrayos/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/e6db2c04531225186513345ac4f6b96c-CP.png",
            "parentId": "2b5981d0fb1cf0b10caf4341f8f58c5a",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "2b5981d0fb1cf0b10caf4341f8f58c5a": {
                "id": null,
                "title": "Tierra Física y Pararrayos",
                "url": "https://www.cyberpuerta.mx/Energia/Tierra-Fisica-y-Pararrayos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "f65de0832bb7b4273f6b943d4768326e",
            "title": "Accesorios para Instalación",
            "link": "https://www.cyberpuerta.mx/Energia/Tierra-Fisica-y-Pararrayos/Accesorios-para-Instalacion/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/f65de0832bb7b4273f6b943d4768326e-CP.png",
            "parentId": "2b5981d0fb1cf0b10caf4341f8f58c5a",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "2b5981d0fb1cf0b10caf4341f8f58c5a": {
                "id": null,
                "title": "Tierra Física y Pararrayos",
                "url": "https://www.cyberpuerta.mx/Energia/Tierra-Fisica-y-Pararrayos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "a4b96cdc3a966239f3fcdc0ee93196ad",
        "title": "Inversores y Convertidores",
        "link": "https://www.cyberpuerta.mx/Energia/Inversores-y-Convertidores/",
        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/a4b96cdc3a966239f3fcdc0ee93196ad-CP.png",
        "parentId": "5f1c30320758d566474702e532b4ff20",
        "subCategories": [
          {
            "id": "749ff83af1795161fb11f779836b1587",
            "title": "Convertidores",
            "link": "https://www.cyberpuerta.mx/Energia/Inversores-y-Convertidores/Convertidores/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/749ff83af1795161fb11f779836b1587-CP.png",
            "parentId": "a4b96cdc3a966239f3fcdc0ee93196ad",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "a4b96cdc3a966239f3fcdc0ee93196ad": {
                "id": null,
                "title": "Inversores y Convertidores",
                "url": "https://www.cyberpuerta.mx/Energia/Inversores-y-Convertidores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "2d48a40b5c5aa3ab7f7040b1ce07b6d5",
            "title": "Inversores",
            "link": "https://www.cyberpuerta.mx/Energia/Inversores-y-Convertidores/Inversores/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/2d48a40b5c5aa3ab7f7040b1ce07b6d5-CP.png",
            "parentId": "a4b96cdc3a966239f3fcdc0ee93196ad",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "a4b96cdc3a966239f3fcdc0ee93196ad": {
                "id": null,
                "title": "Inversores y Convertidores",
                "url": "https://www.cyberpuerta.mx/Energia/Inversores-y-Convertidores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5ed27b7496387b13349fa779069a4f79",
            "title": "Generadores",
            "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/Generadores/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/5ed27b7496387b13349fa779069a4f79-CP.png",
            "parentId": "a4b96cdc3a966239f3fcdc0ee93196ad",
            "subCategories": [],
            "path": {
              "5f1c30320758d566474702e532b4ff20": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Energia/",
                "image": null,
                "stock": 0
              },
              "a4b96cdc3a966239f3fcdc0ee93196ad": {
                "id": null,
                "title": "Inversores y Convertidores",
                "url": "https://www.cyberpuerta.mx/Energia/Inversores-y-Convertidores/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5f1c30320758d566474702e532b4ff20": {
            "id": null,
            "title": "Energía",
            "url": "https://www.cyberpuerta.mx/Energia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      }
    ],
    "path": [],
    "hasSubCategories": true
  },
  {
    "id": "95140439b62cec01428f8b3bc7602a02",
    "title": "Apple",
    "link": "https://www.cyberpuerta.mx/Apple/",
    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/95140439b62cec01428f8b3bc7602a02-CP.png",
    "parentId": "",
    "subCategories": [
      {
        "id": "585809e1451ff",
        "title": "Mac",
        "link": "https://www.cyberpuerta.mx/Apple/Mac/",
        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/667201d1301f9-CP.png",
        "parentId": "95140439b62cec01428f8b3bc7602a02",
        "subCategories": [
          {
            "id": "951fe191766c41dae1235b1b70e249b6",
            "title": "MacBook Air",
            "link": "https://www.cyberpuerta.mx/Apple/Mac/MacBook-Air/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6671eea881d84-CP.png",
            "parentId": "585809e1451ff",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "585809e1451ff": {
                "id": null,
                "title": "Mac",
                "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "9519c168ea789ca6417064e08b303960",
            "title": "MacBook Pro",
            "link": "https://www.cyberpuerta.mx/Apple/Mac/MacBook-Pro/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6671ee5cb5293-CP.png",
            "parentId": "585809e1451ff",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "585809e1451ff": {
                "id": null,
                "title": "Mac",
                "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "951e387a427262d64ccedc1c355c63db",
            "title": "iMac",
            "link": "https://www.cyberpuerta.mx/Apple/Mac/iMac/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6671ee34e4366-CP.png",
            "parentId": "585809e1451ff",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "585809e1451ff": {
                "id": null,
                "title": "Mac",
                "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "95155491bb800534aa96311a03edef1d",
            "title": "Mac mini",
            "link": "https://www.cyberpuerta.mx/Apple/Mac/Mac-mini/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6671ee78c76f5-CP.png",
            "parentId": "585809e1451ff",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "585809e1451ff": {
                "id": null,
                "title": "Mac",
                "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "63b5dc7db0456",
            "title": "Mac Studio",
            "link": "https://www.cyberpuerta.mx/Apple/Mac/Mac-Studio/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6671ee94c7343-CP.png",
            "parentId": "585809e1451ff",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "585809e1451ff": {
                "id": null,
                "title": "Mac",
                "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "951a44ea22bf50e73fa87605bd2f50bc",
            "title": "Monitores Apple",
            "link": "https://www.cyberpuerta.mx/Apple/Mac/Monitores-Apple/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/monitores-apple.png",
            "parentId": "585809e1451ff",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/?force_sid=ar6cv5k3sta38278nlr77u8j1j",
                "image": null,
                "stock": 0
              },
              "585809e1451ff": {
                "id": null,
                "title": "Mac",
                "url": "https://www.cyberpuerta.mx/Apple/Mac/?force_sid=ar6cv5k3sta38278nlr77u8j1j",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "6047b8631ba19",
            "title": "Accesorios para Mac",
            "link": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-para-mac.png",
            "parentId": "585809e1451ff",
            "subCategories": [
              {
                "id": "50be54a8c2b38",
                "title": "Mouse / Teclados / Trackpads",
                "link": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/Mouse-Teclados-Trackpads/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/mouse-teclado-trackpad.png",
                "parentId": "6047b8631ba19",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "585809e1451ff": {
                    "id": null,
                    "title": "Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                    "image": null,
                    "stock": 0
                  },
                  "6047b8631ba19": {
                    "id": null,
                    "title": "Accesorios para Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "31416e913fbd9d35c0250b46240b8cd0",
                "title": "AirPods",
                "link": "https://www.cyberpuerta.mx/Apple/Apple-Music/AirPods/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/airpods(2).png",
                "parentId": "6047b8631ba19",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "585809e1451ff": {
                    "id": null,
                    "title": "Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                    "image": null,
                    "stock": 0
                  },
                  "6047b8631ba19": {
                    "id": null,
                    "title": "Accesorios para Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "c0e3c01656fcd29eb4fff0580abf9a7a",
                "title": "Monitores Apple",
                "link": "https://www.cyberpuerta.mx/Apple/Monitores-Apple/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/monitores-apple(1).png",
                "parentId": "6047b8631ba19",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "585809e1451ff": {
                    "id": null,
                    "title": "Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                    "image": null,
                    "stock": 0
                  },
                  "6047b8631ba19": {
                    "id": null,
                    "title": "Accesorios para Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5c49837eb0fab27bd01baabdfc2bbb66",
                "title": "Adaptadores / Cables para Apple",
                "link": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/Adaptadores-Cables-para-Apple/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables-para-apple.png",
                "parentId": "6047b8631ba19",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "585809e1451ff": {
                    "id": null,
                    "title": "Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                    "image": null,
                    "stock": 0
                  },
                  "6047b8631ba19": {
                    "id": null,
                    "title": "Accesorios para Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60304025282ca",
                "title": "Cargadores para Apple",
                "link": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/Cargadores-para-Apple/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores-cargadores-apple.png",
                "parentId": "6047b8631ba19",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "585809e1451ff": {
                    "id": null,
                    "title": "Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                    "image": null,
                    "stock": 0
                  },
                  "6047b8631ba19": {
                    "id": null,
                    "title": "Accesorios para Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "945d238643bb1f37493d9e24d9e03329",
                "title": "Bases para MacBook",
                "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Laptops/Bases-para-Laptops/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/bases-para-macbook.png",
                "parentId": "6047b8631ba19",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "585809e1451ff": {
                    "id": null,
                    "title": "Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                    "image": null,
                    "stock": 0
                  },
                  "6047b8631ba19": {
                    "id": null,
                    "title": "Accesorios para Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "6047bb5eb2363",
                "title": "Fundas para MacBook",
                "link": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/Fundas-para-MacBook/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fundas-para-macbook.png",
                "parentId": "6047b8631ba19",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "585809e1451ff": {
                    "id": null,
                    "title": "Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                    "image": null,
                    "stock": 0
                  },
                  "6047b8631ba19": {
                    "id": null,
                    "title": "Accesorios para Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "73f4f1cce5b7f2ccef8b3eee9e62c93c",
                "title": "Memorias RAM para Mac",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/Memorias-RAM-para-Mac/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/73f4f1cce5b7f2ccef8b3eee9e62c93c-CP.png",
                "parentId": "6047b8631ba19",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "585809e1451ff": {
                    "id": null,
                    "title": "Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                    "image": null,
                    "stock": 0
                  },
                  "6047b8631ba19": {
                    "id": null,
                    "title": "Accesorios para Mac",
                    "url": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "585809e1451ff": {
                "id": null,
                "title": "Mac",
                "url": "https://www.cyberpuerta.mx/Apple/Mac/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "95140439b62cec01428f8b3bc7602a02": {
            "id": null,
            "title": "Apple",
            "url": "https://www.cyberpuerta.mx/Apple/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5e8f79ac8ef6c",
        "title": "iPad",
        "link": "https://www.cyberpuerta.mx/Apple/iPad/",
        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6672eaed579d3-CP.png",
        "parentId": "95140439b62cec01428f8b3bc7602a02",
        "subCategories": [
          {
            "id": "5e8f7a0246664",
            "title": "iPad Pro",
            "link": "https://www.cyberpuerta.mx/Apple/iPad/iPad-Pro/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6671ed53cd06b-CP.png",
            "parentId": "5e8f79ac8ef6c",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/?force_sid=qbvcdle03a0p3jtoduc7ipl9go",
                "image": null,
                "stock": 0
              },
              "5e8f79ac8ef6c": {
                "id": null,
                "title": "iPad",
                "url": "https://www.cyberpuerta.mx/Apple/iPad/?force_sid=qbvcdle03a0p3jtoduc7ipl9go",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5e8f79e88ad71",
            "title": "iPad Air",
            "link": "https://www.cyberpuerta.mx/Apple/iPad/iPad-Air/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6671e80c38e74-CP.png",
            "parentId": "5e8f79ac8ef6c",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "5e8f79ac8ef6c": {
                "id": null,
                "title": "iPad",
                "url": "https://www.cyberpuerta.mx/Apple/iPad/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "0fc5758eb4e65cf1156cfd503a3d98ae",
            "title": "iPad",
            "link": "https://www.cyberpuerta.mx/Apple/iPad/iPad/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6671edc9e773c-CP.png",
            "parentId": "5e8f79ac8ef6c",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "5e8f79ac8ef6c": {
                "id": null,
                "title": "iPad",
                "url": "https://www.cyberpuerta.mx/Apple/iPad/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5e8f7a1813c71",
            "title": "iPad mini",
            "link": "https://www.cyberpuerta.mx/Apple/iPad/iPad-mini/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6671edee08cd9-CP.png",
            "parentId": "5e8f79ac8ef6c",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "5e8f79ac8ef6c": {
                "id": null,
                "title": "iPad",
                "url": "https://www.cyberpuerta.mx/Apple/iPad/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "50e47be0817fb",
            "title": "Accesorios para iPad",
            "link": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-para-ipad.png",
            "parentId": "5e8f79ac8ef6c",
            "subCategories": [
              {
                "id": "585828ae49461",
                "title": "Apple Pencil",
                "link": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/Apple-Pencil/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/apple-pencil.png",
                "parentId": "50e47be0817fb",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "5e8f79ac8ef6c": {
                    "id": null,
                    "title": "iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/",
                    "image": null,
                    "stock": 0
                  },
                  "50e47be0817fb": {
                    "id": null,
                    "title": "Accesorios para iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5858255047114",
                "title": "Bases y Soportes para iPad",
                "link": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/Bases-y-Soportes-para-iPad/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soporteipadrecortado.png",
                "parentId": "50e47be0817fb",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "5e8f79ac8ef6c": {
                    "id": null,
                    "title": "iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/",
                    "image": null,
                    "stock": 0
                  },
                  "50e47be0817fb": {
                    "id": null,
                    "title": "Accesorios para iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "b96462d82f5ce571bb846b991856df05",
                "title": "AirPods",
                "link": "https://www.cyberpuerta.mx/Apple/Apple-Music/AirPods/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/airpods(3).png",
                "parentId": "50e47be0817fb",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "5e8f79ac8ef6c": {
                    "id": null,
                    "title": "iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/",
                    "image": null,
                    "stock": 0
                  },
                  "50e47be0817fb": {
                    "id": null,
                    "title": "Accesorios para iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "58581aac094f4",
                "title": "Fundas para iPad",
                "link": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/Fundas-para-iPad/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/funda-ipad.png",
                "parentId": "50e47be0817fb",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "5e8f79ac8ef6c": {
                    "id": null,
                    "title": "iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/",
                    "image": null,
                    "stock": 0
                  },
                  "50e47be0817fb": {
                    "id": null,
                    "title": "Accesorios para iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "56751ce705adf697e475829975772096",
                "title": "Adaptadores /Cables para Apple",
                "link": "https://www.cyberpuerta.mx/Apple/Accesorios-para-Apple/Adaptadores-Cables-para-Apple/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables-para-apple(1).png",
                "parentId": "50e47be0817fb",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "5e8f79ac8ef6c": {
                    "id": null,
                    "title": "iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/",
                    "image": null,
                    "stock": 0
                  },
                  "50e47be0817fb": {
                    "id": null,
                    "title": "Accesorios para iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "58581c0cefa8a",
                "title": "Protectores de Pantalla para iPad",
                "link": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/Protectores-de-Pantalla-para-iPad/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/protectoripad.png",
                "parentId": "50e47be0817fb",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "5e8f79ac8ef6c": {
                    "id": null,
                    "title": "iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/",
                    "image": null,
                    "stock": 0
                  },
                  "50e47be0817fb": {
                    "id": null,
                    "title": "Accesorios para iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "010c83810f4a1f0de35d2d2e1e7c107f",
                "title": "Cargadores para Apple",
                "link": "https://www.cyberpuerta.mx/Apple/Accesorios-para-Apple/Adaptadores-Cargadores-de-Corriente-para-Apple/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores-cargadores-apple(1).png",
                "parentId": "50e47be0817fb",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "5e8f79ac8ef6c": {
                    "id": null,
                    "title": "iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/",
                    "image": null,
                    "stock": 0
                  },
                  "50e47be0817fb": {
                    "id": null,
                    "title": "Accesorios para iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "8c5296a5623096fc4a69ff7cf67c92e9",
                "title": "Mouse/ Teclados / Trackpads",
                "link": "https://www.cyberpuerta.mx/Apple/Accesorios-para-Apple/Mouse-Teclados-Trackpads/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/mouse-teclado-trackpad(1).png",
                "parentId": "50e47be0817fb",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "5e8f79ac8ef6c": {
                    "id": null,
                    "title": "iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/",
                    "image": null,
                    "stock": 0
                  },
                  "50e47be0817fb": {
                    "id": null,
                    "title": "Accesorios para iPad",
                    "url": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "5e8f79ac8ef6c": {
                "id": null,
                "title": "iPad",
                "url": "https://www.cyberpuerta.mx/Apple/iPad/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "95140439b62cec01428f8b3bc7602a02": {
            "id": null,
            "title": "Apple",
            "url": "https://www.cyberpuerta.mx/Apple/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5e1e26fab498e",
        "title": "iPhone",
        "link": "https://www.cyberpuerta.mx/Apple/iPhone/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/iphone15(1).png",
        "parentId": "95140439b62cec01428f8b3bc7602a02",
        "subCategories": [
          {
            "id": "6047c156608bb",
            "title": "iPhone",
            "link": "https://www.cyberpuerta.mx/Apple/iPhone/iPhone/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/iphone15.jpg",
            "parentId": "5e1e26fab498e",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "5e1e26fab498e": {
                "id": null,
                "title": "iPhone",
                "url": "https://www.cyberpuerta.mx/Apple/iPhone/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5858306825195",
            "title": "Accesorios para iPhone",
            "link": "https://www.cyberpuerta.mx/Apple/iPhone/Accesorios-para-iPhone/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fundas-protectores.png",
            "parentId": "5e1e26fab498e",
            "subCategories": [
              {
                "id": "51c21a06f0c22",
                "title": "Fundas para iPhone",
                "link": "https://www.cyberpuerta.mx/Apple/iPhone/Accesorios-para-iPhone/Fundas-para-iPhone/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fundasiphone.png",
                "parentId": "5858306825195",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "5e1e26fab498e": {
                    "id": null,
                    "title": "iPhone",
                    "url": "https://www.cyberpuerta.mx/Apple/iPhone/",
                    "image": null,
                    "stock": 0
                  },
                  "5858306825195": {
                    "id": null,
                    "title": "Accesorios para iPhone",
                    "url": "https://www.cyberpuerta.mx/Apple/iPhone/Accesorios-para-iPhone/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "585830f524371",
                "title": "Protectores de Pantalla para iPhone",
                "link": "https://www.cyberpuerta.mx/Apple/iPhone/Accesorios-para-iPhone/Protectores-de-Pantalla-para-iPhone/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/protectores-apple.png",
                "parentId": "5858306825195",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/",
                    "image": null,
                    "stock": 0
                  },
                  "5e1e26fab498e": {
                    "id": null,
                    "title": "iPhone",
                    "url": "https://www.cyberpuerta.mx/Apple/iPhone/",
                    "image": null,
                    "stock": 0
                  },
                  "5858306825195": {
                    "id": null,
                    "title": "Accesorios para iPhone",
                    "url": "https://www.cyberpuerta.mx/Apple/iPhone/Accesorios-para-iPhone/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60c10a6b0706b",
                "title": "AirTag",
                "link": "https://www.cyberpuerta.mx/Apple/iPhone/Accesorios-para-iPhone/AirTag/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/airtag.png",
                "parentId": "5858306825195",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/?force_sid=5l9639033fef1efnuqu5on8niu",
                    "image": null,
                    "stock": 0
                  },
                  "5e1e26fab498e": {
                    "id": null,
                    "title": "iPhone",
                    "url": "https://www.cyberpuerta.mx/Apple/iPhone/?force_sid=5l9639033fef1efnuqu5on8niu",
                    "image": null,
                    "stock": 0
                  },
                  "5858306825195": {
                    "id": null,
                    "title": "Accesorios para iPhone",
                    "url": "https://www.cyberpuerta.mx/Apple/iPhone/Accesorios-para-iPhone/?force_sid=5l9639033fef1efnuqu5on8niu",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "631a5abdd21ea",
                "title": "Fundas para AirTag",
                "link": "https://www.cyberpuerta.mx/Apple/iPhone/Accesorios-para-iPhone/Fundas-para-AirTag/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fundas-air-tag.png",
                "parentId": "5858306825195",
                "subCategories": [],
                "path": {
                  "95140439b62cec01428f8b3bc7602a02": {
                    "id": null,
                    "title": "Apple",
                    "url": "https://www.cyberpuerta.mx/Apple/?force_sid=hetpp3plo0bjish4ts8b8kcv7g",
                    "image": null,
                    "stock": 0
                  },
                  "5e1e26fab498e": {
                    "id": null,
                    "title": "iPhone",
                    "url": "https://www.cyberpuerta.mx/Apple/iPhone/?force_sid=hetpp3plo0bjish4ts8b8kcv7g",
                    "image": null,
                    "stock": 0
                  },
                  "5858306825195": {
                    "id": null,
                    "title": "Accesorios para iPhone",
                    "url": "https://www.cyberpuerta.mx/Apple/iPhone/Accesorios-para-iPhone/?force_sid=hetpp3plo0bjish4ts8b8kcv7g",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "5e1e26fab498e": {
                "id": null,
                "title": "iPhone",
                "url": "https://www.cyberpuerta.mx/Apple/iPhone/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "95140439b62cec01428f8b3bc7602a02": {
            "id": null,
            "title": "Apple",
            "url": "https://www.cyberpuerta.mx/Apple/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "6047c8c3d3a85",
        "title": "Apple Watch",
        "link": "https://www.cyberpuerta.mx/Apple/Apple-Watch/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/applewatch(1).png",
        "parentId": "95140439b62cec01428f8b3bc7602a02",
        "subCategories": [
          {
            "id": "59dd0bebb31ff",
            "title": "Apple Watch",
            "link": "https://www.cyberpuerta.mx/Apple/Apple-Watch/Apple-Watch/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/applewatch.png",
            "parentId": "6047c8c3d3a85",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "6047c8c3d3a85": {
                "id": null,
                "title": "Apple Watch",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Watch/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "2acc6a887ec95b293de7bd6235b50951",
            "title": "Apple Watch Ultra 2",
            "link": "https://www.cyberpuerta.mx/Apple-Watch-Ultra-2/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/applewatchultra2v2.png",
            "parentId": "6047c8c3d3a85",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "6047c8c3d3a85": {
                "id": null,
                "title": "Apple Watch",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Watch/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "1609ef4541b78875367a2bfe3fdc7014",
            "title": "Apple Watch Series 9",
            "link": "https://www.cyberpuerta.mx/Apple-Watch-Series-9/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/applewatch9v2.png",
            "parentId": "6047c8c3d3a85",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "6047c8c3d3a85": {
                "id": null,
                "title": "Apple Watch",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Watch/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "95a13f64ba291526a32f1cc692dacb36",
            "title": "Apple Watch Ultra",
            "link": "https://www.cyberpuerta.mx/Apple-Watch-Ultra/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/apple-watch-ultra.png",
            "parentId": "6047c8c3d3a85",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "6047c8c3d3a85": {
                "id": null,
                "title": "Apple Watch",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Watch/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4f5cc9c72d027aba717ca76edf4e602a",
            "title": "Apple Watch Series 8",
            "link": "https://www.cyberpuerta.mx/Apple-Watch-Series-8/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/apple-watch-series-8.png",
            "parentId": "6047c8c3d3a85",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "6047c8c3d3a85": {
                "id": null,
                "title": "Apple Watch",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Watch/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "b695ff76509b7f31f34800325f56d9a1",
            "title": "Apple Watch SE 2",
            "link": "https://www.cyberpuerta.mx/Apple-Watch-SE-2/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/apple-watch-se-2.png",
            "parentId": "6047c8c3d3a85",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "6047c8c3d3a85": {
                "id": null,
                "title": "Apple Watch",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Watch/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "eaac5f1b1f6ef8e39b15ea52d700214d",
            "title": "Apple Watch SE",
            "link": "https://www.cyberpuerta.mx/Apple-Watch-SE/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/applewatch.png",
            "parentId": "6047c8c3d3a85",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "6047c8c3d3a85": {
                "id": null,
                "title": "Apple Watch",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Watch/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "6047c928cd8cb",
            "title": "Accesorios para Apple Watch",
            "link": "https://www.cyberpuerta.mx/Apple/Apple-Watch/Accesorios-para-Apple-Watch/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesoriosapplewatch.png",
            "parentId": "6047c8c3d3a85",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "6047c8c3d3a85": {
                "id": null,
                "title": "Apple Watch",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Watch/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "401c803df59bfab8d973551af24cbfa3",
            "title": "AirPods",
            "link": "https://www.cyberpuerta.mx/Apple/Apple-Music/AirPods/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/airpods(1).png",
            "parentId": "6047c8c3d3a85",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "6047c8c3d3a85": {
                "id": null,
                "title": "Apple Watch",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Watch/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "95140439b62cec01428f8b3bc7602a02": {
            "id": null,
            "title": "Apple",
            "url": "https://www.cyberpuerta.mx/Apple/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "60f20ed00457d",
        "title": "AppleCare",
        "link": "https://www.cyberpuerta.mx/Apple/AppleCare/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/applecare.png",
        "parentId": "95140439b62cec01428f8b3bc7602a02",
        "subCategories": [],
        "path": {
          "95140439b62cec01428f8b3bc7602a02": {
            "id": null,
            "title": "Apple",
            "url": "https://www.cyberpuerta.mx/Apple/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "2f204009a586faf8cfd48aa2f8715455",
        "title": "Apple TV",
        "link": "https://www.cyberpuerta.mx/Apple/Apple-TV/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/appletv.png",
        "parentId": "95140439b62cec01428f8b3bc7602a02",
        "subCategories": [],
        "path": {
          "95140439b62cec01428f8b3bc7602a02": {
            "id": null,
            "title": "Apple",
            "url": "https://www.cyberpuerta.mx/Apple/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "604029de28762",
        "title": "Apple Music",
        "link": "https://www.cyberpuerta.mx/Apple/Apple-Music/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/applemusicxx.png",
        "parentId": "95140439b62cec01428f8b3bc7602a02",
        "subCategories": [
          {
            "id": "6042706341a1a",
            "title": "AirPods",
            "link": "https://www.cyberpuerta.mx/Apple/Apple-Music/AirPods/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/airpods.png",
            "parentId": "604029de28762",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "604029de28762": {
                "id": null,
                "title": "Apple Music",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Music/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "604270b77a93a",
            "title": "AirPods Max",
            "link": "https://www.cyberpuerta.mx/Apple/Apple-Music/AirPods-Max/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/airpods-plus.png",
            "parentId": "604029de28762",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "604029de28762": {
                "id": null,
                "title": "Apple Music",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Music/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "604270dbebc8e",
            "title": "HomePod",
            "link": "https://www.cyberpuerta.mx/Apple/Apple-Music/HomePod/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/homepod.png",
            "parentId": "604029de28762",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "604029de28762": {
                "id": null,
                "title": "Apple Music",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Music/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "50be5bdb3b709",
            "title": "Beats",
            "link": "https://www.cyberpuerta.mx/Apple/Apple-Music/Beats/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/beats.png",
            "parentId": "604029de28762",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "604029de28762": {
                "id": null,
                "title": "Apple Music",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Music/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "612cee9b55e1f",
            "title": "Accesorios Apple Music",
            "link": "https://www.cyberpuerta.mx/Apple/Apple-Music/Accesorios-Apple-Music/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-apple-music.png",
            "parentId": "604029de28762",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "604029de28762": {
                "id": null,
                "title": "Apple Music",
                "url": "https://www.cyberpuerta.mx/Apple/Apple-Music/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "95140439b62cec01428f8b3bc7602a02": {
            "id": null,
            "title": "Apple",
            "url": "https://www.cyberpuerta.mx/Apple/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "951a0a71c12550ed3e315bb8ec7690eb",
        "title": "Accesorios para Apple",
        "link": "https://www.cyberpuerta.mx/Apple/Accesorios-para-Apple/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios2.png",
        "parentId": "95140439b62cec01428f8b3bc7602a02",
        "subCategories": [
          {
            "id": "22c7e02fb826302fcbad4fbf633ac82f",
            "title": "Accesorios para Mac",
            "link": "https://www.cyberpuerta.mx/Apple/Mac/Accesorios-para-Mac/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-para-mac(1).png",
            "parentId": "951a0a71c12550ed3e315bb8ec7690eb",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "951a0a71c12550ed3e315bb8ec7690eb": {
                "id": null,
                "title": "Accesorios para Apple",
                "url": "https://www.cyberpuerta.mx/Apple/Accesorios-para-Apple/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "83c99711d66f89fd8ef1dfca819c1b9b",
            "title": "Accesorios para iPad",
            "link": "https://www.cyberpuerta.mx/Apple/iPad/Accesorios-para-iPad/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-para-ipad(1).png",
            "parentId": "951a0a71c12550ed3e315bb8ec7690eb",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "951a0a71c12550ed3e315bb8ec7690eb": {
                "id": null,
                "title": "Accesorios para Apple",
                "url": "https://www.cyberpuerta.mx/Apple/Accesorios-para-Apple/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "54123495944a96851fa0f0bfecd6ff03",
            "title": "Accesorios para iPhone",
            "link": "https://www.cyberpuerta.mx/Apple/iPhone/Accesorios-para-iPhone/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-para-iphone.png",
            "parentId": "951a0a71c12550ed3e315bb8ec7690eb",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "951a0a71c12550ed3e315bb8ec7690eb": {
                "id": null,
                "title": "Accesorios para Apple",
                "url": "https://www.cyberpuerta.mx/Apple/Accesorios-para-Apple/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "ef47a90850af0b6361e2f78f277988e1",
            "title": "Accesorios para Apple Watch",
            "link": "https://www.cyberpuerta.mx/Apple/Apple-Watch/Accesorios-para-Apple-Watch/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-para-applewatch(1).png",
            "parentId": "951a0a71c12550ed3e315bb8ec7690eb",
            "subCategories": [],
            "path": {
              "95140439b62cec01428f8b3bc7602a02": {
                "id": null,
                "title": "Apple",
                "url": "https://www.cyberpuerta.mx/Apple/",
                "image": null,
                "stock": 0
              },
              "951a0a71c12550ed3e315bb8ec7690eb": {
                "id": null,
                "title": "Accesorios para Apple",
                "url": "https://www.cyberpuerta.mx/Apple/Accesorios-para-Apple/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "95140439b62cec01428f8b3bc7602a02": {
            "id": null,
            "title": "Apple",
            "url": "https://www.cyberpuerta.mx/Apple/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      }
    ],
    "path": [],
    "hasSubCategories": true
  },
  {
    "id": "eea2f7869252c43aab816b4d6bdd2b65",
    "title": "Celulares y Telefonía",
    "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/telecomunicacion.png",
    "parentId": "",
    "subCategories": [
      {
        "id": "30e930a66136038153c252f246effe8d",
        "title": "Celulares",
        "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/telefoniamovil.png",
        "parentId": "eea2f7869252c43aab816b4d6bdd2b65",
        "subCategories": [
          {
            "id": "4fe49ee305363",
            "title": "Celulares",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Celulares/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/celulares.png",
            "parentId": "30e930a66136038153c252f246effe8d",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "30e930a66136038153c252f246effe8d": {
                "id": null,
                "title": "Celulares",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "bd7f116cb25357ca319260750c05df55",
            "title": "Energía para Celulares",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Celulares/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/bd7f116cb25357ca319260750c05df55-CP.png",
            "parentId": "30e930a66136038153c252f246effe8d",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "30e930a66136038153c252f246effe8d": {
                "id": null,
                "title": "Celulares",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "520280c6540fd",
            "title": "Protección para Celulares",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Proteccion-para-Celulares/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/proteccion-para-celulares.png",
            "parentId": "30e930a66136038153c252f246effe8d",
            "subCategories": [
              {
                "id": "5202813c758ef",
                "title": "Fundas para Celulares",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Proteccion-para-Celulares/Fundas-para-Celulares/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fundas.png",
                "parentId": "520280c6540fd",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "30e930a66136038153c252f246effe8d": {
                    "id": null,
                    "title": "Celulares",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                    "image": null,
                    "stock": 0
                  },
                  "520280c6540fd": {
                    "id": null,
                    "title": "Protección para Celulares",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Proteccion-para-Celulares/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "561fc9ca34946",
                "title": "Protectores para Celulares",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Proteccion-para-Celulares/Protectores-para-Celulares/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/protectores.png",
                "parentId": "520280c6540fd",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "30e930a66136038153c252f246effe8d": {
                    "id": null,
                    "title": "Celulares",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                    "image": null,
                    "stock": 0
                  },
                  "520280c6540fd": {
                    "id": null,
                    "title": "Protección para Celulares",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Proteccion-para-Celulares/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "543575d08dbd1",
                "title": "Adaptadores Bluetooth",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Proteccion-para-Celulares/Adaptadores-Bluetooth/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptador-bluetooth.png",
                "parentId": "520280c6540fd",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "30e930a66136038153c252f246effe8d": {
                    "id": null,
                    "title": "Celulares",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                    "image": null,
                    "stock": 0
                  },
                  "520280c6540fd": {
                    "id": null,
                    "title": "Protección para Celulares",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Proteccion-para-Celulares/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "30e930a66136038153c252f246effe8d": {
                "id": null,
                "title": "Celulares",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "585afc2a62005",
            "title": "Soportes para Celulares y Tablets",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Soportes-para-Celulares-y-Tablets/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/out/pictures/master/category/icon/soportemovtablets2.png",
            "parentId": "30e930a66136038153c252f246effe8d",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "30e930a66136038153c252f246effe8d": {
                "id": null,
                "title": "Celulares",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "1ebd534c712e9ca65511d2b82e7d2659",
            "title": "Lentes de Realidad Virtual",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/Lentes-de-Realidad-Virtual/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/1ebd534c712e9ca65511d2b82e7d2659-CP.png",
            "parentId": "30e930a66136038153c252f246effe8d",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "30e930a66136038153c252f246effe8d": {
                "id": null,
                "title": "Celulares",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "58e2b3177fec2",
            "title": "Accesorios para Celulares",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Accesorios-para-Celulares/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-celulares.png",
            "parentId": "30e930a66136038153c252f246effe8d",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "30e930a66136038153c252f246effe8d": {
                "id": null,
                "title": "Celulares",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "2d3a69af08b34381e55dd2180870295b",
            "title": "Smartwatch",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Gadgets/Smartwatch/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/2d3a69af08b34381e55dd2180870295b-CP.png",
            "parentId": "30e930a66136038153c252f246effe8d",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "30e930a66136038153c252f246effe8d": {
                "id": null,
                "title": "Celulares",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "6e3c4889ca71af1684dbcb8ba3702db7",
            "title": "Amplificadores de Celular",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Amplificadores-de-Celular/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6e3c4889ca71af1684dbcb8ba3702db7-CP.png",
            "parentId": "30e930a66136038153c252f246effe8d",
            "subCategories": [
              {
                "id": "3784f805486141ba7b67de83241a059a",
                "title": "Amplificadores de Celular",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Amplificadores-de-Celular/Amplificadores-de-Celular/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/3784f805486141ba7b67de83241a059a-CP.png",
                "parentId": "6e3c4889ca71af1684dbcb8ba3702db7",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "30e930a66136038153c252f246effe8d": {
                    "id": null,
                    "title": "Celulares",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                    "image": null,
                    "stock": 0
                  },
                  "6e3c4889ca71af1684dbcb8ba3702db7": {
                    "id": null,
                    "title": "Amplificadores de Celular",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Amplificadores-de-Celular/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "c249ac6ec4358801a510bfa370154502",
                "title": "Antenas para Amplificadores",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Amplificadores-de-Celular/Antenas-para-Amplificadores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c249ac6ec4358801a510bfa370154502-CP.png",
                "parentId": "6e3c4889ca71af1684dbcb8ba3702db7",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "30e930a66136038153c252f246effe8d": {
                    "id": null,
                    "title": "Celulares",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                    "image": null,
                    "stock": 0
                  },
                  "6e3c4889ca71af1684dbcb8ba3702db7": {
                    "id": null,
                    "title": "Amplificadores de Celular",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Amplificadores-de-Celular/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4e105adccbf8999c4703cf822bafa504",
                "title": "Separadores para Amplificadores",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Amplificadores-de-Celular/Separadores-para-Amplificadores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/4e105adccbf8999c4703cf822bafa504-CP.png",
                "parentId": "6e3c4889ca71af1684dbcb8ba3702db7",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "30e930a66136038153c252f246effe8d": {
                    "id": null,
                    "title": "Celulares",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                    "image": null,
                    "stock": 0
                  },
                  "6e3c4889ca71af1684dbcb8ba3702db7": {
                    "id": null,
                    "title": "Amplificadores de Celular",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Amplificadores-de-Celular/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "f18589f164017c7cf026fa81e5c108fd",
                "title": "Fuentes de Poder para Amplificadores",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Amplificadores-de-Celular/Fuentes-de-Poder-para-Amplificadores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/f18589f164017c7cf026fa81e5c108fd-CP.png",
                "parentId": "6e3c4889ca71af1684dbcb8ba3702db7",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "30e930a66136038153c252f246effe8d": {
                    "id": null,
                    "title": "Celulares",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                    "image": null,
                    "stock": 0
                  },
                  "6e3c4889ca71af1684dbcb8ba3702db7": {
                    "id": null,
                    "title": "Amplificadores de Celular",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/Amplificadores-de-Celular/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "30e930a66136038153c252f246effe8d": {
                "id": null,
                "title": "Celulares",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Celulares/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "eea2f7869252c43aab816b4d6bdd2b65": {
            "id": null,
            "title": "Celulares y Telefonía",
            "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "54356ddbc3f75",
        "title": "Telefonía Convencional",
        "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-Convencional/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/telefonia-convencional.png",
        "parentId": "eea2f7869252c43aab816b4d6bdd2b65",
        "subCategories": [
          {
            "id": "54357bf28049b",
            "title": "Teléfonos Alámbricos",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-Convencional/Telefonos-Alambricos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/telefonia-convencional(1).png",
            "parentId": "54356ddbc3f75",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "54356ddbc3f75": {
                "id": null,
                "title": "Telefonía Convencional",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-Convencional/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "2004afb82ba75dcf72e1b046728731e8",
            "title": "Energía para Teléfonos Convencionales",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-Convencionales/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/2004afb82ba75dcf72e1b046728731e8-CP.png",
            "parentId": "54356ddbc3f75",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "54356ddbc3f75": {
                "id": null,
                "title": "Telefonía Convencional",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-Convencional/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "54357c0529d9f",
            "title": "Teléfonos Inalámbricos",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-Convencional/Telefonos-Inalambricos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/telefono-inalambrico(1).png",
            "parentId": "54356ddbc3f75",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "54356ddbc3f75": {
                "id": null,
                "title": "Telefonía Convencional",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-Convencional/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "eea2f7869252c43aab816b4d6bdd2b65": {
            "id": null,
            "title": "Celulares y Telefonía",
            "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "520284677a25c",
        "title": "Telefonía VoIP",
        "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/telefonia-voip(1).png",
        "parentId": "eea2f7869252c43aab816b4d6bdd2b65",
        "subCategories": [
          {
            "id": "30e7f3e87a01db46458ff284600f3751",
            "title": "Teléfonos VoIP",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/Telefonos-VoIP/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/telefonia-voip.png",
            "parentId": "520284677a25c",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "520284677a25c": {
                "id": null,
                "title": "Telefonía VoIP",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "54356cc9997a4",
            "title": "Accesorios para Teléfonos VoIP",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/Accesorios-para-Telefonos-VoIP/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-telefonia-movil.png",
            "parentId": "520284677a25c",
            "subCategories": [
              {
                "id": "1ae33ef781b7f26d9ce54d8c106262a8",
                "title": "Energía para Teléfonos VoIP",
                "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Telefonos-VoIP/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-telefonia-voip.png",
                "parentId": "54356cc9997a4",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "520284677a25c": {
                    "id": null,
                    "title": "Telefonía VoIP",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/",
                    "image": null,
                    "stock": 0
                  },
                  "54356cc9997a4": {
                    "id": null,
                    "title": "Accesorios para Teléfonos VoIP",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/Accesorios-para-Telefonos-VoIP/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5202850e100b2",
                "title": "Módulos de Expansión",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/Accesorios-para-Telefonos-VoIP/Modulos-de-Expansion/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/modulos-expansion.png",
                "parentId": "54356cc9997a4",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "520284677a25c": {
                    "id": null,
                    "title": "Telefonía VoIP",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/",
                    "image": null,
                    "stock": 0
                  },
                  "54356cc9997a4": {
                    "id": null,
                    "title": "Accesorios para Teléfonos VoIP",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/Accesorios-para-Telefonos-VoIP/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "520285db1d94f",
                "title": "Gateways y ATAs",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/Accesorios-para-Telefonos-VoIP/Gateways-y-ATAs/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gateways-atas.png",
                "parentId": "54356cc9997a4",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "520284677a25c": {
                    "id": null,
                    "title": "Telefonía VoIP",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/",
                    "image": null,
                    "stock": 0
                  },
                  "54356cc9997a4": {
                    "id": null,
                    "title": "Accesorios para Teléfonos VoIP",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/Accesorios-para-Telefonos-VoIP/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5435782e71db9",
                "title": "Tarjetas VoIP",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/Accesorios-para-Telefonos-VoIP/Tarjetas-VoIP/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjetas-voip.png",
                "parentId": "54356cc9997a4",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "520284677a25c": {
                    "id": null,
                    "title": "Telefonía VoIP",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/",
                    "image": null,
                    "stock": 0
                  },
                  "54356cc9997a4": {
                    "id": null,
                    "title": "Accesorios para Teléfonos VoIP",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/Accesorios-para-Telefonos-VoIP/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "544fc6e3f13ee",
                "title": "Plataformas de Comunicaciones Unificadas",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/Accesorios-para-Telefonos-VoIP/Plataformas-de-Comunicaciones-Unificadas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pcunificadas.png",
                "parentId": "54356cc9997a4",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "520284677a25c": {
                    "id": null,
                    "title": "Telefonía VoIP",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/",
                    "image": null,
                    "stock": 0
                  },
                  "54356cc9997a4": {
                    "id": null,
                    "title": "Accesorios para Teléfonos VoIP",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/Accesorios-para-Telefonos-VoIP/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "520284677a25c": {
                "id": null,
                "title": "Telefonía VoIP",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "eea2f7869252c43aab816b4d6bdd2b65": {
            "id": null,
            "title": "Celulares y Telefonía",
            "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5435aa2810134",
        "title": "Sistemas para Conferencias",
        "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Sistemas-para-Conferencias/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sistemas-conferencia.png",
        "parentId": "eea2f7869252c43aab816b4d6bdd2b65",
        "subCategories": [
          {
            "id": "5435aa34b8ff9",
            "title": "Sistemas de Conferencia",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Sistemas-para-Conferencias/Sistemas-de-Conferencia/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sistemas-conferencia(1).png",
            "parentId": "5435aa2810134",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "5435aa2810134": {
                "id": null,
                "title": "Sistemas para Conferencias",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Sistemas-para-Conferencias/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5435aa772728e",
            "title": "Kits de Micrófonos",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Sistemas-para-Conferencias/Kits-de-Microfonos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/microfonos-conferencias.png",
            "parentId": "5435aa2810134",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "5435aa2810134": {
                "id": null,
                "title": "Sistemas para Conferencias",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Sistemas-para-Conferencias/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "55a3fb27a3f4e",
            "title": "Accesorios para Sistemas de Conferencia",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Sistemas-para-Conferencias/Accesorios-para-Sistemas-de-Conferencia/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-sistemas-conferencia.png",
            "parentId": "5435aa2810134",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "5435aa2810134": {
                "id": null,
                "title": "Sistemas para Conferencias",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Sistemas-para-Conferencias/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "eea2f7869252c43aab816b4d6bdd2b65": {
            "id": null,
            "title": "Celulares y Telefonía",
            "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "543fe49e86d33",
        "title": "Audio para Telefonía",
        "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/auriculares-gral(3).png",
        "parentId": "eea2f7869252c43aab816b4d6bdd2b65",
        "subCategories": [
          {
            "id": "54357958a8762",
            "title": "Manos Libres",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/Manos-Libres/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/manos-libres(1).png",
            "parentId": "543fe49e86d33",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "543fe49e86d33": {
                "id": null,
                "title": "Audio para Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "2e63d41d4ba347c89faf2933469a6dbc",
            "title": "Audífonos",
            "link": "https://www.cyberpuerta.mx/Audifonos-con-Microfono/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/audifonos(2).png",
            "parentId": "543fe49e86d33",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "543fe49e86d33": {
                "id": null,
                "title": "Audio para Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "02b77d175ed6b0ed80c6c83f7f3004d9",
            "title": "Audífonos Intrauriculares",
            "link": "https://www.cyberpuerta.mx/Audifonos-Intrauriculares-con-Microfono/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/intrauriculares(1).png",
            "parentId": "543fe49e86d33",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "543fe49e86d33": {
                "id": null,
                "title": "Audio para Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "544146ab0d981",
            "title": "Auriculares y Monoaurales",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/Auriculares-y-Monoaurales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/auriculares(5).png",
            "parentId": "543fe49e86d33",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "543fe49e86d33": {
                "id": null,
                "title": "Audio para Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "543576fa7d950",
            "title": "Altavoces",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/Altavoces/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/altavoz(1).png",
            "parentId": "543fe49e86d33",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "543fe49e86d33": {
                "id": null,
                "title": "Audio para Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5445561d89ffa",
            "title": "Cables para Auriculares",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/Cables-para-Auriculares/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables-auriculares.png",
            "parentId": "543fe49e86d33",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "543fe49e86d33": {
                "id": null,
                "title": "Audio para Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "594ab33a502cd",
            "title": "Adaptadores de Audio",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/Adaptadores-de-Audio/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores-de-audio.png",
            "parentId": "543fe49e86d33",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "543fe49e86d33": {
                "id": null,
                "title": "Audio para Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "543579ab5de57",
            "title": "Dispositivos para Descolgar",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/Dispositivos-para-Descolgar/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/dispositivo-descolgar.png",
            "parentId": "543fe49e86d33",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "543fe49e86d33": {
                "id": null,
                "title": "Audio para Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5435729612faf",
            "title": "Almohadilla",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/Almohadilla/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/almohadillas.png",
            "parentId": "543fe49e86d33",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "543fe49e86d33": {
                "id": null,
                "title": "Audio para Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "eea2f7869252c43aab816b4d6bdd2b65": {
            "id": null,
            "title": "Celulares y Telefonía",
            "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "591dd81f6e918",
        "title": "Radiocomunicación",
        "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/radios-de-comunicacion.png",
        "parentId": "eea2f7869252c43aab816b4d6bdd2b65",
        "subCategories": [
          {
            "id": "591ddb8e696df",
            "title": "Radios",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Radios/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/radios(1).png",
            "parentId": "591dd81f6e918",
            "subCategories": [],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "591dd81f6e918": {
                "id": null,
                "title": "Radiocomunicación",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "591ddbe7eb0fe",
            "title": "Accesorios para Radio",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-para-radios.png",
            "parentId": "591dd81f6e918",
            "subCategories": [
              {
                "id": "16882d7a4e94927f11ec0e4d7e67c02f",
                "title": "Energía para Radios",
                "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Telecomunicacion/Energia-para-Radios/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/16882d7a4e94927f11ec0e4d7e67c02f-CP.png",
                "parentId": "591ddbe7eb0fe",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "591dd81f6e918": {
                    "id": null,
                    "title": "Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "591ddbe7eb0fe": {
                    "id": null,
                    "title": "Accesorios para Radio",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "591de106ae833",
                "title": "Audio para Radios",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/Audio-para-Radios/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/microfonos-para-radio.png",
                "parentId": "591ddbe7eb0fe",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "591dd81f6e918": {
                    "id": null,
                    "title": "Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "591ddbe7eb0fe": {
                    "id": null,
                    "title": "Accesorios para Radio",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "591de17060838",
                "title": "Antenas para Radio",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/Antenas-para-Radio/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/antentas-para-radio.png",
                "parentId": "591ddbe7eb0fe",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "591dd81f6e918": {
                    "id": null,
                    "title": "Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "591ddbe7eb0fe": {
                    "id": null,
                    "title": "Accesorios para Radio",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "59c0233065a51",
                "title": "Clips para Radio",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/Clips-para-Radio/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/clips-radio.png",
                "parentId": "591ddbe7eb0fe",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "591dd81f6e918": {
                    "id": null,
                    "title": "Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "591ddbe7eb0fe": {
                    "id": null,
                    "title": "Accesorios para Radio",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5a1c534c6410b",
                "title": "Cables para Radio",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/Cables-para-Radio/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables-radio.png",
                "parentId": "591ddbe7eb0fe",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "591dd81f6e918": {
                    "id": null,
                    "title": "Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "591ddbe7eb0fe": {
                    "id": null,
                    "title": "Accesorios para Radio",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5bb383b2113f3",
                "title": "Fundas para Radio",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/Fundas-para-Radio/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fundaradio.png",
                "parentId": "591ddbe7eb0fe",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "591dd81f6e918": {
                    "id": null,
                    "title": "Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "591ddbe7eb0fe": {
                    "id": null,
                    "title": "Accesorios para Radio",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5c1bf05e560ea",
                "title": "Montajes para Radio",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/Montajes-para-Radio/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/montajespararadios.png",
                "parentId": "591ddbe7eb0fe",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "591dd81f6e918": {
                    "id": null,
                    "title": "Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "591ddbe7eb0fe": {
                    "id": null,
                    "title": "Accesorios para Radio",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Accesorios-para-Radio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "591dd81f6e918": {
                "id": null,
                "title": "Radiocomunicación",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "85718480c34b3f55d5916c9e07a78855",
            "title": "Antenas Radiocomunicación",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Antenas-Radiocomunicacion/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/85718480c34b3f55d5916c9e07a78855-CP.png",
            "parentId": "591dd81f6e918",
            "subCategories": [
              {
                "id": "520a9f9cfdae9ca672df1ef98f76ca59",
                "title": "Antenas Móviles",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Antenas-Radiocomunicacion/Antenas-Moviles/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/520a9f9cfdae9ca672df1ef98f76ca59-CP.png",
                "parentId": "85718480c34b3f55d5916c9e07a78855",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "591dd81f6e918": {
                    "id": null,
                    "title": "Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "85718480c34b3f55d5916c9e07a78855": {
                    "id": null,
                    "title": "Antenas Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Antenas-Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "c1bdbbba1791f3f12e171ccb012ff04b",
                "title": "Antenas para Estaciones Base y Repetidores",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Antenas-Radiocomunicacion/Antenas-para-Estaciones-Base-y-Repetidores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c1bdbbba1791f3f12e171ccb012ff04b-CP.png",
                "parentId": "85718480c34b3f55d5916c9e07a78855",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "591dd81f6e918": {
                    "id": null,
                    "title": "Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "85718480c34b3f55d5916c9e07a78855": {
                    "id": null,
                    "title": "Antenas Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Antenas-Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "99f3177069f4fa66fc2a0bc9021189c0",
                "title": "Montaje para Antenas",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Antenas-Radiocomunicacion/Montaje-para-Antenas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/99f3177069f4fa66fc2a0bc9021189c0-CP.png",
                "parentId": "85718480c34b3f55d5916c9e07a78855",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "591dd81f6e918": {
                    "id": null,
                    "title": "Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "85718480c34b3f55d5916c9e07a78855": {
                    "id": null,
                    "title": "Antenas Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Antenas-Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "591dd81f6e918": {
                "id": null,
                "title": "Radiocomunicación",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "aea0c91a531b58af541e3ab80675b0d9",
            "title": "Equipo de Laboratorio",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Equipo-de-Laboratorio/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/aea0c91a531b58af541e3ab80675b0d9-CP.png",
            "parentId": "591dd81f6e918",
            "subCategories": [
              {
                "id": "19cb18c97b444c2635dc3dbbb028b8ce",
                "title": "Atenuadores",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Equipo-de-Laboratorio/Atenuadores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/19cb18c97b444c2635dc3dbbb028b8ce-CP.png",
                "parentId": "aea0c91a531b58af541e3ab80675b0d9",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "591dd81f6e918": {
                    "id": null,
                    "title": "Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "aea0c91a531b58af541e3ab80675b0d9": {
                    "id": null,
                    "title": "Equipo de Laboratorio",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Equipo-de-Laboratorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "a54c02b8e4d157b6ca7ab81056417d24",
                "title": "Wattmetros",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Equipo-de-Laboratorio/Wattmetros/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/a54c02b8e4d157b6ca7ab81056417d24-CP.png",
                "parentId": "aea0c91a531b58af541e3ab80675b0d9",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "591dd81f6e918": {
                    "id": null,
                    "title": "Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "aea0c91a531b58af541e3ab80675b0d9": {
                    "id": null,
                    "title": "Equipo de Laboratorio",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Equipo-de-Laboratorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "c47f05b7388403bc7a4d37b509ad0807",
                "title": "Elementos para Wattmetros",
                "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Equipo-de-Laboratorio/Elementos-para-Wattmetros/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c47f05b7388403bc7a4d37b509ad0807-CP.png",
                "parentId": "aea0c91a531b58af541e3ab80675b0d9",
                "subCategories": [],
                "path": {
                  "eea2f7869252c43aab816b4d6bdd2b65": {
                    "id": null,
                    "title": "Celulares y Telefonía",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                    "image": null,
                    "stock": 0
                  },
                  "591dd81f6e918": {
                    "id": null,
                    "title": "Radiocomunicación",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                    "image": null,
                    "stock": 0
                  },
                  "aea0c91a531b58af541e3ab80675b0d9": {
                    "id": null,
                    "title": "Equipo de Laboratorio",
                    "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/Equipo-de-Laboratorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "eea2f7869252c43aab816b4d6bdd2b65": {
                "id": null,
                "title": "Celulares y Telefonía",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
                "image": null,
                "stock": 0
              },
              "591dd81f6e918": {
                "id": null,
                "title": "Radiocomunicación",
                "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Radiocomunicacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "eea2f7869252c43aab816b4d6bdd2b65": {
            "id": null,
            "title": "Celulares y Telefonía",
            "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "6643ecfe66823",
        "title": "Estabilizadores para Celulares",
        "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Estabilizadores-para-Celulares/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soporteestabilizador.png",
        "parentId": "eea2f7869252c43aab816b4d6bdd2b65",
        "subCategories": [],
        "path": {
          "eea2f7869252c43aab816b4d6bdd2b65": {
            "id": null,
            "title": "Celulares y Telefonía",
            "url": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      }
    ],
    "path": [],
    "hasSubCategories": true
  },
  {
    "id": "def002bcdc2c4295471662c1d3bb7f16",
    "title": "Punto de Venta (POS)",
    "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/icon-pos.min.png",
    "parentId": "",
    "subCategories": [
      {
        "id": "6d39ddbeb85974ae6a751bac7fff93ff",
        "title": "Sistemas POS",
        "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Sistemas-POS/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pos(1).png",
        "parentId": "def002bcdc2c4295471662c1d3bb7f16",
        "subCategories": [],
        "path": {
          "def002bcdc2c4295471662c1d3bb7f16": {
            "id": null,
            "title": "Punto de Venta (POS)",
            "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "defb9f6591094daa22933bba2649bcdf",
        "title": "Monitores POS",
        "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Monitores-POS/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/monitorpos.png",
        "parentId": "def002bcdc2c4295471662c1d3bb7f16",
        "subCategories": [],
        "path": {
          "def002bcdc2c4295471662c1d3bb7f16": {
            "id": null,
            "title": "Punto de Venta (POS)",
            "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "514899e39d31e",
        "title": "Impresoras de Tickets",
        "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Impresoras-de-Tickets/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresora-tickets(1).png",
        "parentId": "def002bcdc2c4295471662c1d3bb7f16",
        "subCategories": [],
        "path": {
          "def002bcdc2c4295471662c1d3bb7f16": {
            "id": null,
            "title": "Punto de Venta (POS)",
            "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "5a3c47747a068",
        "title": "Impresoras de Etiquetas",
        "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Impresoras-de-Etiquetas/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresora-de-etiquetas-new.png",
        "parentId": "def002bcdc2c4295471662c1d3bb7f16",
        "subCategories": [],
        "path": {
          "def002bcdc2c4295471662c1d3bb7f16": {
            "id": null,
            "title": "Punto de Venta (POS)",
            "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "54aefe4d310c1",
        "title": "Impresoras Móviles",
        "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Impresoras-Moviles/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresora-movil.png",
        "parentId": "def002bcdc2c4295471662c1d3bb7f16",
        "subCategories": [],
        "path": {
          "def002bcdc2c4295471662c1d3bb7f16": {
            "id": null,
            "title": "Punto de Venta (POS)",
            "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "def464990639c2fec36276b823e2fb85",
        "title": "Cajones de dinero",
        "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Cajones-de-dinero/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cajones.png",
        "parentId": "def002bcdc2c4295471662c1d3bb7f16",
        "subCategories": [],
        "path": {
          "def002bcdc2c4295471662c1d3bb7f16": {
            "id": null,
            "title": "Punto de Venta (POS)",
            "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/?force_sid=v1jh1gmo1gfrq14ch1p8aeh1na",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "51a6236fecd2c",
        "title": "Lectores y Terminales",
        "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lectores-barras(1).png",
        "parentId": "def002bcdc2c4295471662c1d3bb7f16",
        "subCategories": [
          {
            "id": "defdf32f84c530bc1ef77138fb551393",
            "title": "Lectores de Código de Barras",
            "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/Lectores-de-Codigo-de-Barras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lector-de-codigo-de-barras.png",
            "parentId": "51a6236fecd2c",
            "subCategories": [],
            "path": {
              "def002bcdc2c4295471662c1d3bb7f16": {
                "id": null,
                "title": "Punto de Venta (POS)",
                "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
                "image": null,
                "stock": 0
              },
              "51a6236fecd2c": {
                "id": null,
                "title": "Lectores y Terminales",
                "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fe3c26e933f2",
            "title": "Lectores de Banda Magnética",
            "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/Lectores-de-Banda-Magnetica/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lectores-banda-magnetica.png",
            "parentId": "51a6236fecd2c",
            "subCategories": [],
            "path": {
              "def002bcdc2c4295471662c1d3bb7f16": {
                "id": null,
                "title": "Punto de Venta (POS)",
                "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
                "image": null,
                "stock": 0
              },
              "51a6236fecd2c": {
                "id": null,
                "title": "Lectores y Terminales",
                "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5238ad6a2971a",
            "title": "Lectores de Mostrador",
            "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/Lectores-de-Mostrador/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lectores-mostrador.png",
            "parentId": "51a6236fecd2c",
            "subCategories": [],
            "path": {
              "def002bcdc2c4295471662c1d3bb7f16": {
                "id": null,
                "title": "Punto de Venta (POS)",
                "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
                "image": null,
                "stock": 0
              },
              "51a6236fecd2c": {
                "id": null,
                "title": "Lectores y Terminales",
                "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51acd4a8321c0",
            "title": "Terminales Portátiles",
            "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/Terminales-Portatiles/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/terminal-portatil.png",
            "parentId": "51a6236fecd2c",
            "subCategories": [],
            "path": {
              "def002bcdc2c4295471662c1d3bb7f16": {
                "id": null,
                "title": "Punto de Venta (POS)",
                "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
                "image": null,
                "stock": 0
              },
              "51a6236fecd2c": {
                "id": null,
                "title": "Lectores y Terminales",
                "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5238adb8b3a1c",
            "title": "Accesorios",
            "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/Accesorios/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios.jpg",
            "parentId": "51a6236fecd2c",
            "subCategories": [
              {
                "id": "d27242970cd742a8a2510c1c6e188c53",
                "title": "Fuentes de Alimentación",
                "link": "https://www.cyberpuerta.mx/Energia/Energia-Punto-de-Venta-POS/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fuentes-alimentacion-pos.png",
                "parentId": "5238adb8b3a1c",
                "subCategories": [],
                "path": {
                  "def002bcdc2c4295471662c1d3bb7f16": {
                    "id": null,
                    "title": "Punto de Venta (POS)",
                    "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
                    "image": null,
                    "stock": 0
                  },
                  "51a6236fecd2c": {
                    "id": null,
                    "title": "Lectores y Terminales",
                    "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/",
                    "image": null,
                    "stock": 0
                  },
                  "5238adb8b3a1c": {
                    "id": null,
                    "title": "Accesorios",
                    "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/Accesorios/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51a623b24bc84",
                "title": "Bases y Montajes",
                "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/Accesorios/Bases-y-Montajes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/bases.png",
                "parentId": "5238adb8b3a1c",
                "subCategories": [],
                "path": {
                  "def002bcdc2c4295471662c1d3bb7f16": {
                    "id": null,
                    "title": "Punto de Venta (POS)",
                    "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
                    "image": null,
                    "stock": 0
                  },
                  "51a6236fecd2c": {
                    "id": null,
                    "title": "Lectores y Terminales",
                    "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/",
                    "image": null,
                    "stock": 0
                  },
                  "5238adb8b3a1c": {
                    "id": null,
                    "title": "Accesorios",
                    "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/Accesorios/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51a623c4d19a3",
                "title": "Cables",
                "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/Accesorios/Cables/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables-codigos-barras.png",
                "parentId": "5238adb8b3a1c",
                "subCategories": [],
                "path": {
                  "def002bcdc2c4295471662c1d3bb7f16": {
                    "id": null,
                    "title": "Punto de Venta (POS)",
                    "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
                    "image": null,
                    "stock": 0
                  },
                  "51a6236fecd2c": {
                    "id": null,
                    "title": "Lectores y Terminales",
                    "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/",
                    "image": null,
                    "stock": 0
                  },
                  "5238adb8b3a1c": {
                    "id": null,
                    "title": "Accesorios",
                    "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/Accesorios/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5bb256d0242b2",
                "title": "Fundas y Protectores POS",
                "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/Accesorios/Fundas-y-Protectores-POS/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/fundapos.png",
                "parentId": "5238adb8b3a1c",
                "subCategories": [],
                "path": {
                  "def002bcdc2c4295471662c1d3bb7f16": {
                    "id": null,
                    "title": "Punto de Venta (POS)",
                    "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
                    "image": null,
                    "stock": 0
                  },
                  "51a6236fecd2c": {
                    "id": null,
                    "title": "Lectores y Terminales",
                    "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/",
                    "image": null,
                    "stock": 0
                  },
                  "5238adb8b3a1c": {
                    "id": null,
                    "title": "Accesorios",
                    "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/Accesorios/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "def002bcdc2c4295471662c1d3bb7f16": {
                "id": null,
                "title": "Punto de Venta (POS)",
                "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
                "image": null,
                "stock": 0
              },
              "51a6236fecd2c": {
                "id": null,
                "title": "Lectores y Terminales",
                "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Lectores-y-Terminales/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "def002bcdc2c4295471662c1d3bb7f16": {
            "id": null,
            "title": "Punto de Venta (POS)",
            "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "51f0101bbbc4f",
        "title": "Teclados POS",
        "link": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/Teclados-POS/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/teclado-pos.png",
        "parentId": "def002bcdc2c4295471662c1d3bb7f16",
        "subCategories": [],
        "path": {
          "def002bcdc2c4295471662c1d3bb7f16": {
            "id": null,
            "title": "Punto de Venta (POS)",
            "url": "https://www.cyberpuerta.mx/Punto-de-Venta-POS/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      }
    ],
    "path": [],
    "hasSubCategories": true
  },
  {
    "id": "51cb2e762583a",
    "title": "Seguridad y Vigilancia",
    "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/icon-seguridad-vigilancia.png",
    "parentId": "",
    "subCategories": [
      {
        "id": "5554b46b3fff9",
        "title": "Cámaras y Sistemas de Vigilancia",
        "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/kits-vigilancia(1).png",
        "parentId": "51cb2e762583a",
        "subCategories": [
          {
            "id": "51bb3d7213db4",
            "title": "Kits de Vigilancia",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Kits-de-Vigilancia/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/kits-vigilancia.png",
            "parentId": "5554b46b3fff9",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b46b3fff9": {
                "id": null,
                "title": "Cámaras y Sistemas de Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51f045bf736b8",
            "title": "Cámaras de Seguridad CCTV",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Camaras-de-Seguridad-CCTV/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cctv-domo(1).png",
            "parentId": "5554b46b3fff9",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b46b3fff9": {
                "id": null,
                "title": "Cámaras y Sistemas de Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51f045ede139b",
            "title": "Cámaras de Seguridad IP",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Camaras-de-Seguridad-IP/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ip-exteriores(1).png",
            "parentId": "5554b46b3fff9",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/?force_sid=0m1lvsecdsp31ijgsilfm0314d",
                "image": null,
                "stock": 0
              },
              "5554b46b3fff9": {
                "id": null,
                "title": "Cámaras y Sistemas de Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/?force_sid=0m1lvsecdsp31ijgsilfm0314d",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "54760ade30a69",
            "title": "Seguridad para Bebés",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Seguridad-para-Bebes/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/seguridad-bebes.png",
            "parentId": "5554b46b3fff9",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b46b3fff9": {
                "id": null,
                "title": "Cámaras y Sistemas de Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5a4c0ef3a96e4",
            "title": "Cámaras Ocultas",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Camaras-Ocultas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/camaras-ocultas.png",
            "parentId": "5554b46b3fff9",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b46b3fff9": {
                "id": null,
                "title": "Cámaras y Sistemas de Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "62d08abdd5666",
            "title": "Cámaras Termicas",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Camaras-Termicas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/camaras-termicas.png",
            "parentId": "5554b46b3fff9",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b46b3fff9": {
                "id": null,
                "title": "Cámaras y Sistemas de Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51ca1175806d8",
            "title": "Grabadoras de Video Digital (DVR)",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Grabadoras-de-Video-Digital-DVR/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/grabadoras-video-digital.png",
            "parentId": "5554b46b3fff9",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b46b3fff9": {
                "id": null,
                "title": "Cámaras y Sistemas de Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51f044f376e3e",
            "title": "Grabadoras de Video en Red (NVR)",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Grabadoras-de-Video-en-Red-NVR/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/nvr.png",
            "parentId": "5554b46b3fff9",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b46b3fff9": {
                "id": null,
                "title": "Cámaras y Sistemas de Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5b6b5f9f61649",
            "title": "Accesorios para Videovigilancia",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-videovigilancia-2018.png",
            "parentId": "5554b46b3fff9",
            "subCategories": [
              {
                "id": "6b3c9877fa83acf5e40120e1c23e4e31",
                "title": "Discos Duros para Videovigilancia",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Discos-Duros-para-Videovigilancia/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/discos-duros-vigilancia.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51f6c4f083782",
                "title": "Monitores CCTV",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/Monitores-CCTV/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/monitores-cctv.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51f6c4e1e9d07",
                "title": "Carcasas y Gabinetes",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/Carcasas-y-Gabinetes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/carcasas.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51f6c4cf0c619",
                "title": "Brazos y Soportes",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/Brazos-y-Soportes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/mounts-brackets.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5b6b66104fc09",
                "title": "Cajas de Conexiones",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/Cajas-de-Conexiones/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cajadeconexiones.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51f6c541174bc",
                "title": "Objetivos Varifocales",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/Objetivos-Varifocales/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/objetivos.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5547aabf23014",
                "title": "Burbujas Humeadas",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/Burbujas-Humeadas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/burbujas-humeadas.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "17e07c2020c8396960f2aa5e95005b45",
                "title": "Tarjetas para Captura de Vídeo",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Componentes/Tarjetas-de-Expansion/Tarjetas-para-Captura-de-Video/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjetasparacapturadevideo.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5554b87b4a4af",
                "title": "Servidores de Video IP",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/Servidores-de-Video-IP/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/servidores-video-ip.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "560ea370aa33c",
                "title": "Micrófonos Profesionales",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/Microfonos-Profesionales/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/microfonos-seguridad.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "59c3fd111d092",
                "title": "Controles para Videovigilancia",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/Controles-para-Videovigilancia/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/controles-videovigilancia.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5b77470b7ac80",
                "title": "Iluminadores IR",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/Iluminadores-IR/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/iluminadores-ir.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "658c53270b407",
                "title": "Accesorios para DVR Móviles",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/Accesorios-para-DVR-Moviles/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesoriosdvrmoviles.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51eda7d173ee7",
                "title": "Transceptores y Receptores",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/Transceptores-y-Receptores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tranceptores.png",
                "parentId": "5b6b5f9f61649",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6b5f9f61649": {
                    "id": null,
                    "title": "Accesorios para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Accesorios-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b46b3fff9": {
                "id": null,
                "title": "Cámaras y Sistemas de Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5b90534371229",
            "title": "Cables para Videovigilancia",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Cables-para-Videovigilancia/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cablesparavigilancia.png",
            "parentId": "5554b46b3fff9",
            "subCategories": [
              {
                "id": "3c3a581e946b357201e6d07d52fde7a8",
                "title": "Cables Coaxiales",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Cables-de-Red/Cables-Coaxiales/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/coaxial(1).png",
                "parentId": "5b90534371229",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b90534371229": {
                    "id": null,
                    "title": "Cables para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Cables-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5224e210536c6",
                "title": "Conectores Coaxiales",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Cables-para-Videovigilancia/Conectores-Coaxiales/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/conectores-coaxiales.png",
                "parentId": "5b90534371229",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b90534371229": {
                    "id": null,
                    "title": "Cables para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Cables-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "51eda6f64cc4f",
                "title": "Cables y Adaptadores",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Cables-para-Videovigilancia/Cables-y-Adaptadores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables-vigilancia.png",
                "parentId": "5b90534371229",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b46b3fff9": {
                    "id": null,
                    "title": "Cámaras y Sistemas de Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5b90534371229": {
                    "id": null,
                    "title": "Cables para Videovigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Cables-para-Videovigilancia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b46b3fff9": {
                "id": null,
                "title": "Cámaras y Sistemas de Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "b5636cbdbd0e42bd33d61713017f9000",
            "title": "Energía para Cámaras y Grabadoras",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Seguridad-Vigilancia/Energia-para-Camaras-y-Grabadoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-camaras-grabadoras.png",
            "parentId": "5554b46b3fff9",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b46b3fff9": {
                "id": null,
                "title": "Cámaras y Sistemas de Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "51f6c550b0771",
            "title": "Software para Seguridad y Vigilancia",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/Software-para-Seguridad-y-Vigilancia/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/software.png",
            "parentId": "5554b46b3fff9",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b46b3fff9": {
                "id": null,
                "title": "Cámaras y Sistemas de Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "78adb1612cc0cd68cf7f8f1243f90831",
            "title": "Videowalls",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/TV-y-Pantallas/Videowall/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/videowall.png",
            "parentId": "5554b46b3fff9",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b46b3fff9": {
                "id": null,
                "title": "Cámaras y Sistemas de Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Camaras-y-Sistemas-de-Vigilancia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "51cb2e762583a": {
            "id": null,
            "title": "Seguridad y Vigilancia",
            "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5554b4fea9668",
        "title": "Sistemas de Alarma",
        "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sensor-movimiento(1).png",
        "parentId": "51cb2e762583a",
        "subCategories": [
          {
            "id": "55b8e98c61cfc",
            "title": "Kits de Alarma",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Kits-de-Alarma/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/kit-alarma.png",
            "parentId": "5554b4fea9668",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4fea9668": {
                "id": null,
                "title": "Sistemas de Alarma",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5554cc81ee46a",
            "title": "Paneles de Control",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Paneles-de-Control/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/panel-control(1).png",
            "parentId": "5554b4fea9668",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4fea9668": {
                "id": null,
                "title": "Sistemas de Alarma",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5b049646a1701",
            "title": "Sistemas de Alarma Contra Incendios",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Sistemas-de-Alarma-Contra-Incendios/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/alarma-contra-incendios-panel(1).png",
            "parentId": "5554b4fea9668",
            "subCategories": [
              {
                "id": "5b7b35316a8fe",
                "title": "Paneles de Control para Alarmas de Incendio",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Sistemas-de-Alarma-Contra-Incendios/Paneles-de-Control-para-Alarmas-de-Incendio/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/alarma-contra-incendios-panel.png",
                "parentId": "5b049646a1701",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b049646a1701": {
                    "id": null,
                    "title": "Sistemas de Alarma Contra Incendios",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Sistemas-de-Alarma-Contra-Incendios/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5bc75d237fabc",
                "title": "Estaciones Manuales de Emergencia",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Sistemas-de-Alarma-Contra-Incendios/Estaciones-Manuales-de-Emergencia/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/eme.png",
                "parentId": "5b049646a1701",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b049646a1701": {
                    "id": null,
                    "title": "Sistemas de Alarma Contra Incendios",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Sistemas-de-Alarma-Contra-Incendios/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5b807f6b7e7bb",
                "title": "Montajes y Cubiertas",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Sistemas-de-Alarma-Contra-Incendios/Montajes-y-Cubiertas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/montajeycubiertas.png",
                "parentId": "5b049646a1701",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b049646a1701": {
                    "id": null,
                    "title": "Sistemas de Alarma Contra Incendios",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Sistemas-de-Alarma-Contra-Incendios/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4fea9668": {
                "id": null,
                "title": "Sistemas de Alarma",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5b6cbefa0dc7b",
            "title": "Detectores y Sensores",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/detectores-sensores.png",
            "parentId": "5554b4fea9668",
            "subCategories": [
              {
                "id": "5554cb3cd8626",
                "title": "Sensores de Movimiento",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/Sensores-de-Movimiento/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sensor-movimiento.png",
                "parentId": "5b6cbefa0dc7b",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbefa0dc7b": {
                    "id": null,
                    "title": "Detectores y Sensores",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5554cb220cac1",
                "title": "Detectores de Rotura de Vidrios",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/Detectores-de-Rotura-de-Vidrios/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/detector-rotura-vidrio.png",
                "parentId": "5b6cbefa0dc7b",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbefa0dc7b": {
                    "id": null,
                    "title": "Detectores y Sensores",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5b049b113490e",
                "title": "Detectores Fotoeléctricos",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/Detectores-Fotoelectricos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/detectores-fotoelectricos.png",
                "parentId": "5b6cbefa0dc7b",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbefa0dc7b": {
                    "id": null,
                    "title": "Detectores y Sensores",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5554caa796e54",
                "title": "Detectores de Gas y Humo",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/Detectores-de-Gas-y-Humo/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/alarma-humo.png",
                "parentId": "5b6cbefa0dc7b",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbefa0dc7b": {
                    "id": null,
                    "title": "Detectores y Sensores",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5b64daa373ed9",
                "title": "Detectores de Temperatura",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/Detectores-de-Temperatura/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/detectores-temperatura.png",
                "parentId": "5b6cbefa0dc7b",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbefa0dc7b": {
                    "id": null,
                    "title": "Detectores y Sensores",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5b64dc79a5b2b",
                "title": "Sensores de Humedad",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/Sensores-de-Humedad/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sensores-humedad.png",
                "parentId": "5b6cbefa0dc7b",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbefa0dc7b": {
                    "id": null,
                    "title": "Detectores y Sensores",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "6176df4de749a",
                "title": "Detectores de Metal",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/Detectores-de-Metal/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/detectores-de-metal.png",
                "parentId": "5b6cbefa0dc7b",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbefa0dc7b": {
                    "id": null,
                    "title": "Detectores y Sensores",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5bc775b2e800e",
                "title": "Soportes y Montajes para Detectores",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/Soportes-y-Montajes-para-Detectores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cp-crow-eds2000b-1.png",
                "parentId": "5b6cbefa0dc7b",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbefa0dc7b": {
                    "id": null,
                    "title": "Detectores y Sensores",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5554cbb67e7ac",
                "title": "Unidades Magnéticas",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/Unidades-Magneticas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sensores-magneticos.png",
                "parentId": "5b6cbefa0dc7b",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbefa0dc7b": {
                    "id": null,
                    "title": "Detectores y Sensores",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Detectores-y-Sensores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4fea9668": {
                "id": null,
                "title": "Sistemas de Alarma",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5b6cbfccdb7f3",
            "title": "Accesorios para Alarmas",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-alarmas.png",
            "parentId": "5554b4fea9668",
            "subCategories": [
              {
                "id": "5554cd4224d54",
                "title": "Sirenas y Bocinas",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/Sirenas-y-Bocinas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sirenas-balizas.png",
                "parentId": "5b6cbfccdb7f3",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbfccdb7f3": {
                    "id": null,
                    "title": "Accesorios para Alarmas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5b9055f3da941",
                "title": "Estrobos y Luces Giratorias",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/Estrobos-y-Luces-Giratorias/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/luzestroboscopica.png",
                "parentId": "5b6cbfccdb7f3",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbfccdb7f3": {
                    "id": null,
                    "title": "Accesorios para Alarmas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "e478db3b4615afa0fb59f1fdbfd6386a",
                "title": "Luces Vehiculares",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/Luces-Vehiculares/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/e478db3b4615afa0fb59f1fdbfd6386a-CP.png",
                "parentId": "5b6cbfccdb7f3",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbfccdb7f3": {
                    "id": null,
                    "title": "Accesorios para Alarmas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "a08f1d70c5ea87b8f6940d6c4ad8bc07",
                "title": "Luces Perimetrales",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/Luces-Perimetrales/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/a08f1d70c5ea87b8f6940d6c4ad8bc07-CP.png",
                "parentId": "5b6cbfccdb7f3",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbfccdb7f3": {
                    "id": null,
                    "title": "Accesorios para Alarmas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5554cc208a162",
                "title": "Teclados y Controles Remoto",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/Teclados-y-Controles-Remoto/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/teclados-remotos.png",
                "parentId": "5b6cbfccdb7f3",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbfccdb7f3": {
                    "id": null,
                    "title": "Accesorios para Alarmas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5554cc00e0970",
                "title": "Botones de Pánico",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/Botones-de-Panico/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/boton-panico.png",
                "parentId": "5b6cbfccdb7f3",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbfccdb7f3": {
                    "id": null,
                    "title": "Accesorios para Alarmas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "12f03cb734a794d327fd44a7726a4b36",
                "title": "Transmisores por Radio",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/Transmisores-por-Radio/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/12f03cb734a794d327fd44a7726a4b36-CP.png",
                "parentId": "5b6cbfccdb7f3",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbfccdb7f3": {
                    "id": null,
                    "title": "Accesorios para Alarmas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5a185f4eefa7c",
                "title": "Tampers",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/Tampers/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tampers.png",
                "parentId": "5b6cbfccdb7f3",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbfccdb7f3": {
                    "id": null,
                    "title": "Accesorios para Alarmas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5581bb321fde8",
                "title": "Tarjetas y Módulos",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/Tarjetas-y-Modulos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjetas-modulos.png",
                "parentId": "5b6cbfccdb7f3",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbfccdb7f3": {
                    "id": null,
                    "title": "Accesorios para Alarmas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "e77ab0e20dd2cacc0ff5b7cce98fd6f5",
                "title": "Accesorios para Luces Vehiculares",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/Accesorios-para-Luces-Vehiculares/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/e77ab0e20dd2cacc0ff5b7cce98fd6f5-CP.png",
                "parentId": "5b6cbfccdb7f3",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6cbfccdb7f3": {
                    "id": null,
                    "title": "Accesorios para Alarmas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Accesorios-para-Alarmas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4fea9668": {
                "id": null,
                "title": "Sistemas de Alarma",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "9d1f0e8e5990fff8db40c746827c8c55",
            "title": "Cercas Eléctricas",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Cercas-Electricas/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/9d1f0e8e5990fff8db40c746827c8c55-CP.png",
            "parentId": "5554b4fea9668",
            "subCategories": [
              {
                "id": "50990be52c7d4e98d0e40c897ce02842",
                "title": "Aisladores",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Cercas-Electricas/Aisladores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/50990be52c7d4e98d0e40c897ce02842-CP.png",
                "parentId": "9d1f0e8e5990fff8db40c746827c8c55",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "9d1f0e8e5990fff8db40c746827c8c55": {
                    "id": null,
                    "title": "Cercas Eléctricas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Cercas-Electricas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "b3852158727fe1e7df9399066ba29be5",
                "title": "Energizadores",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Cercas-Electricas/Energizadores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/b3852158727fe1e7df9399066ba29be5-CP.png",
                "parentId": "9d1f0e8e5990fff8db40c746827c8c55",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "9d1f0e8e5990fff8db40c746827c8c55": {
                    "id": null,
                    "title": "Cercas Eléctricas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Cercas-Electricas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "3617647b612d3327c45c31e1de023830",
                "title": "Postes",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Cercas-Electricas/Postes/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/3617647b612d3327c45c31e1de023830-CP.png",
                "parentId": "9d1f0e8e5990fff8db40c746827c8c55",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "9d1f0e8e5990fff8db40c746827c8c55": {
                    "id": null,
                    "title": "Cercas Eléctricas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Cercas-Electricas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "52af87a363e372ceb60898944b782711",
                "title": "Accesorios para Cercas Eléctricas",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Cercas-Electricas/Accesorios-para-Cercas-Electricas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/52af87a363e372ceb60898944b782711-CP.png",
                "parentId": "9d1f0e8e5990fff8db40c746827c8c55",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4fea9668": {
                    "id": null,
                    "title": "Sistemas de Alarma",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                    "image": null,
                    "stock": 0
                  },
                  "9d1f0e8e5990fff8db40c746827c8c55": {
                    "id": null,
                    "title": "Cercas Eléctricas",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/Cercas-Electricas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4fea9668": {
                "id": null,
                "title": "Sistemas de Alarma",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "1ce626c43e0775ab5f04e754bc04a597",
            "title": "Energía para Sistemas de Alarmas",
            "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Seguridad-Vigilancia/Energia-para-Sistemas-de-Alarmas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-sistemas-alarma.png",
            "parentId": "5554b4fea9668",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4fea9668": {
                "id": null,
                "title": "Sistemas de Alarma",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Sistemas-de-Alarma/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "51cb2e762583a": {
            "id": null,
            "title": "Seguridad y Vigilancia",
            "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5554b4acd8db7",
        "title": "Control de Acceso",
        "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/control-acceso.png",
        "parentId": "51cb2e762583a",
        "subCategories": [
          {
            "id": "1ab3545a913bc1d1847f3e19991bf593",
            "title": "Videoporteros e Interfones",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Videoporteros-e-Interfones/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/1ab3545a913bc1d1847f3e19991bf593-CP.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [
              {
                "id": "54c132939e8f1",
                "title": "Videoporteros",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Videoporteros-e-Interfones/Videoporteros/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/control-acceso(1).png",
                "parentId": "1ab3545a913bc1d1847f3e19991bf593",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "1ab3545a913bc1d1847f3e19991bf593": {
                    "id": null,
                    "title": "Videoporteros e Interfones",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Videoporteros-e-Interfones/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "e30ea13c992b34d1704a630a4f6c3b39",
                "title": "Interfones",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Videoporteros-e-Interfones/Interfones/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/e30ea13c992b34d1704a630a4f6c3b39-CP.png",
                "parentId": "1ab3545a913bc1d1847f3e19991bf593",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "1ab3545a913bc1d1847f3e19991bf593": {
                    "id": null,
                    "title": "Videoporteros e Interfones",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Videoporteros-e-Interfones/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5b2a238ddad4281c5c6cb7cbb1b944df",
                "title": "Frentes de Calle",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Videoporteros-e-Interfones/Frentes-de-Calle/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/5b2a238ddad4281c5c6cb7cbb1b944df-CP.png",
                "parentId": "1ab3545a913bc1d1847f3e19991bf593",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "1ab3545a913bc1d1847f3e19991bf593": {
                    "id": null,
                    "title": "Videoporteros e Interfones",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Videoporteros-e-Interfones/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5554bcc5632a7",
            "title": "Control de Acceso con Tarjeta",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Control-de-Acceso-con-Tarjeta/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/control-acceso-tarjeta.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5554c6553a137",
            "title": "Control de Acceso y Asistencia Biométrico",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Control-de-Acceso-y-Asistencia-Biometrico/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/control-acceso-biometrico.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5d7c26ee7bc95",
            "title": "Relojes Checadores",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Relojes-Checadores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/relojes-checadores.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5581a7ebb7e95",
            "title": "Lectores de Huella Digital",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Lectores-de-Huella-Digital/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lectores-huella.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5b6e083b337a9",
            "title": "Paneles Control de Acceso",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Paneles-Control-de-Acceso/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/panelesdecontroldeacceso3.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [
              {
                "id": "5b6e09d07fcd6",
                "title": "Paneles Controladores",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Paneles-Control-de-Acceso/Paneles-Controladores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/panelescontroladores2.png",
                "parentId": "5b6e083b337a9",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6e083b337a9": {
                    "id": null,
                    "title": "Paneles Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Paneles-Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5b91b6c5e5721",
                "title": "Accesorios para Paneles Controladores",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Paneles-Control-de-Acceso/Accesorios-para-Paneles-Controladores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesoriosparapanelescontroladores.png",
                "parentId": "5b6e083b337a9",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "5b6e083b337a9": {
                    "id": null,
                    "title": "Paneles Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Paneles-Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5554bca937662",
            "title": "Cerraduras y Cerrojos para Control de Acceso",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Cerraduras-y-Cerrojos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cerraduras-cerrojos.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5a0210e0638b4",
            "title": "Tarjetas y Tags de Acceso",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Tarjetas-y-Tags-de-Acceso/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarjetas-acceso.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5554d242b6179",
            "title": "Kits de Instalación",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Kits-de-Instalacion/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/kit-instalacion.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5a26df52934c3",
            "title": "Botones de Salida",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Botones-de-Salida/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/boton-salida(1).png",
            "parentId": "5554b4acd8db7",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "62d5f2511a0ca",
            "title": "Accesorios para Videoporteros",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Accesorios-para-Videoporteros/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-para-video-reporteros.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "d8b58b17392a71d8bf24dd9d55448eec",
            "title": "Acceso Vehicular",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/d8b58b17392a71d8bf24dd9d55448eec-CP.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [
              {
                "id": "5554c66c94b59",
                "title": "Control de Acceso Vehicular",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/Control-de-Acceso-Vehicular/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/control-acceso-vehicular.png",
                "parentId": "d8b58b17392a71d8bf24dd9d55448eec",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "d8b58b17392a71d8bf24dd9d55448eec": {
                    "id": null,
                    "title": "Acceso Vehicular",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "3a87cd8255f6407c9197016ab43b211b",
                "title": "Barreras Vehiculares",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/Barreras-Vehiculares/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/3a87cd8255f6407c9197016ab43b211b-CP.png",
                "parentId": "d8b58b17392a71d8bf24dd9d55448eec",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "d8b58b17392a71d8bf24dd9d55448eec": {
                    "id": null,
                    "title": "Acceso Vehicular",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "6dd0a938f824fb3f9f03c1883566fe26",
                "title": "Accesorios para Barreras Vehiculares",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/Accesorios-para-Barreras-Vehiculares/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6dd0a938f824fb3f9f03c1883566fe26-CP.png",
                "parentId": "d8b58b17392a71d8bf24dd9d55448eec",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "d8b58b17392a71d8bf24dd9d55448eec": {
                    "id": null,
                    "title": "Acceso Vehicular",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "8cc0379e73a5aa88ee88af3bc66766ea",
                "title": "Puertas Abatibles y Corredizas",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/Puertas-Abatibles-y-Corredizas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/8cc0379e73a5aa88ee88af3bc66766ea-CP.png",
                "parentId": "d8b58b17392a71d8bf24dd9d55448eec",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "d8b58b17392a71d8bf24dd9d55448eec": {
                    "id": null,
                    "title": "Acceso Vehicular",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "ae0daddd0a6f3b2edc84bf6bf2cd56df",
                "title": "Picos Poncha Llantas",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/Picos-Poncha-Llantas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/ae0daddd0a6f3b2edc84bf6bf2cd56df-CP.png",
                "parentId": "d8b58b17392a71d8bf24dd9d55448eec",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "d8b58b17392a71d8bf24dd9d55448eec": {
                    "id": null,
                    "title": "Acceso Vehicular",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "a8e25c69f626e07be661d27a33e79227",
                "title": "Topes y Reductores de Velocidad",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/Topes-y-Reductores-de-Velocidad/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/a8e25c69f626e07be661d27a33e79227-CP.png",
                "parentId": "d8b58b17392a71d8bf24dd9d55448eec",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "d8b58b17392a71d8bf24dd9d55448eec": {
                    "id": null,
                    "title": "Acceso Vehicular",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "45a468a482392f9a7e0fdfd99b81c079",
                "title": "Semáforos y Señalización",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/Semaforos-y-Senalizacion/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/45a468a482392f9a7e0fdfd99b81c079-CP.png",
                "parentId": "d8b58b17392a71d8bf24dd9d55448eec",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "d8b58b17392a71d8bf24dd9d55448eec": {
                    "id": null,
                    "title": "Acceso Vehicular",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Acceso-Vehicular/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "4fae9483701a782d18520f4566cfbc7f",
            "title": "Puertas de Cortesía y Torniquetes",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Puertas-de-Cortesia-y-Torniquetes/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/4fae9483701a782d18520f4566cfbc7f-CP.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "7061318a4876302a84a033850e7fcba5",
            "title": "Sistemas de Emergencia",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Sistemas-de-Emergencia/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/7061318a4876302a84a033850e7fcba5-CP.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [
              {
                "id": "0b758734f3c296fcd8ea1abc6e21dae4",
                "title": "Accesorios para Puertas de Emergencia",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Sistemas-de-Emergencia/Accesorios-para-Puertas-de-Emergencia/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/0b758734f3c296fcd8ea1abc6e21dae4-CP.png",
                "parentId": "7061318a4876302a84a033850e7fcba5",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "7061318a4876302a84a033850e7fcba5": {
                    "id": null,
                    "title": "Sistemas de Emergencia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Sistemas-de-Emergencia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "6ed6d2def7504e619d8cc71fec620bbc",
                "title": "Señales de Emergencia",
                "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Sistemas-de-Emergencia/Senales-de-Emergencia/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6ed6d2def7504e619d8cc71fec620bbc-CP.png",
                "parentId": "7061318a4876302a84a033850e7fcba5",
                "subCategories": [],
                "path": {
                  "51cb2e762583a": {
                    "id": null,
                    "title": "Seguridad y Vigilancia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                    "image": null,
                    "stock": 0
                  },
                  "5554b4acd8db7": {
                    "id": null,
                    "title": "Control de Acceso",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                    "image": null,
                    "stock": 0
                  },
                  "7061318a4876302a84a033850e7fcba5": {
                    "id": null,
                    "title": "Sistemas de Emergencia",
                    "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Sistemas-de-Emergencia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "2868defaebd57f01cf46a5af1efa6ada",
            "title": "Tarjetas de Relevador",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Tarjetas-de-Relevador/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/2868defaebd57f01cf46a5af1efa6ada-CP.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "2f24be8e9bca9dc7b9720c3e8eecc81b",
            "title": "Protección de Mercancia",
            "link": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/Proteccion-de-Mercancia/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/2f24be8e9bca9dc7b9720c3e8eecc81b-CP.png",
            "parentId": "5554b4acd8db7",
            "subCategories": [],
            "path": {
              "51cb2e762583a": {
                "id": null,
                "title": "Seguridad y Vigilancia",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
                "image": null,
                "stock": 0
              },
              "5554b4acd8db7": {
                "id": null,
                "title": "Control de Acceso",
                "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/Control-de-Acceso/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "51cb2e762583a": {
            "id": null,
            "title": "Seguridad y Vigilancia",
            "url": "https://www.cyberpuerta.mx/Seguridad-y-Vigilancia/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      }
    ],
    "path": [],
    "hasSubCategories": true
  },
  {
    "id": "4fcd1e7337747",
    "title": "Hogar",
    "link": "https://www.cyberpuerta.mx/Hogar/",
    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/icon-hogar.min.png",
    "parentId": "",
    "subCategories": [
      {
        "id": "58e692639f8b4",
        "title": "Smart Home",
        "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/smart-home(1).png",
        "parentId": "4fcd1e7337747",
        "subCategories": [
          {
            "id": "5b3a62c76f409",
            "title": "Smart WiFi",
            "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Smart-WiFi/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/smart-wifi.png",
            "parentId": "58e692639f8b4",
            "subCategories": [
              {
                "id": "5b3c04d3400e9",
                "title": "Wi-Fi en Malla",
                "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Smart-WiFi/Wi-Fi-en-Malla/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/wifi-en-malla.png",
                "parentId": "5b3a62c76f409",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "58e692639f8b4": {
                    "id": null,
                    "title": "Smart Home",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                    "image": null,
                    "stock": 0
                  },
                  "5b3a62c76f409": {
                    "id": null,
                    "title": "Smart WiFi",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Smart-WiFi/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "58e692639f8b4": {
                "id": null,
                "title": "Smart Home",
                "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5b3fa3d6b5c46",
            "title": "Audio y Vídeo Inteligente",
            "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Audio-Video-Inteligente/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/extensor-audio.png",
            "parentId": "58e692639f8b4",
            "subCategories": [
              {
                "id": "5b4542643ffad",
                "title": "Extensores de Audio WiFi",
                "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Audio-Video-Inteligente/Extensores-de-Audio-WiFi/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/extensor-audio(1).png",
                "parentId": "5b3fa3d6b5c46",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "58e692639f8b4": {
                    "id": null,
                    "title": "Smart Home",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                    "image": null,
                    "stock": 0
                  },
                  "5b3fa3d6b5c46": {
                    "id": null,
                    "title": "Audio y Vídeo Inteligente",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Audio-Video-Inteligente/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "58e692639f8b4": {
                "id": null,
                "title": "Smart Home",
                "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5b3d4531b1345",
            "title": "Seguridad Inteligente",
            "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Seguridad-Inteligente/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/seguridad-inteligente.png",
            "parentId": "58e692639f8b4",
            "subCategories": [
              {
                "id": "ae16d6ab8285cba9a68f39c83774b08b",
                "title": "Cámaras Inteligentes",
                "link": "https://www.cyberpuerta.mx/Camaras-Inteligentes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/camara-inteligente.png",
                "parentId": "5b3d4531b1345",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "58e692639f8b4": {
                    "id": null,
                    "title": "Smart Home",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                    "image": null,
                    "stock": 0
                  },
                  "5b3d4531b1345": {
                    "id": null,
                    "title": "Seguridad Inteligente",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Seguridad-Inteligente/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "7f6dbb6e21ac0bc9764bc523a9406a1c",
                "title": "Cerraduras Inteligentes",
                "link": "https://www.cyberpuerta.mx/Cerraduras-Inteligentes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cerradura-inteligente.png",
                "parentId": "5b3d4531b1345",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "58e692639f8b4": {
                    "id": null,
                    "title": "Smart Home",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                    "image": null,
                    "stock": 0
                  },
                  "5b3d4531b1345": {
                    "id": null,
                    "title": "Seguridad Inteligente",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Seguridad-Inteligente/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "58e692639f8b4": {
                "id": null,
                "title": "Smart Home",
                "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5b4684ef39363",
            "title": "Asistentes de Voz",
            "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Asistentes-de-Voz/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/asistente-voz.png",
            "parentId": "58e692639f8b4",
            "subCategories": [
              {
                "id": "5c3f718e1f8d4",
                "title": "Asistentes de Voz",
                "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Asistentes-de-Voz/Asistentes-de-Voz/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/asistentedevoz.png",
                "parentId": "5b4684ef39363",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "58e692639f8b4": {
                    "id": null,
                    "title": "Smart Home",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                    "image": null,
                    "stock": 0
                  },
                  "5b4684ef39363": {
                    "id": null,
                    "title": "Asistentes de Voz",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Asistentes-de-Voz/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5b46852186da5",
                "title": "Hubs y Controles Smart",
                "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Asistentes-de-Voz/Hubs-Controles-Smart/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hubs-controles.png",
                "parentId": "5b4684ef39363",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/?force_sid=pau9fau6ia4sa0m31saqkqjk0t",
                    "image": null,
                    "stock": 0
                  },
                  "58e692639f8b4": {
                    "id": null,
                    "title": "Smart Home",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/?force_sid=pau9fau6ia4sa0m31saqkqjk0t",
                    "image": null,
                    "stock": 0
                  },
                  "5b4684ef39363": {
                    "id": null,
                    "title": "Asistentes de Voz",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Asistentes-de-Voz/?force_sid=pau9fau6ia4sa0m31saqkqjk0t",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "58e692639f8b4": {
                "id": null,
                "title": "Smart Home",
                "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "5b3d490068d62",
            "title": "Iluminación Inteligente",
            "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Iluminacion-Inteligente/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/iluminacion-inteligente.png",
            "parentId": "58e692639f8b4",
            "subCategories": [
              {
                "id": "5b3d4a632c78c",
                "title": "Smart Plugs",
                "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Iluminacion-Inteligente/Smart-Plugs/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/smart-plug.png",
                "parentId": "5b3d490068d62",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "58e692639f8b4": {
                    "id": null,
                    "title": "Smart Home",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                    "image": null,
                    "stock": 0
                  },
                  "5b3d490068d62": {
                    "id": null,
                    "title": "Iluminación Inteligente",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Iluminacion-Inteligente/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5b3e593105835",
                "title": "Interruptores y Atenuadores Inteligentes",
                "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Iluminacion-Inteligente/Interruptores-y-Atenuadores-Inteligentes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/interruptor-inteligente.png",
                "parentId": "5b3d490068d62",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "58e692639f8b4": {
                    "id": null,
                    "title": "Smart Home",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                    "image": null,
                    "stock": 0
                  },
                  "5b3d490068d62": {
                    "id": null,
                    "title": "Iluminación Inteligente",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Iluminacion-Inteligente/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "5b3e5bfb7ec04",
                "title": "Focos Inteligentes",
                "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Iluminacion-Inteligente/Focos-Inteligentes/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/foco-inteligente.png",
                "parentId": "5b3d490068d62",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "58e692639f8b4": {
                    "id": null,
                    "title": "Smart Home",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                    "image": null,
                    "stock": 0
                  },
                  "5b3d490068d62": {
                    "id": null,
                    "title": "Iluminación Inteligente",
                    "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Iluminacion-Inteligente/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "58e692639f8b4": {
                "id": null,
                "title": "Smart Home",
                "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "ec55d29d96f512ef4dcca5785cd57d5d",
            "title": "Aspiradoras Inteligentes",
            "link": "https://www.cyberpuerta.mx/Aspiradoras-Inteligentes/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/aspiradoras-inteligentes.png",
            "parentId": "58e692639f8b4",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "58e692639f8b4": {
                "id": null,
                "title": "Smart Home",
                "url": "https://www.cyberpuerta.mx/Hogar/Smart-Home/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fcd1e7337747": {
            "id": null,
            "title": "Hogar",
            "url": "https://www.cyberpuerta.mx/Hogar/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "4fcd1ea2edd50",
        "title": "Línea Blanca",
        "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/liena-blanca.png",
        "parentId": "4fcd1e7337747",
        "subCategories": [
          {
            "id": "4fcd2370c554b",
            "title": "Lavadoras",
            "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Lavadoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/imgs-categorias-lavadora.png",
            "parentId": "4fcd1ea2edd50",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/?force_sid=jqlie8rpirtulknaqe0n44bbgb",
                "image": null,
                "stock": 0
              },
              "4fcd1ea2edd50": {
                "id": null,
                "title": "Línea Blanca",
                "url": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/?force_sid=jqlie8rpirtulknaqe0n44bbgb",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fcd2382ca4a1",
            "title": "Secadoras",
            "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Secadoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0003_secadoras.png",
            "parentId": "4fcd1ea2edd50",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd1ea2edd50": {
                "id": null,
                "title": "Línea Blanca",
                "url": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fcd2339eee0d",
            "title": "Refrigeradores",
            "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Refrigeradores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/imgs-categorias-refrigerador.png",
            "parentId": "4fcd1ea2edd50",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd1ea2edd50": {
                "id": null,
                "title": "Línea Blanca",
                "url": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fcd23556bbf1",
            "title": "Congeladores",
            "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Congeladores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0002_congeladores.png",
            "parentId": "4fcd1ea2edd50",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd1ea2edd50": {
                "id": null,
                "title": "Línea Blanca",
                "url": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fcd2364d1866",
            "title": "Lavavajillas",
            "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Lavavajillas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lavavajillas.png",
            "parentId": "4fcd1ea2edd50",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd1ea2edd50": {
                "id": null,
                "title": "Línea Blanca",
                "url": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fcd2393ea617",
            "title": "Estufas y Campanas",
            "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Estufas-y-Campanas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/imgs-categorias-estufa.png",
            "parentId": "4fcd1ea2edd50",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd1ea2edd50": {
                "id": null,
                "title": "Línea Blanca",
                "url": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fcd23a1b5250",
            "title": "Hornos de Microondas",
            "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Hornos-de-Microondas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hornodemicrondas.png",
            "parentId": "4fcd1ea2edd50",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/?force_sid=dnihent7i57sas2lbka1phrcmt",
                "image": null,
                "stock": 0
              },
              "4fcd1ea2edd50": {
                "id": null,
                "title": "Línea Blanca",
                "url": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/?force_sid=dnihent7i57sas2lbka1phrcmt",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fcd23b430e3e",
            "title": "Hornos Eléctricos",
            "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Hornos-Electricos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hornos.png",
            "parentId": "4fcd1ea2edd50",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd1ea2edd50": {
                "id": null,
                "title": "Línea Blanca",
                "url": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fcd23becc803",
            "title": "Aspiradoras",
            "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Aspiradoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/aspiradora.png",
            "parentId": "4fcd1ea2edd50",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd1ea2edd50": {
                "id": null,
                "title": "Línea Blanca",
                "url": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "553fd1093ec6b",
            "title": "Hidrolavadoras",
            "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Hidrolavadoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hidrolavadoras.png",
            "parentId": "4fcd1ea2edd50",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd1ea2edd50": {
                "id": null,
                "title": "Línea Blanca",
                "url": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "61e20013ce85f",
            "title": "Parrillas de Gas",
            "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Parrillas-de-Gas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/parrillas-de-gas.png",
            "parentId": "4fcd1ea2edd50",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd1ea2edd50": {
                "id": null,
                "title": "Línea Blanca",
                "url": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "60f9f6e25c296",
            "title": "Parrillas Eléctricas",
            "link": "https://www.cyberpuerta.mx/Hogar/Cocina/Parrillas-Electricas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/parrillas-electricas.png",
            "parentId": "4fcd1ea2edd50",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd1ea2edd50": {
                "id": null,
                "title": "Línea Blanca",
                "url": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fcd1e7337747": {
            "id": null,
            "title": "Hogar",
            "url": "https://www.cyberpuerta.mx/Hogar/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "4fcd231909296",
        "title": "Electrodomésticos de Cocina",
        "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cocina.png",
        "parentId": "4fcd1e7337747",
        "subCategories": [
          {
            "id": "4fcd23ce0fb6c",
            "title": "Licuadoras",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Licuadoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/licuadoras.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fcd23d91c7ed",
            "title": "Batidoras",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Batidoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/batidoras.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "60f0a9d4db18c",
            "title": "Cafeteras",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cafeteras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/26cafeteras.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "63d458b31eda7",
            "title": "Chocomileras",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Chocomileras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/chocomileras.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "63d8190b30e78",
            "title": "Teteras y Hervidoras de Agua",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Teteras-y-Hervidores-de-Agua/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/teteras-y-hervidoras-de-agua.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "2a5293022ab65dac56e046fb69cee9e5",
            "title": "Extractores y Exprimidores",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Extractores-y-Exprimidores/",
            "image": null,
            "parentId": "4fcd231909296",
            "subCategories": [
              {
                "id": "63d7f731b8997",
                "title": "Extractores",
                "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Extractores-y-Exprimidoress/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/extractores-y-exprimidores.png",
                "parentId": "2a5293022ab65dac56e046fb69cee9e5",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/?force_sid=u231omi2bl6ju5s2q0mq0b3ri6",
                    "image": null,
                    "stock": 0
                  },
                  "4fcd231909296": {
                    "id": null,
                    "title": "Electrodomésticos de Cocina",
                    "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/?force_sid=u231omi2bl6ju5s2q0mq0b3ri6",
                    "image": null,
                    "stock": 0
                  },
                  "2a5293022ab65dac56e046fb69cee9e5": {
                    "id": null,
                    "title": "Extractores y Exprimidores",
                    "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Extractores-y-Exprimidores/?force_sid=u231omi2bl6ju5s2q0mq0b3ri6",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "13f525f2311bb4c440fdf0642de0d908",
                "title": "Exprimidores",
                "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Extractores-y-Exprimidores/Exprimidores/",
                "image": null,
                "parentId": "2a5293022ab65dac56e046fb69cee9e5",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "4fcd231909296": {
                    "id": null,
                    "title": "Electrodomésticos de Cocina",
                    "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                    "image": null,
                    "stock": 0
                  },
                  "2a5293022ab65dac56e046fb69cee9e5": {
                    "id": null,
                    "title": "Extractores y Exprimidores",
                    "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Extractores-y-Exprimidores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "4fcd23e7bbb1f",
            "title": "Enfriadoras de Vinos",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Enfriadoras-de-Vinos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/enfriadoras-vino.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "503d0f8683596",
            "title": "Preparación de Alimentos",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Preparacion-de-Alimentos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/preparacin.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/?force_sid=u231omi2bl6ju5s2q0mq0b3ri6",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/?force_sid=u231omi2bl6ju5s2q0mq0b3ri6",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "63d81b4ab8bdf",
            "title": "Freidoras de Aire",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Freidoras-de-Aire/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/freidoras-de-aire.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "63d805729d48b",
            "title": "Procesadores de Alimentos",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Procesadores-de-Alimentos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/procesadores-de-alimentos.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "63d81d8f4297e",
            "title": "Baterías de Cocina",
            "link": "https://www.cyberpuerta.mx/Hogar/Cocina/Baterias-de-Cocina/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/baterias-d-cocina.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "54355fce94859",
            "title": "Ollas y Vaporeras",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Ollas-y-Vaporeras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ollas-cacerolas.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "54355f5ea38e5",
            "title": "Sartenes y Comales",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Sartenes-y-Comales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/2sartenesycomales.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "54355c325260e",
            "title": "Cubiertos y Utensilios",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cubiertos-y-Utensilios/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tenedores-y-espatulas.png",
            "parentId": "4fcd231909296",
            "subCategories": [
              {
                "id": "63d9bc397375c",
                "title": "Cubiertos",
                "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cubiertos-y-Utensilios/Cubiertos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cubiertos.png",
                "parentId": "54355c325260e",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "4fcd231909296": {
                    "id": null,
                    "title": "Electrodomésticos de Cocina",
                    "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                    "image": null,
                    "stock": 0
                  },
                  "54355c325260e": {
                    "id": null,
                    "title": "Cubiertos y Utensilios",
                    "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cubiertos-y-Utensilios/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "543563674a89a",
                "title": "Utensilios",
                "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cubiertos-y-Utensilios/Utensilios/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tenedores-y-espatulas(1).png",
                "parentId": "54355c325260e",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "4fcd231909296": {
                    "id": null,
                    "title": "Electrodomésticos de Cocina",
                    "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                    "image": null,
                    "stock": 0
                  },
                  "54355c325260e": {
                    "id": null,
                    "title": "Cubiertos y Utensilios",
                    "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cubiertos-y-Utensilios/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "543563561839a",
                "title": "Cuchillos",
                "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cubiertos-y-Utensilios/Cuchillos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cuchillos.png",
                "parentId": "54355c325260e",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "4fcd231909296": {
                    "id": null,
                    "title": "Electrodomésticos de Cocina",
                    "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                    "image": null,
                    "stock": 0
                  },
                  "54355c325260e": {
                    "id": null,
                    "title": "Cubiertos y Utensilios",
                    "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cubiertos-y-Utensilios/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "4fce4106e8687",
            "title": "Molinos para Granos de Café",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Molinos-para-Granos-de-Cafe/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/molinos-para-granos-de-cafe.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "60f0ab3a53759",
            "title": "Dispensadores de Agua",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Dispensadores-de-Agua/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/28dispensadores-de-agua.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/?force_sid=a9o3n2dd68vkh4ooso829tqb7b",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/?force_sid=a9o3n2dd68vkh4ooso829tqb7b",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "60f0abdfeae26",
            "title": "Filtros de Agua",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Filtros-de-Agua/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/29filtros-de-agua.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "61df5ab5a28ce",
            "title": "Grameras",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Grameras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0000_grameras.png",
            "parentId": "4fcd231909296",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd231909296": {
                "id": null,
                "title": "Electrodomésticos de Cocina",
                "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fcd1e7337747": {
            "id": null,
            "title": "Hogar",
            "url": "https://www.cyberpuerta.mx/Hogar/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "4fcd2328148a2",
        "title": "Planchado y Costura",
        "link": "https://www.cyberpuerta.mx/Hogar/Planchado-y-Costura/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/planchado-costura.png",
        "parentId": "4fcd1e7337747",
        "subCategories": [
          {
            "id": "4fcd23f5541be",
            "title": "Planchas",
            "link": "https://www.cyberpuerta.mx/Hogar/Planchado-y-Costura/Planchas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/planchas(1).png",
            "parentId": "4fcd2328148a2",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd2328148a2": {
                "id": null,
                "title": "Planchado y Costura",
                "url": "https://www.cyberpuerta.mx/Hogar/Planchado-y-Costura/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fcd24090fc3b",
            "title": "Máquinas de Coser",
            "link": "https://www.cyberpuerta.mx/Hogar/Planchado-y-Costura/Maquinas-de-Coser/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/maquina-coser.png",
            "parentId": "4fcd2328148a2",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fcd2328148a2": {
                "id": null,
                "title": "Planchado y Costura",
                "url": "https://www.cyberpuerta.mx/Hogar/Planchado-y-Costura/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fcd1e7337747": {
            "id": null,
            "title": "Hogar",
            "url": "https://www.cyberpuerta.mx/Hogar/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "4fce68aa429af",
        "title": "Cuidado Personal",
        "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cuidado-personal.png",
        "parentId": "4fcd1e7337747",
        "subCategories": [
          {
            "id": "4fce6aca8ce6e",
            "title": "Depiladoras",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Depiladoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/depiladora.png",
            "parentId": "4fce68aa429af",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fce68aa429af": {
                "id": null,
                "title": "Cuidado Personal",
                "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fce6af792604",
            "title": "Rasuradoras y Cortadoras",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Rasuradoras-y-Cortadoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/rasuradoras.png",
            "parentId": "4fce68aa429af",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fce68aa429af": {
                "id": null,
                "title": "Cuidado Personal",
                "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fce6a8ee86e2",
            "title": "Secadoras",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Secadoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/secadoras.png",
            "parentId": "4fce68aa429af",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fce68aa429af": {
                "id": null,
                "title": "Cuidado Personal",
                "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4fce6aa5f2d3d",
            "title": "Alisadores y Rizadores",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Alisadores-y-Rizadores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/alizadorrizador.png",
            "parentId": "4fce68aa429af",
            "subCategories": [
              {
                "id": "664b863809cda",
                "title": "Planchas Alaciadoras",
                "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Alisadores-y-Rizadores/Planchas-Alaciadoras/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/planchascabello.png",
                "parentId": "4fce6aa5f2d3d",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "4fce68aa429af": {
                    "id": null,
                    "title": "Cuidado Personal",
                    "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                    "image": null,
                    "stock": 0
                  },
                  "4fce6aa5f2d3d": {
                    "id": null,
                    "title": "Alisadores y Rizadores",
                    "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Alisadores-y-Rizadores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "664b89b7bd766",
                "title": "Cepillos Alisadores",
                "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Alisadores-y-Rizadores/Cepillos-Alisadores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cepillosalisadores.png",
                "parentId": "4fce6aa5f2d3d",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "4fce68aa429af": {
                    "id": null,
                    "title": "Cuidado Personal",
                    "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                    "image": null,
                    "stock": 0
                  },
                  "4fce6aa5f2d3d": {
                    "id": null,
                    "title": "Alisadores y Rizadores",
                    "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Alisadores-y-Rizadores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "664b8989e0a09",
                "title": "Rizadores",
                "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Alisadores-y-Rizadores/Rizadores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/rizadores.png",
                "parentId": "4fce6aa5f2d3d",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "4fce68aa429af": {
                    "id": null,
                    "title": "Cuidado Personal",
                    "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                    "image": null,
                    "stock": 0
                  },
                  "4fce6aa5f2d3d": {
                    "id": null,
                    "title": "Alisadores y Rizadores",
                    "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Alisadores-y-Rizadores/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fce68aa429af": {
                "id": null,
                "title": "Cuidado Personal",
                "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "583ddc38f1a62",
            "title": "Masajeadores",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Masajeadores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/masajeadores.png",
            "parentId": "4fce68aa429af",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fce68aa429af": {
                "id": null,
                "title": "Cuidado Personal",
                "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5edad0f65f03d",
            "title": "Termómetros",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Termometros/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/termometros.png",
            "parentId": "4fce68aa429af",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fce68aa429af": {
                "id": null,
                "title": "Cuidado Personal",
                "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5e95e7550dda2",
            "title": "Cubrebocas",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Cubrebocas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cubrebocas.png",
            "parentId": "4fce68aa429af",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fce68aa429af": {
                "id": null,
                "title": "Cuidado Personal",
                "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "61e74018bea2c",
            "title": "Caretas",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Caretas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/careta.png",
            "parentId": "4fce68aa429af",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fce68aa429af": {
                "id": null,
                "title": "Cuidado Personal",
                "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5e949d748b463",
            "title": "Gel Antibacterial",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Gel-Antibacterial/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gelantibacterial.png",
            "parentId": "4fce68aa429af",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fce68aa429af": {
                "id": null,
                "title": "Cuidado Personal",
                "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "60f0a515ebb46",
            "title": "Desinfectantes y Sanitizantes",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Desinfectantes-y-Sanitizantes/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/25desinfectantes-y-sanitizantes.png",
            "parentId": "4fce68aa429af",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fce68aa429af": {
                "id": null,
                "title": "Cuidado Personal",
                "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5f15f80332995",
            "title": "Tapetes Sanitizantes",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Tapetes-Sanitizantes/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tapetessanitizantes.png",
            "parentId": "4fce68aa429af",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fce68aa429af": {
                "id": null,
                "title": "Cuidado Personal",
                "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "6185c84b7f152",
            "title": "Báscula Corporal",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Bascula-Corporal/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0000_bascula-corporal.png",
            "parentId": "4fce68aa429af",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "4fce68aa429af": {
                "id": null,
                "title": "Cuidado Personal",
                "url": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fcd1e7337747": {
            "id": null,
            "title": "Hogar",
            "url": "https://www.cyberpuerta.mx/Hogar/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "501c11ca637d5",
        "title": "Clima y Ambiente",
        "link": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/clima-ambiente.png",
        "parentId": "4fcd1e7337747",
        "subCategories": [
          {
            "id": "501c12ffe45f2",
            "title": "Aires Acondicionados",
            "link": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/Aires-Acondicionados/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/aires-acondicionados.png",
            "parentId": "501c11ca637d5",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "501c11ca637d5": {
                "id": null,
                "title": "Clima y Ambiente",
                "url": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "501c12e8c924f",
            "title": "Ventiladores",
            "link": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/Ventiladores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ventiladores(2).png",
            "parentId": "501c11ca637d5",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "501c11ca637d5": {
                "id": null,
                "title": "Clima y Ambiente",
                "url": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "501c130e6b0bc",
            "title": "Enfriadores y Calefactores",
            "link": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/Calefactores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/clima-calefactores.png",
            "parentId": "501c11ca637d5",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "501c11ca637d5": {
                "id": null,
                "title": "Clima y Ambiente",
                "url": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5e862a9157544",
            "title": "Purificadores de Aire",
            "link": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/Purificadores-de-Aire/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/purificadores-de-aire.png",
            "parentId": "501c11ca637d5",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "501c11ca637d5": {
                "id": null,
                "title": "Clima y Ambiente",
                "url": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "62229bc852c29",
            "title": "Difusor de Aromas",
            "link": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/Difusor-de-Aromas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/difusir-de-aromas.png",
            "parentId": "501c11ca637d5",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "501c11ca637d5": {
                "id": null,
                "title": "Clima y Ambiente",
                "url": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "f366e81d94423ce5a783f4ff9a7ed489",
            "title": "Extractores de Aire",
            "link": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/Extractores-de-Aire/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/66db092b1b31b-CP.png",
            "parentId": "501c11ca637d5",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "501c11ca637d5": {
                "id": null,
                "title": "Clima y Ambiente",
                "url": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "633b42c26ec99",
            "title": "Filtros para Purificadores de Aire",
            "link": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/Filtros-para-Purificadores-de-Aire/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/filtros-para-purificadores-de-aire.png",
            "parentId": "501c11ca637d5",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "501c11ca637d5": {
                "id": null,
                "title": "Clima y Ambiente",
                "url": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fcd1e7337747": {
            "id": null,
            "title": "Hogar",
            "url": "https://www.cyberpuerta.mx/Hogar/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "595bce7e9bc54",
        "title": "Herramientas",
        "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/herramientas(2).png",
        "parentId": "4fcd1e7337747",
        "subCategories": [
          {
            "id": "60c92ccd1cbd7",
            "title": "Herramientas Eléctricas y Construcción",
            "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0030_herramientas-electricas-y-construccion.png",
            "parentId": "595bce7e9bc54",
            "subCategories": [
              {
                "id": "595bd08a71d34",
                "title": "Taladros",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/Taladros/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/herramientas-electricas.png",
                "parentId": "60c92ccd1cbd7",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c92ccd1cbd7": {
                    "id": null,
                    "title": "Herramientas Eléctricas y Construcción",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60c92d1b82d98",
                "title": "Rotomartillos",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/Rotomartillos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0022_rotomartillos.png",
                "parentId": "60c92ccd1cbd7",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c92ccd1cbd7": {
                    "id": null,
                    "title": "Herramientas Eléctricas y Construcción",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60ca801742299",
                "title": "Destornilladores",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/Destornilladores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0023_destornilladores.png",
                "parentId": "60c92ccd1cbd7",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c92ccd1cbd7": {
                    "id": null,
                    "title": "Herramientas Eléctricas y Construcción",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60c93193ec0dd",
                "title": "Sierras",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/Sierras/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0021_sierras.png",
                "parentId": "60c92ccd1cbd7",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c92ccd1cbd7": {
                    "id": null,
                    "title": "Herramientas Eléctricas y Construcción",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60c939ebe18bf",
                "title": "Lijadoras y Pulidoras",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/Lijadoras-y-Pulidoras/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0020_lijadoras-y-pulidoras.png",
                "parentId": "60c92ccd1cbd7",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c92ccd1cbd7": {
                    "id": null,
                    "title": "Herramientas Eléctricas y Construcción",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "78fa3e9d81ff54343ff64135817175c4",
                "title": "Hidrolavadoras",
                "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Hidrolavadoras/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ferreteria_0005_hidrolavadoras.png",
                "parentId": "60c92ccd1cbd7",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c92ccd1cbd7": {
                    "id": null,
                    "title": "Herramientas Eléctricas y Construcción",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "32e24615491d917b0ba899d966b8708a",
                "title": "Aspiradoras",
                "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Aspiradoras/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ferreteria_0004_aspiradoras.png",
                "parentId": "60c92ccd1cbd7",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c92ccd1cbd7": {
                    "id": null,
                    "title": "Herramientas Eléctricas y Construcción",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "61c1039bdb6b9",
                "title": "Cortadores",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/Cortadores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0001_cortadores.png",
                "parentId": "60c92ccd1cbd7",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c92ccd1cbd7": {
                    "id": null,
                    "title": "Herramientas Eléctricas y Construcción",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4a1fa9377ba16ab2f14b1b0e1a202063",
                "title": "Set de Herramientas y Accesorios",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ferreteria_0003_sets-de-herramientas.png",
                "parentId": "60c92ccd1cbd7",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c92ccd1cbd7": {
                    "id": null,
                    "title": "Herramientas Eléctricas y Construcción",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "6281d069bcec2b063591b80399cf50e0",
                "title": "Energía para Herramientas Eléctricas",
                "link": "https://www.cyberpuerta.mx/Energia/Energia-para-Herramientas-Electricas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia-para-herramientas-electricas.png",
                "parentId": "60c92ccd1cbd7",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c92ccd1cbd7": {
                    "id": null,
                    "title": "Herramientas Eléctricas y Construcción",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "653bdc7dd41e3",
                "title": "Generadores",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/Generadores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/generador.png",
                "parentId": "60c92ccd1cbd7",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c92ccd1cbd7": {
                    "id": null,
                    "title": "Herramientas Eléctricas y Construcción",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "595bce7e9bc54": {
                "id": null,
                "title": "Herramientas",
                "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "60c9400addeca",
            "title": "Herramientas de Aire",
            "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-de-Aire/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/7herramientasdeaire.png",
            "parentId": "595bce7e9bc54",
            "subCategories": [
              {
                "id": "60c940cb28513",
                "title": "Compresores",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-de-Aire/Compresores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/8compresores.png",
                "parentId": "60c9400addeca",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/?force_sid=q89f23ug2fu9dboqe8l9pjpimd",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/?force_sid=q89f23ug2fu9dboqe8l9pjpimd",
                    "image": null,
                    "stock": 0
                  },
                  "60c9400addeca": {
                    "id": null,
                    "title": "Herramientas de Aire",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-de-Aire/?force_sid=q89f23ug2fu9dboqe8l9pjpimd",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60c9463ceec0f",
                "title": "Pistolas de Aire",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-de-Aire/Pistolas-de-Aire/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/9pistolas-de-aire.png",
                "parentId": "60c9400addeca",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c9400addeca": {
                    "id": null,
                    "title": "Herramientas de Aire",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-de-Aire/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60c946b11850c",
                "title": "Mangueras para Compresores",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-de-Aire/Mangueras-para-Compresores/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/10mangueras-para-compresores.png",
                "parentId": "60c9400addeca",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c9400addeca": {
                    "id": null,
                    "title": "Herramientas de Aire",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-de-Aire/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "595bce7e9bc54": {
                "id": null,
                "title": "Herramientas",
                "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "60c93a95d2d9a",
            "title": "Herramientas Manuales",
            "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0029_herramientas-manuales.png",
            "parentId": "595bce7e9bc54",
            "subCategories": [
              {
                "id": "60c93ba4a2d0b",
                "title": "Martillos y Marros",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/Martillos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0019_martillos-y-marros.png",
                "parentId": "60c93a95d2d9a",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c93a95d2d9a": {
                    "id": null,
                    "title": "Herramientas Manuales",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60c93ebdd0e7c",
                "title": "Engrapadoras Industriales",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/Engrapadoras-Industriales/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0018_engrapadoras-industriales.png",
                "parentId": "60c93a95d2d9a",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c93a95d2d9a": {
                    "id": null,
                    "title": "Herramientas Manuales",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "70a91fcc10e35652aa0a243434a5a02b",
                "title": "Desarmadores y Puntas",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/Desarmadores-y-Puntas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ferreteria_0002_desarmadores-y-puntas.png",
                "parentId": "60c93a95d2d9a",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c93a95d2d9a": {
                    "id": null,
                    "title": "Herramientas Manuales",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60c93fb1117e3",
                "title": "Escaleras",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/Escaleras/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/escaleras.png",
                "parentId": "60c93a95d2d9a",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c93a95d2d9a": {
                    "id": null,
                    "title": "Herramientas Manuales",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "04656f549799e7d6f9e7aadee55205ba",
                "title": "Palas y Picos",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Palas-y-Picos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ferreteria_0001_palas-y-picos.png",
                "parentId": "60c93a95d2d9a",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c93a95d2d9a": {
                    "id": null,
                    "title": "Herramientas Manuales",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "61c0d9f97ff53",
                "title": "Polipastos",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/Polipastos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0002_polipasto.png",
                "parentId": "60c93a95d2d9a",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c93a95d2d9a": {
                    "id": null,
                    "title": "Herramientas Manuales",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "661dc10d5885e",
                "title": "Herramientas de Medición",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/Herramientas-de-Medicion/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hmedicion.png",
                "parentId": "60c93a95d2d9a",
                "subCategories": [
                  {
                    "id": "60c94eb1204b8",
                    "title": "Flexómetro",
                    "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/Herramientas-de-Medicion/Flexometro/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/flexometro.png",
                    "parentId": "661dc10d5885e",
                    "subCategories": [],
                    "path": {
                      "4fcd1e7337747": {
                        "id": null,
                        "title": "Hogar",
                        "url": "https://www.cyberpuerta.mx/Hogar/",
                        "image": null,
                        "stock": 0
                      },
                      "595bce7e9bc54": {
                        "id": null,
                        "title": "Herramientas",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                        "image": null,
                        "stock": 0
                      },
                      "60c93a95d2d9a": {
                        "id": null,
                        "title": "Herramientas Manuales",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/",
                        "image": null,
                        "stock": 0
                      },
                      "661dc10d5885e": {
                        "id": null,
                        "title": "Herramientas de Medición",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/Herramientas-de-Medicion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "661dc2178c037",
                    "title": "Distanciómetros",
                    "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/Herramientas-de-Medicion/Distanciometros/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/distanciometro.png",
                    "parentId": "661dc10d5885e",
                    "subCategories": [],
                    "path": {
                      "4fcd1e7337747": {
                        "id": null,
                        "title": "Hogar",
                        "url": "https://www.cyberpuerta.mx/Hogar/",
                        "image": null,
                        "stock": 0
                      },
                      "595bce7e9bc54": {
                        "id": null,
                        "title": "Herramientas",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                        "image": null,
                        "stock": 0
                      },
                      "60c93a95d2d9a": {
                        "id": null,
                        "title": "Herramientas Manuales",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/",
                        "image": null,
                        "stock": 0
                      },
                      "661dc10d5885e": {
                        "id": null,
                        "title": "Herramientas de Medición",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/Herramientas-de-Medicion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c93a95d2d9a": {
                    "id": null,
                    "title": "Herramientas Manuales",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "61c119d07d55f",
                "title": "Tornillos de Banco",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/Tornillo-de-Banco/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0003_tornillo-de-banco.png",
                "parentId": "60c93a95d2d9a",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c93a95d2d9a": {
                    "id": null,
                    "title": "Herramientas Manuales",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "64fa62cfa3629",
                "title": "Cuerdas",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/Cuerdas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cuerdas.png",
                "parentId": "60c93a95d2d9a",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c93a95d2d9a": {
                    "id": null,
                    "title": "Herramientas Manuales",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "657a2175b1c3a",
                "title": "Tarrajas",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/Tarrajas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tarrajas.png",
                "parentId": "60c93a95d2d9a",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60c93a95d2d9a": {
                    "id": null,
                    "title": "Herramientas Manuales",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Manuales/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "595bce7e9bc54": {
                "id": null,
                "title": "Herramientas",
                "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "60ca724776584",
            "title": "Herramientas para Auto",
            "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Auto/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/11herramientas-para-auto.png",
            "parentId": "595bce7e9bc54",
            "subCategories": [
              {
                "id": "85e6f269ee1de65d8027c172ca8d666d",
                "title": "Lijadoras y Pulidoras",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-Electricas-y-Construccion/Lijadoras-y-Pulidoras/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lijadoras-y-pulidoras.png",
                "parentId": "60ca724776584",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca724776584": {
                    "id": null,
                    "title": "Herramientas para Auto",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Auto/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60ca72ae15ad3",
                "title": "Gatos Hidráulicos y Torres",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Auto/Gatos-Hidraulicos-y-Torres/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gatos-hidraulicos-y-torres.png",
                "parentId": "60ca724776584",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/?force_sid=q89f23ug2fu9dboqe8l9pjpimd",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/?force_sid=q89f23ug2fu9dboqe8l9pjpimd",
                    "image": null,
                    "stock": 0
                  },
                  "60ca724776584": {
                    "id": null,
                    "title": "Herramientas para Auto",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Auto/?force_sid=q89f23ug2fu9dboqe8l9pjpimd",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "abe4839b6088460f9de406b3550f25ec",
                "title": "Desarmadores y Puntas",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/Desarmadores-y-Puntas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ferreteria_0002_desarmadores-y-puntas(1).png",
                "parentId": "60ca724776584",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca724776584": {
                    "id": null,
                    "title": "Herramientas para Auto",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Auto/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "ca1edfd92270cda3255899bfdeb398c0",
                "title": "Pinzas",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/Pinzas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pinzas.png",
                "parentId": "60ca724776584",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca724776584": {
                    "id": null,
                    "title": "Herramientas para Auto",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Auto/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "a84c25a049886864fc3a13827153f9b3",
                "title": "Dados",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/Dados/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/dados.png",
                "parentId": "60ca724776584",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca724776584": {
                    "id": null,
                    "title": "Herramientas para Auto",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Auto/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "61def5e3a9114",
                "title": "Linternas",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Auto/Linternas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/_0002_linternas.png",
                "parentId": "60ca724776584",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca724776584": {
                    "id": null,
                    "title": "Herramientas para Auto",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Auto/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "0b43fcaf86c21a21bc3c3255d61cade2",
                "title": "Llaves",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/Llaves/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/llaves.png",
                "parentId": "60ca724776584",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca724776584": {
                    "id": null,
                    "title": "Herramientas para Auto",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Auto/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "595bce7e9bc54": {
                "id": null,
                "title": "Herramientas",
                "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "60ca7ce47667d",
            "title": "Cuidado del Jardín",
            "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0028_cuidado-del-jardin.png",
            "parentId": "595bce7e9bc54",
            "subCategories": [
              {
                "id": "60ca7d75bf6a0",
                "title": "Corte y Mantenimiento",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Corte-y-Mantenimiento/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0016_corte-y-mantenimiento.png",
                "parentId": "60ca7ce47667d",
                "subCategories": [
                  {
                    "id": "60ca7ed21cc26",
                    "title": "Sopladoras",
                    "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Corte-y-Mantenimiento/Sopladoras/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0015_sopladoras.png",
                    "parentId": "60ca7d75bf6a0",
                    "subCategories": [],
                    "path": {
                      "4fcd1e7337747": {
                        "id": null,
                        "title": "Hogar",
                        "url": "https://www.cyberpuerta.mx/Hogar/",
                        "image": null,
                        "stock": 0
                      },
                      "595bce7e9bc54": {
                        "id": null,
                        "title": "Herramientas",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca7ce47667d": {
                        "id": null,
                        "title": "Cuidado del Jardín",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca7d75bf6a0": {
                        "id": null,
                        "title": "Corte y Mantenimiento",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Corte-y-Mantenimiento/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "60ca7f9c38c11",
                    "title": "Podadoras",
                    "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Corte-y-Mantenimiento/Podadoras/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0014_podadoras.png",
                    "parentId": "60ca7d75bf6a0",
                    "subCategories": [],
                    "path": {
                      "4fcd1e7337747": {
                        "id": null,
                        "title": "Hogar",
                        "url": "https://www.cyberpuerta.mx/Hogar/",
                        "image": null,
                        "stock": 0
                      },
                      "595bce7e9bc54": {
                        "id": null,
                        "title": "Herramientas",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca7ce47667d": {
                        "id": null,
                        "title": "Cuidado del Jardín",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca7d75bf6a0": {
                        "id": null,
                        "title": "Corte y Mantenimiento",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Corte-y-Mantenimiento/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "60ca7fc5af801",
                    "title": "Tijeras de Podar",
                    "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Corte-y-Mantenimiento/Tijeras-de-Podar/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0013_tijeras-de-podar.png",
                    "parentId": "60ca7d75bf6a0",
                    "subCategories": [],
                    "path": {
                      "4fcd1e7337747": {
                        "id": null,
                        "title": "Hogar",
                        "url": "https://www.cyberpuerta.mx/Hogar/",
                        "image": null,
                        "stock": 0
                      },
                      "595bce7e9bc54": {
                        "id": null,
                        "title": "Herramientas",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca7ce47667d": {
                        "id": null,
                        "title": "Cuidado del Jardín",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca7d75bf6a0": {
                        "id": null,
                        "title": "Corte y Mantenimiento",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Corte-y-Mantenimiento/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca7ce47667d": {
                    "id": null,
                    "title": "Cuidado del Jardín",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "60ca7fe932811",
                "title": "Riego",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Riego/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0012_riego.png",
                "parentId": "60ca7ce47667d",
                "subCategories": [
                  {
                    "id": "60ca82168a746",
                    "title": "Mangueras",
                    "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Riego/Mangueras/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0011_mangueras.png",
                    "parentId": "60ca7fe932811",
                    "subCategories": [],
                    "path": {
                      "4fcd1e7337747": {
                        "id": null,
                        "title": "Hogar",
                        "url": "https://www.cyberpuerta.mx/Hogar/",
                        "image": null,
                        "stock": 0
                      },
                      "595bce7e9bc54": {
                        "id": null,
                        "title": "Herramientas",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca7ce47667d": {
                        "id": null,
                        "title": "Cuidado del Jardín",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca7fe932811": {
                        "id": null,
                        "title": "Riego",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Riego/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "60ca8246d0c30",
                    "title": "Aspersores y Pistolas de Riego",
                    "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Riego/Aspersores/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0010_aspersores.png",
                    "parentId": "60ca7fe932811",
                    "subCategories": [],
                    "path": {
                      "4fcd1e7337747": {
                        "id": null,
                        "title": "Hogar",
                        "url": "https://www.cyberpuerta.mx/Hogar/",
                        "image": null,
                        "stock": 0
                      },
                      "595bce7e9bc54": {
                        "id": null,
                        "title": "Herramientas",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca7ce47667d": {
                        "id": null,
                        "title": "Cuidado del Jardín",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca7fe932811": {
                        "id": null,
                        "title": "Riego",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Riego/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "60ca829e6ab20",
                    "title": "Porta Mangueras",
                    "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Riego/Porta-Mangueras/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0009_porta-mangueras.png",
                    "parentId": "60ca7fe932811",
                    "subCategories": [],
                    "path": {
                      "4fcd1e7337747": {
                        "id": null,
                        "title": "Hogar",
                        "url": "https://www.cyberpuerta.mx/Hogar/",
                        "image": null,
                        "stock": 0
                      },
                      "595bce7e9bc54": {
                        "id": null,
                        "title": "Herramientas",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca7ce47667d": {
                        "id": null,
                        "title": "Cuidado del Jardín",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca7fe932811": {
                        "id": null,
                        "title": "Riego",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Riego/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca7ce47667d": {
                    "id": null,
                    "title": "Cuidado del Jardín",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "60ca82d426f40",
                "title": "Palas y Picos",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/Palas-y-Picos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0008_palas-y-picos.png",
                "parentId": "60ca7ce47667d",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca7ce47667d": {
                    "id": null,
                    "title": "Cuidado del Jardín",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cuidado-del-Jardin/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "595bce7e9bc54": {
                "id": null,
                "title": "Herramientas",
                "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "60ca87a0da6ac",
            "title": "Plomería y Grifería",
            "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0027_plomeria-y-griferia.png",
            "parentId": "595bce7e9bc54",
            "subCategories": [
              {
                "id": "56fd82d792bab",
                "title": "Calentadores de Agua",
                "link": "https://www.cyberpuerta.mx/Hogar/Linea-Blanca/Calentadores-de-Agua/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/calentadores.jpg",
                "parentId": "60ca87a0da6ac",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca87a0da6ac": {
                    "id": null,
                    "title": "Plomería y Grifería",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60ca8e62482a8",
                "title": "Bombas de Agua",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/Bombas-de-Agua/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/12bombas-de-agua.png",
                "parentId": "60ca87a0da6ac",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca87a0da6ac": {
                    "id": null,
                    "title": "Plomería y Grifería",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "65de61e7aa74a",
                "title": "Llaves y Monomandos",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/Llaves-y-Monomandos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/llaves.png",
                "parentId": "60ca87a0da6ac",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca87a0da6ac": {
                    "id": null,
                    "title": "Plomería y Grifería",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "65de20b022d8e",
                "title": "Regaderas y Accesorios",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/Regaderas-y-Accesorios/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/regaderasaccesorios.png",
                "parentId": "60ca87a0da6ac",
                "subCategories": [
                  {
                    "id": "65de258517e4f",
                    "title": "Regaderas",
                    "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/Regaderas-y-Accesorios/Regaderas/",
                    "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/out/pictures/master/category/icon/regaderasaccesorios(1).png",
                    "parentId": "65de20b022d8e",
                    "subCategories": [],
                    "path": {
                      "4fcd1e7337747": {
                        "id": null,
                        "title": "Hogar",
                        "url": "https://www.cyberpuerta.mx/Hogar/",
                        "image": null,
                        "stock": 0
                      },
                      "595bce7e9bc54": {
                        "id": null,
                        "title": "Herramientas",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca87a0da6ac": {
                        "id": null,
                        "title": "Plomería y Grifería",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/",
                        "image": null,
                        "stock": 0
                      },
                      "65de20b022d8e": {
                        "id": null,
                        "title": "Regaderas y Accesorios",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/Regaderas-y-Accesorios/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "65de259fee1ad",
                    "title": "Accesorios para Baño",
                    "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/Regaderas-y-Accesorios/Accesorios-para-Bano/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/65de259fee1ad-CP.png",
                    "parentId": "65de20b022d8e",
                    "subCategories": [],
                    "path": {
                      "4fcd1e7337747": {
                        "id": null,
                        "title": "Hogar",
                        "url": "https://www.cyberpuerta.mx/Hogar/",
                        "image": null,
                        "stock": 0
                      },
                      "595bce7e9bc54": {
                        "id": null,
                        "title": "Herramientas",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                        "image": null,
                        "stock": 0
                      },
                      "60ca87a0da6ac": {
                        "id": null,
                        "title": "Plomería y Grifería",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/",
                        "image": null,
                        "stock": 0
                      },
                      "65de20b022d8e": {
                        "id": null,
                        "title": "Regaderas y Accesorios",
                        "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/Regaderas-y-Accesorios/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca87a0da6ac": {
                    "id": null,
                    "title": "Plomería y Grifería",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "5c75a153802b7",
                "title": "Tuberías y Registros",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/Tuberias-y-Registros/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tuberias.jpg",
                "parentId": "60ca87a0da6ac",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60ca87a0da6ac": {
                    "id": null,
                    "title": "Plomería y Grifería",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Plomeria-y-Griferia/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "595bce7e9bc54": {
                "id": null,
                "title": "Herramientas",
                "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "60db3a4574148",
            "title": "Cerrajería",
            "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cerrajeria/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0026_cerrajeria.png",
            "parentId": "595bce7e9bc54",
            "subCategories": [
              {
                "id": "627436e2d0469",
                "title": "Cerraduras",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cerrajeria/Cerraduras/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cerrojos-y-manijas.png",
                "parentId": "60db3a4574148",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60db3a4574148": {
                    "id": null,
                    "title": "Cerrajería",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cerrajeria/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60db3aa0426bf",
                "title": "Candados",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cerrajeria/Candados/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0006_candados.png",
                "parentId": "60db3a4574148",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60db3a4574148": {
                    "id": null,
                    "title": "Cerrajería",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Cerrajeria/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "595bce7e9bc54": {
                "id": null,
                "title": "Herramientas",
                "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "c6a79331ccd407aa19dd5ae8441d5221",
            "title": "Herramientas para Cables",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/Accesorios-para-Cables/Herramientas-para-Cables/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c6a79331ccd407aa19dd5ae8441d5221-CP.png",
            "parentId": "595bce7e9bc54",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "595bce7e9bc54": {
                "id": null,
                "title": "Herramientas",
                "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "60cb848ac953e",
            "title": "Sets de Herramientas",
            "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0025_sets-de-herramientas.png",
            "parentId": "595bce7e9bc54",
            "subCategories": [
              {
                "id": "60cb85b149e23",
                "title": "Desarmadores y Puntas",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/Desarmadores-y-Puntas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0005_desarmadores-y-puntas.png",
                "parentId": "60cb848ac953e",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60cb848ac953e": {
                    "id": null,
                    "title": "Sets de Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60cbc7b818e1d",
                "title": "Dados",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/Dados/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/dados.png",
                "parentId": "60cb848ac953e",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60cb848ac953e": {
                    "id": null,
                    "title": "Sets de Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60cbcb266c332",
                "title": "Pinzas",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/Pinzas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0003_pinzas.png",
                "parentId": "60cb848ac953e",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60cb848ac953e": {
                    "id": null,
                    "title": "Sets de Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60cbcb712e802",
                "title": "Brocas",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/Brocas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0002_brocas.png",
                "parentId": "60cb848ac953e",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60cb848ac953e": {
                    "id": null,
                    "title": "Sets de Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60d4cc5e6b466",
                "title": "Llaves",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/Llaves/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0000_llaves.png",
                "parentId": "60cb848ac953e",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60cb848ac953e": {
                    "id": null,
                    "title": "Sets de Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60cbd579a37a3",
                "title": "Cajas de Herramientas",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/Cajas-para-Herramientas/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/hogar_0001_cajas-para-herramientas.png",
                "parentId": "60cb848ac953e",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60cb848ac953e": {
                    "id": null,
                    "title": "Sets de Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "60de4e2c5f616",
                "title": "Discos",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/Discos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/20discos.png",
                "parentId": "60cb848ac953e",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60cb848ac953e": {
                    "id": null,
                    "title": "Sets de Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "630698c9cb66b",
                "title": "Tornillos, Pijas y Bisagras",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/Tornillos-Pijas-y-Bisagras/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tornillos-pijas-bisagras.png",
                "parentId": "60cb848ac953e",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "60cb848ac953e": {
                    "id": null,
                    "title": "Sets de Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Sets-de-Herramientas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "595bce7e9bc54": {
                "id": null,
                "title": "Herramientas",
                "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "616da9ac20660",
            "title": "Herramientas para Soldar",
            "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Soldar/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cautines(1).png",
            "parentId": "595bce7e9bc54",
            "subCategories": [
              {
                "id": "61730f58a4f2a",
                "title": "Soldadoras",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Soldar/Soldadoras/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soldadoras.png",
                "parentId": "616da9ac20660",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "616da9ac20660": {
                    "id": null,
                    "title": "Herramientas para Soldar",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Soldar/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "616da9fa306cd",
                "title": "Cautines",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Soldar/Cautines/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cautines.png",
                "parentId": "616da9ac20660",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/?force_sid=cbkg0h4mnm3a0gu9258e7i9f06",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/?force_sid=cbkg0h4mnm3a0gu9258e7i9f06",
                    "image": null,
                    "stock": 0
                  },
                  "616da9ac20660": {
                    "id": null,
                    "title": "Herramientas para Soldar",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Soldar/?force_sid=cbkg0h4mnm3a0gu9258e7i9f06",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "61702ca4c2bfb",
                "title": "Soldaduras",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Soldar/Soldaduras/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soldaduras.png",
                "parentId": "616da9ac20660",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "616da9ac20660": {
                    "id": null,
                    "title": "Herramientas para Soldar",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Soldar/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "65d524957527d",
                "title": "Caretas para Soldar",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Soldar/Caretas-para-Soldar/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/caretassoldar.jpg",
                "parentId": "616da9ac20660",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "616da9ac20660": {
                    "id": null,
                    "title": "Herramientas para Soldar",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Soldar/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "616daac02d19f",
                "title": "Accesorios para Soldar",
                "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Soldar/Accesorios-para-Soldar/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesoriosoldar.jpg",
                "parentId": "616da9ac20660",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "616da9ac20660": {
                    "id": null,
                    "title": "Herramientas para Soldar",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/Herramientas-para-Soldar/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "595bce7e9bc54": {
                "id": null,
                "title": "Herramientas",
                "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "543563a7679ef",
            "title": "Navajas",
            "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cubiertos-y-Utensilios/Navajas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/navajas.png",
            "parentId": "595bce7e9bc54",
            "subCategories": [
              {
                "id": "66354a265ccaf",
                "title": "Cutters",
                "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cubiertos-y-Utensilios/Navajas/Cutters/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cutters.png",
                "parentId": "543563a7679ef",
                "subCategories": [],
                "path": {
                  "4fcd1e7337747": {
                    "id": null,
                    "title": "Hogar",
                    "url": "https://www.cyberpuerta.mx/Hogar/",
                    "image": null,
                    "stock": 0
                  },
                  "595bce7e9bc54": {
                    "id": null,
                    "title": "Herramientas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                    "image": null,
                    "stock": 0
                  },
                  "543563a7679ef": {
                    "id": null,
                    "title": "Navajas",
                    "url": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cubiertos-y-Utensilios/Navajas/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "595bce7e9bc54": {
                "id": null,
                "title": "Herramientas",
                "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "61df30478f873",
            "title": "Básculas Comerciales",
            "link": "https://www.cyberpuerta.mx/Hogar/Herramientas/Basculas-Comerciales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/basculascomerciales.png",
            "parentId": "595bce7e9bc54",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "595bce7e9bc54": {
                "id": null,
                "title": "Herramientas",
                "url": "https://www.cyberpuerta.mx/Hogar/Herramientas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fcd1e7337747": {
            "id": null,
            "title": "Hogar",
            "url": "https://www.cyberpuerta.mx/Hogar/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "648a3b7c92692",
        "title": "Cybermerch",
        "link": "https://www.cyberpuerta.mx/Hogar/Cybermerch/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cybermerch.png",
        "parentId": "4fcd1e7337747",
        "subCategories": [
          {
            "id": "65ca692751a3a",
            "title": "T-Shirts",
            "link": "https://www.cyberpuerta.mx/Hogar/Cybermerch/T-Shirts/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cybermerch(1).png",
            "parentId": "648a3b7c92692",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "648a3b7c92692": {
                "id": null,
                "title": "Cybermerch",
                "url": "https://www.cyberpuerta.mx/Hogar/Cybermerch/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fcd1e7337747": {
            "id": null,
            "title": "Hogar",
            "url": "https://www.cyberpuerta.mx/Hogar/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "616a11afa08a4",
        "title": "Para Niños",
        "link": "https://www.cyberpuerta.mx/Hogar/Para-Ninos/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/para-ninos.png",
        "parentId": "4fcd1e7337747",
        "subCategories": [
          {
            "id": "ad2602e2e775cff7614df1408bc009f7",
            "title": "Tabletas para Niños",
            "link": "https://www.cyberpuerta.mx/Tabletas-para-Ninos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/tablets-para-ninos.png",
            "parentId": "616a11afa08a4",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "616a11afa08a4": {
                "id": null,
                "title": "Para Niños",
                "url": "https://www.cyberpuerta.mx/Hogar/Para-Ninos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "595132dc79c97",
            "title": "Juguetes",
            "link": "https://www.cyberpuerta.mx/Hogar/Para-Ninos/Juguetes/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/juguetes.png",
            "parentId": "616a11afa08a4",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "616a11afa08a4": {
                "id": null,
                "title": "Para Niños",
                "url": "https://www.cyberpuerta.mx/Hogar/Para-Ninos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "a3da253d6988e11d632f9fd3ddaf3dae",
            "title": "Cubrebocas para Niños",
            "link": "https://www.cyberpuerta.mx/Cubrebocas-para-Ninos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cubrebocas-para-nino.png",
            "parentId": "616a11afa08a4",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "616a11afa08a4": {
                "id": null,
                "title": "Para Niños",
                "url": "https://www.cyberpuerta.mx/Hogar/Para-Ninos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "c0f59a0b277a4c8382843c31b06ab0f2",
            "title": "Caretas para Niños",
            "link": "https://www.cyberpuerta.mx/Caretas-para-Ninos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/careta-para-ninos.png",
            "parentId": "616a11afa08a4",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "616a11afa08a4": {
                "id": null,
                "title": "Para Niños",
                "url": "https://www.cyberpuerta.mx/Hogar/Para-Ninos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fcd1e7337747": {
            "id": null,
            "title": "Hogar",
            "url": "https://www.cyberpuerta.mx/Hogar/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5ecc3c1bb169c",
        "title": "Muebles",
        "link": "https://www.cyberpuerta.mx/Hogar/Muebles/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/muebles.png",
        "parentId": "4fcd1e7337747",
        "subCategories": [
          {
            "id": "5e949bc88a05e",
            "title": "Sillas",
            "link": "https://www.cyberpuerta.mx/Hogar/Muebles/Sillas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sillas.png",
            "parentId": "5ecc3c1bb169c",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "5ecc3c1bb169c": {
                "id": null,
                "title": "Muebles",
                "url": "https://www.cyberpuerta.mx/Hogar/Muebles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5e949c7043adc",
            "title": "Mesas y Escritorios",
            "link": "https://www.cyberpuerta.mx/Hogar/Muebles/Mesas-y-Escritorios/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/mesasyescritorios.png",
            "parentId": "5ecc3c1bb169c",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "5ecc3c1bb169c": {
                "id": null,
                "title": "Muebles",
                "url": "https://www.cyberpuerta.mx/Hogar/Muebles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "af67e51767ab38a00ce7c87f4150fec5",
            "title": "Estaciones de Trabajo de Pie",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/Estaciones-de-Trabajo-de-Pie/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/estaciones-de-trabajo(2).png",
            "parentId": "5ecc3c1bb169c",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "5ecc3c1bb169c": {
                "id": null,
                "title": "Muebles",
                "url": "https://www.cyberpuerta.mx/Hogar/Muebles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "6607657759913",
            "title": "Estantes y Repisas",
            "link": "https://www.cyberpuerta.mx/Hogar/Muebles/Estantes-y-Repisas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/estamtesrepisas.png",
            "parentId": "5ecc3c1bb169c",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "5ecc3c1bb169c": {
                "id": null,
                "title": "Muebles",
                "url": "https://www.cyberpuerta.mx/Hogar/Muebles/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fcd1e7337747": {
            "id": null,
            "title": "Hogar",
            "url": "https://www.cyberpuerta.mx/Hogar/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "61f2dfac1bb6e",
        "title": "Maletas",
        "link": "https://www.cyberpuerta.mx/Hogar/Maletas/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/maletas.png",
        "parentId": "4fcd1e7337747",
        "subCategories": [],
        "path": {
          "4fcd1e7337747": {
            "id": null,
            "title": "Hogar",
            "url": "https://www.cyberpuerta.mx/Hogar/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "62059045054f6",
        "title": "Iluminación",
        "link": "https://www.cyberpuerta.mx/Hogar/Iluminacion/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/iluminacion.png",
        "parentId": "4fcd1e7337747",
        "subCategories": [
          {
            "id": "61a95c5f03f2b",
            "title": "Focos LED",
            "link": "https://www.cyberpuerta.mx/Hogar/Iluminacion/Focos-LED/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/focos-led.png",
            "parentId": "62059045054f6",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "62059045054f6": {
                "id": null,
                "title": "Iluminación",
                "url": "https://www.cyberpuerta.mx/Hogar/Iluminacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "62059663cab95",
            "title": "Luces LED para Interiores",
            "link": "https://www.cyberpuerta.mx/Hogar/Iluminacion/Luces-LED-para-Interiores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/leds-interiores.png",
            "parentId": "62059045054f6",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "62059045054f6": {
                "id": null,
                "title": "Iluminación",
                "url": "https://www.cyberpuerta.mx/Hogar/Iluminacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "6321fb6a2c5e0",
            "title": "Lámparas",
            "link": "https://www.cyberpuerta.mx/Hogar/Iluminacion/Lamparas/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/lamparas.png",
            "parentId": "62059045054f6",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "62059045054f6": {
                "id": null,
                "title": "Iluminación",
                "url": "https://www.cyberpuerta.mx/Hogar/Iluminacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "633cb2a2165d2",
            "title": "Reflectores",
            "link": "https://www.cyberpuerta.mx/Hogar/Iluminacion/Reflectores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/reflectores.png",
            "parentId": "62059045054f6",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "62059045054f6": {
                "id": null,
                "title": "Iluminación",
                "url": "https://www.cyberpuerta.mx/Hogar/Iluminacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "4c8a9ba0f919f7f43952cddf0c9f0afa",
            "title": "Rieles para Lámparas",
            "link": "https://www.cyberpuerta.mx/Hogar/Iluminacion/Rieles-para-Lamparas/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/669000c2c3bc7-CP.png",
            "parentId": "62059045054f6",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "62059045054f6": {
                "id": null,
                "title": "Iluminación",
                "url": "https://www.cyberpuerta.mx/Hogar/Iluminacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fcd1e7337747": {
            "id": null,
            "title": "Hogar",
            "url": "https://www.cyberpuerta.mx/Hogar/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "2a7d334eed0a1b612dc511bcbb9c7c6c",
        "title": "Mascotas",
        "link": "https://www.cyberpuerta.mx/Hogar/Mascotas/",
        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/2a7d334eed0a1b612dc511bcbb9c7c6c-CP.png",
        "parentId": "4fcd1e7337747",
        "subCategories": [
          {
            "id": "1eaa71ebae1d5bd04705b3baeaca97dd",
            "title": "Dispensadores para Mascotas",
            "link": "https://www.cyberpuerta.mx/Hogar/Mascotas/Dispensadores-para-Mascotas/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/1eaa71ebae1d5bd04705b3baeaca97dd-CP.png",
            "parentId": "2a7d334eed0a1b612dc511bcbb9c7c6c",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/?force_sid=i8f0rqnp8jd2rolc1uebferk4v",
                "image": null,
                "stock": 0
              },
              "2a7d334eed0a1b612dc511bcbb9c7c6c": {
                "id": null,
                "title": "Mascotas",
                "url": "https://www.cyberpuerta.mx/Hogar/Mascotas/?force_sid=i8f0rqnp8jd2rolc1uebferk4v",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "7a041328a05ba22a2ac07906cf03af7f",
            "title": "Cortadoras de Pelo para Mascotas",
            "link": "https://www.cyberpuerta.mx/Hogar/Mascotas/Cortadoras-de-Pelo-para-Mascotas/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/7a041328a05ba22a2ac07906cf03af7f-CP.png",
            "parentId": "2a7d334eed0a1b612dc511bcbb9c7c6c",
            "subCategories": [],
            "path": {
              "4fcd1e7337747": {
                "id": null,
                "title": "Hogar",
                "url": "https://www.cyberpuerta.mx/Hogar/",
                "image": null,
                "stock": 0
              },
              "2a7d334eed0a1b612dc511bcbb9c7c6c": {
                "id": null,
                "title": "Mascotas",
                "url": "https://www.cyberpuerta.mx/Hogar/Mascotas/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "4fcd1e7337747": {
            "id": null,
            "title": "Hogar",
            "url": "https://www.cyberpuerta.mx/Hogar/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      }
    ],
    "path": [],
    "hasSubCategories": true
  },
  {
    "id": "5e8f92ef7a3a3",
    "title": "Home Office",
    "link": "https://www.cyberpuerta.mx/Home-Office/",
    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/5e8f92ef7a3a3-CP.png",
    "parentId": "",
    "subCategories": [
      {
        "id": "5e8f938ed17b6",
        "title": "Cómputo",
        "link": "https://www.cyberpuerta.mx/Home-Office/Computo/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/computo.png",
        "parentId": "5e8f92ef7a3a3",
        "subCategories": [
          {
            "id": "8314798feaa480886a0b4b0aa145aabc",
            "title": "Laptops",
            "link": "https://www.cyberpuerta.mx/Computadoras/Laptops/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/laptops-home2.png",
            "parentId": "5e8f938ed17b6",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f938ed17b6": {
                "id": null,
                "title": "Cómputo",
                "url": "https://www.cyberpuerta.mx/Home-Office/Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "87aa3fd6957850d3ff0332f0beefe2fd",
            "title": "Pc's de Escritorio",
            "link": "https://www.cyberpuerta.mx/Computadoras/PC-s-de-Escritorio/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/pcs-escritorio.png",
            "parentId": "5e8f938ed17b6",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f938ed17b6": {
                "id": null,
                "title": "Cómputo",
                "url": "https://www.cyberpuerta.mx/Home-Office/Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "9ebbfad22fb4d64a35cdef6622d845a5",
            "title": "Workstations",
            "link": "https://www.cyberpuerta.mx/Computadoras/Workstations/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/workstations.png",
            "parentId": "5e8f938ed17b6",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f938ed17b6": {
                "id": null,
                "title": "Cómputo",
                "url": "https://www.cyberpuerta.mx/Home-Office/Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "dd0abf13f4e278ee628d79d86ace6989",
            "title": "Monitores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/Monitores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/monitores.png",
            "parentId": "5e8f938ed17b6",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f938ed17b6": {
                "id": null,
                "title": "Cómputo",
                "url": "https://www.cyberpuerta.mx/Home-Office/Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "974f7e62843cc30af08749805f1f7f76",
            "title": "Soportes para Monitores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/Soportes-para-Monitores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/soporteparamonitores.png",
            "parentId": "5e8f938ed17b6",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f938ed17b6": {
                "id": null,
                "title": "Cómputo",
                "url": "https://www.cyberpuerta.mx/Home-Office/Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "dcd1858868a6c767da66ca65311cbedf",
            "title": "Cables",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Cables/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cables3.png",
            "parentId": "5e8f938ed17b6",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f938ed17b6": {
                "id": null,
                "title": "Cómputo",
                "url": "https://www.cyberpuerta.mx/Home-Office/Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "6a536a6d13dace1052a19bc2667992bf",
            "title": "Adaptadores",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Adaptadores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/adaptadores.png",
            "parentId": "5e8f938ed17b6",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f938ed17b6": {
                "id": null,
                "title": "Cómputo",
                "url": "https://www.cyberpuerta.mx/Home-Office/Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "7d72703bf402e9a11a274d61989c4a34",
            "title": "Docking Stations",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-Portatiles/Docking-Stations/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/dockingstations.png",
            "parentId": "5e8f938ed17b6",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f938ed17b6": {
                "id": null,
                "title": "Cómputo",
                "url": "https://www.cyberpuerta.mx/Home-Office/Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "b0b7a007bc2988f4281ef91beec5a3d2",
            "title": "Memorias RAM",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/b0b7a007bc2988f4281ef91beec5a3d2-CP.png",
            "parentId": "5e8f938ed17b6",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f938ed17b6": {
                "id": null,
                "title": "Cómputo",
                "url": "https://www.cyberpuerta.mx/Home-Office/Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "3d06704cd4b3ed71c65aedbc30a9fdde",
            "title": "Estaciones de Trabajo de Pie",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/Estaciones-de-Trabajo/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/estaciones-de-trabajo(1).png",
            "parentId": "5e8f938ed17b6",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f938ed17b6": {
                "id": null,
                "title": "Cómputo",
                "url": "https://www.cyberpuerta.mx/Home-Office/Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "a8acbf9304f6727fc6ca6f93e6224041",
            "title": "Accesorios para Estaciones de Trabajo de Pie",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/Accesorios-para-Estaciones-de-Trabajo/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/accesorios-para-estaciones-de-trabajo(1).png",
            "parentId": "5e8f938ed17b6",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f938ed17b6": {
                "id": null,
                "title": "Cómputo",
                "url": "https://www.cyberpuerta.mx/Home-Office/Computo/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5e8f92ef7a3a3": {
            "id": null,
            "title": "Home Office",
            "url": "https://www.cyberpuerta.mx/Home-Office/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5e8f9410f3eb2",
        "title": "Periféricos",
        "link": "https://www.cyberpuerta.mx/Home-Office/Perifericos/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/perifericos.png",
        "parentId": "5e8f92ef7a3a3",
        "subCategories": [
          {
            "id": "182b1df362118acc72926642d9ac56b5",
            "title": "Mouse",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/Mouse/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/mouse.png",
            "parentId": "5e8f9410f3eb2",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f9410f3eb2": {
                "id": null,
                "title": "Periféricos",
                "url": "https://www.cyberpuerta.mx/Home-Office/Perifericos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "3476e5f67a386023a864e1bcecc2f516",
            "title": "Kits de Teclado y Mouse",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Dispositivos-de-Entrada/Kits-de-Teclado-y-Mouse/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/teclado-mouse.png",
            "parentId": "5e8f9410f3eb2",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f9410f3eb2": {
                "id": null,
                "title": "Periféricos",
                "url": "https://www.cyberpuerta.mx/Home-Office/Perifericos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "856f515be06836f9246adcfb1f240bf0",
            "title": "Audífonos",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Audio/Audifonos/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/856f515be06836f9246adcfb1f240bf0-CP.png",
            "parentId": "5e8f9410f3eb2",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f9410f3eb2": {
                "id": null,
                "title": "Periféricos",
                "url": "https://www.cyberpuerta.mx/Home-Office/Perifericos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "2b89257058ec757d9c65cce140908c33",
            "title": "Bocinas",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/GPS-y-Auto/Estereos-para-Autos/Bocinas/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/2b89257058ec757d9c65cce140908c33-CP.png",
            "parentId": "5e8f9410f3eb2",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f9410f3eb2": {
                "id": null,
                "title": "Periféricos",
                "url": "https://www.cyberpuerta.mx/Home-Office/Perifericos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5e8f92ef7a3a3": {
            "id": null,
            "title": "Home Office",
            "url": "https://www.cyberpuerta.mx/Home-Office/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5e8f9447f3a20",
        "title": "Almacenamiento",
        "link": "https://www.cyberpuerta.mx/Home-Office/Almacenamiento/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/almacenamiento.png",
        "parentId": "5e8f92ef7a3a3",
        "subCategories": [
          {
            "id": "09fb277aff695811f1e23c06756be063",
            "title": "Discos Duros Externos",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/Discos-Duros-Externos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/discosdurosexternos.png",
            "parentId": "5e8f9447f3a20",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f9447f3a20": {
                "id": null,
                "title": "Almacenamiento",
                "url": "https://www.cyberpuerta.mx/Home-Office/Almacenamiento/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "68a7278b96d3c583ef51469d1af6fd8c",
            "title": "SSD",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Discos-Duros-SSD-NAS/SSD/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ssds.png",
            "parentId": "5e8f9447f3a20",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f9447f3a20": {
                "id": null,
                "title": "Almacenamiento",
                "url": "https://www.cyberpuerta.mx/Home-Office/Almacenamiento/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "44ea0e30834fd6a7d1e60d2b644e6cb5",
            "title": "Memorias USB",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Memorias-RAM-y-Flash/Memorias-USB/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/44ea0e30834fd6a7d1e60d2b644e6cb5-CP.png",
            "parentId": "5e8f9447f3a20",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f9447f3a20": {
                "id": null,
                "title": "Almacenamiento",
                "url": "https://www.cyberpuerta.mx/Home-Office/Almacenamiento/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5e8f92ef7a3a3": {
            "id": null,
            "title": "Home Office",
            "url": "https://www.cyberpuerta.mx/Home-Office/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5e8f947290c85",
        "title": "Redes",
        "link": "https://www.cyberpuerta.mx/Home-Office/Redes/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/redes.png",
        "parentId": "5e8f92ef7a3a3",
        "subCategories": [
          {
            "id": "07f056f4232cec6132948d73a8415a6a",
            "title": "Routers",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Router/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/router.png",
            "parentId": "5e8f947290c85",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f947290c85": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Home-Office/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "b3ca7037fabda8381d99c5b5877e924a",
            "title": "Access Points",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Access-Points/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/acces-point(1).png",
            "parentId": "5e8f947290c85",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f947290c85": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Home-Office/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "afcb79a0fc6f98ef07066c01f642c4ea",
            "title": "Wi-Fi en Malla",
            "link": "https://www.cyberpuerta.mx/Hogar/Smart-Home/Smart-WiFi/Wi-Fi-en-Malla/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/wifi-en-malla(2).png",
            "parentId": "5e8f947290c85",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f947290c85": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Home-Office/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5bc7aef369819ed8b632d6f14ed700a2",
            "title": "Extensores de Señal WiFi",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/Redes/Extensor-de-Senal-WiFi/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/estensoresderango.png",
            "parentId": "5e8f947290c85",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f947290c85": {
                "id": null,
                "title": "Redes",
                "url": "https://www.cyberpuerta.mx/Home-Office/Redes/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5e8f92ef7a3a3": {
            "id": null,
            "title": "Home Office",
            "url": "https://www.cyberpuerta.mx/Home-Office/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5e8f953882eea",
        "title": "Impresión",
        "link": "https://www.cyberpuerta.mx/Home-Office/Impresion/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresion.png",
        "parentId": "5e8f92ef7a3a3",
        "subCategories": [
          {
            "id": "b94f09b45db1dfd1ebfdc914d331c3ec",
            "title": "Multifuncionales",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Multifuncionales/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/multifuncionales.png",
            "parentId": "5e8f953882eea",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f953882eea": {
                "id": null,
                "title": "Impresión",
                "url": "https://www.cyberpuerta.mx/Home-Office/Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "1cfd35a471264eab6872cc5dbda4055e",
            "title": "Impresoras",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Impresoras-y-Multifuncionales/Impresoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/impresoras.png",
            "parentId": "5e8f953882eea",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f953882eea": {
                "id": null,
                "title": "Impresión",
                "url": "https://www.cyberpuerta.mx/Home-Office/Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "416ed2205589fa677e9d0f245d7c14ef",
            "title": "Consumibles",
            "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Consumibles-para-Impresoras/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/consumibles.png",
            "parentId": "5e8f953882eea",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f953882eea": {
                "id": null,
                "title": "Impresión",
                "url": "https://www.cyberpuerta.mx/Home-Office/Impresion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5e8f92ef7a3a3": {
            "id": null,
            "title": "Home Office",
            "url": "https://www.cyberpuerta.mx/Home-Office/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5e8f9735a069a",
        "title": "Comunicación",
        "link": "https://www.cyberpuerta.mx/Home-Office/Comunicacion/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/comunicacion.png",
        "parentId": "5e8f92ef7a3a3",
        "subCategories": [
          {
            "id": "27b13921024b55ad445b7bded8368ad9",
            "title": "Webcams",
            "link": "https://www.cyberpuerta.mx/Computadoras/Webcam-Audio/Webcams/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/webcam.png",
            "parentId": "5e8f9735a069a",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f9735a069a": {
                "id": null,
                "title": "Comunicación",
                "url": "https://www.cyberpuerta.mx/Home-Office/Comunicacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "df6fbfb653c5e0af2c4b0a0be6c2179c",
            "title": "Audífonos",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/Audifonos/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/df6fbfb653c5e0af2c4b0a0be6c2179c-CP.png",
            "parentId": "5e8f9735a069a",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f9735a069a": {
                "id": null,
                "title": "Comunicación",
                "url": "https://www.cyberpuerta.mx/Home-Office/Comunicacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "cd8a0f8ccaf82cb7f26e4f3261c484fe",
            "title": "Teléfonos VoIP",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Telefonia-VoIP/Telefonos-VoIP/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/cd8a0f8ccaf82cb7f26e4f3261c484fe-CP.png",
            "parentId": "5e8f9735a069a",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f9735a069a": {
                "id": null,
                "title": "Comunicación",
                "url": "https://www.cyberpuerta.mx/Home-Office/Comunicacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "337ba0f1ba4b9c7487a6da5ed7794102",
            "title": "Audio para Telefonía",
            "link": "https://www.cyberpuerta.mx/Celulares-y-Telefonia/Audio-para-Telefonia/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/337ba0f1ba4b9c7487a6da5ed7794102-CP.png",
            "parentId": "5e8f9735a069a",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f9735a069a": {
                "id": null,
                "title": "Comunicación",
                "url": "https://www.cyberpuerta.mx/Home-Office/Comunicacion/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5e8f92ef7a3a3": {
            "id": null,
            "title": "Home Office",
            "url": "https://www.cyberpuerta.mx/Home-Office/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5e8f97a7cf905",
        "title": "Energía",
        "link": "https://www.cyberpuerta.mx/Home-Office/Energia/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/energia.png",
        "parentId": "5e8f92ef7a3a3",
        "subCategories": [
          {
            "id": "70d509db0448daf41fe0239c5e0c3c05",
            "title": "No Breaks",
            "link": "https://www.cyberpuerta.mx/Energia/No-Break-UPS/No-Break/Filtro/MAX/10675/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/nobreaks.png",
            "parentId": "5e8f97a7cf905",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f97a7cf905": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Home-Office/Energia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5e6f18af9db63be96ae77e564e35557b",
            "title": "Reguladores",
            "link": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/Reguladores-de-Voltaje/Filtro/MAX/6095/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/reguladores.png",
            "parentId": "5e8f97a7cf905",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f97a7cf905": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Home-Office/Energia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "d5674e5bfa90e57edd33e3b49bc95403",
            "title": "Supresores de Pico",
            "link": "https://www.cyberpuerta.mx/Energia/Proteccion-Contra-Descargas/Supresores-de-Picos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/supresordepicos.png",
            "parentId": "5e8f97a7cf905",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f97a7cf905": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Home-Office/Energia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "06ea7c36344d4c947ca89aa2d4f58729",
            "title": "Extensiones",
            "link": "https://www.cyberpuerta.mx/Energia/Extensiones/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/extensiones.png",
            "parentId": "5e8f97a7cf905",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f97a7cf905": {
                "id": null,
                "title": "Energía",
                "url": "https://www.cyberpuerta.mx/Home-Office/Energia/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5e8f92ef7a3a3": {
            "id": null,
            "title": "Home Office",
            "url": "https://www.cyberpuerta.mx/Home-Office/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5e8f97c3981f9",
        "title": "Muebles y Ambiente",
        "link": "https://www.cyberpuerta.mx/Home-Office/Muebles-y-Ambiente/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/mueblesyambiente.png",
        "parentId": "5e8f92ef7a3a3",
        "subCategories": [
          {
            "id": "eec876651df1e5d20c992fa7343d0754",
            "title": "Sillas",
            "link": "https://www.cyberpuerta.mx/Hogar/Muebles/Sillas/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/eec876651df1e5d20c992fa7343d0754-CP.png",
            "parentId": "5e8f97c3981f9",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f97c3981f9": {
                "id": null,
                "title": "Muebles y Ambiente",
                "url": "https://www.cyberpuerta.mx/Home-Office/Muebles-y-Ambiente/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "8e2b4fba61044816d46a868ab69952c6",
            "title": "Sillas Gamer",
            "link": "https://www.cyberpuerta.mx/Computo-Hardware/PC-Gaming/Sillas-Gamer/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/silla-gamer.png",
            "parentId": "5e8f97c3981f9",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f97c3981f9": {
                "id": null,
                "title": "Muebles y Ambiente",
                "url": "https://www.cyberpuerta.mx/Home-Office/Muebles-y-Ambiente/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "8d9394527ced56af5e217edf833d8302",
            "title": "Mesas y Escritorios",
            "link": "https://www.cyberpuerta.mx/Hogar/Muebles/Mesas-y-Escritorios/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/mesasyescritorios(1).png",
            "parentId": "5e8f97c3981f9",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f97c3981f9": {
                "id": null,
                "title": "Muebles y Ambiente",
                "url": "https://www.cyberpuerta.mx/Home-Office/Muebles-y-Ambiente/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "d6ba297d80f2c89e42ce8ec2d089d9d9",
            "title": "Aire Acondicionado",
            "link": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/Aires-Acondicionados/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/aireacondicionado.png",
            "parentId": "5e8f97c3981f9",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f97c3981f9": {
                "id": null,
                "title": "Muebles y Ambiente",
                "url": "https://www.cyberpuerta.mx/Home-Office/Muebles-y-Ambiente/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5429ee4416baf303d4d31cbca5bd9404",
            "title": "Ventiladores",
            "link": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/Ventiladores/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/ventiladores.png",
            "parentId": "5e8f97c3981f9",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f97c3981f9": {
                "id": null,
                "title": "Muebles y Ambiente",
                "url": "https://www.cyberpuerta.mx/Home-Office/Muebles-y-Ambiente/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5e8f92ef7a3a3": {
            "id": null,
            "title": "Home Office",
            "url": "https://www.cyberpuerta.mx/Home-Office/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "5e8f985b02701",
        "title": "Salud e Higiene",
        "link": "https://www.cyberpuerta.mx/Home-Office/Salud-e-Higiene/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/saludehigiene.png",
        "parentId": "5e8f92ef7a3a3",
        "subCategories": [
          {
            "id": "722b26c1cf733012cfeb3b417c38239e",
            "title": "Purificadores de Aire",
            "link": "https://www.cyberpuerta.mx/Hogar/Clima-y-Ambiente/Purificadores-de-Aire/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/purificadoresdeaire.png",
            "parentId": "5e8f985b02701",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f985b02701": {
                "id": null,
                "title": "Salud e Higiene",
                "url": "https://www.cyberpuerta.mx/Home-Office/Salud-e-Higiene/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "e0dad0608bec39a5f447c5753db502d5",
            "title": "Limpiadores para Electrónicos",
            "link": "https://www.cyberpuerta.mx/Computadoras/Accesorios-para-PC-s/Limpiadores-para-Electronicos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/limpiadoresparaelectronicos2.png",
            "parentId": "5e8f985b02701",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f985b02701": {
                "id": null,
                "title": "Salud e Higiene",
                "url": "https://www.cyberpuerta.mx/Home-Office/Salud-e-Higiene/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "5fe7c09695845fb364ac58aed827e123",
            "title": "Gel Antibacterial",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Gel-Antibacterial/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/gelantibacterial(1).png",
            "parentId": "5e8f985b02701",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f985b02701": {
                "id": null,
                "title": "Salud e Higiene",
                "url": "https://www.cyberpuerta.mx/Home-Office/Salud-e-Higiene/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "2ccf84b85a5e450f2e925b69b1a5093d",
            "title": "Cubrebocas",
            "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Cubrebocas",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/cubrebocas(1).png",
            "parentId": "5e8f985b02701",
            "subCategories": [],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "5e8f985b02701": {
                "id": null,
                "title": "Salud e Higiene",
                "url": "https://www.cyberpuerta.mx/Home-Office/Salud-e-Higiene/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "5e8f92ef7a3a3": {
            "id": null,
            "title": "Home Office",
            "url": "https://www.cyberpuerta.mx/Home-Office/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "601086b46df3d",
        "title": "Arte y Diseño",
        "link": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/arteydiseno.png",
        "parentId": "5e8f92ef7a3a3",
        "subCategories": [
          {
            "id": "62437984d3c79",
            "title": "Plumones y Marcadores Artísticos",
            "link": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/Plumones-y-Marcadores-Artisticos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/mar5_0004_plumonesymarcadoresartisticos.png",
            "parentId": "601086b46df3d",
            "subCategories": [
              {
                "id": "62437a1731d7a",
                "title": "Marcadores Artísticos",
                "link": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/Plumones-y-Marcadores-Artisticos/Marcadores-Artisticos/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/mar5_0003_marcadoresartisticos.png",
                "parentId": "62437984d3c79",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "601086b46df3d": {
                    "id": null,
                    "title": "Arte y Diseño",
                    "url": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/",
                    "image": null,
                    "stock": 0
                  },
                  "62437984d3c79": {
                    "id": null,
                    "title": "Plumones y Marcadores Artísticos",
                    "url": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/Plumones-y-Marcadores-Artisticos/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "62705b9c47683",
                "title": "Plumones",
                "link": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/Plumones-y-Marcadores-Artisticos/Plumones/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/plumones.png",
                "parentId": "62437984d3c79",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "601086b46df3d": {
                    "id": null,
                    "title": "Arte y Diseño",
                    "url": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/",
                    "image": null,
                    "stock": 0
                  },
                  "62437984d3c79": {
                    "id": null,
                    "title": "Plumones y Marcadores Artísticos",
                    "url": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/Plumones-y-Marcadores-Artisticos/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "601086b46df3d": {
                "id": null,
                "title": "Arte y Diseño",
                "url": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "627574cd1f0c1",
            "title": "Aerosoles",
            "link": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/Aerosoles/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/aerosoles.png",
            "parentId": "601086b46df3d",
            "subCategories": [
              {
                "id": "627575a7c5185",
                "title": "Spray",
                "link": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/Aerosoles/Spray/",
                "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/spray.png",
                "parentId": "627574cd1f0c1",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "601086b46df3d": {
                    "id": null,
                    "title": "Arte y Diseño",
                    "url": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/",
                    "image": null,
                    "stock": 0
                  },
                  "627574cd1f0c1": {
                    "id": null,
                    "title": "Aerosoles",
                    "url": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/Aerosoles/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "601086b46df3d": {
                "id": null,
                "title": "Arte y Diseño",
                "url": "https://www.cyberpuerta.mx/Hogar/Arte-y-Diseno/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "5e8f92ef7a3a3": {
            "id": null,
            "title": "Home Office",
            "url": "https://www.cyberpuerta.mx/Home-Office/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "9cb0352b759d35d314c1df71f1284440",
        "title": "Artículos de Oficina",
        "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/9cb0352b759d35d314c1df71f1284440-CP.png",
        "parentId": "5e8f92ef7a3a3",
        "subCategories": [
          {
            "id": "a31601cd8f64c4e0251eb4f9d1ffd840",
            "title": "Escritura",
            "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/a31601cd8f64c4e0251eb4f9d1ffd840-CP.png",
            "parentId": "9cb0352b759d35d314c1df71f1284440",
            "subCategories": [
              {
                "id": "08abea16f2d685370db5170400e90d9c",
                "title": "Bolígrafos",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/Boligrafos/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/08abea16f2d685370db5170400e90d9c-CP.png",
                "parentId": "a31601cd8f64c4e0251eb4f9d1ffd840",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "a31601cd8f64c4e0251eb4f9d1ffd840": {
                    "id": null,
                    "title": "Escritura",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "3966dedde081c2d85a1e09889c87bf7e",
                "title": "Lapiceros",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/Lapiceros/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/3966dedde081c2d85a1e09889c87bf7e-CP.png",
                "parentId": "a31601cd8f64c4e0251eb4f9d1ffd840",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "a31601cd8f64c4e0251eb4f9d1ffd840": {
                    "id": null,
                    "title": "Escritura",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "32c3455e7b377b8f88fcf7eecd0846fe",
                "title": "Lápices",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/Lapices/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/32c3455e7b377b8f88fcf7eecd0846fe-CP.png",
                "parentId": "a31601cd8f64c4e0251eb4f9d1ffd840",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "a31601cd8f64c4e0251eb4f9d1ffd840": {
                    "id": null,
                    "title": "Escritura",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "ba4e192e3ff06fc2d2206e3acc9968ae",
                "title": "Marca Textos",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/Marca-Textos/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/ba4e192e3ff06fc2d2206e3acc9968ae-CP.png",
                "parentId": "a31601cd8f64c4e0251eb4f9d1ffd840",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "a31601cd8f64c4e0251eb4f9d1ffd840": {
                    "id": null,
                    "title": "Escritura",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "b04bfcfc6cb3acd80b298254fe1e94d0",
                "title": "Marcadores y Borradores para Pizarrón",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/Marcadores-y-Borradores-para-Pizarron/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/b04bfcfc6cb3acd80b298254fe1e94d0-CP.png",
                "parentId": "a31601cd8f64c4e0251eb4f9d1ffd840",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "a31601cd8f64c4e0251eb4f9d1ffd840": {
                    "id": null,
                    "title": "Escritura",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "3deb3026a4e0f9ae6cfdc461558f6a57",
                "title": "Marcadores Permanentes",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/Marcadores-Permanentes/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/3deb3026a4e0f9ae6cfdc461558f6a57-CP.png",
                "parentId": "a31601cd8f64c4e0251eb4f9d1ffd840",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "a31601cd8f64c4e0251eb4f9d1ffd840": {
                    "id": null,
                    "title": "Escritura",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "b7ca2567fcb519bf86e252c0b6e88a75",
                "title": "Marcadores a Base de Agua",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/Marcadores-a-Base-de-Agua/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/b7ca2567fcb519bf86e252c0b6e88a75-CP.png",
                "parentId": "a31601cd8f64c4e0251eb4f9d1ffd840",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "a31601cd8f64c4e0251eb4f9d1ffd840": {
                    "id": null,
                    "title": "Escritura",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "c287513292b6d60f69bc6af9710fbabb",
                "title": "Corrector",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/Corrector/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c287513292b6d60f69bc6af9710fbabb-CP.png",
                "parentId": "a31601cd8f64c4e0251eb4f9d1ffd840",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "a31601cd8f64c4e0251eb4f9d1ffd840": {
                    "id": null,
                    "title": "Escritura",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "55ebc9753510b43919a6c4900c1ef5fe",
                "title": "Colores",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/Colores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/55ebc9753510b43919a6c4900c1ef5fe-CP.png",
                "parentId": "a31601cd8f64c4e0251eb4f9d1ffd840",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "a31601cd8f64c4e0251eb4f9d1ffd840": {
                    "id": null,
                    "title": "Escritura",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "0cbda94fc1d3da6d196b5fd932f318a4",
                "title": "Borradores",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/Borradores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/0cbda94fc1d3da6d196b5fd932f318a4-CP.png",
                "parentId": "a31601cd8f64c4e0251eb4f9d1ffd840",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/?force_sid=kp7mgqve2cd549bmts0895nfhk",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/?force_sid=kp7mgqve2cd549bmts0895nfhk",
                    "image": null,
                    "stock": 0
                  },
                  "a31601cd8f64c4e0251eb4f9d1ffd840": {
                    "id": null,
                    "title": "Escritura",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/?force_sid=kp7mgqve2cd549bmts0895nfhk",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "9ce94c67a2eb3ca8656b1d1c72b5ca5b",
                "title": "Sacapuntas",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/Sacapuntas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/9ce94c67a2eb3ca8656b1d1c72b5ca5b-CP.png",
                "parentId": "a31601cd8f64c4e0251eb4f9d1ffd840",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "a31601cd8f64c4e0251eb4f9d1ffd840": {
                    "id": null,
                    "title": "Escritura",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Escritura/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "9cb0352b759d35d314c1df71f1284440": {
                "id": null,
                "title": "Artículos de Oficina",
                "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "22f7dcdab0d41e20094b67cfc8975bfb",
            "title": "Papelería",
            "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/22f7dcdab0d41e20094b67cfc8975bfb-CP.png",
            "parentId": "9cb0352b759d35d314c1df71f1284440",
            "subCategories": [
              {
                "id": "4a684ca34344de2a9a024015c60ddaef",
                "title": "Sujeción",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/4a684ca34344de2a9a024015c60ddaef-CP.png",
                "parentId": "22f7dcdab0d41e20094b67cfc8975bfb",
                "subCategories": [
                  {
                    "id": "cbb4d5cc99ac61cc15e9971fc4a6fc2e",
                    "title": "Grapas",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/Grapas/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/cbb4d5cc99ac61cc15e9971fc4a6fc2e-CP.png",
                    "parentId": "4a684ca34344de2a9a024015c60ddaef",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "4a684ca34344de2a9a024015c60ddaef": {
                        "id": null,
                        "title": "Sujeción",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "e0b218ff03dd097bf39d6f012c5c71f7",
                    "title": "Broches",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/Broches/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/e0b218ff03dd097bf39d6f012c5c71f7-CP.png",
                    "parentId": "4a684ca34344de2a9a024015c60ddaef",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "4a684ca34344de2a9a024015c60ddaef": {
                        "id": null,
                        "title": "Sujeción",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "4393cd22470cb97c91c743348de27ee0",
                    "title": "Clips",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/Clips/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/4393cd22470cb97c91c743348de27ee0-CP.png",
                    "parentId": "4a684ca34344de2a9a024015c60ddaef",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "4a684ca34344de2a9a024015c60ddaef": {
                        "id": null,
                        "title": "Sujeción",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "1aab7cf1836606af3e4b0a4b03ed6799",
                    "title": "Sujetadores",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/Sujetadores/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/1aab7cf1836606af3e4b0a4b03ed6799-CP.png",
                    "parentId": "4a684ca34344de2a9a024015c60ddaef",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "4a684ca34344de2a9a024015c60ddaef": {
                        "id": null,
                        "title": "Sujeción",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "b3b6359cc26784f1f6c7b8c1720e6a4b",
                    "title": "Pins y Chinches",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/Pins-y-Chinches/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/b3b6359cc26784f1f6c7b8c1720e6a4b-CP.png",
                    "parentId": "4a684ca34344de2a9a024015c60ddaef",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "4a684ca34344de2a9a024015c60ddaef": {
                        "id": null,
                        "title": "Sujeción",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "712461cca0ab7c0b5fe6e3be282b4a8c",
                    "title": "Lápices Adhesivos",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/Lapices-Adhesivos/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/712461cca0ab7c0b5fe6e3be282b4a8c-CP.png",
                    "parentId": "4a684ca34344de2a9a024015c60ddaef",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "4a684ca34344de2a9a024015c60ddaef": {
                        "id": null,
                        "title": "Sujeción",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "10f3c6cb1b2fffec52f27bc4107d4988",
                    "title": "Cintas Adhesivas",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/Cintas-Adhesivas/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/10f3c6cb1b2fffec52f27bc4107d4988-CP.png",
                    "parentId": "4a684ca34344de2a9a024015c60ddaef",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "4a684ca34344de2a9a024015c60ddaef": {
                        "id": null,
                        "title": "Sujeción",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "f6381d84cb9bb036dda9af58512d3e34",
                    "title": "Pegamentos",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/Pegamentos/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/f6381d84cb9bb036dda9af58512d3e34-CP.png",
                    "parentId": "4a684ca34344de2a9a024015c60ddaef",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "4a684ca34344de2a9a024015c60ddaef": {
                        "id": null,
                        "title": "Sujeción",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "20f5df1b9418060880c6236c7c80f85b",
                    "title": "Engrapadoras",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Engrapadoras/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/20f5df1b9418060880c6236c7c80f85b-CP.png",
                    "parentId": "4a684ca34344de2a9a024015c60ddaef",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "4a684ca34344de2a9a024015c60ddaef": {
                        "id": null,
                        "title": "Sujeción",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Sujecion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "22f7dcdab0d41e20094b67cfc8975bfb": {
                    "id": null,
                    "title": "Papelería",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "c439ae8dfa9322f00903c422736b3c49",
                "title": "Útiles",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Utiles/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c439ae8dfa9322f00903c422736b3c49-CP.png",
                "parentId": "22f7dcdab0d41e20094b67cfc8975bfb",
                "subCategories": [
                  {
                    "id": "15ad5b4124642dccf6e6b707e4a4c832",
                    "title": "Cuadernos",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Utiles/Cuadernos/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/15ad5b4124642dccf6e6b707e4a4c832-CP.png",
                    "parentId": "c439ae8dfa9322f00903c422736b3c49",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "c439ae8dfa9322f00903c422736b3c49": {
                        "id": null,
                        "title": "Útiles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Utiles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "d4b50d91c0024d44cb50c8af8de135f5",
                    "title": "Blocks",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Utiles/Blocks/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/d4b50d91c0024d44cb50c8af8de135f5-CP.png",
                    "parentId": "c439ae8dfa9322f00903c422736b3c49",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "c439ae8dfa9322f00903c422736b3c49": {
                        "id": null,
                        "title": "Útiles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Utiles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "22f7dcdab0d41e20094b67cfc8975bfb": {
                    "id": null,
                    "title": "Papelería",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "2bdae45be8ef6bd7a6893e9df712ecd3",
                "title": "Corte",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/2bdae45be8ef6bd7a6893e9df712ecd3-CP.png",
                "parentId": "22f7dcdab0d41e20094b67cfc8975bfb",
                "subCategories": [
                  {
                    "id": "fdbf9f92804dd5eb97112a6be7e14def",
                    "title": "Navajas",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/Navajas/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/fdbf9f92804dd5eb97112a6be7e14def-CP.png",
                    "parentId": "2bdae45be8ef6bd7a6893e9df712ecd3",
                    "subCategories": [
                      {
                        "id": "138f4731570aac24b4570e186849c5bd",
                        "title": "Navajas de Bolsillo",
                        "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cubiertos-y-Utensilios/Navajas/Navajas-de-Bolsillo/",
                        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/138f4731570aac24b4570e186849c5bd-CP.png",
                        "parentId": "fdbf9f92804dd5eb97112a6be7e14def",
                        "subCategories": [],
                        "path": {
                          "5e8f92ef7a3a3": {
                            "id": null,
                            "title": "Home Office",
                            "url": "https://www.cyberpuerta.mx/Home-Office/",
                            "image": null,
                            "stock": 0
                          },
                          "9cb0352b759d35d314c1df71f1284440": {
                            "id": null,
                            "title": "Artículos de Oficina",
                            "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                            "image": null,
                            "stock": 0
                          },
                          "22f7dcdab0d41e20094b67cfc8975bfb": {
                            "id": null,
                            "title": "Papelería",
                            "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                            "image": null,
                            "stock": 0
                          },
                          "2bdae45be8ef6bd7a6893e9df712ecd3": {
                            "id": null,
                            "title": "Corte",
                            "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/",
                            "image": null,
                            "stock": 0
                          },
                          "fdbf9f92804dd5eb97112a6be7e14def": {
                            "id": null,
                            "title": "Navajas",
                            "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/Navajas/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      },
                      {
                        "id": "0b6c2e78c17698c284d8e30548bd7fe4",
                        "title": "Cutters",
                        "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cubiertos-y-Utensilios/Navajas/Cutters/",
                        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/0b6c2e78c17698c284d8e30548bd7fe4-CP.png",
                        "parentId": "fdbf9f92804dd5eb97112a6be7e14def",
                        "subCategories": [],
                        "path": {
                          "5e8f92ef7a3a3": {
                            "id": null,
                            "title": "Home Office",
                            "url": "https://www.cyberpuerta.mx/Home-Office/",
                            "image": null,
                            "stock": 0
                          },
                          "9cb0352b759d35d314c1df71f1284440": {
                            "id": null,
                            "title": "Artículos de Oficina",
                            "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                            "image": null,
                            "stock": 0
                          },
                          "22f7dcdab0d41e20094b67cfc8975bfb": {
                            "id": null,
                            "title": "Papelería",
                            "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                            "image": null,
                            "stock": 0
                          },
                          "2bdae45be8ef6bd7a6893e9df712ecd3": {
                            "id": null,
                            "title": "Corte",
                            "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/",
                            "image": null,
                            "stock": 0
                          },
                          "fdbf9f92804dd5eb97112a6be7e14def": {
                            "id": null,
                            "title": "Navajas",
                            "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/Navajas/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      },
                      {
                        "id": "d2e5bbf164e835524d474ac5a50703b2",
                        "title": "Navajas para Cutter",
                        "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Cubiertos-y-Utensilios/Navajas/Navajas-para-Cutter/",
                        "image": "https://cdn.cyberpuerta.mx/catalog/images/category/d2e5bbf164e835524d474ac5a50703b2-CP.png",
                        "parentId": "fdbf9f92804dd5eb97112a6be7e14def",
                        "subCategories": [],
                        "path": {
                          "5e8f92ef7a3a3": {
                            "id": null,
                            "title": "Home Office",
                            "url": "https://www.cyberpuerta.mx/Home-Office/",
                            "image": null,
                            "stock": 0
                          },
                          "9cb0352b759d35d314c1df71f1284440": {
                            "id": null,
                            "title": "Artículos de Oficina",
                            "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                            "image": null,
                            "stock": 0
                          },
                          "22f7dcdab0d41e20094b67cfc8975bfb": {
                            "id": null,
                            "title": "Papelería",
                            "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                            "image": null,
                            "stock": 0
                          },
                          "2bdae45be8ef6bd7a6893e9df712ecd3": {
                            "id": null,
                            "title": "Corte",
                            "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/",
                            "image": null,
                            "stock": 0
                          },
                          "fdbf9f92804dd5eb97112a6be7e14def": {
                            "id": null,
                            "title": "Navajas",
                            "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/Navajas/",
                            "image": null,
                            "stock": 0
                          }
                        },
                        "hasSubCategories": false
                      }
                    ],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "2bdae45be8ef6bd7a6893e9df712ecd3": {
                        "id": null,
                        "title": "Corte",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": true
                  },
                  {
                    "id": "1d01f790f62f16d4f77918e6198c92ba",
                    "title": "Guillotinas",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/Guillotinas/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/1d01f790f62f16d4f77918e6198c92ba-CP.png",
                    "parentId": "2bdae45be8ef6bd7a6893e9df712ecd3",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "2bdae45be8ef6bd7a6893e9df712ecd3": {
                        "id": null,
                        "title": "Corte",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "9c86ee0fba685ee374dcd6e87fadafd3",
                    "title": "Tijeras",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/Tijeras/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/9c86ee0fba685ee374dcd6e87fadafd3-CP.png",
                    "parentId": "2bdae45be8ef6bd7a6893e9df712ecd3",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "2bdae45be8ef6bd7a6893e9df712ecd3": {
                        "id": null,
                        "title": "Corte",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "701e39c5a8bea9632036c221993f704f",
                    "title": "Perforadoras",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/Perforadoras/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/701e39c5a8bea9632036c221993f704f-CP.png",
                    "parentId": "2bdae45be8ef6bd7a6893e9df712ecd3",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "2bdae45be8ef6bd7a6893e9df712ecd3": {
                        "id": null,
                        "title": "Corte",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Corte/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "22f7dcdab0d41e20094b67cfc8975bfb": {
                    "id": null,
                    "title": "Papelería",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "0fe7abe7168d8c17d1cbdfac617c1da8",
                "title": "Archivo",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/0fe7abe7168d8c17d1cbdfac617c1da8-CP.png",
                "parentId": "22f7dcdab0d41e20094b67cfc8975bfb",
                "subCategories": [
                  {
                    "id": "21e49264f2399710c1b5a458233ac355",
                    "title": "Carpetas",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/Carpetas/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/21e49264f2399710c1b5a458233ac355-CP.png",
                    "parentId": "0fe7abe7168d8c17d1cbdfac617c1da8",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "0fe7abe7168d8c17d1cbdfac617c1da8": {
                        "id": null,
                        "title": "Archivo",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "4bb905bb5565e1d5b1e056c5410f9799",
                    "title": "Fólder",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/Folder/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/4bb905bb5565e1d5b1e056c5410f9799-CP.png",
                    "parentId": "0fe7abe7168d8c17d1cbdfac617c1da8",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "0fe7abe7168d8c17d1cbdfac617c1da8": {
                        "id": null,
                        "title": "Archivo",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "4c249a0c16d4e5a74eea95abdb2124ad",
                    "title": "Sobres",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/Sobres/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/4c249a0c16d4e5a74eea95abdb2124ad-CP.png",
                    "parentId": "0fe7abe7168d8c17d1cbdfac617c1da8",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "0fe7abe7168d8c17d1cbdfac617c1da8": {
                        "id": null,
                        "title": "Archivo",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "88cbef99bdf297f37a5a0dc3ca773db9",
                    "title": "Separadores",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/Separadores/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/88cbef99bdf297f37a5a0dc3ca773db9-CP.png",
                    "parentId": "0fe7abe7168d8c17d1cbdfac617c1da8",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "0fe7abe7168d8c17d1cbdfac617c1da8": {
                        "id": null,
                        "title": "Archivo",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "72708d9e2ed8f64b8368cffd903e82d0",
                    "title": "Revisteros",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/Revisteros/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/72708d9e2ed8f64b8368cffd903e82d0-CP.png",
                    "parentId": "0fe7abe7168d8c17d1cbdfac617c1da8",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "0fe7abe7168d8c17d1cbdfac617c1da8": {
                        "id": null,
                        "title": "Archivo",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "e0f5466b8b40fcdd6d515ee5f736d525",
                    "title": "Archivos Expandibles",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/Archivos-Expandibles/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/e0f5466b8b40fcdd6d515ee5f736d525-CP.png",
                    "parentId": "0fe7abe7168d8c17d1cbdfac617c1da8",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "0fe7abe7168d8c17d1cbdfac617c1da8": {
                        "id": null,
                        "title": "Archivo",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "bc15cc027549041b2a28aff316f05440",
                    "title": "Portadocumentos",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/Portadocumentos/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/bc15cc027549041b2a28aff316f05440-CP.png",
                    "parentId": "0fe7abe7168d8c17d1cbdfac617c1da8",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "0fe7abe7168d8c17d1cbdfac617c1da8": {
                        "id": null,
                        "title": "Archivo",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "ae59d79ac3017489ca1ab2fe32c8a6d0",
                    "title": "Cajas para Archivo",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/Cajas-para-Archivo/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/ae59d79ac3017489ca1ab2fe32c8a6d0-CP.png",
                    "parentId": "0fe7abe7168d8c17d1cbdfac617c1da8",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "0fe7abe7168d8c17d1cbdfac617c1da8": {
                        "id": null,
                        "title": "Archivo",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Archivo/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "22f7dcdab0d41e20094b67cfc8975bfb": {
                    "id": null,
                    "title": "Papelería",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "e834d354421acfa80cf5227f747f8a92",
                "title": "Papeles",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/e834d354421acfa80cf5227f747f8a92-CP.png",
                "parentId": "22f7dcdab0d41e20094b67cfc8975bfb",
                "subCategories": [
                  {
                    "id": "c07b822a6d42b3642850a7e417652cd7",
                    "title": "Papel",
                    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Papel/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c07b822a6d42b3642850a7e417652cd7-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "79848be45f805fc725f3c60b3c8afd04",
                    "title": "Papel Stock",
                    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Papel-Stock/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/79848be45f805fc725f3c60b3c8afd04-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "cfcdb45845df17f459f80c0d87c50111",
                    "title": "Papel Opalina",
                    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Papel-Opalina/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/cfcdb45845df17f459f80c0d87c50111-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "47e9e058bf699ac88b0cf6de4fb99254",
                    "title": "Papel POS",
                    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Papel-POS/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/47e9e058bf699ac88b0cf6de4fb99254-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "7915b0b767ea6e1cb9133e09e4b28076",
                    "title": "Papel Fotográfico",
                    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Papel-Fotografico/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/7915b0b767ea6e1cb9133e09e4b28076-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "4eced8c864f63879337e586e1923ca81",
                    "title": "Papel de Colores",
                    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Papel-de-Colores/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/4eced8c864f63879337e586e1923ca81-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "8b9744650877785695cfdf0f3e41da9e",
                    "title": "Rollos de Papel para Plotter",
                    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Rollos-de-Papel-para-Plotter/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/8b9744650877785695cfdf0f3e41da9e-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "b8499cb7236006958a2c0fb018f9dc49",
                    "title": "Etiquetas Adhesivas",
                    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Etiquetas-Adhesivas/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/b8499cb7236006958a2c0fb018f9dc49-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "04f74a881dd14a74d654ad5d152a89ce",
                    "title": "Etiquetas para CD/DVD",
                    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Etiquetas-para-CD-DVD/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/04f74a881dd14a74d654ad5d152a89ce-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "5e5b0fba12f2344e83d5857f0d5f9aab",
                    "title": "Formas Impresas",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/Formas-Impresas/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/5e5b0fba12f2344e83d5857f0d5f9aab-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "bcd41119848b3ee8f2b22a71197db683",
                    "title": "Tarjetas de Presentación",
                    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Tarjetas-de-Presentacion/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/bcd41119848b3ee8f2b22a71197db683-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "2275c1a98dc97375d31f5e263287d399",
                    "title": "Tarjetas para Credenciales",
                    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Tarjetas-para-Credenciales/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/2275c1a98dc97375d31f5e263287d399-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "9762e1055d0d13c6ccbc54e4eb186e5e",
                    "title": "Tarjetas Checadoras",
                    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Tarjetas-Checadoras/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/9762e1055d0d13c6ccbc54e4eb186e5e-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "6b3697a30877df3c50d470fb829b29f8",
                    "title": "Papel Reciclado",
                    "link": "https://www.cyberpuerta.mx/Impresion-y-Copiado/Papeles-para-Impresoras/Papel-Reciclado/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6b3697a30877df3c50d470fb829b29f8-CP.png",
                    "parentId": "e834d354421acfa80cf5227f747f8a92",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "e834d354421acfa80cf5227f747f8a92": {
                        "id": null,
                        "title": "Papeles",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Papeles/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "22f7dcdab0d41e20094b67cfc8975bfb": {
                    "id": null,
                    "title": "Papelería",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "f0de190d64a8eba8727f789157130ff8",
                "title": "Encuadernación",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Encuadernacion/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/f0de190d64a8eba8727f789157130ff8-CP.png",
                "parentId": "22f7dcdab0d41e20094b67cfc8975bfb",
                "subCategories": [
                  {
                    "id": "816f2d6d6f9ddf26df5199e3e9bdfcd3",
                    "title": "Engargoladoras",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Encuadernacion/Engargoladoras/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/816f2d6d6f9ddf26df5199e3e9bdfcd3-CP.png",
                    "parentId": "f0de190d64a8eba8727f789157130ff8",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "f0de190d64a8eba8727f789157130ff8": {
                        "id": null,
                        "title": "Encuadernación",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Encuadernacion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "1f79867b70b5fe7fb2637c3136fd1847",
                    "title": "Encuadernadoras",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Encuadernacion/Encuadernadoras/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/1f79867b70b5fe7fb2637c3136fd1847-CP.png",
                    "parentId": "f0de190d64a8eba8727f789157130ff8",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "f0de190d64a8eba8727f789157130ff8": {
                        "id": null,
                        "title": "Encuadernación",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Encuadernacion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "3e714286798624700f3187e641d76073",
                    "title": "Enmicadoras",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Encuadernacion/Enmicadoras/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/3e714286798624700f3187e641d76073-CP.png",
                    "parentId": "f0de190d64a8eba8727f789157130ff8",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "f0de190d64a8eba8727f789157130ff8": {
                        "id": null,
                        "title": "Encuadernación",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Encuadernacion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "8c4cda75ce96003aa8750000d0c43f1f",
                    "title": "Espirales, Gusanos y Arillos",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Encuadernacion/Espirales-Gusanos-y-Arillos/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/8c4cda75ce96003aa8750000d0c43f1f-CP.png",
                    "parentId": "f0de190d64a8eba8727f789157130ff8",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "f0de190d64a8eba8727f789157130ff8": {
                        "id": null,
                        "title": "Encuadernación",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Encuadernacion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  },
                  {
                    "id": "c227686d5aece687bb5d8a56ba00f3c4",
                    "title": "Micas, Cubiertas y Protectores",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Encuadernacion/Micas-Cubiertas-y-Protectores/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c227686d5aece687bb5d8a56ba00f3c4-CP.png",
                    "parentId": "f0de190d64a8eba8727f789157130ff8",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "22f7dcdab0d41e20094b67cfc8975bfb": {
                        "id": null,
                        "title": "Papelería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                        "image": null,
                        "stock": 0
                      },
                      "f0de190d64a8eba8727f789157130ff8": {
                        "id": null,
                        "title": "Encuadernación",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/Encuadernacion/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "22f7dcdab0d41e20094b67cfc8975bfb": {
                    "id": null,
                    "title": "Papelería",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Papeleria/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              }
            ],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "9cb0352b759d35d314c1df71f1284440": {
                "id": null,
                "title": "Artículos de Oficina",
                "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "af48c0d2cf0ffa2da4d721586ca3f16b",
            "title": "Accesorios para Escritorio",
            "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/af48c0d2cf0ffa2da4d721586ca3f16b-CP.png",
            "parentId": "9cb0352b759d35d314c1df71f1284440",
            "subCategories": [
              {
                "id": "795be88d86fbc96bede7c6ad28c46b9e",
                "title": "Sellos, Cojines y Tintas de Sello",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Sellos-Cojines-y-Tintas-de-Sello/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/795be88d86fbc96bede7c6ad28c46b9e-CP.png",
                "parentId": "af48c0d2cf0ffa2da4d721586ca3f16b",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "af48c0d2cf0ffa2da4d721586ca3f16b": {
                    "id": null,
                    "title": "Accesorios para Escritorio",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "58f430b319254955377050338f5cd16c",
                "title": "Foliadores",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Foliadores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/58f430b319254955377050338f5cd16c-CP.png",
                "parentId": "af48c0d2cf0ffa2da4d721586ca3f16b",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "af48c0d2cf0ffa2da4d721586ca3f16b": {
                    "id": null,
                    "title": "Accesorios para Escritorio",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "a5478ebd7180f16a01375f453a72e202",
                "title": "Trituradoras de Papel",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Trituradoras-de-Papel/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/a5478ebd7180f16a01375f453a72e202-CP.png",
                "parentId": "af48c0d2cf0ffa2da4d721586ca3f16b",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "af48c0d2cf0ffa2da4d721586ca3f16b": {
                    "id": null,
                    "title": "Accesorios para Escritorio",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "830c434d013597813cde5acd44e7b2c4",
                "title": "Banderitas Adhesivas",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Banderitas-Adhesivas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/830c434d013597813cde5acd44e7b2c4-CP.png",
                "parentId": "af48c0d2cf0ffa2da4d721586ca3f16b",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "af48c0d2cf0ffa2da4d721586ca3f16b": {
                    "id": null,
                    "title": "Accesorios para Escritorio",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "a2550be599154814245dacc18d6641d0",
                "title": "Notas Adhesivas",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Notas-Adhesivas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/a2550be599154814245dacc18d6641d0-CP.png",
                "parentId": "af48c0d2cf0ffa2da4d721586ca3f16b",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "af48c0d2cf0ffa2da4d721586ca3f16b": {
                    "id": null,
                    "title": "Accesorios para Escritorio",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "4c4d16aef0c4672764b461424aa31af5",
                "title": "Tablas con Clip",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Tablas-con-Clip/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/4c4d16aef0c4672764b461424aa31af5-CP.png",
                "parentId": "af48c0d2cf0ffa2da4d721586ca3f16b",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "af48c0d2cf0ffa2da4d721586ca3f16b": {
                    "id": null,
                    "title": "Accesorios para Escritorio",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "ae7331ed6a0aa82deccc6180273c1aa3",
                "title": "Detectora de Billetes Falsos",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Detectora-de-Billetes-Falsos/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/ae7331ed6a0aa82deccc6180273c1aa3-CP.png",
                "parentId": "af48c0d2cf0ffa2da4d721586ca3f16b",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "af48c0d2cf0ffa2da4d721586ca3f16b": {
                    "id": null,
                    "title": "Accesorios para Escritorio",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "f7ff0b4110b3140ad36f5392d4a9fc09",
                "title": "Engrapadoras",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Engrapadoras/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/f7ff0b4110b3140ad36f5392d4a9fc09-CP.png",
                "parentId": "af48c0d2cf0ffa2da4d721586ca3f16b",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "af48c0d2cf0ffa2da4d721586ca3f16b": {
                    "id": null,
                    "title": "Accesorios para Escritorio",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "55be3d661d6d8620f9ce5d665ef55ba5",
                "title": "Calculadoras",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Calculadoras/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/55be3d661d6d8620f9ce5d665ef55ba5-CP.png",
                "parentId": "af48c0d2cf0ffa2da4d721586ca3f16b",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "af48c0d2cf0ffa2da4d721586ca3f16b": {
                    "id": null,
                    "title": "Accesorios para Escritorio",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "89d10bca3f39becbe0f9e54bcc54bdf4",
                "title": "Despachadores de Cinta",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Despachadores-de-Cinta/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/89d10bca3f39becbe0f9e54bcc54bdf4-CP.png",
                "parentId": "af48c0d2cf0ffa2da4d721586ca3f16b",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "af48c0d2cf0ffa2da4d721586ca3f16b": {
                    "id": null,
                    "title": "Accesorios para Escritorio",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "27b0eb7a95df3865c454c75124de5d5e",
                "title": "Desengrapadoras",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Desengrapadoras/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/27b0eb7a95df3865c454c75124de5d5e-CP.png",
                "parentId": "af48c0d2cf0ffa2da4d721586ca3f16b",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "af48c0d2cf0ffa2da4d721586ca3f16b": {
                    "id": null,
                    "title": "Accesorios para Escritorio",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "3f8737a5d442176da508ddc036d8f9b7",
                "title": "Organizadores de Escritorio",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Organizadores-de-Escritorio/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/3f8737a5d442176da508ddc036d8f9b7-CP.png",
                "parentId": "af48c0d2cf0ffa2da4d721586ca3f16b",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/?force_sid=6k76gqvvg00vmqtn24udc3d379",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/?force_sid=6k76gqvvg00vmqtn24udc3d379",
                    "image": null,
                    "stock": 0
                  },
                  "af48c0d2cf0ffa2da4d721586ca3f16b": {
                    "id": null,
                    "title": "Accesorios para Escritorio",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/?force_sid=6k76gqvvg00vmqtn24udc3d379",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "e5dc1aa0669b44a176ef9eedc7a3b604",
                "title": "Tarjeteros",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/Tarjeteros/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/e5dc1aa0669b44a176ef9eedc7a3b604-CP.png",
                "parentId": "af48c0d2cf0ffa2da4d721586ca3f16b",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "af48c0d2cf0ffa2da4d721586ca3f16b": {
                    "id": null,
                    "title": "Accesorios para Escritorio",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Accesorios-para-Escritorio/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "9cb0352b759d35d314c1df71f1284440": {
                "id": null,
                "title": "Artículos de Oficina",
                "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "3cd0674fee3bf6b1e8bce86eae090926",
            "title": "Muebles",
            "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Muebles/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/3cd0674fee3bf6b1e8bce86eae090926-CP.png",
            "parentId": "9cb0352b759d35d314c1df71f1284440",
            "subCategories": [
              {
                "id": "89e8c8c276fc405fc86f3153a12eb1ef",
                "title": "Pizarrones y Pantallas de Tripie",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Muebles/Pizarrones-y-Pantallas-de-Tripie/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/89e8c8c276fc405fc86f3153a12eb1ef-CP.png",
                "parentId": "3cd0674fee3bf6b1e8bce86eae090926",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "3cd0674fee3bf6b1e8bce86eae090926": {
                    "id": null,
                    "title": "Muebles",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Muebles/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "d6ccbe477795d9a37ae724da0970e68f",
                "title": "Archiveros",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Muebles/Archiveros/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/d6ccbe477795d9a37ae724da0970e68f-CP.png",
                "parentId": "3cd0674fee3bf6b1e8bce86eae090926",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "3cd0674fee3bf6b1e8bce86eae090926": {
                    "id": null,
                    "title": "Muebles",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Muebles/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "64e3a3567b8190329cf99485e92a40cb",
                "title": "Sillas",
                "link": "https://www.cyberpuerta.mx/Hogar/Muebles/Sillas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/64e3a3567b8190329cf99485e92a40cb-CP.png",
                "parentId": "3cd0674fee3bf6b1e8bce86eae090926",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "3cd0674fee3bf6b1e8bce86eae090926": {
                    "id": null,
                    "title": "Muebles",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Muebles/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "e6b8bd40487c2650693b3cbebffd5cf0",
                "title": "Estaciones de Trabajo",
                "link": "https://www.cyberpuerta.mx/Computo-Hardware/Monitores/Estaciones-de-Trabajo-de-Pie/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/e6b8bd40487c2650693b3cbebffd5cf0-CP.png",
                "parentId": "3cd0674fee3bf6b1e8bce86eae090926",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "3cd0674fee3bf6b1e8bce86eae090926": {
                    "id": null,
                    "title": "Muebles",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Muebles/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "ce3ed519fc785bc432ba081b23f88785",
                "title": "Mesas y Escritorios",
                "link": "https://www.cyberpuerta.mx/Hogar/Muebles/Mesas-y-Escritorios/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/ce3ed519fc785bc432ba081b23f88785-CP.png",
                "parentId": "3cd0674fee3bf6b1e8bce86eae090926",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "3cd0674fee3bf6b1e8bce86eae090926": {
                    "id": null,
                    "title": "Muebles",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Muebles/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "03fe9db6d5c12e62170e4195817670d2",
                "title": "Estantes y Repisas",
                "link": "https://www.cyberpuerta.mx/Hogar/Muebles/Estantes-y-Repisas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/03fe9db6d5c12e62170e4195817670d2-CP.png",
                "parentId": "3cd0674fee3bf6b1e8bce86eae090926",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "3cd0674fee3bf6b1e8bce86eae090926": {
                    "id": null,
                    "title": "Muebles",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Muebles/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "d9522b895042145a697566b5cdcadd92",
                "title": "Dispensadores de Agua",
                "link": "https://www.cyberpuerta.mx/Hogar/Electrodomesticos-de-Cocina/Dispensadores-de-Agua/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/d9522b895042145a697566b5cdcadd92-CP.png",
                "parentId": "3cd0674fee3bf6b1e8bce86eae090926",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "3cd0674fee3bf6b1e8bce86eae090926": {
                    "id": null,
                    "title": "Muebles",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Muebles/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "9cb0352b759d35d314c1df71f1284440": {
                "id": null,
                "title": "Artículos de Oficina",
                "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          },
          {
            "id": "1ca6c87270ef97e579d000d00cc03ec9",
            "title": "Artículos de Limpieza",
            "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/",
            "image": "https://cdn.cyberpuerta.mx/catalog/images/category/1ca6c87270ef97e579d000d00cc03ec9-CP.png",
            "parentId": "9cb0352b759d35d314c1df71f1284440",
            "subCategories": [
              {
                "id": "1a7c5f864b8dbcbd51eb38ac618eb60f",
                "title": "Cubrebocas",
                "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Cubrebocas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/1a7c5f864b8dbcbd51eb38ac618eb60f-CP.png",
                "parentId": "1ca6c87270ef97e579d000d00cc03ec9",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "1ca6c87270ef97e579d000d00cc03ec9": {
                    "id": null,
                    "title": "Artículos de Limpieza",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "33dfd2e52aa9095d206f3e443a1b752d",
                "title": "Jarcería",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/Jarceria/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/33dfd2e52aa9095d206f3e443a1b752d-CP.png",
                "parentId": "1ca6c87270ef97e579d000d00cc03ec9",
                "subCategories": [
                  {
                    "id": "77c00814b6c507dac05017da82af1993",
                    "title": "Cestos y Basureros",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/Jarceria/Cestos-y-Basureros/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/77c00814b6c507dac05017da82af1993-CP.png",
                    "parentId": "33dfd2e52aa9095d206f3e443a1b752d",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "1ca6c87270ef97e579d000d00cc03ec9": {
                        "id": null,
                        "title": "Artículos de Limpieza",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/",
                        "image": null,
                        "stock": 0
                      },
                      "33dfd2e52aa9095d206f3e443a1b752d": {
                        "id": null,
                        "title": "Jarcería",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/Jarceria/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "1ca6c87270ef97e579d000d00cc03ec9": {
                    "id": null,
                    "title": "Artículos de Limpieza",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "be39182d3358895913200428a25a10de",
                "title": "Despachadores",
                "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/Despachadores/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/be39182d3358895913200428a25a10de-CP.png",
                "parentId": "1ca6c87270ef97e579d000d00cc03ec9",
                "subCategories": [
                  {
                    "id": "2005340b3f59b0e936b0bc2ddfea806c",
                    "title": "Papel",
                    "link": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/Despachadores/Papel/",
                    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/2005340b3f59b0e936b0bc2ddfea806c-CP.png",
                    "parentId": "be39182d3358895913200428a25a10de",
                    "subCategories": [],
                    "path": {
                      "5e8f92ef7a3a3": {
                        "id": null,
                        "title": "Home Office",
                        "url": "https://www.cyberpuerta.mx/Home-Office/",
                        "image": null,
                        "stock": 0
                      },
                      "9cb0352b759d35d314c1df71f1284440": {
                        "id": null,
                        "title": "Artículos de Oficina",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                        "image": null,
                        "stock": 0
                      },
                      "1ca6c87270ef97e579d000d00cc03ec9": {
                        "id": null,
                        "title": "Artículos de Limpieza",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/",
                        "image": null,
                        "stock": 0
                      },
                      "be39182d3358895913200428a25a10de": {
                        "id": null,
                        "title": "Despachadores",
                        "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/Despachadores/",
                        "image": null,
                        "stock": 0
                      }
                    },
                    "hasSubCategories": false
                  }
                ],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "1ca6c87270ef97e579d000d00cc03ec9": {
                    "id": null,
                    "title": "Artículos de Limpieza",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": true
              },
              {
                "id": "c28869d430b0031fd79cf62727fae318",
                "title": "Caretas",
                "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Caretas/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/c28869d430b0031fd79cf62727fae318-CP.png",
                "parentId": "1ca6c87270ef97e579d000d00cc03ec9",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "1ca6c87270ef97e579d000d00cc03ec9": {
                    "id": null,
                    "title": "Artículos de Limpieza",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "d6b5ae90f0e3452da6ad0ba9a5d51c9b",
                "title": "Tapetes Sanitizantes",
                "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Tapetes-Sanitizantes/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/d6b5ae90f0e3452da6ad0ba9a5d51c9b-CP.png",
                "parentId": "1ca6c87270ef97e579d000d00cc03ec9",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "1ca6c87270ef97e579d000d00cc03ec9": {
                    "id": null,
                    "title": "Artículos de Limpieza",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "68ef7ef9396ff93c6c87d2fcdb5e0743",
                "title": "Desinfectantes y Sanitizantes",
                "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Desinfectantes-y-Sanitizantes/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/68ef7ef9396ff93c6c87d2fcdb5e0743-CP.png",
                "parentId": "1ca6c87270ef97e579d000d00cc03ec9",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "1ca6c87270ef97e579d000d00cc03ec9": {
                    "id": null,
                    "title": "Artículos de Limpieza",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "d287ebc6b9e588ad5f6a04b5fddf4976",
                "title": "Gel Antibacterial",
                "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Gel-Antibacterial/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/d287ebc6b9e588ad5f6a04b5fddf4976-CP.png",
                "parentId": "1ca6c87270ef97e579d000d00cc03ec9",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "1ca6c87270ef97e579d000d00cc03ec9": {
                    "id": null,
                    "title": "Artículos de Limpieza",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              },
              {
                "id": "6f2a6abd9469ccf9eb5cda9b7a03be65",
                "title": "Guantes de Látex",
                "link": "https://www.cyberpuerta.mx/Hogar/Cuidado-Personal/Guantes-de-Latex/",
                "image": "https://cdn.cyberpuerta.mx/catalog/images/category/6f2a6abd9469ccf9eb5cda9b7a03be65-CP.png",
                "parentId": "1ca6c87270ef97e579d000d00cc03ec9",
                "subCategories": [],
                "path": {
                  "5e8f92ef7a3a3": {
                    "id": null,
                    "title": "Home Office",
                    "url": "https://www.cyberpuerta.mx/Home-Office/",
                    "image": null,
                    "stock": 0
                  },
                  "9cb0352b759d35d314c1df71f1284440": {
                    "id": null,
                    "title": "Artículos de Oficina",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                    "image": null,
                    "stock": 0
                  },
                  "1ca6c87270ef97e579d000d00cc03ec9": {
                    "id": null,
                    "title": "Artículos de Limpieza",
                    "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/Articulos-de-Limpieza/",
                    "image": null,
                    "stock": 0
                  }
                },
                "hasSubCategories": false
              }
            ],
            "path": {
              "5e8f92ef7a3a3": {
                "id": null,
                "title": "Home Office",
                "url": "https://www.cyberpuerta.mx/Home-Office/",
                "image": null,
                "stock": 0
              },
              "9cb0352b759d35d314c1df71f1284440": {
                "id": null,
                "title": "Artículos de Oficina",
                "url": "https://www.cyberpuerta.mx/Home-Office/Articulos-de-Oficina/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": true
          }
        ],
        "path": {
          "5e8f92ef7a3a3": {
            "id": null,
            "title": "Home Office",
            "url": "https://www.cyberpuerta.mx/Home-Office/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      }
    ],
    "path": [],
    "hasSubCategories": true
  },
  {
    "id": "25994254d5ae4945e61d0654dcf04059",
    "title": "Software y Servicios",
    "link": "https://www.cyberpuerta.mx/Software-y-Servicios/",
    "image": "https://cdn.cyberpuerta.mx/catalog/images/category/25994254d5ae4945e61d0654dcf04059-CP.png",
    "parentId": "",
    "subCategories": [
      {
        "id": "646b35415c2c789c9672d9ee03787ed1",
        "title": "Sistemas Operativos",
        "link": "https://www.cyberpuerta.mx/Software-y-Servicios/Sistemas-Operativos/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sistemas-operativos.png",
        "parentId": "25994254d5ae4945e61d0654dcf04059",
        "subCategories": [],
        "path": {
          "25994254d5ae4945e61d0654dcf04059": {
            "id": null,
            "title": "Software y Servicios",
            "url": "https://www.cyberpuerta.mx/Software-y-Servicios/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "5942c3a5a0bfe",
        "title": "Sistemas Operativos para Servidores",
        "link": "https://www.cyberpuerta.mx/Software-y-Servicios/Sistemas-Operativos-para-Servidores/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/sistemas-operativos-para-servidores.png",
        "parentId": "25994254d5ae4945e61d0654dcf04059",
        "subCategories": [],
        "path": {
          "25994254d5ae4945e61d0654dcf04059": {
            "id": null,
            "title": "Software y Servicios",
            "url": "https://www.cyberpuerta.mx/Software-y-Servicios/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "646c7abc9c587e9005fd3efcf0a75172",
        "title": "Microsoft Office",
        "link": "https://www.cyberpuerta.mx/Software-y-Servicios/Microsoft-Office/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/microsoft-office.png",
        "parentId": "25994254d5ae4945e61d0654dcf04059",
        "subCategories": [],
        "path": {
          "25994254d5ae4945e61d0654dcf04059": {
            "id": null,
            "title": "Software y Servicios",
            "url": "https://www.cyberpuerta.mx/Software-y-Servicios/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "619d7f13a5d91",
        "title": "Microsoft 365",
        "link": "https://www.cyberpuerta.mx/Software-y-Servicios/Microsoft-365/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/microsoft-365.png",
        "parentId": "25994254d5ae4945e61d0654dcf04059",
        "subCategories": [],
        "path": {
          "25994254d5ae4945e61d0654dcf04059": {
            "id": null,
            "title": "Software y Servicios",
            "url": "https://www.cyberpuerta.mx/Software-y-Servicios/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "6466a761aa3fc983d41988056c027d7b",
        "title": "Antivirus",
        "link": "https://www.cyberpuerta.mx/Software-y-Servicios/Antivirus/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/seguridad.png",
        "parentId": "25994254d5ae4945e61d0654dcf04059",
        "subCategories": [],
        "path": {
          "25994254d5ae4945e61d0654dcf04059": {
            "id": null,
            "title": "Software y Servicios",
            "url": "https://www.cyberpuerta.mx/Software-y-Servicios/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "59a74cb08b219",
        "title": "Videojuegos",
        "link": "https://www.cyberpuerta.mx/Software-y-Servicios/Videojuegos/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/videojuegos-main.png",
        "parentId": "25994254d5ae4945e61d0654dcf04059",
        "subCategories": [
          {
            "id": "686d1c60211a49bb4ee8cc9f7bada530",
            "title": "Juegos Xbox One",
            "link": "https://www.cyberpuerta.mx/Juegos-Xbox-One/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/videojuegos-xboxone.png",
            "parentId": "59a74cb08b219",
            "subCategories": [],
            "path": {
              "25994254d5ae4945e61d0654dcf04059": {
                "id": null,
                "title": "Software y Servicios",
                "url": "https://www.cyberpuerta.mx/Software-y-Servicios/",
                "image": null,
                "stock": 0
              },
              "59a74cb08b219": {
                "id": null,
                "title": "Videojuegos",
                "url": "https://www.cyberpuerta.mx/Software-y-Servicios/Videojuegos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "42d278d6bfda5f26fb4968b2d079ed98",
            "title": "Juegos Xbox 360",
            "link": "https://www.cyberpuerta.mx/Juegos-Xbox-360/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/videojuegos-xbox360.png",
            "parentId": "59a74cb08b219",
            "subCategories": [],
            "path": {
              "25994254d5ae4945e61d0654dcf04059": {
                "id": null,
                "title": "Software y Servicios",
                "url": "https://www.cyberpuerta.mx/Software-y-Servicios/",
                "image": null,
                "stock": 0
              },
              "59a74cb08b219": {
                "id": null,
                "title": "Videojuegos",
                "url": "https://www.cyberpuerta.mx/Software-y-Servicios/Videojuegos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "814120ed00f8647845f1cde741152d20",
            "title": "Complementos para Videojuegos",
            "link": "https://www.cyberpuerta.mx/Audio-y-Video/Consolas-y-Juegos/Complementos-para-Videojuegos/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/complementos-videojuegos.png",
            "parentId": "59a74cb08b219",
            "subCategories": [],
            "path": {
              "25994254d5ae4945e61d0654dcf04059": {
                "id": null,
                "title": "Software y Servicios",
                "url": "https://www.cyberpuerta.mx/Software-y-Servicios/",
                "image": null,
                "stock": 0
              },
              "59a74cb08b219": {
                "id": null,
                "title": "Videojuegos",
                "url": "https://www.cyberpuerta.mx/Software-y-Servicios/Videojuegos/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "25994254d5ae4945e61d0654dcf04059": {
            "id": null,
            "title": "Software y Servicios",
            "url": "https://www.cyberpuerta.mx/Software-y-Servicios/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      },
      {
        "id": "59a717e27b339",
        "title": "Respaldos y Utilidades",
        "link": "https://www.cyberpuerta.mx/Software-y-Servicios/Respaldos-y-Utilidades/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/respaldos-utilidades.png",
        "parentId": "25994254d5ae4945e61d0654dcf04059",
        "subCategories": [],
        "path": {
          "25994254d5ae4945e61d0654dcf04059": {
            "id": null,
            "title": "Software y Servicios",
            "url": "https://www.cyberpuerta.mx/Software-y-Servicios/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": false
      },
      {
        "id": "5c704922c0b9f",
        "title": "Licencias y Garantías",
        "link": "https://www.cyberpuerta.mx/Software-y-Servicios/Licencias-y-Garantias/",
        "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/garantias-1.png",
        "parentId": "25994254d5ae4945e61d0654dcf04059",
        "subCategories": [
          {
            "id": "5c70495f54174",
            "title": "Licencias de Aplicación de Software",
            "link": "https://www.cyberpuerta.mx/Software-y-Servicios/Licencias-y-Garantias/Licencias-de-Aplicacion-de-Software/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/1.png",
            "parentId": "5c704922c0b9f",
            "subCategories": [],
            "path": {
              "25994254d5ae4945e61d0654dcf04059": {
                "id": null,
                "title": "Software y Servicios",
                "url": "https://www.cyberpuerta.mx/Software-y-Servicios/",
                "image": null,
                "stock": 0
              },
              "5c704922c0b9f": {
                "id": null,
                "title": "Licencias y Garantías",
                "url": "https://www.cyberpuerta.mx/Software-y-Servicios/Licencias-y-Garantias/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          },
          {
            "id": "531e2ee390d93",
            "title": "Garantías",
            "link": "https://www.cyberpuerta.mx/Software-y-Servicios/Licencias-y-Garantias/Garantias/",
            "image": "https://www.cyberpuerta.mx/out/pictures/master/category/icon/garantias-6.png",
            "parentId": "5c704922c0b9f",
            "subCategories": [],
            "path": {
              "25994254d5ae4945e61d0654dcf04059": {
                "id": null,
                "title": "Software y Servicios",
                "url": "https://www.cyberpuerta.mx/Software-y-Servicios/",
                "image": null,
                "stock": 0
              },
              "5c704922c0b9f": {
                "id": null,
                "title": "Licencias y Garantías",
                "url": "https://www.cyberpuerta.mx/Software-y-Servicios/Licencias-y-Garantias/",
                "image": null,
                "stock": 0
              }
            },
            "hasSubCategories": false
          }
        ],
        "path": {
          "25994254d5ae4945e61d0654dcf04059": {
            "id": null,
            "title": "Software y Servicios",
            "url": "https://www.cyberpuerta.mx/Software-y-Servicios/",
            "image": null,
            "stock": 0
          }
        },
        "hasSubCategories": true
      }
    ],
    "path": [],
    "hasSubCategories": true
  }
]
export type Categories = typeof categories
export const storeRatings = {
  "playStore": {
    "rating": "4.7",
    "numberOfReviews": 10538
  },
  "appStore": {
    "rating": "4.71429",
    "numberOfReviews": 42
  }
}
export type StoreRatings = typeof storeRatings
