import {accountPaths, paymentMethodPaths, basketPaths, paypalPaths} from '~/utils/services/paths';
import type {IPaymentMethod, IPaymentInstallmentsData, IRememberedCard, ICalc, IPaypalInstallmentsMSI} from '~/types';
import {$cpFetch} from '~/composables/useCpFetch';
import {useCpUser} from '~/composables/useCpUser';

export interface IPaypalBillingAgreementResponse {
    create_time: string;
    description: string;
    id: string;
    links: Array<{
        href: string;
        method: string;
        rel: string;
    }>;
    merchant: {
        payee_info: {
            email: string;
        };
    };
    payer: {
        payer_info: {
            email: string;
            first_name: string;
            last_name: string;
            payer_id: string;
            tenant: string;
        };
    };
    plan: {
        merchant_preferences: {
            accepted_pymt_types: string;
            multi_factor_activation: boolean;
        };
    };
    state: string;
    update_time: string;
}

type paymentMethodResponse = {
    status: string
    msg: string
    paypalUrl: string | null
}

// Is responding 201 always, so we'll catch both responses
type createPaypalBillingAgreementResponse = {
    id?: string
    status?: string
    msg?: string
}
type transactionContextResponse = {
    status: string
    msg: string
}
export interface IPpcpPaymentInfoRequestParams {
    id: string;
    month: string;
    type: string;
}

interface IPpcpPaymentInfoResponsePayload {
    id: string,
    status: string,
    payment_source: {
        card: {
            brand: string,
            last_digits: string,
            verification_status: string,
        }
    },
    links: Array<{
        rel: string,
        href: string,
        method: string,
    }>,
}

interface IPaypalPaymentSettings {
    clientId: string
    debug: boolean
    token: string
    riskId: string
    flowId: string
}

interface IPaypalOrderCreateInfo {
    data: {
        id: string,
        status: string,
    }
}

export const getPayPalBillingAgreements = async () => {
    return await $cpFetch<{ data: Array<IPaypalBillingAgreementResponse>}>(accountPaths.paypalBillingAgreement(useCpUser().userEmail!));
};

export const createPaypalBillingAgreement = async (token: string) => {
    return await $cpFetch<createPaypalBillingAgreementResponse>(accountPaths.paypalBillingAgreement(useCpUser().userEmail!), {
        method: 'POST',
        parseResponse: (txt) => (txt === '' ? '' : JSON.parse(txt)),
        body: {
            ba_token: token,
        },
    });
};

export const deletePayPalBillingAgreements = async (userId: string, agreementID: string) => {
    return await $cpFetch(accountPaths.deletePaypalBillingAgreement(userId, agreementID), {
        method: 'DELETE',
    });
};

export const getPaymentMethods = async () => {
    return await $cpFetch<Array<IPaymentMethod>>(paymentMethodPaths.all);
};

export const validatePaymentMethod = async (paypalCallbackUrl?: string) => {
    let body = {};
    if (paypalCallbackUrl) {
 body = {paypalCallbackUrl};
}
    return await $cpFetch<paymentMethodResponse>(paymentMethodPaths.validate(), {
        method: 'POST',
        parseResponse: (txt) => (txt === '' ? '' : JSON.parse(txt)),
        body,
    });
};

export const getPaypalInstallments = (metaId: string, cardId?: string) => {
    return $cpFetch<{data: IPaymentInstallmentsData}>(paypalPaths.paypalInstallments, {
    params: {
        meta_id: metaId,
        ...cardId ? {id: cardId} : {},
    },
    });
};

export const setPaypalMonthInstallment = (month: number, metaId: string) => {
    return $cpFetch<IPaymentInstallmentsData>(basketPaths.paypalInstallments(), {
        method: 'POST',
        parseResponse: (txt) => (txt === '' ? '' : JSON.parse(txt)),
        params: {
            meta_id: metaId,
        },
        body: {
            month,
        },
    });
};

export const getRememberedCards = (email: string, metaId: string) => $cpFetch<{data: Array<IRememberedCard>}>(
    accountPaths.paypalRememberedCards(email), {
        params: {
            meta_id: metaId,
        },
    });

export const getRememberedCardById = (email: string, metaId: string, cardId: string) => $cpFetch<{data: IRememberedCard}>(
    accountPaths.paypalRememberedCardById(email, cardId), {
        params: {
            meta_id: metaId,
        },
});
export const deleteRememberedCard = (email: string, cardId: string, metaId: string) => $cpFetch(
    accountPaths.paypalDeleteRememberedCard(email, cardId), {
    method: 'DELETE',
    params: {
        meta_id: metaId,
    }});

export const createRememberedCard = (email: string, payload: {number: string, }) =>
    $cpFetch<{data: Array<IRememberedCard>}>(accountPaths.paypalCreateRememberedCard(email), {
    method: 'POST',
    body: payload,
});

export const setTransactionContext = (metaId: string) => {
    return $cpFetch<transactionContextResponse>(paypalPaths.paypalTransactionContext, {
        method: 'POST',
        params: {
            meta_id: metaId,
        },
        parseResponse: (txt) => (txt === '' ? '' : JSON.parse(txt)),
    });
};

export const getPaypalPpcpPaymentInfo = (orderId: string, metaId: string) => {
    return $cpFetch<{data: IPpcpPaymentInfoResponsePayload}>(paypalPaths.paypalPaymentInfo(orderId), {
        params: {
            meta_id: metaId,
        },
    });
};

export const getPaypalPaymentSettings = (metaId: string) => {
    return $cpFetch<{data: IPaypalPaymentSettings}>(paypalPaths.paypalSettings, {
        method: 'GET',
        params: {
            meta_id: metaId,
        },
    });
};

export const getPaypalFraudnetInfo = () => {
    return $cpFetch<{data: {riskId: string, flowId: string}}>(paypalPaths.paypalFraudnet, {
        method: 'GET',
    });
};

export const createOrderPaypalPpcp = async (metaId: string) => {
    const data = await $cpFetch<IPaypalOrderCreateInfo>(paypalPaths.paypalCreateOrder, {
        method: 'POST',
        params: {
            meta_id: metaId,
        },
    });
    return data.data.id;
};


export const getPaypalInstallmentsMSI = (total: number, isCheckout = false) => {
    let params = {total};
    if (isCheckout) params = {...params, ...{isCheckout: true}};
    return $cpFetch<IPaypalInstallmentsMSI>(paypalPaths.installments, {
        params: params,
    });
};
